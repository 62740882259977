import { FormEvent, useState } from 'react';

import global from 'theme/global/Global.module.scss';
import styles from './AiAccess.module.scss';

import { TopBarLayout } from 'template/topBarLayout/TopBarLayout.template';

import { miscApi } from 'services/api/misc.api';
import { commonUtils } from 'helpers/utilities/common.util';
import { miscValidations } from 'helpers/validation/misc.validation';

import { AiAccessInputInterface } from 'interfaces/misc.interface';

import { AiAccessTopBar } from './components/aiAccessTopBar/AiAccessTopBar';
import { AiAccessDetails } from './components/aiAccessDetails/AiAccessDetails';

export const AiAccess = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [inputDetails, setInputDetails] = useState<AiAccessInputInterface>({ value: '' });
    const [aiAccessDetails, setAiAccessDetails] = useState<string[]>([]);

    const inputOnChangeHandler = (value: string) => {
        setInputDetails((prevState) => ({ ...prevState, value: value.toLowerCase().trim(), error: '' }));
    };

    const grantAiAccessHandler = async () => {
        setIsLoading(true);

        const emailString = commonUtils.trimCommaAndSpaceSeparatedString(inputDetails.value).toString();
        const aiAccessStatus = await miscApi.aiAccess(emailString);
        setIsLoading(false);

        if (aiAccessStatus !== 'Success') {
            return setInputDetails((prevState) => ({ ...prevState, error: 'Error in granting AI Access' }));
        } else {
            const aiAccessEmailArray = aiAccessDetails;
            const emailArray = commonUtils.trimCommaAndSpaceSeparatedString(inputDetails.value);
            emailArray.map((email) => (aiAccessEmailArray.includes(email) ? null : aiAccessEmailArray.push(email)));
            setAiAccessDetails(aiAccessEmailArray);
        }
    };

    const validateDetails = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const isFormDetailsValid = miscValidations.grantAiAccessFormValidation(inputDetails.value);
        if (isFormDetailsValid) setInputDetails((prevState) => ({ ...prevState, error: isFormDetailsValid }));
        else grantAiAccessHandler();
    };

    return (
        <div className={`${global.componentWrapper} ${styles.searchKeyComponent}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    <TopBarLayout
                        topBar={
                            <AiAccessTopBar
                                inputDetails={inputDetails}
                                inputOnChangeHandler={inputOnChangeHandler}
                                isLoading={isLoading}
                                validateDetails={validateDetails}
                            />
                        }
                        content={<AiAccessDetails aiAccessDetails={aiAccessDetails} />}
                    />
                </div>
            </div>
        </div>
    );
};
