import { startCase } from 'lodash';

import { cellUtils } from 'helpers/utilities/cell.util';

import { SubscribeLinkDetailsInterface } from 'interfaces/misc.interface';

export const generateSubscribeLinkCells = (linkDetails: SubscribeLinkDetailsInterface) => {
    const cell = cellUtils.tableCell;

    return {
        coupon: cell('Coupon', linkDetails.coupon),
        link: cell('Link', linkDetails.link),
        hiddenCouponLink: cell('Hidden Coupon Link', linkDetails.hiddenCouponLink),
        discountBy: cell('Discount By', startCase(linkDetails.discountBy)),
        discountValue: cell('Discount Value', linkDetails.discountValue),
    };
};
