import { ReactNode } from 'react';

import { styled, Tabs } from '@mui/material';

export const CustomTab = styled(Tabs)({
    '.MuiTabs-scroller': {
        '.MuiTabs-flexContainer': {
            '.MuiButtonBase-root': {
                '&.Mui-selected': {
                    color: 'var(--clr_text_info)',
                },
            },
        },

        '.MuiTabs-indicator': {
            height: 2,
            background: 'var(--clr_text_info)',
        },
    },
});

interface TabPanelProps {
    index: number | string;
    value: number | string;
    className: string;
    children?: ReactNode;
}

export const TabPanel = ({ index, value, className, children }: TabPanelProps) => {
    return (
        <div hidden={value !== index} className={`${className || ''}`}>
            {value === index && children}
        </div>
    );
};
