import { useRef } from 'react';
import dayjs from 'dayjs';
import { startCase } from 'lodash';

import { TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { DateTimeInput } from 'theme/mui/dateTimePicker.theme';

import { commonUtils } from 'helpers/utilities/common.util';

interface DateTimeInterface {
    value: string;
    inputOnChangeHandler: (value: string, fieldName?: string) => void;
    error?: boolean;
    fieldName?: string;
    label?: string;
    isRequired?: boolean;
    minDateTime?: string;
    maxDateTime?: string;
    showOnlyValue?: boolean;
    disableMinute?: boolean;
    className?: string;
}

export const DateTimeModule = ({
    value,
    inputOnChangeHandler,
    error,
    fieldName,
    label,
    isRequired,
    minDateTime,
    maxDateTime,
    showOnlyValue,
    disableMinute,
    className,
}: DateTimeInterface) => {
    const dateTimeEvent = useRef();

    const determineDateTimeHandler = () => {
        const pickedTime = dayjs(dateTimeEvent.current).toISOString();
        fieldName ? inputOnChangeHandler(`${pickedTime}`, fieldName) : inputOnChangeHandler(`${pickedTime}`);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimeInput
                onChange={(event: any) => (dateTimeEvent.current = event)}
                onClose={() => determineDateTimeHandler()}
                value={value || null}
                openTo="month"
                views={disableMinute ? ['year', 'month', 'day', 'hours'] : ['year', 'month', 'day', 'hours', 'minutes']}
                renderInput={(params) => <TextField {...params} error={error} />}
                label={commonUtils.starMarked(label || startCase(fieldName || ''), isRequired)}
                minDateTime={minDateTime ? dayjs(minDateTime) : undefined}
                maxDateTime={maxDateTime ? dayjs(maxDateTime) : undefined}
                inputFormat={disableMinute ? 'YYYY/MM/DD  |  HH' : 'YYYY/MM/DD  |  HH:mm'}
                className={`${isRequired ? 'isRequired' : ''} ${showOnlyValue ? 'showOnlyValue' : ''} ${
                    className || ''
                }`}
            />
        </LocalizationProvider>
    );
};
