import { orderBy } from 'lodash';

import { HiOutlineUserPlus } from 'react-icons/hi2';
import { IoMdGlobe } from 'react-icons/io';

import styles from './CctFilterTopBar.module.scss';

import { InputFieldsInterface } from 'interfaces/ui/inputFields.interface';
import { DATA_CONSTANT } from 'constants/data/data.constants';

export const filterInputFieldsData = (): InputFieldsInterface[] => {
    return [
        {
            fieldName: 'search',
            fieldClassName: `${styles.inputField}`,
        },
        {
            fieldName: 'country',
            fieldClassName: `${styles.inputField}`,
        },
        {
            fieldName: 'status',
            fieldType: 'select',
            multipleFields: orderBy(
                Object.values(DATA_CONSTANT.OPTIONS.FILTER_CCT).map((data) => {
                    return { value: data };
                }),
                ['value'],
                ['asc'],
            ),
            isMultipleSelect: true,
            fieldClassName: `${styles.selectOption}`,
        },
    ];
};

export const filterInputFieldsIconsArray = {
    email: <HiOutlineUserPlus />,
    country: <IoMdGlobe />,
};
