import { ReactNode, useState } from 'react';

import { BsArrowLeftShort } from 'react-icons/bs';

import Tab from '@mui/material/Tab';

import { CustomTab, TabPanel } from 'theme/mui/tabs.theme';
import { IconBtn } from 'theme/mui/buttons.theme';

import global from 'theme/global/Global.module.scss';
import styles from './TabLayout.template.module.scss';

import { useWindowDimensions } from 'helpers/customHooks/useWindowDimensions.hook';

import { MobileTopBar } from 'components/common/mobileTopBar/MobileTopBar';

interface TabLayoutInterface {
    topBar?: ReactNode;
    tabs:
        | {
              id: number;
              label: string;
              content: ReactNode;
          }[]
        | undefined;
    backBtnOnClickHandler?: any;
    noOuterPadding?: boolean;
    className?: string;
}

export const TabLayout = ({ topBar, tabs, backBtnOnClickHandler, noOuterPadding, className }: TabLayoutInterface) => {
    const windowWidth = useWindowDimensions().width;
    const [activeTabIndex, setActiveTabIndex] = useState(0);

    return (
        <div className={`${global.componentWrapper} ${styles.tabLayoutTemplate} ${className || ''}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    {windowWidth < 768 && windowWidth > 0 && (
                        <div className={`${styles.mobileNav}`}>
                            <MobileTopBar />
                        </div>
                    )}

                    <div
                        className={`${styles.mainContainer} ${!noOuterPadding && styles.defaultPadding} ${
                            windowWidth < 768 && styles.mobileTopBarGap
                        }`}
                    >
                        {topBar && <div className={`${styles.topBarContainer}`}>{topBar}</div>}
                        {tabs && (
                            <div className={`${styles.details}`}>
                                {backBtnOnClickHandler && (
                                    <IconBtn
                                        onClick={() => backBtnOnClickHandler()}
                                        className={`${styles.backBtnContainer} ${
                                            !noOuterPadding && styles.leftPadding
                                        }`}
                                    >
                                        <BsArrowLeftShort className={`${styles.icon}`} />
                                    </IconBtn>
                                )}
                                <CustomTab
                                    value={activeTabIndex}
                                    onChange={(e: any, index: number) => setActiveTabIndex(index)}
                                    variant="scrollable"
                                    className={`${styles.tabChanger} ${!noOuterPadding && styles.leftPadding} ${
                                        backBtnOnClickHandler && styles.addBefore
                                    }`}
                                >
                                    {tabs.map((item) => (
                                        <Tab key={item.label} label={item.label} className={`${styles.tabs}`} />
                                    ))}
                                </CustomTab>

                                <div className={`${styles.tabContentWrapper}`}>
                                    {tabs.map((item) => {
                                        return (
                                            activeTabIndex === item.id && (
                                                <TabPanel
                                                    key={item.id}
                                                    value={activeTabIndex}
                                                    index={activeTabIndex}
                                                    className={`${styles.tabContent}`}
                                                >
                                                    {item.content}
                                                </TabPanel>
                                            )
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
