import axios from 'axios';

import { logger } from '../logger.service';

import {
    ContentCategoryInterface,
    ContentTagInterface,
    CreateContentDataInterface,
    ViewContentResponseInterface,
} from 'interfaces/content.interface';

export const contentApi = {
    getCategories: async (): Promise<ContentCategoryInterface[] | null> => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/contents/categories/all`);
            return response.data;
        } catch (error: any) {
            logger.error('contentApi.getCategories() error: ', error.response || error);
            return null;
        }
    },

    getTags: async (): Promise<ContentTagInterface[] | null> => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/contents/tags/all`);
            return response.data;
        } catch (error: any) {
            logger.error('contentApi.getTags() error: ', error.response || error);
            return null;
        }
    },

    getContent: async (slug: string): Promise<ViewContentResponseInterface | string> => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/contents?slug=${slug}`);
            return response.data;
        } catch (error: any) {
            logger.error('contentApi.getContent() error: ', error.response || error);
            return `Error : ${error.response.data.message}`;
        }
    },

    postContent: async (contentBody: Partial<CreateContentDataInterface>): Promise<string | null> => {
        try {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const response = await axios.post(`${process.env.REACT_APP_API}/contents`, contentBody);
            return null;
        } catch (error: any) {
            logger.error('contentApi.postContent() error: ', error.response || error);
            return `Error : ${error.response.data.message.toString().replaceAll(',', ', ')}`;
        }
    },

    updateContent: async (slug: string, contentBody: Partial<CreateContentDataInterface>): Promise<string | null> => {
        try {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const response = await axios.patch(`${process.env.REACT_APP_API}/contents?slug=${slug}`, contentBody);
            return null;
        } catch (error: any) {
            logger.error('contentApi.updateContent() error: ', error.response || error);
            return `Error : ${error.response.data.message.toString().replaceAll(',', ', ')}`;
        }
    },
};
