import axios from 'axios';

import { logger } from '../logger.service';

import { AdminLoginResponseInterface } from 'interfaces/admin.interface';

export const adminApi = {
    signIn: async (email: string, password: string): Promise<AdminLoginResponseInterface | string> => {
        try {
            const url = `${process.env.REACT_APP_API}/users/sign-in-admin-password`;
            const response = await axios.post(url, {
                email: email,
                password: password,
            });
            return response.data;
        } catch (error: any) {
            logger.error('adminApi.signIn() error: ', error.response || error);
            return `Error : ${error.response.data.message}`;
        }
    },
};
