import { NavigateFunction } from 'react-router-dom';

import { ADMIN_ACTIONS } from 'context/features/adminSlice.redux';
import { encodeValue, decodeValue } from './cryptography.security';
import { securityLS } from 'services/localService/security.local';
import { DATA_CONSTANT } from 'constants/data/data.constants';

export const checkExpiryTime = async (dispatch: any) => {
    const storedExpiryTime = securityLS.getSessionExpiryData();
    if (!storedExpiryTime) return dispatch(ADMIN_ACTIONS.LOGOUT_ADMIN());
    else return storedExpiryTime;
};

export const setNewExpiryDate = (navigate: NavigateFunction) => {
    const newExpiryTime = new Date(Date.now() + DATA_CONSTANT.TIME.AUTO_LOGOUT).toISOString();
    const newEncodedExpiryTime = encodeValue(newExpiryTime, 'cp2');
    securityLS.setSessionExpiryData(newEncodedExpiryTime);
    navigate('/user/details');
};

export const processExpiryTime = async (dispatch: any, navigate: NavigateFunction) => {
    const storedExpiryTime = await checkExpiryTime(dispatch);
    const decodedExpiryTime = decodeValue(storedExpiryTime, 'cp2');
    if (decodedExpiryTime === 'WelcomeToClassPoint') {
        setNewExpiryDate(navigate);
    } else {
        const convertedExpiryTime = Date.parse(decodedExpiryTime);
        if (isNaN(convertedExpiryTime)) dispatch(ADMIN_ACTIONS.LOGOUT_ADMIN());
        else {
            const now = new Date();
            if (now > new Date(decodedExpiryTime)) dispatch(ADMIN_ACTIONS.LOGOUT_ADMIN());
            else setNewExpiryDate(navigate);
        }
    }
};
