import { cellUtils } from 'helpers/utilities/cell.util';

export const searchUsersNotMembersGridData = (
    usersStringArray: string[],
    copyEmailValue: (details: string) => void,
) => {
    let usersString = '';
    for (let user of usersStringArray) {
        if (usersString.length > 0) usersString = `${usersString}<br/>${user}`;
        else usersString = `${user}`;
    }

    return {
        heading: 'ClassPoint Non Users',
        rows: [[cellUtils.tableCell('Email', usersString.replaceAll(',', usersString))]],
        cellProps: {
            Email: { getSelectedValueOnClick: copyEmailValue },
        },
    };
};
