import { FormEvent, useEffect, useRef, useState } from 'react';

import global from 'theme/global/Global.module.scss';
import styles from './WebinarReport.module.scss';

import { TopBarLayout } from 'template/topBarLayout/TopBarLayout.template';
import { CommonModal } from 'template/modal/common/CommonModal.template';
import { MultipleSearchBox } from 'template/searchBox/multiple/MultipleSearchBox.template';
import { InputFields } from 'template/inputFields/InputFields.template';

import { commonUtils } from 'helpers/utilities/common.util';
import { userValidations } from 'helpers/validation/user.validation';
import { webinarReportHelper } from 'helpers/webinarReport.helper';
import { dateTimeUtils } from 'helpers/utilities/dateTime.util';
import { fileUtils } from 'helpers/utilities/file.util';

import {
    CheckUserApiResponseInterface,
    WebinarReportApiResponseInterface,
    WebinarReportFormInterface,
} from 'interfaces/user.interface';
import { CsvUploadFileInputInterface } from 'interfaces/common.interface';

import { WebinarReportDetails } from './components/webinarReportDetails/WebinarReportDetails';
import { WebinarReportTopBar } from './components/webinarReportTopBar/WebinarReportTopBar';
import { SearchUsersDetails } from '../searchUsers/components/searchUsersDetails/SearchUsersDetails';
import { InstantAlert } from 'components/common/instantAlert/InstantAlert';
import { UserDetails } from '../userDetails/UserDetails';
import { webinarDefinition } from './webinarDefination.data';

export const WebinarReport = () => {
    const showNotification = useRef<any>();

    const [isLoading, setIsLoading] = useState(false);
    const [isSelectedUsersDetailsLoading, setIsSelectedUsersDetailsLoading] = useState(false);
    const [showInstantAlert, setShowInstantAlert] = useState(false);
    const [isCsvModalOpen, setIsCsvModalOpen] = useState(false);
    const [webinarUserReport, setWebinarUserReport] = useState<WebinarReportApiResponseInterface[]>();
    const [selectedUsersDetails, setSelectedUsersDetails] = useState<CheckUserApiResponseInterface>();
    const [singleUserDetailsEmail, setSingleUserDetailsEmail] = useState<string | undefined>();
    const [singleUserDetailsError, setSingleUserDetailsError] = useState('');
    const [csvFileDetails, setCsvFileDetails] = useState<CsvUploadFileInputInterface>({});
    const [inputDetails, setInputDetails] = useState<WebinarReportFormInterface>({});
    const [userDetailsTableHeading, setUserDetailsTableHeading] = useState('');

    const inputOnChangeHandler = (value: string, fieldName?: string) => {
        if (!fieldName) return;
        setInputDetails((prevState) => ({
            ...prevState,
            [fieldName]: fieldName === 'email' ? value.toLowerCase() : value,
            error: '',
            errorField: prevState.errorField === fieldName ? '' : prevState.errorField,
        }));
    };

    const fileInputOnChangeHandler = (file: any) => {
        const fileInfo = file && file[0];
        if (!fileInfo) return;
        const isValidCsvFile = fileUtils.isValidExcelOrCsv(fileInfo);
        setCsvFileDetails({
            file: isValidCsvFile ? fileInfo : undefined,
            error: isValidCsvFile ? '' : 'Error : Please select a valid CSV file',
        });
    };

    const removeFileSelectionHandler = () => {
        setCsvFileDetails({ file: undefined, error: '' });
    };

    const csvFileHandler = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsCsvModalOpen(false);
        webinarReportHelper.readCsvFile(csvFileDetails.file, setCsvFileDetails, setInputDetails);
    };

    const getUserStatus = async (invalidEmails: string[]) => {
        if (!inputDetails.email || !inputDetails.webinarTime) return;
        setWebinarUserReport(undefined);
        setSelectedUsersDetails(undefined);

        // Note : Having "<" or ">" sign in email will cause error in the .match method
        const stringWithoutExceptionSymbols = commonUtils.removeCharactersFromString(inputDetails.email || '', [
            '<',
            '>',
        ]);

        const emailArray = commonUtils.trimCommaAndSpaceSeparatedString(stringWithoutExceptionSymbols);
        const invalidEmailArray = invalidEmails;
        const validEmailArray = emailArray.filter((email) => !invalidEmailArray.includes(email));
        let duplicateEmailArray = commonUtils.getDuplicateItemsInArray(validEmailArray);
        const uniqueEmailArray = commonUtils.getUniqueItemsInArray(validEmailArray);

        const duplicateEmailsErrorMessage = webinarReportHelper.generateErrorMessage(duplicateEmailArray, 'duplicate');
        const invalidEmailsErrorMessage = webinarReportHelper.generateErrorMessage(invalidEmailArray, 'invalid');
        setInputDetails((prevState) => ({
            ...prevState,
            error: duplicateEmailsErrorMessage
                ? `${duplicateEmailsErrorMessage} <br/> ${invalidEmailsErrorMessage}`
                : invalidEmailsErrorMessage,
        }));

        const constructedIsoDateFromLocalTime = dateTimeUtils.constructIsoFromLocalTime(inputDetails.webinarTime);
        const userReport = await webinarReportHelper.getUserStatusDataFromApi(
            uniqueEmailArray,
            constructedIsoDateFromLocalTime,
        );
        setIsLoading(false);

        if (typeof userReport === 'string') setInputDetails((prevState) => ({ ...prevState, error: userReport }));
        else setWebinarUserReport(userReport);
    };

    const validateDetails = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsLoading(true);

        const isFormDetailsValid = userValidations.webinarReportFormValidation(inputDetails);
        if (!Array.isArray(isFormDetailsValid)) {
            setInputDetails((prevState) => ({
                ...prevState,
                error: `Error : ${isFormDetailsValid.error}`,
                errorField: isFormDetailsValid.errorField,
            }));
            return setIsLoading(false);
        } else return getUserStatus(isFormDetailsValid);
    };

    const getClickCategoryUsers = async (value: string, fieldName: string) => {
        const selectedEmails = webinarUserReport
            ?.filter((item) => item.status === fieldName)
            ?.map((item) => item.email);
        if (!selectedEmails) return;

        setSelectedUsersDetails(undefined);
        setIsSelectedUsersDetailsLoading(true);

        const headingArray = {
            oldUserBasic: 'Old User Basic Details',
            oldUserPro: 'Old User Pro Details',
            oldUserBasicToPro: 'Old User Basic To Pro Details',
            newUserBasic: 'New User Basic Details',
            newUserPro: 'New User Pro Details',
            notUser: 'Not User Details',
        };
        setUserDetailsTableHeading(headingArray[fieldName as keyof typeof headingArray]);

        const userDetails = await webinarReportHelper.getSelectedUserDataFromApi(selectedEmails);
        setIsSelectedUsersDetailsLoading(false);

        if (typeof userDetails === 'string') setInputDetails((prevState) => ({ ...prevState, error: userDetails }));
        else setSelectedUsersDetails(userDetails);
    };

    const getSingleUserDetailsHandler = async (email: string) => {
        if (!email) return setSingleUserDetailsError('Email is required');
        setSingleUserDetailsEmail(email);
    };

    const singleUserDetailsErrorHandler = (error: string) => {
        setSingleUserDetailsError(error);
    };

    const singleUserBackBtnHandler = () => setSingleUserDetailsEmail(undefined);

    const uploadCsvModalCloseHandler = () => {
        setIsCsvModalOpen(false);
        setCsvFileDetails({});
    };

    useEffect(() => {
        clearTimeout(showNotification.current);
        showNotification.current = setTimeout(() => {
            showInstantAlert && setShowInstantAlert(false);
            singleUserDetailsError && setSingleUserDetailsError('');
        }, 2000);
        return () => clearTimeout(showNotification.current);
    }, [showInstantAlert, singleUserDetailsError]);

    return singleUserDetailsEmail ? (
        <UserDetails
            singleUserDetailsEmail={singleUserDetailsEmail}
            tableBackBtnOnClickHandler={singleUserBackBtnHandler}
            singleUserDetailsErrorHandler={singleUserDetailsErrorHandler}
        />
    ) : (
        <div className={`${global.componentWrapper} ${styles.webinarReportComponent}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    {(showInstantAlert || singleUserDetailsError) && (
                        <InstantAlert
                            type={singleUserDetailsError ? 'error' : 'success'}
                            text={singleUserDetailsError ? singleUserDetailsError : 'Successfully copied'}
                        />
                    )}

                    <CommonModal
                        isModalOpen={isCsvModalOpen}
                        modalCloseHandler={() => uploadCsvModalCloseHandler()}
                        modalContent={
                            <form onSubmit={(e) => csvFileHandler(e)} className={`${styles.csvInputModal}`}>
                                <MultipleSearchBox
                                    isLoading={isLoading}
                                    details={csvFileDetails}
                                    submitBtnText="Upload file"
                                    inputContent={
                                        <div className={`${styles.inputFieldContainer}`}>
                                            <InputFields
                                                inputFields={[
                                                    {
                                                        fieldType: 'file',
                                                        fieldName: 'file',
                                                        label: 'Upload CSV file',
                                                        error: csvFileDetails.error ? true : false,
                                                        removeSelection: () => removeFileSelectionHandler(),
                                                        fieldClassName: `${styles.csvInputField}`,
                                                    },
                                                ]}
                                                inputDetails={csvFileDetails}
                                                fileInputOnChangeHandler={fileInputOnChangeHandler}
                                            />
                                        </div>
                                    }
                                />
                            </form>
                        }
                    />

                    <TopBarLayout
                        topBar={
                            <WebinarReportTopBar
                                isLoading={isLoading}
                                inputDetails={inputDetails}
                                inputOnChangeHandler={inputOnChangeHandler}
                                validateDetails={validateDetails}
                                setIsCsvModalOpen={setIsCsvModalOpen}
                            />
                        }
                        content={
                            <div className={`${styles.webinarDetails}`}>
                                <WebinarReportDetails
                                    isSelectedUsersDetailsLoading={isSelectedUsersDetailsLoading}
                                    webinarUserReport={webinarUserReport}
                                    getClickCategoryUsers={getClickCategoryUsers}
                                />
                                {selectedUsersDetails && (
                                    <SearchUsersDetails
                                        setShowInstantAlert={setShowInstantAlert}
                                        checkUsersDetails={selectedUsersDetails}
                                        getSingleUserDetailsHandler={getSingleUserDetailsHandler}
                                        tableHeading={userDetailsTableHeading}
                                    />
                                )}
                                {webinarUserReport && (
                                    <div className={`${styles.definitionTable}`}>
                                        <h3 className={`${global.head4} ${styles.head}`}>
                                            --- Definition of terms ---
                                        </h3>
                                        {webinarDefinition.map((data, index) => (
                                            <p key={index} className={`${global.bodyLarge} ${styles.text}`}>
                                                <span className={`${styles.highlight}`}>{data.highlight}</span>
                                                {data.details}
                                            </p>
                                        ))}
                                    </div>
                                )}
                            </div>
                        }
                    />
                </div>
                B
            </div>
        </div>
    );
};
