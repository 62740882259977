import styles from './LatestUsersDetails.module.scss';

import { InputFields } from 'template/inputFields/InputFields.template';

import { LatestUsersTableOptionsInterface } from 'interfaces/user.interface';

interface LatestUsersTableOptionsComponentInterface {
    showTableOptions: Partial<LatestUsersTableOptionsInterface>;
    showTableOptionsToggleHandler: (value: string, fieldName?: string) => void;
}

export const LatestUsersTableOptions = ({
    showTableOptions,
    showTableOptionsToggleHandler,
}: LatestUsersTableOptionsComponentInterface) => {
    const fields = [
        { fieldName: 'created', label: 'Created' },
        { fieldName: 'firstCp2SignIn', label: 'First CP2 Sign In' },
        { fieldName: 'addedFirstActivityButton', label: 'Added First Activity Button' },
        { fieldName: 'firstSlideshowStarted', label: 'First Slideshow Started' },
        { fieldName: 'firstClassSessionStarted', label: 'First Class Session Started' },
        { fieldName: 'planStatus', label: 'Plan Status' },
        { fieldName: 'lastClassSessionStarted', label: 'Last Class Session Started' },
        { fieldName: 'country', label: 'Country' },
        { fieldName: 'leadSource', label: 'Lead Source' },
        { fieldName: 'jobRole', label: 'Job Role' },
        { fieldName: 'organization', label: 'Organization' },
        { fieldName: 'firstGoPro', label: 'First Go Pro' },
        { fieldName: 'lastGoPro', label: 'Last Go Pro' },
        // { fieldName: 'hasEnteredSlideshow', label: 'Has Entered Slideshow' },
        { fieldName: 'savedClassesCount', label: 'Saved Classes Count' },
        { fieldName: 'signupOs', label: 'Signup OS' },
        { fieldName: 'cpVer', label: 'CP Ver' },
    ];

    return (
        <div className={`${styles.tableOptions}`}>
            <InputFields
                inputFields={fields.map((data) => {
                    return {
                        fieldType: 'checkbox',
                        fieldName: data.fieldName,
                        label: data.label,
                    };
                })}
                inputDetails={showTableOptions}
                inputOnChangeHandler={showTableOptionsToggleHandler}
            />
        </div>
    );
};
