import axios from 'axios';

import { logger } from '../logger.service';

import {
    CheckUserApiResponseInterface,
    LatestUsersInterface,
    UsageLogsInterface,
    UserInterface,
    WebinarReportApiResponseInterface,
    CurrentClassInterface,
} from 'interfaces/user.interface';

export const userApi = {
    getUser: async (value: string, type: 'email' | 'userId'): Promise<UserInterface | string> => {
        try {
            const query = type === 'email' ? `?email=${value}` : `?userId=${value}`;
            const url = `${process.env.REACT_APP_API}/users${query}`;
            const response = await axios.get(url);
            return response.data;
        } catch (error: any) {
            logger.error('userApi.getUser() error: ', error.response || error);
            return `Error : ${error.response ? error.response.data.message : error}`;
        }
    },

    changeUserEmail: async (
        adminEmail: string,
        adminPassword: string,
        oldEmail: string,
        newEmail: string,
    ): Promise<string[] | string> => {
        try {
            const url = `${process.env.REACT_APP_API}/admin/change-email?adminEmail=${adminEmail}&adminPassword=${adminPassword}`;
            const response = await axios.patch(url, { oldEmail, newEmail });
            return ['success', response.data];
        } catch (error: any) {
            logger.error('userApi.changeUserEmail() error: ', error.response || error);
            return `Error : ${error.response ? error.response.data.message : error}`;
        }
    },

    deleteUser: async (
        adminEmail: string,
        adminPassword: string,
        email: string,
        hasUserRequestedDeletion = true,
    ): Promise<object | string> => {
        try {
            const url = `${process.env.REACT_APP_API}/admin/delete-user?adminEmail=${adminEmail}&adminPassword=${adminPassword}`;
            const response = await axios.delete(url, { data: { email, hasUserRequestedDeletion } });
            return response.data;
        } catch (error: any) {
            logger.error('userApi.deleteUser() error: ', error.response || error);
            return `Error : ${error.response ? error.response.data.message : error}`;
        }
    },

    clearUserLocation: async (adminEmail: string, adminPassword: string, email: string): Promise<object | string> => {
        try {
            const url = `${process.env.REACT_APP_API}/admin/clear-location?adminEmail=${adminEmail}&adminPassword=${adminPassword}&userEmail=${email}`;
            const response = await axios.patch(url);
            return response.data;
        } catch (error: any) {
            logger.error('userApi.clearUserLocation() error: ', error.response || error);
            return `Error : ${error.response ? error.response.data.message : error}`;
        }
    },

    checkUser: async (emailString: string): Promise<CheckUserApiResponseInterface | string> => {
        try {
            const url = `${process.env.REACT_APP_API}/admin/find-users-from-emails?emailsString=${emailString}`;
            const response = await axios.get(url);
            return response.data;
        } catch (error: any) {
            logger.error('userApi.checkUser() error: ', error.response || error);
            return `Error : ${error.response ? error.response.data.message : error}`;
        }
    },

    usageLogs: async (email: string, date: string): Promise<UsageLogsInterface[] | string> => {
        try {
            const url = `${process.env.REACT_APP_API}/admin/usage-logs?email=${email}&dateIso=${date}`;
            const response = await axios.get(url);
            return response.data;
        } catch (error: any) {
            logger.error('userApi.usageLogs() error: ', error.response || error);
            return `Error : ${error.response ? error.response.data.message : error}`;
        }
    },

    debugLogs: async (email: string, date: string, hour: string): Promise<{ data: string } | string> => {
        try {
            const url = `https://cpcontent.blob.core.windows.net/addin-logs/${process.env.REACT_APP_ENV}/${email}/${date}/${date}.${hour}.log`;
            const response = await axios.get(url);
            return { data: response.data };
        } catch (error: any) {
            logger.error('userApi.usageLogs() error: ', error.response || error);
            return `Error : ${error.response ? error.response.data.message : error}`;
        }
    },

    liveClasses: async (baseUrl: string, email: string): Promise<CurrentClassInterface | string> => {
        try {
            const url = `${baseUrl}/liveclasses?email=${email}`;
            const response = await axios.get(url);
            return response.data;
        } catch (error: any) {
            logger.error('userApi.liveClasses() error: ', error.response || error);
            return `Error : ${error.response ? error.response.data.message : error}`;
        }
    },

    latestUsers: async (page: number, perPage: number, email: string): Promise<LatestUsersInterface[] | string> => {
        try {
            const url = `${process.env.REACT_APP_API}/admin/latest-users?page=${page}&perPage=${perPage}&email=${email}`;
            const response = await axios.get(url);
            return response.data;
        } catch (error: any) {
            logger.error('userApi.latestUsers() error: ', error.response || error);
            return `Error : ${error.response ? error.response.data.message : error}`;
        }
    },

    webinarReport: async (
        emailString: string[],
        webinarTime: string,
    ): Promise<WebinarReportApiResponseInterface[] | string> => {
        try {
            const url = `${process.env.REACT_APP_API}/admin/webinar-report`;
            const response = await axios.post(url, { emails: emailString, webinarTime: webinarTime });
            return response.data;
        } catch (error: any) {
            logger.error('userApi.webinarReport() error: ', error.response || error);
            return `Error : ${error.response ? error.response.data.message : error}`;
        }
    },
};
