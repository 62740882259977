import { CurrentClassInterface } from 'interfaces/user.interface';

import { generateCurrentClassCells } from 'data/generateCells/generateCurrentClassCells.data';

export const currentClassSummaryGridData = (liveClasses: CurrentClassInterface) => {
    const cell = generateCurrentClassCells(liveClasses);

    return {
        heading: 'Current Class Overview',
        rows: [
            [cell.classDuration, cell.starsAwarded, cell.activityType],
            [cell.onlineParticipantListLength, cell.offlineParticipantListLength, cell.isInSlideshow],
        ],
    };
};
