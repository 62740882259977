import axios from 'axios';

import { logger } from '../logger.service';
import { uploadToAzureStorage } from '../azureStorage.service';

export const azureStorageApi = {
    uploadToStorage: async (
        file: any,
        fileName: string,
        cpcsRegion: string,
        containerName: string,
    ): Promise<string> => {
        const urlFromAzureStorage = await uploadToAzureStorage(file, fileName, cpcsRegion, containerName);
        if (urlFromAzureStorage) return urlFromAzureStorage;

        const form = new FormData();
        form.append('blobFile', file, fileName);

        try {
            const url = `${process.env.REACT_APP_FILEAPP}/add/cpfile/${containerName}`;
            const response = await axios.post(url, form, { headers: { 'Content-Type': 'multipart/form-data' } });
            return response.data.fileUrl;
        } catch (error: any) {
            logger.error('Error : azureStorage.uploadToStorage()', error.response || error);
            return '';
        }
    },
};
