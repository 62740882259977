import { FormEvent, useEffect, useRef, useState } from 'react';

import global from 'theme/global/Global.module.scss';
import styles from './PastSessions.module.scss';

import { TopBarLayout } from 'template/topBarLayout/TopBarLayout.template';

import { sessionApi } from 'services/api/session.api';
import { userApi } from 'services/api/user.api';

import { TopBarSimpleFormInterface } from 'interfaces/ui/topBar.interface';
import { ClassSessionDetailsInterface, PastClassSessionsByUserInterface } from 'interfaces/session.interface';
import { DATA_CONSTANT } from 'constants/data/data.constants';

import { PastSessionsTopBar } from './components/pastSessionsTopBar/PastSessionsTopBar';
import { PastSessionsDetails } from './components/pastSessionsDetails/PastSessionsDetails';
import { SingleSessionDetails } from './components/singleSessionDetails/SingleSessionDetails';

export const PastSessions = () => {
    const timeOutUpdatePastSessions = useRef<any>();

    const [isLoading, setIsLoading] = useState(false);
    const [inputDetails, setInputDetails] = useState<TopBarSimpleFormInterface>({
        value: '',
        error: '',
    });
    const [pageNumber, setPageNumber] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [sessionsDetails, setSessionsDetails] = useState<PastClassSessionsByUserInterface[]>();
    // useState<(PastClassSessionsByUserInterface | PastClassSessionsByClassInterface)[]>();
    // const [sessionType, setSessionType] = useState<'user' | 'class'>();
    const [singleSessionDetails, setSingleSessionDetails] = useState<ClassSessionDetailsInterface>();

    const inputOnChangeHandler = (value: string) => {
        setInputDetails((prevState) => ({ ...prevState, value: value.toLowerCase(), error: '' }));
    };

    const getSessions = async (type: 'user' | 'class') => {
        setIsLoading(true);
        setSessionsDetails(undefined);
        // setSessionType(undefined);

        // let sessionResponse: (PastClassSessionsByUserInterface | PastClassSessionsByClassInterface)[] | string = [];
        let sessionResponse: PastClassSessionsByUserInterface[] | string = [];

        if (type === 'user') {
            const userDetails = await userApi.getUser(inputDetails.value, 'email');
            if (typeof userDetails === 'string') {
                setInputDetails((prevState) => ({ ...prevState, error: userDetails }));
                return setIsLoading(false);
            }
            const userId = userDetails._id;
            sessionResponse = await sessionApi.getPastClassSessionsByUser(userId, pageNumber, rowsPerPage);
            // setSessionType('user');
        }
        // else {
        //     sessionResponse = await sessionApi.getPastClassSessionsByClass(inputDetails.value, pageOffset, 10);
        //     setSessionType('class');
        // }

        setIsLoading(false);
        if (typeof sessionResponse === 'string') {
            const error = sessionResponse;
            setInputDetails((prevState) => ({ ...prevState, error: error }));
        } else setSessionsDetails(sessionResponse);
    };

    const getSingleSessionDetails = async (classSessionId: string) => {
        setIsLoading(true);

        const singleSessionDetails = await sessionApi.getSingleSessionDetails(classSessionId);
        if (typeof singleSessionDetails === 'string') {
            setInputDetails((prevState) => ({ ...prevState, error: singleSessionDetails }));
            return setIsLoading(false);
        } else setSingleSessionDetails(singleSessionDetails);

        return setIsLoading(false);
    };

    const goBackToSessionsList = () => setSingleSessionDetails(undefined);

    const changeTableProperty = (action: 'add1Page' | 'sub1Page' | 'setPage' | 'setRow', value?: number) => {
        const actionObject = {
            add1Page: () => setPageNumber((prevState) => prevState + 1),
            sub1Page: () => setPageNumber((prevState) => prevState - 1),
            setPage: () => setPageNumber(value || 0),
            setRow: () => setRowsPerPage(value || 0),
        };
        return action ? actionObject[action]() : undefined;
    };

    const validateDetails = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!inputDetails.value.trim() || !inputDetails.value.trim().match(DATA_CONSTANT.REGEX.EMAIL)) {
            return setInputDetails((prevState) => ({
                ...prevState,
                error: 'Error : Please enter a valid email',
            }));
        }
        // if (!inputDetails.value.trim().match(DATA_CONSTANT.REGEX.EMAIL)) getSessions('class');
        getSessions('user');
    };

    useEffect(() => {
        clearTimeout(timeOutUpdatePastSessions.current);
        timeOutUpdatePastSessions.current = setTimeout(() => {
            if (inputDetails.value.trim() && inputDetails.value.trim().match(DATA_CONSTANT.REGEX.EMAIL)) {
                getSessions('user');
            }
        }, DATA_CONSTANT.TIME.GET_PAST_SESSIONS_API_CALL_DELAY);

        return () => clearTimeout(timeOutUpdatePastSessions.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber, rowsPerPage]);

    return (
        <div className={`${global.componentWrapper} ${styles.pastSessionsComponent}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    {/* {(showInstantAlert || singleUserDetailsError) && (
                        <InstantAlert
                            type={singleUserDetailsError ? 'error' : 'success'}
                            text={singleUserDetailsError ? singleUserDetailsError : 'Successfully copied'}
                        />
                    )} */}

                    <TopBarLayout
                        topBar={
                            <PastSessionsTopBar
                                isLoading={isLoading}
                                inputDetails={inputDetails}
                                inputOnChangeHandler={inputOnChangeHandler}
                                validateDetails={validateDetails}
                            />
                        }
                        content={
                            singleSessionDetails ? (
                                <SingleSessionDetails
                                    singleSessionDetails={singleSessionDetails}
                                    goBackToSessionsList={goBackToSessionsList}
                                />
                            ) : (
                                <PastSessionsDetails
                                    // sessionType={sessionType}
                                    sessionsDetails={sessionsDetails}
                                    getSingleSessionDetails={getSingleSessionDetails}
                                    pageNumber={pageNumber}
                                    rowsPerPage={rowsPerPage}
                                    changeTableProperty={changeTableProperty}
                                />
                            )
                        }
                    />
                </div>
            </div>
        </div>
    );
};
