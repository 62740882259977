import Drawer from '@mui/material/Drawer';

import global from 'theme/global/Global.module.scss';
import styles from './SideBar.module.scss';

import { UI_ACTIONS } from 'context/features/uiSlice.redux';
import { useAppDispatch, useAppSelector } from 'helpers/customHooks/useRedux.hook';
import { useWindowDimensions } from 'helpers/customHooks/useWindowDimensions.hook';

import { Navigation } from './components/Navigation';

export const SideBar = () => {
    const dispatch = useAppDispatch();

    const uiStore = useAppSelector((state) => state).ui;
    const windowWidth = useWindowDimensions().width;

    return (
        <div className={`${global.componentWrapper} ${styles.sideBarComponent}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    {windowWidth > 767 && <Navigation />}
                    {windowWidth < 768 && (
                        <Drawer
                            anchor={'right'}
                            open={uiStore.isMobileNavOpen}
                            onClose={() => dispatch(UI_ACTIONS.TOGGLE_MOBILE_NAV())}
                        >
                            <Navigation />
                        </Drawer>
                    )}
                </div>
            </div>
        </div>
    );
};
