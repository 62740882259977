import { styled, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';

// ========== Tables ==========
export const CustomTable = styled(Table)({});

export const CustomTableHead = styled(TableHead)({});

export const CustomTableBody = styled(TableBody)({});

export const CustomTableRow = styled(TableRow)({});

export const CustomTableCell = styled(TableCell)({
    color: 'var(--clr_text_primary)',
    borderBlockEnd: '0.5px solid var(--clr_text_neutral_light)',
});
