import { BiError } from 'react-icons/bi';

import Modal from '@mui/material/Modal';

import { BtnError, BtnNeutral } from 'theme/mui/buttons.theme';

import global from 'theme/global/Global.module.scss';
import styles from './WarningModal.template.module.scss';

interface WarningModalInterface {
    isModalOpen: boolean;
    modalCloseHandler: () => void;
    heading: string;
    subText: string;
    subTextHighlight?: string;
    isLoading: boolean;
    cancelBtnText?: string;
    confirmBtnText?: string;
    confirmBtnClickHandler: () => void;
    className?: string;
}

export const WarningModal = ({
    isModalOpen,
    modalCloseHandler,
    heading,
    subText,
    subTextHighlight,
    isLoading,
    cancelBtnText,
    confirmBtnText,
    confirmBtnClickHandler,
    className,
}: WarningModalInterface) => {
    return (
        <Modal
            open={isModalOpen}
            onClose={() => modalCloseHandler()}
            className={`${styles.warningModalTemplate} ${className || ''}`}
        >
            <div className={`${global.moduleWrapper} ${styles.advanceSearchContentModule}`}>
                <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                    <div className={`${styles.content}`}>
                        <div className={`${styles.headContainer}`}>
                            <BiError className={`${styles.icon}`} />
                            <h4 className={`${global.head4} ${styles.head}`}>{heading}</h4>
                            <BiError className={`${styles.icon}`} />
                        </div>
                        <p className={`${global.bodyLarge} ${styles.subHead}`}>
                            {subText}{' '}
                            {subTextHighlight && <span className={`${styles.highlight}`}>{subTextHighlight}</span>}?
                            <br /> You can not undo this action.
                        </p>
                        <div className={`${styles.btnContainer}`}>
                            <BtnNeutral onClick={() => modalCloseHandler()} className={`${styles.btn}`}>
                                {cancelBtnText || 'Cancel'}
                            </BtnNeutral>
                            <BtnError
                                loading={isLoading}
                                onClick={() => confirmBtnClickHandler()}
                                className={`${styles.btn}`}
                            >
                                {confirmBtnText || 'Confirm'}
                            </BtnError>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};
