import { cellUtils } from 'helpers/utilities/cell.util';

import { CurrentClassInterface } from 'interfaces/user.interface';

import { generateCurrentClassCells } from 'data/generateCells/generateCurrentClassCells.data';

export const currentClassCurrentActivityGridData = (
    liveClasses: CurrentClassInterface,
    showCurrentActivityJson: () => void,
) => {
    const cell = generateCurrentClassCells(liveClasses);
    const customCell = cellUtils.tableCell;

    return {
        heading: 'Current Activity',
        rows: [
            [cell.activityType, cell.isSubmissionClosed],
            [cell.activityResponsesLength, customCell('View', 'View JSON')],
        ],
        cellProps: {
            View: { getSelectedValueOnClick: showCurrentActivityJson },
        },
    };
};
