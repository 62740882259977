import { styled, ListItemButton, Menu, MenuItem } from '@mui/material';

// ========== List Items ==========
export const NavItemBtn = styled(ListItemButton)({
    padding: 0,
});

export const NavMenu = styled(Menu)({
    '.MuiMenu-paper': {
        minWidth: 0,
        minHeight: 0,
    },
});

export const SelectItem = styled(MenuItem)({});
