import { BlobServiceClient, ContainerClient } from '@azure/storage-blob';
import { AzureStorages } from 'constants/azureStorages.constant';

const determineStorage = (cpcsRegion: string) => {
    const isDev = cpcsRegion.startsWith('dev') || cpcsRegion.startsWith('localhost');
    let region = '';
    if (cpcsRegion === 'localhost') region = '11';
    else {
        const last4Digits = cpcsRegion.slice(-4);
        // when cpcsRegion has a structure of 'cpcs11'
        if (isNaN(Number(last4Digits))) region = cpcsRegion.slice(-2);
        // when cpcsRegion has a structure of 'cpcs1101'
        else region = cpcsRegion.slice(-4, -2);
    }

    let targetStorage = AzureStorages.find(
        (storage) => storage.isDev === isDev && storage.region === region && storage.isTemp === false,
    );

    if (!targetStorage) {
        // if target storage is not found, fall back to 11
        targetStorage = AzureStorages.find(
            (storage) => storage.isDev === isDev && storage.region === '11' && storage.isTemp === false,
        );
    }

    // if still cannot find one, return null so we can use file server instead
    if (!targetStorage) return null;

    return {
        storageAccountName: targetStorage.storageAccountName,
        sasToken: targetStorage.sasToken,
    };
};

const createBlobInContainer = async (
    containerClient: ContainerClient,
    file: File,
    fileName: string,
): Promise<boolean> => {
    const blobClient = containerClient.getBlockBlobClient(fileName);
    const options = { blobHTTPHeaders: { blobContentType: file.type } };
    try {
        await blobClient.uploadData(file, options);
        return true;
    } catch (error: any) {
        return false;
    }
};

export const uploadToAzureStorage = async (file: any, fileName: string, cpcsRegion: string, containerName: string) => {
    if (!file) return '';

    try {
        const storage = determineStorage(cpcsRegion);
        if (!storage) return '';

        const blobService = new BlobServiceClient(
            `https://${storage.storageAccountName}.blob.core.windows.net/?${storage.sasToken}`,
        );
        const containerClient: ContainerClient = blobService.getContainerClient(containerName);
        const uploadSuccess = await createBlobInContainer(containerClient, file, fileName);

        return uploadSuccess
            ? `https://${storage.storageAccountName}.blob.core.windows.net/${containerName}/${fileName}`
            : '';
    } catch (error) {
        return '';
    }
};
