import { ReactNode } from 'react';

import global from 'theme/global/Global.module.scss';
import styles from './Block.template.module.scss';

interface BlockInterface {
    heading?: string;
    content: ReactNode;
    isNotDefaultModule?: boolean;
    isFullHeight?: boolean;
    className?: string;
}

export const Block = ({ heading, content, isNotDefaultModule, isFullHeight, className }: BlockInterface) => {
    return (
        <div
            className={`${!isNotDefaultModule && global.defaultModule} ${global.moduleWrapper} ${
                styles.dataGridTemplate
            } ${isFullHeight && styles.fullHeight} ${className || ''}`}
        >
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    {heading && <h6 className={`${global.head6} ${styles.heading}`}>{heading}</h6>}
                    <div className={`${styles.mainContainer}`}>{content}</div>
                </div>
            </div>
        </div>
    );
};
