import { startCase } from 'lodash';

import { AffiliateInterface } from 'interfaces/affiliate.interface';
import { dateTimeUtils } from 'helpers/utilities/dateTime.util';

export const getAllAffiliatesTableData = (
    allAffiliatesDetails: AffiliateInterface[],
    getSingleAffiliateHandler: (email: string) => void,
) => {
    return {
        heading: `All Affiliates`,
        rows: allAffiliatesDetails.map((item) => ({
            Name: item.name,
            Created: dateTimeUtils.getDateTimeFromIso(item.created),
            Email: item.email,
            'Country code': startCase(item.countryCode) || undefined,
            'Discount percentage': item.discountPercentage,
            'Commission percentage': item.commission * 100,
            'Invoices Count': item.transactionsCount,
        })),
        cellProps: {
            Email: { getSelectedValueOnClick: getSingleAffiliateHandler },
        },
    };
};
