import { navigationData } from 'components/sideBar/components/NavigationData';
import { ExpandListItemInterface } from 'interfaces/ui/sideBar.interface';

export const sideBarUtils = {
    getAllSubLevelId: (): ExpandListItemInterface => {
        let finalObject: ExpandListItemInterface = {};

        navigationData.forEach((navGroup) => {
            const navGroupWithSubLevels = navGroup.filter((group) => group.subLevelLinks.length > 0);
            if (navGroupWithSubLevels.length > 0) {
                navGroupWithSubLevels.forEach((mainLevelItem) => {
                    finalObject = { ...finalObject, [mainLevelItem.active]: false };
                });
            }
        });

        return finalObject;
    },
};
