import global from 'theme/global/Global.module.scss';
import styles from './ViewEditKeysDetails.module.scss';

import { DataTable } from 'template/dataTable/DataTable.template';

import { KeyInterface } from 'interfaces/key.interface';

import { viewEditKeysTableData } from 'data/key/viewEditKeys/viewEditKey.tableData';

interface ViewEditKeysDetailsInterface {
    keyDetails: KeyInterface[];
    getSingleKeyDetailsHandler: (key: string) => void;
}

export const ViewEditKeysDetails = ({ keyDetails, getSingleKeyDetailsHandler }: ViewEditKeysDetailsInterface) => {
    const viewEditKeyDetails = viewEditKeysTableData(keyDetails, getSingleKeyDetailsHandler);

    return (
        <div className={`${global.moduleWrapper} ${styles.keyDetailsModule}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>{<DataTable data={viewEditKeyDetails} />}</div>
            </div>
        </div>
    );
};
