import { styled, FormControl } from '@mui/material';

// ========== Mui Form ==========
export const MuiForm = styled(FormControl)({
    '&:hover': {
        '.MuiFormLabel-root': {
            color: 'var(--clr_text_primary)',
        },
    },

    '.MuiFormLabel-root': {
        fontSize: '14px',
        color: 'var(--clr_text_neutral)',

        '&:not(.MuiInputLabel-shrink)': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',

            width: '100%',
            height: '100%',
            transform: 'translate(14px, 0px) scale(1)',
        },

        '&.Mui-focused': {
            color: 'var(--clr_text_info)',
        },

        '&.isRequired': {
            color: 'var(--clr_text_warn_light)',
        },
    },
});
