import ReactJson from 'react-json-view';

import global from 'theme/global/Global.module.scss';
import styles from './JsonViewer.template.module.scss';

interface JsonViewerInterface {
    jsonData: object;
    isDefaultModule?: boolean;
    isCopyDisabled?: boolean;
    className?: string;
}

export const JsonViewer = ({ jsonData, isDefaultModule, isCopyDisabled, className }: JsonViewerInterface) => {
    return (
        <div
            className={` ${isDefaultModule && global.defaultModule} ${global.moduleWrapper} ${
                styles.jsonViewerTemplate
            } ${className || ''}`}
        >
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    <ReactJson
                        src={jsonData}
                        displayDataTypes={false}
                        indentWidth={8}
                        displayObjectSize={false}
                        theme="rjv-default"
                        iconStyle="triangle"
                        enableClipboard={!isCopyDisabled}
                    />
                </div>
            </div>
        </div>
    );
};
