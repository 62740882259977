import { commonUtils } from 'helpers/utilities/common.util';
import { dateTimeUtils } from 'helpers/utilities/dateTime.util';
import { cellUtils } from 'helpers/utilities/cell.util';

import { keyHelper } from 'helpers/key.helper';

import { KeyInterface } from 'interfaces/key.interface';

export const generateKeyCells = (keyDetails: KeyInterface) => {
    const cell = cellUtils.tableCell;

    const calculateActivatedToMaxRatio = () => {
        if (!keyDetails.maxActivations) return 'Unlimited';

        const activatedUsers = keyDetails.activatedUsers?.length || 0;
        const numberOfActivations = keyDetails.numberOfActivations || 0;
        const maxActivations = keyDetails.maxActivations || 0;

        const totalActivated = activatedUsers + numberOfActivations;
        return `${totalActivated}/${maxActivations}`;
    };

    const allKeyCategories = keyHelper.getAllCategories();

    return {
        key: cell('Key', keyDetails.key),

        type: cell('Type', keyDetails.type),
        category: cell('Category', allKeyCategories.find((data) => data.value === keyDetails.category)?.label),
        isTrial: cell('Is Trial', commonUtils.booleanToYesNo(keyDetails.isTrial)),
        createdBy: cell('Created By', keyDetails.createdBy),
        created: cell('Created', dateTimeUtils.getDateTimeFromIso(keyDetails.created)),
        validTo: cell('Valid To', dateTimeUtils.getDateTimeFromIso(keyDetails.validTo)),
        validFrom: cell('Valid From', dateTimeUtils.getDateTimeFromIso(keyDetails.validFrom)),
        soldDate: cell('Sold Date', dateTimeUtils.getDateTimeFromIso(keyDetails.soldDate)),

        duration: cell('Duration (Days)', keyDetails.duration),

        organization: cell('Organization ', keyDetails.organization),
        isPremium: cell('Is Premium', commonUtils.booleanToYesNo(keyDetails.isPremium)),
        planExpiryDate: cell('Plan Expiry Date', dateTimeUtils.getDateTimeFromIso(keyDetails.planExpiryDate)),

        maxActivations: cell('Max Activations', keyDetails.maxActivations),
        activatedUsers: cell('Activated Users Count', keyDetails.activatedUsers?.length),
        numberOfActivations: cell('Number of Activations', keyDetails.numberOfActivations),
        activatedToMaxRatio: cell('Activated/Max Activations', calculateActivatedToMaxRatio()),

        note: cell('Note', keyDetails.note),
        domains: cell('Domains', keyDetails.domains?.toString()),
        countries: cell('Countries', keyDetails.countries?.toString()),
    };
};
