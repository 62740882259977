import { UsageLogsInterface } from 'interfaces/user.interface';

export const usageLogsTableData = (usageLogs: UsageLogsInterface[] | undefined) => {
    const tableRows = usageLogs?.map((logEntry) => ({
        Time: `${logEntry.time.slice(0, 10)} | ${logEntry.time.slice(11, 19)}`,
        Event: logEntry.event,
        RowProps: {
            rowColor: logEntry.color,
        },
    }));

    const tableCellProps = {
        Time: {
            isNoWrap: true,
        },
    };

    return {
        heading: 'Usage Logs',
        rows: tableRows || [],
        cellProps: tableCellProps,
    };
};
