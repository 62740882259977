import { FormEvent, useEffect, useRef, useState } from 'react';

import global from 'theme/global/Global.module.scss';
import styles from './LatestUsers.module.scss';

import { TopBarLayout } from 'template/topBarLayout/TopBarLayout.template';

import { useAppSelector } from 'helpers/customHooks/useRedux.hook';
import { commonUtils } from 'helpers/utilities/common.util';
import { dateTimeUtils } from 'helpers/utilities/dateTime.util';
import { userApi } from 'services/api/user.api';

import { LatestUsersFilterDataInterface, LatestUsersInterface } from 'interfaces/user.interface';
import { DATA_CONSTANT } from 'constants/data/data.constants';

import { LatestUsersTopBar } from './components/latestUsersTopBar/LatestUsersTopBar';
import { LatestUsersDetails } from './components/latestUsersDetails/LatestUsersDetails';
import { UserDetails } from '../userDetails/UserDetails';
import { InstantAlert } from 'components/common/instantAlert/InstantAlert';

export const LatestUsers = () => {
    const adminEmail = useAppSelector((state) => state).admin.email;
    const timeOutUpdateLatestUsers = useRef<any>();
    const showNotification = useRef<any>();

    const [latestUsersDetails, setLatestUsersDetails] = useState<LatestUsersInterface[]>();
    const [filteredLatestUsersDetails, setFilteredLatestUsersDetails] = useState<LatestUsersInterface[]>();
    const [pageNumber, setPageNumber] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [isLoading, setIsLoading] = useState(false);
    const [filterDetails, setFilterDetails] = useState<LatestUsersFilterDataInterface>({});
    const [error, setError] = useState('');
    const [singleUserDetailsEmail, setSingleUserDetailsEmail] = useState<string | undefined>('');
    const [singleUserDetailsError, setSingleUserDetailsError] = useState('');

    const inputOnChangeHandler = (value: string, fieldName?: string) => {
        if (!fieldName) return;
        if (fieldName === 'isEmailVerified' || fieldName === 'hasSignedIn') {
            // if (fieldName === 'isEmailVerified' || fieldName === 'hasEnteredSlideshow') {
            const result = commonUtils.convertToBoolean(value);
            const booleanValue = typeof result === 'boolean' ? result : false;
            return setFilterDetails((prevState) => ({ ...prevState, [fieldName]: booleanValue }));
        } else return setFilterDetails((prevState) => ({ ...prevState, [fieldName]: value }));
    };

    const multipleSelectOnChangeHandler = (value: string[], fieldName?: string) => {
        if (!fieldName) return;
        setFilterDetails((prevState) => ({ ...prevState, [fieldName]: value }));
    };

    const getLatestUsersHandler = async () => {
        setIsLoading(true);
        const latestUsersData = adminEmail && (await userApi.latestUsers(pageNumber, rowsPerPage, adminEmail));

        if (typeof latestUsersData === 'string') {
            setLatestUsersDetails(undefined);
            setError(`Error : ${latestUsersData}`);
        } else {
            setLatestUsersDetails(latestUsersData);
            setFilteredLatestUsersDetails(latestUsersData);
        }

        setIsLoading(false);

        const filterDetailsKeys = Object.keys(filterDetails);
        if (latestUsersData && typeof latestUsersData !== 'string' && filterDetailsKeys.length > 0) {
            filterDataHandler(latestUsersData);
        }
    };

    const filterDataHandler = (userDetails?: LatestUsersInterface[]) => {
        setError('');

        const template = filterDetails.template;
        const email = filterDetails.email?.toLowerCase().trim();
        const country = filterDetails.country?.toLowerCase().trim();
        const planStatus = filterDetails.planStatus;
        // const hasEnteredSlideshow = filterDetails.hasEnteredSlideshow;
        const signInFrom = dateTimeUtils.constructIsoFromLocalTime(filterDetails.signInFrom);
        const signInTo = dateTimeUtils.constructIsoFromLocalTime(filterDetails.signInTo);

        const filterDetailsKeys = Object.keys(filterDetails);
        let finalData = userDetails || latestUsersDetails;

        if (finalData) {
            for (let keyValue of filterDetailsKeys) {
                if (keyValue === 'email' && email) {
                    finalData = finalData.filter((data) => data.email.toLowerCase().trim().includes(email));
                }
                if (keyValue === 'country' && country) {
                    finalData = finalData.filter(
                        (data) => data.country && data.country.toLowerCase().trim().includes(country),
                    );
                }
                if (keyValue === 'planStatus' && planStatus) {
                    const searchString = planStatus.toString().toLowerCase().trim().replaceAll(',', ' ');
                    finalData = finalData.filter((data) => data.planStatus.toLowerCase().trim().includes(searchString));
                }
                // if (keyValue === 'hasEnteredSlideshow') {
                //     finalData = finalData.filter((data) => data.hasEnteredSlideshow === hasEnteredSlideshow);
                // }
                if (keyValue === 'hasSignedIn') {
                    if (filterDetails.hasSignedIn === true) finalData = finalData.filter((data) => data.firstCp2SignIn);
                    else finalData = finalData.filter((data) => !data.firstCp2SignIn);
                }
                if (keyValue === 'signInFrom' && signInFrom) {
                    finalData = finalData.filter(
                        (data) => data.firstCp2SignIn && new Date(data.firstCp2SignIn) >= new Date(signInFrom),
                    );
                }
                if (keyValue === 'signInTo' && signInTo) {
                    finalData = finalData.filter(
                        (data) => data.firstCp2SignIn && new Date(data.firstCp2SignIn) <= new Date(signInTo),
                    );
                }
                if (keyValue === 'template' && template) {
                    if (template === DATA_CONSTANT.OPTIONS.LATEST_USERS_FILTER_TEMPLATE.INACTIVE_NEW_USER) {
                        const currentDateConstructIsoFromLocalTime = dateTimeUtils.constructIsoFromLocalTime(
                            new Date(),
                        );
                        const time1DayAgo = dateTimeUtils.addOrSubtractTime(
                            currentDateConstructIsoFromLocalTime,
                            -1,
                            'day',
                        );

                        finalData = finalData.filter(
                            (data) =>
                                // data.hasEnteredSlideshow &&
                                data.firstCp2SignIn && new Date(data.firstCp2SignIn) >= new Date(time1DayAgo),
                        );
                    }
                    if (template === DATA_CONSTANT.OPTIONS.LATEST_USERS_FILTER_TEMPLATE.BASIC_FOR_2_DAYS) {
                        const time2DaysAgo = dateTimeUtils.addOrSubtractTime(new Date().toISOString(), -2, 'day');

                        finalData = finalData.filter(
                            (data) =>
                                data.planStatus.toLowerCase().trim() === 'basic' &&
                                data.firstCp2SignIn &&
                                new Date(data.firstCp2SignIn) <= new Date(time2DaysAgo),
                        );
                    }
                    if (template === DATA_CONSTANT.OPTIONS.LATEST_USERS_FILTER_TEMPLATE.GO_PRO_WITHIN_2_DAYS) {
                        // eslint-disable-next-line
                        finalData = finalData.filter((data) => {
                            if (data.firstCp2SignIn && data.firstGoPro) {
                                const timeDifference =
                                    new Date(data.firstGoPro).getTime() - new Date(data.firstCp2SignIn).getTime();

                                // 2 Days * 24 Hours * Seconds * Milliseconds
                                if (timeDifference <= 2 * 24 * 3600 * 1000) return data;
                            }
                        });
                    }
                }
            }

            setFilteredLatestUsersDetails(finalData);
            finalData.length < 1 && setError('No match found');
        }
    };

    const changeTableProperty = (action: 'add1Page' | 'sub1Page' | 'setPage' | 'setRow', value?: number) => {
        const actionObject = {
            add1Page: () => setPageNumber((prevState) => prevState + 1),
            sub1Page: () => setPageNumber((prevState) => prevState - 1),
            setPage: () => setPageNumber(value || 0),
            setRow: () => setRowsPerPage(value || 0),
        };
        return action ? actionObject[action]() : undefined;
    };

    const resetFilterHandler = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setFilterDetails({});
        setFilteredLatestUsersDetails(latestUsersDetails);
    };

    const getSingleUserDetailsHandler = async (email: string) => {
        if (!email) return;
        setSingleUserDetailsEmail(email);
    };

    const singleUserDetailsErrorHandler = (error: string) => {
        setSingleUserDetailsError(error);
    };

    const tableBackBtnOnClickHandler = () => {
        setSingleUserDetailsEmail(undefined);
    };

    useEffect(() => {
        clearTimeout(showNotification.current);
        showNotification.current = setTimeout(() => setSingleUserDetailsError(''), 2000);
        return () => clearTimeout(showNotification.current);
    }, [singleUserDetailsError]);

    useEffect(() => {
        filterDataHandler();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterDetails]);

    useEffect(() => {
        clearTimeout(timeOutUpdateLatestUsers.current);
        timeOutUpdateLatestUsers.current = setTimeout(() => {
            getLatestUsersHandler();
        }, DATA_CONSTANT.TIME.GET_LATEST_USERS_API_CALL_DELAY);

        return () => clearTimeout(timeOutUpdateLatestUsers.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber, rowsPerPage]);

    return singleUserDetailsEmail ? (
        <UserDetails
            singleUserDetailsEmail={singleUserDetailsEmail}
            tableBackBtnOnClickHandler={tableBackBtnOnClickHandler}
            singleUserDetailsErrorHandler={singleUserDetailsErrorHandler}
        />
    ) : (
        <div className={`${global.componentWrapper} ${styles.latestUsersComponent}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    {singleUserDetailsError && <InstantAlert type="error" text={singleUserDetailsError} />}

                    <TopBarLayout
                        topBar={
                            <LatestUsersTopBar
                                filterDetails={filterDetails}
                                inputOnChangeHandler={inputOnChangeHandler}
                                multipleSelectOnChangeHandler={multipleSelectOnChangeHandler}
                                resetFilterHandler={resetFilterHandler}
                            />
                        }
                        content={
                            <LatestUsersDetails
                                isLoading={isLoading}
                                latestUsersDetails={filteredLatestUsersDetails}
                                error={error}
                                pageNumber={pageNumber}
                                rowsPerPage={rowsPerPage}
                                changeTableProperty={changeTableProperty}
                                getSingleUserDetailsHandler={getSingleUserDetailsHandler}
                            />
                        }
                    />
                </div>
            </div>
        </div>
    );
};
