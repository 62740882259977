import { orderBy } from 'lodash';

import { BiNote } from 'react-icons/bi';
import { HiOutlineUserPlus } from 'react-icons/hi2';
import { IoMdGlobe } from 'react-icons/io';
import { TbStack } from 'react-icons/tb';

import styles from './ViewEditKeysTopBar.module.scss';

import { keyHelper } from 'helpers/key.helper';
import { createKeyLS } from 'services/localService/createKey.local';

import { InputFieldsInterface } from 'interfaces/ui/inputFields.interface';
import { ViewEditKeysFormInterface } from 'interfaces/key.interface';
import { DATA_CONSTANT } from 'constants/data/data.constants';

export const multipleSearchInputFieldsData = (keyFormDetails: ViewEditKeysFormInterface): InputFieldsInterface[] => {
    const allMarkets = keyHelper.getAllMarkets();
    const allKeyCategories = keyHelper.getAllCategories();
    const marketAndCategoryObject = createKeyLS.getCreateKeyCategory();

    const selectedMarketCategories =
        keyFormDetails.market && marketAndCategoryObject
            ? marketAndCategoryObject[keyFormDetails.market]?.categories
            : undefined;

    return [
        {
            fieldName: 'type',
            fieldType: 'select',
            multipleFields: [
                {
                    value: DATA_CONSTANT.PLAN_TYPE.ORGANIZATION,
                },
                {
                    value: DATA_CONSTANT.PLAN_TYPE.STANDARD,
                },
            ],
            isRequired: true,
            fieldClassName: `${styles.selectOption}`,
        },
        {
            fieldName: 'market',
            fieldType: 'select',
            multipleFields: orderBy([...allMarkets, { value: '', label: 'All' }], ['label'], ['asc']),
            fieldClassName: `${styles.selectOption}`,
        },
        {
            fieldName: 'category',
            fieldType: 'select',
            multipleFields: keyFormDetails.market
                ? orderBy(selectedMarketCategories, ['label'], ['asc'])
                : orderBy(allKeyCategories, ['label'], ['asc']),
            fieldClassName: `${styles.selectOption}`,
        },
        {
            fieldName: 'countries',
            fieldClassName: `${styles.inputField}`,
            label: 'Country',
        },
        {
            fieldName: 'note',
            fieldClassName: `${styles.inputField}`,
        },
        {
            fieldName: 'createdBy',
            fieldClassName: `${styles.inputField}`,
        },
        {
            fieldName: 'isActive',
            fieldType: 'selectBoolean',
            fieldClassName: `${styles.selectOption}`,
        },
        {
            fieldName: 'isTrial',
            fieldType: 'selectBoolean',
            fieldClassName: `${styles.selectOption}`,
        },
        {
            fieldName: 'createdFrom',
            fieldType: 'date',
            maxDateTime: new Date().toISOString(),
        },
    ];
};

export const multipleSearchInputIconsArray = {
    countries: <IoMdGlobe />,
    note: <BiNote />,
    createdBy: <HiOutlineUserPlus />,
    type: <TbStack />,
};
