import { FormEvent, useEffect, useRef, useState } from 'react';

import global from 'theme/global/Global.module.scss';
import styles from './ViewEditKeys.module.scss';

import { TopBarLayout } from 'template/topBarLayout/TopBarLayout.template';
import { TabLayout } from 'template/tabLayout/TabLayout.template';

import { keyApi } from 'services/api/key.api';
import { objectUtils } from 'helpers/utilities/object.util';
import { keyValidations } from 'helpers/validation/key.validation';
import { useAppSelector } from 'helpers/customHooks/useRedux.hook';
import { ActivatedUserApiInterface, KeyInterface, ViewEditKeysFormInterface } from 'interfaces/key.interface';

import { ViewEditKeysTopBar } from './components/viewEditKeysTopBar/ViewEditKeysTopBar';
import { SearchKeysDetails } from '../searchKeys/components/searchKeysDetails/SearchKeysDetails';
import { ViewEditKeysDetails } from './components/viewEditKeysDetails/ViewEditKeysDetails';
import { SearchKeysSummary } from '../searchKeys/components/searchKeysSummary/SearchKeysSummary';
import { JsonDetails } from '../searchKeys/components/searchKeysDetails/JsonDetails';
import { InstantAlert } from 'components/common/instantAlert/InstantAlert';

export const ViewEditKeys = () => {
    const adminStore = useAppSelector((state) => state).admin;

    const showNotification = useRef<any>();

    const [isGetKeyLoading, setIsGetKeyLoading] = useState(false);
    const [isActivatedUsersLoading, setIsActivatedUsersLoading] = useState(false);
    const [showInstantAlert, setShowInstantAlert] = useState(false);
    const [singleKeyDetails, setSingleKeyDetails] = useState<KeyInterface>();
    const [multipleKeyDetails, setMultipleKeyDetails] = useState<KeyInterface[]>();
    const [activatedUsersDetails, setActivatedUsersDetails] = useState<ActivatedUserApiInterface[]>();
    const [keyFormDetails, setKeyFormDetails] = useState<ViewEditKeysFormInterface>({
        createdBy: adminStore.userDisplayName,
    });

    const inputOnChangeHandler = (value: string, fieldName?: string) => {
        if (!fieldName) return;
        setKeyFormDetails((prevState) => ({ ...prevState, [fieldName]: value, error: '' }));
    };

    const getMultipleKeysDetails = async (type?: string) => {
        if (type === 'delete' && (!multipleKeyDetails || multipleKeyDetails.length < 2)) {
            setSingleKeyDetails(undefined);
            setMultipleKeyDetails(undefined);
            return setKeyFormDetails({ createdBy: adminStore.userDisplayName });
        }

        setIsGetKeyLoading(true);

        const formWithoutError = objectUtils.removeProperty(keyFormDetails, ['error']);
        const trimmedFormDetails = objectUtils.trimSpace(formWithoutError);
        const formDetailsWithoutEmptyFields = objectUtils.removeEmptyField(trimmedFormDetails);
        const formDetailsWithConstructedIsoDateFromLocalTime = objectUtils.constructIsoFromLocalTimeInObject(
            formDetailsWithoutEmptyFields,
            ['createdFrom'],
        );

        const key = await keyApi.getKeyMultiple(formDetailsWithConstructedIsoDateFromLocalTime);
        setIsGetKeyLoading(false);

        if (typeof key === 'string') setKeyFormDetails((prevState) => ({ ...prevState, error: key }));
        else setMultipleKeyDetails(key);
    };

    const getActivatedUsers = async () => {
        setIsActivatedUsersLoading(true);
        const activatedUsers = (singleKeyDetails && (await keyApi.activatedUsers(singleKeyDetails.key))) || '';
        setIsActivatedUsersLoading(false);
        if (typeof activatedUsers !== 'string') setActivatedUsersDetails(activatedUsers);
    };

    const getSingleKeyDetailsHandler = async (key: string) => {
        if (!key) return;
        const data = multipleKeyDetails?.find((item) => item.key === key);
        setSingleKeyDetails(data);
    };

    const tableBackBtnOnClickHandler = () => {
        setSingleKeyDetails(undefined);
        setActivatedUsersDetails([]);
    };

    const validateDetails = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setSingleKeyDetails(undefined);
        setMultipleKeyDetails(undefined);

        if (!objectUtils.getObjectLength(keyFormDetails)) return;
        const isFormDetailsValid = keyValidations.viewEditKeysFormValidation(keyFormDetails);
        if (isFormDetailsValid !== '') {
            setKeyFormDetails((prevState: ViewEditKeysFormInterface) => ({ ...prevState, error: isFormDetailsValid }));
        } else return getMultipleKeysDetails();
    };

    useEffect(() => {
        singleKeyDetails?.key && getSingleKeyDetailsHandler(singleKeyDetails.key);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [multipleKeyDetails]);

    useEffect(() => {
        clearTimeout(showNotification.current);
        showNotification.current = setTimeout(() => setShowInstantAlert(false), 2000);
        return () => clearTimeout(showNotification.current);
    }, [showInstantAlert]);

    const tabContent = singleKeyDetails && [
        {
            id: 0,
            label: 'Key Info',
            content: (
                <>
                    <SearchKeysSummary
                        keyDetails={singleKeyDetails}
                        setSingleKeyDetails={setSingleKeyDetails}
                        refreshKeyDetails={getMultipleKeysDetails}
                        setShowInstantAlert={setShowInstantAlert}
                    />
                    <SearchKeysDetails
                        keyDetails={singleKeyDetails}
                        activatedUsersDetails={activatedUsersDetails}
                        getActivatedUsers={getActivatedUsers}
                        isLoading={isActivatedUsersLoading}
                    />
                </>
            ),
        },
        {
            id: 1,
            label: 'JSON',
            content: <JsonDetails keyDetails={singleKeyDetails} />,
        },
    ];

    return (
        <div className={`${global.componentWrapper} ${styles.viewEditComponent}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    {showInstantAlert && <InstantAlert type="success" text="Successfully copied" />}
                    <TopBarLayout
                        topBar={
                            <ViewEditKeysTopBar
                                keyFormDetails={keyFormDetails}
                                inputOnChangeHandler={inputOnChangeHandler}
                                isLoading={isGetKeyLoading}
                                validateDetails={validateDetails}
                            />
                        }
                        content={
                            !singleKeyDetails ? (
                                multipleKeyDetails && (
                                    <ViewEditKeysDetails
                                        keyDetails={multipleKeyDetails}
                                        getSingleKeyDetailsHandler={getSingleKeyDetailsHandler}
                                    />
                                )
                            ) : (
                                <TabLayout
                                    tabs={tabContent}
                                    backBtnOnClickHandler={tableBackBtnOnClickHandler}
                                    noOuterPadding={true}
                                />
                            )
                        }
                    />
                </div>
            </div>
        </div>
    );
};
