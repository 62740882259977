import { StoreType } from 'context/app/store.redux';

export const storeLS = {
    setStoreData: (value: StoreType) => {
        localStorage.setItem('store', JSON.stringify(value));
    },

    getStoreData: (): StoreType | null => {
        return JSON.parse(localStorage.getItem('store')!);
    },
};
