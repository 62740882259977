import global from 'theme/global/Global.module.scss';
import styles from './UserDetailsModules.module.scss';

import { DataGrid } from 'template/dataGrid/DataGrid.template';
import { DataTable } from 'template/dataTable/DataTable.template';

import { UserInterface } from 'interfaces/user.interface';

import { subscriptionDetailsGridData } from 'data/user/userDetails/details/subscriptionDetails.gridData';
import { userOnboardingTableData } from 'data/user/userDetails/details/userOnboarding.tableData';
import { goProTriggersTableData } from 'data/user/userDetails/details/goProTriggers.tableData';
import { subscriptionHistoryTableData } from 'data/user/userDetails/details/subscriptionHistory.tableData';

interface SubscriptionDetailsInterface {
    user: UserInterface;
}

export const SubscriptionDetails = ({ user }: SubscriptionDetailsInterface) => {
    const subscriptionDetails = subscriptionDetailsGridData(user);
    const userOnboarding = userOnboardingTableData(user);
    const goProTriggers = goProTriggersTableData(user);
    const subscriptionHistory = subscriptionHistoryTableData(user);

    return (
        <div className={`${global.moduleWrapper} ${styles.subscriptionDetailsModule}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    <DataGrid data={subscriptionDetails} />
                    <DataTable data={userOnboarding} isCollapsible={true} />
                    <DataTable data={goProTriggers} isCollapsible={true} />
                    <DataTable data={subscriptionHistory} isCollapsible={true} />
                </div>
            </div>
        </div>
    );
};
