import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import { Button } from '@material-ui/core';
import MaterialTable from 'material-table';
import { MdSync, MdRemoveRedEye } from 'react-icons/md';

import global from 'theme/global/Global.module.scss';
import styles from './LiveSessions.module.scss';

import { DefaultLayout } from 'template/defaultLayout/DefaultLayout.template';
import { CommonModal } from 'template/modal/common/CommonModal.template';

import { sessionApi } from 'services/api/session.api';

import ClassDetails from './ClassDetails';

import { tableIcons } from './materialTableIcons';

const LiveClassesRegionView = () => {
    // eslint-disable-next-line  @typescript-eslint/no-unused-vars
    const [region, setRegion] = useState(useParams().region);
    const [liveClassesRegion, setLiveClassesRegion] = useState<any[]>([]);
    const [email, setEmail] = useState('');
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const navigate = useNavigate();

    useEffect(() => {
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let intervalId = setInterval(async () => {
            await loadLiveClasses();
        }, 5000);
        return () => clearInterval(intervalId);
    });

    const init = async () => {
        try {
            await loadLiveClasses();
        } catch (error) {
            setLiveClassesRegion([]);
        }
    };

    const loadLiveClasses = async () => {
        if (!region) return;
        const liveClassesRegionDict = await sessionApi.getClassesInRegion(region);
        setLiveClassesRegion(Object.values(liveClassesRegionDict));
    };

    return (
        <div className={`${global.componentWrapper} ${styles.liveSessionsComponent}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    <DefaultLayout
                        content={
                            <div className={`${styles.details}`}>
                                <div style={{ maxWidth: '100%', margin: 'auto' }}>
                                    <MaterialTable
                                        columns={[
                                            {
                                                title: 'classCode',
                                                field: 'classCode',
                                                // type: 'numeric',
                                                defaultSort: 'asc',
                                            },
                                            { title: 'email', field: 'email' },
                                            { title: 'country', field: 'country' },
                                            { title: 'isInSlideshow', field: 'isInSlideshow' },
                                            // { title: 'participantCount', field: 'count', type: 'numeric' },
                                            { title: 'participantCount', field: 'count' },
                                            { title: 'currentActivity', field: 'activityType' },
                                        ]}
                                        data={liveClassesRegion}
                                        title={region}
                                        options={{
                                            filtering: true,
                                            search: false,
                                            pageSize: 10,
                                            emptyRowsWhenPaging: false,
                                            pageSizeOptions: [10, 20, 50],
                                            detailPanelColumnAlignment: 'right',
                                            actionsColumnIndex: -1,
                                        }}
                                        actions={[
                                            {
                                                icon: () => (
                                                    <i className="material-icons">
                                                        <MdSync />
                                                    </i>
                                                ),
                                                tooltip: 'Reload live classes',
                                                isFreeAction: true,
                                                onClick: async () => loadLiveClasses(),
                                            },
                                            {
                                                icon: () => (
                                                    <i className="material-icons">
                                                        <MdRemoveRedEye />
                                                    </i>
                                                ),
                                                tooltip: 'View details',
                                                onClick: (event, rowData) => {
                                                    setEmail(rowData.email);
                                                    handleOpen();
                                                },
                                            },
                                        ]}
                                        icons={tableIcons as any}
                                    />
                                </div>

                                <Button
                                    style={{ marginTop: '10px', textTransform: 'none' }}
                                    variant="text"
                                    color="primary"
                                    onClick={() => navigate('/session/live-session')}
                                >
                                    Back to summary
                                </Button>
                            </div>
                        }
                    />

                    <CommonModal
                        isModalOpen={open}
                        modalCloseHandler={handleClose}
                        modalContent={<ClassDetails region={region} email={email} />}
                        fixedWidth={'60vw'}
                        fixedHeight={'80vh'}
                        className={styles.liveClassDetailsModal}
                    />
                </div>
            </div>
        </div>
    );
};

export default LiveClassesRegionView;
