import global from 'theme/global/Global.module.scss';
import styles from './AiAccessDetails.module.scss';

import { DataTable } from 'template/dataTable/DataTable.template';

import { aiAccessTableData } from 'data/misc/aiAccess.tableData';

interface AiAccessDetailsInterface {
    aiAccessDetails: string[];
}

export const AiAccessDetails = ({ aiAccessDetails }: AiAccessDetailsInterface) => {
    const aiAccessDetailsTableData = aiAccessTableData(aiAccessDetails);

    return (
        <div className={`${global.moduleWrapper} ${styles.aiAccessDetailsModule}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    <DataTable data={aiAccessDetailsTableData} />
                </div>
            </div>
        </div>
    );
};
