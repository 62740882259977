import dayjs from 'dayjs';
import { startCase } from 'lodash';

import { TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { DateInput } from 'theme/mui/dateTimePicker.theme';

import { commonUtils } from 'helpers/utilities/common.util';

interface DateInterface {
    value: string;
    inputOnChangeHandler: (value: string, fieldName?: string) => void;
    error?: boolean;
    fieldName?: string;
    label?: string;
    isRequired?: boolean;
    minDateTime?: string;
    maxDateTime?: string;
    showOnlyValue?: boolean;
    className?: string;
}

export const DateModule = ({
    value,
    inputOnChangeHandler,
    error,
    fieldName,
    label,
    isRequired,
    minDateTime,
    maxDateTime,
    showOnlyValue,
    className,
}: DateInterface) => {
    const determineDateHandler = (dateEvent: any) => {
        const pickedTime = dayjs(dateEvent).toISOString();
        fieldName ? inputOnChangeHandler(`${pickedTime}`, fieldName) : inputOnChangeHandler(`${pickedTime}`);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateInput
                onChange={(event: any) => event && event.$D && determineDateHandler(event)}
                value={value || null}
                openTo="month"
                views={['year', 'month', 'day']}
                renderInput={(params) => <TextField {...params} error={error} />}
                label={commonUtils.starMarked(label || startCase(fieldName || ''), isRequired)}
                minDate={minDateTime ? dayjs(minDateTime) : undefined}
                maxDate={maxDateTime ? dayjs(maxDateTime) : undefined}
                inputFormat="YYYY/MM/DD"
                className={`${isRequired ? 'isRequired' : ''} ${showOnlyValue ? 'showOnlyValue' : ''} ${
                    className || ''
                }`}
            />
        </LocalizationProvider>
    );
};
