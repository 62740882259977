import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';

import { BtnInfo, IconBtn } from 'theme/mui/buttons.theme';

import global from 'theme/global/Global.module.scss';
import styles from './UserDetailsModules.module.scss';

import { InputFields } from 'template/inputFields/InputFields.template';

import { dateTimeUtils } from 'helpers/utilities/dateTime.util';
import { commonUtils } from 'helpers/utilities/common.util';
import { userApi } from 'services/api/user.api';

import { UserInterface } from 'interfaces/user.interface';

interface DebugLogsInterface {
    user: UserInterface;
}

export const DebugLogs = ({ user }: DebugLogsInterface) => {
    const [debugLogs, setDebugLogs] = useState<{ [key: string]: string } | undefined>();
    const [activeDebugLog, setActiveDebugLog] = useState('');
    const [debugLogsDate, setDebugLogsDate] = useState(dateTimeUtils.constructIsoFromLocalTime(new Date()));
    const [isLoading, setIsLoading] = useState(false);

    const availableDebugLogsTimeStamp = debugLogs && Object.keys(debugLogs).map((item) => item.split('-').pop());

    const dateChangeHandler = (actionType: string) => {
        if (actionType === 'next') {
            const nextDay: any = dayjs(debugLogsDate).add(1, 'day');
            return setDebugLogsDate(nextDay);
        } else {
            const previousDay: any = dayjs(debugLogsDate).subtract(1, 'day');
            return setDebugLogsDate(previousDay);
        }
    };

    const getDebugLogs = async () => {
        setIsLoading(true);
        setDebugLogs(undefined);

        if (debugLogsDate) {
            const getTimeValues = dateTimeUtils.getTimeValues(debugLogsDate);
            let debugLogsDataArray = {};

            for (let i = 0; i < 24; i++) {
                const debugLogsData = await userApi.debugLogs(
                    user.email.trim(),
                    `${getTimeValues.year}${getTimeValues.month}${getTimeValues.day}`,
                    commonUtils.padDigits(i),
                );

                const timeStamp =
                    i > 0 ? (i === 12 ? `time-12 PM` : i < 12 ? `time-${i} AM` : `time-${i - 12} PM`) : `time-12 AM`;
                if (typeof debugLogsData !== 'string')
                    debugLogsDataArray = { ...debugLogsDataArray, [timeStamp]: debugLogsData.data };
            }

            setDebugLogs(debugLogsDataArray);
        }

        setIsLoading(false);
    };

    useEffect(() => {
        debugLogsDate && getDebugLogs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debugLogsDate]);

    return (
        <div className={`${global.moduleWrapper} ${styles.debugLogsModule} ${styles.removeMinContentHeight}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content} ${styles.debugLogsContent}`}>
                    <div className={`${styles.headingContainer}`}>
                        <h6 className={`${global.head6} ${styles.head}`}>
                            {isLoading
                                ? 'Debug logs | Data loading...'
                                : debugLogs && Object.keys(debugLogs).length < 1
                                ? `No debug logs | ${user.email}`
                                : `Debug logs | ${user.email}`}
                        </h6>
                        <div className={`${styles.debugLogsDatePicker}`}>
                            <IconBtn
                                onClick={() => dateChangeHandler('previous')}
                                className={`${styles.debugLogsDateArrowBtn}`}
                            >
                                <BsArrowLeft className={`${styles.arrowIcon}`} />
                            </IconBtn>
                            <div className={`${styles.debugLogsText}`}>
                                <p className={`${styles.dateLabel}`}>Date :</p>
                                <InputFields
                                    inputFields={[
                                        {
                                            label: 'Select date',
                                            fieldType: 'date',
                                            showOnlyValue: true,
                                            disableMinute: true,
                                            fieldClassName: styles.debugLogsDateField,
                                        },
                                    ]}
                                    inputDetails={debugLogsDate}
                                    inputOnChangeHandler={(value: string) => setDebugLogsDate(value)}
                                />
                            </div>
                            <IconBtn
                                onClick={() => dateChangeHandler('next')}
                                className={`${styles.debugLogsDateArrowBtn}`}
                            >
                                <BsArrowRight className={`${styles.arrowIcon}`} />
                            </IconBtn>
                        </div>
                    </div>

                    <div className={`${styles.timeStampBtnContainer}`}>
                        {availableDebugLogsTimeStamp?.map((data) => (
                            <BtnInfo
                                onClick={() => data && setActiveDebugLog(data)}
                                className={`${styles.timeStampBtn} ${
                                    activeDebugLog.split('-').pop() === data && styles.active
                                }`}
                            >
                                {data}
                            </BtnInfo>
                        ))}
                    </div>

                    {debugLogs && (
                        <pre className={`${global.bodyLarge} ${styles.logText}`}>
                            {debugLogs[`time-${activeDebugLog}`]}
                        </pre>
                    )}
                </div>
            </div>
        </div>
    );
};
