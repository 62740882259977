import { BiError } from 'react-icons/bi';

import global from 'theme/global/Global.module.scss';
import styles from './ComingSoon.module.scss';

export const ComingSoon = () => {
    return (
        <div className={`${global.componentWrapper} ${styles.comingSoonComponent}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    <div className={`${styles.iconContainer}`}>
                        <BiError className={`${styles.icon}`} />
                    </div>
                    <p className={`${global.bodyXl} ${styles.text}`}>Coming soon</p>
                </div>
            </div>
        </div>
    );
};
