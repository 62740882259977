import global from 'theme/global/Global.module.scss';
import styles from './GetAffiliateSummary.module.scss';

import { DataGrid } from 'template/dataGrid/DataGrid.template';

import { AffiliateInterface } from 'interfaces/affiliate.interface';

import { getAffiliateSummaryGridData } from 'data/affiliate/getAffiliate/summary/getAffiliateSummary.gridData';

interface GetAffiliateSummaryInterface {
    affiliateDetails: AffiliateInterface;
}

export const GetAffiliateSummary = ({ affiliateDetails }: GetAffiliateSummaryInterface) => {
    const keySummary = getAffiliateSummaryGridData(affiliateDetails);

    return (
        <div className={`${global.moduleWrapper} ${styles.affiliateSummaryModule}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    <DataGrid data={keySummary} />
                </div>
            </div>
        </div>
    );
};
