import { CurrentClassInterface } from 'interfaces/user.interface';

import { generateCurrentClassCells } from 'data/generateCells/generateCurrentClassCells.data';

export const currentClassToolbarActionsGridData = (liveClasses: CurrentClassInterface) => {
    const cell = generateCurrentClassCells(liveClasses);

    return {
        heading: 'Toolbar Actions',
        rows: [
            [
                cell.toolbarActions_ink,
                cell.toolbarActions_addShape,
                cell.toolbarActions_addTextbox,
                cell.toolbarActions_dragObject,
            ],
            [
                cell.toolbarActions_addWhiteboard,
                cell.toolbarActions_quickPoll,
                cell.toolbarActions_startTimer,
                cell.toolbarActions_openBrowserTab,
            ],
            [
                cell.toolbarActions_pickName,
                cell.toolbarActions_openLeaderboard,
                cell.toolbarActions_awardStar,
                cell.toolbarActions_deductStar,
            ],
        ],
    };
};
