import parse from 'html-react-parser';

import global from 'theme/global/Global.module.scss';
import styles from './DataGrid.template.module.scss';

import { commonUtils } from 'helpers/utilities/common.util';

import { CellInterface } from 'interfaces/ui/cell.interface';
import { GridCellPropsInterface } from 'interfaces/ui/dataGrid.interface';

interface DataGridInterface {
    data: {
        heading?: string;
        rows: CellInterface[][];
        cellProps?: { [tableKey: string]: GridCellPropsInterface };
    };
    isNotDefaultModule?: boolean;
    customHeadingElement?: JSX.Element;
    isFullHeight?: boolean;
    displaySingleLine?: boolean;
    className?: string;
}

export const DataGrid = ({
    data,
    isNotDefaultModule,
    customHeadingElement,
    isFullHeight,
    displaySingleLine,
    className,
}: DataGridInterface) => {
    const cellProps = data.cellProps || {};

    const cellOnClickHandler = (dataLabel: string, dataValue: any) => {
        const getSelectedValueOnClick = cellProps[dataLabel]?.getSelectedValueOnClick;
        if (getSelectedValueOnClick) getSelectedValueOnClick(dataValue);
    };

    const determineIsActiveLink = (dataLabel: string) => {
        const onClickAction = cellProps[dataLabel]?.getSelectedValueOnClick;
        if (onClickAction) return true;
        return false;
    };

    return (
        <div
            className={`${!isNotDefaultModule && global.defaultModule} ${global.moduleWrapper} ${
                styles.dataGridTemplate
            } ${className || ''} ${isFullHeight && styles.fullHeight}`}
        >
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    {data.heading && (
                        <div className={`${styles.headingContainer}`}>
                            <h6 className={`${global.head6} ${styles.heading}`}>{data.heading}</h6>
                            {customHeadingElement}
                        </div>
                    )}
                    <div className={`${styles.detailsContainer}`}>
                        {data.rows.length > 0 &&
                            data.rows.map((row, index) => {
                                return (
                                    <div key={index} className={`${styles.row}`}>
                                        {row.length > 0 &&
                                            row.map((cellValue, index) => (
                                                <div key={index} className={`${styles.details}`}>
                                                    <p
                                                        className={`${global.bodyXs} ${styles.fieldName} ${
                                                            determineIsActiveLink(cellValue.label) && styles.clickable
                                                        }`}
                                                    >
                                                        {cellValue.label}
                                                    </p>
                                                    <div className={`${styles.valueContainer}`}>
                                                        <p
                                                            onClick={() =>
                                                                cellOnClickHandler(cellValue.label, cellValue.value)
                                                            }
                                                            className={`${global.body} ${styles.fieldValue} ${
                                                                determineIsActiveLink(cellValue.label) &&
                                                                styles.highlight
                                                            } ${displaySingleLine && styles.singleLine}`}
                                                        >
                                                            {parse(commonUtils.convertToString(cellValue.value))}
                                                        </p>
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </div>
        </div>
    );
};
