import { useState } from 'react';

import { BtnError, BtnInfo } from 'theme/mui/buttons.theme';

import global from 'theme/global/Global.module.scss';
import styles from './SearchKeysSummary.module.scss';

import { DataGrid } from 'template/dataGrid/DataGrid.template';
import { Block } from 'template/block/Block.template';
import { CommonModal } from 'template/modal/common/CommonModal.template';
import { WarningModal } from 'template/modal/warning/WarningModal.template';

import { keyApi } from 'services/api/key.api';
import { KeyInterface } from 'interfaces/key.interface';

import { searchKeysSummaryGridData } from 'data/key/searchKeys/summary/searchKeysSummary.gridData';
import { EditKeyModalContent } from './components/EditKeyModalContent';

interface SearchKeysSummaryInterface {
    keyDetails: KeyInterface;
    setSingleKeyDetails: (arg: KeyInterface | undefined) => void;
    refreshKeyDetails: (arg?: string) => void;
    setShowInstantAlert?: (arg: boolean) => void;
}

export const SearchKeysSummary = ({
    keyDetails,
    setSingleKeyDetails,
    refreshKeyDetails,
    setShowInstantAlert,
}: SearchKeysSummaryInterface) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const copyKeyValue = (keyValue: string) => {
        navigator.clipboard.writeText(keyValue.replaceAll('<br/>', ' '));
        setShowInstantAlert && setShowInstantAlert(true);
    };

    const deleteKeyHandler = async () => {
        setIsLoading(true);

        const deleteKey = await keyApi.deleteKey(keyDetails.key.trim());
        setIsLoading(false);

        if (deleteKey) {
            refreshKeyDetails('delete');
            setSingleKeyDetails(undefined);
            setIsDeleteModalOpen(false);
        } else return;
    };

    const keySummary = searchKeysSummaryGridData(keyDetails, copyKeyValue);

    return (
        <div className={`${global.moduleWrapper} ${styles.keySummaryModule}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    <DataGrid data={keySummary} />

                    <CommonModal
                        isModalOpen={isEditModalOpen}
                        modalCloseHandler={() => setIsEditModalOpen(false)}
                        modalContent={
                            <EditKeyModalContent
                                modalCloseHandler={() => setIsEditModalOpen(false)}
                                keyDetails={keyDetails}
                                refreshKeyDetails={refreshKeyDetails}
                            />
                        }
                    />

                    <WarningModal
                        isModalOpen={isDeleteModalOpen}
                        modalCloseHandler={() => setIsDeleteModalOpen(false)}
                        heading={'Delete Key'}
                        subText={`Are you sure you want to delete`}
                        subTextHighlight={`${keyDetails.key}`}
                        isLoading={isLoading}
                        confirmBtnText={'Delete'}
                        confirmBtnClickHandler={() => deleteKeyHandler()}
                    />

                    <Block
                        content={
                            <div className={`${styles.btnContainer}`}>
                                <BtnInfo onClick={() => setIsEditModalOpen(true)} className={`${styles.btn}`}>
                                    Edit
                                </BtnInfo>
                                <BtnError onClick={() => setIsDeleteModalOpen(true)} className={`${styles.btn}`}>
                                    Delete
                                </BtnError>
                            </div>
                        }
                    />
                </div>
            </div>
        </div>
    );
};
