import global from 'theme/global/Global.module.scss';
import styles from './GetAffiliateDetails.module.scss';

import { JsonViewer } from 'template/jsonViewer/JsonViewer.template';

import { AffiliateInterface } from 'interfaces/affiliate.interface';

export const JsonDetails = ({ affiliateDetails }: { affiliateDetails: AffiliateInterface }) => {
    return (
        <div className={`${global.moduleWrapper} ${styles.jsonDetailsModule}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    <JsonViewer jsonData={affiliateDetails} />
                </div>
            </div>
        </div>
    );
};
