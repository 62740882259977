import { FormEvent } from 'react';
import { RiKey2Line } from 'react-icons/ri';

import global from 'theme/global/Global.module.scss';
import styles from './GetAffiliateTopBar.module.scss';

import { SimpleSearchBox } from 'template/searchBox/simple/SimpleSearchBox.template';

import { GetAffiliateFormInterface } from 'interfaces/affiliate.interface';

interface GetAffiliateTopBarInterface {
    formDetails: GetAffiliateFormInterface;
    inputOnChangeHandler: (value: string) => void;
    isLoading: boolean;
    validateDetails: (arg: FormEvent<HTMLFormElement>) => void;
}

export const GetAffiliateTopBar = ({
    formDetails,
    inputOnChangeHandler,
    isLoading,
    validateDetails,
}: GetAffiliateTopBarInterface) => {
    return (
        <div className={`${global.moduleWrapper} ${styles.topBarModule}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    <form onSubmit={(e) => validateDetails(e)} className={`${styles.form}`}>
                        <SimpleSearchBox
                            details={formDetails}
                            inputOnChangeHandler={inputOnChangeHandler}
                            isLoading={isLoading}
                            placeholder={`Enter email`}
                            inputIcon={<RiKey2Line className={`${styles.inputIcon}`} />}
                            submitBtnText="Get affiliate"
                        />
                    </form>
                </div>
            </div>
        </div>
    );
};
