import global from 'theme/global/Global.module.scss';
import styles from './CurrentClassCard.module.scss';

import { CurrentClassInterface } from 'interfaces/user.interface';

import { Avatar } from 'components/common/avatar/Avatar';

export const CurrentClassCard = ({ liveClasses }: { liveClasses: CurrentClassInterface }) => {
    const data = {
        classSessionName: liveClasses.classSessionName || '',
        avatarUrl: liveClasses.classSessionAvatar,
        classCode: liveClasses.classCode,
        isSavedClass: liveClasses.savedClassId ? 'SAVED' : 'PUBLIC',
    };

    return (
        <div className={`${global.defaultModule} ${global.moduleWrapper} ${styles.currentClassCardModule}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    <div className={`${styles.avatarContainer}`}>
                        <Avatar
                            url={data.avatarUrl}
                            text={data.classSessionName || data.classCode}
                            globalFontSize="head5"
                        />
                    </div>
                    <div className={`${styles.basicDetails}`}>
                        <h5 className={`${global.head5} ${styles.name}`}>{data.classCode}</h5>
                        <p className={`${global.bodySmall} ${styles.email}`}>{data.classSessionName}</p>
                    </div>
                    <div className={`${styles.classTypeContainer}`}>
                        <p className={`${global.bodyXs} ${styles.classType} ${styles[data.isSavedClass]}`}>
                            {data.isSavedClass}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};
