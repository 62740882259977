import { startCase } from 'lodash';

import { MdFilePresent, MdOutlineCancel } from 'react-icons/md';

import global from 'theme/global/Global.module.scss';
import styles from './InputModules.template.module.scss';

import { commonUtils } from 'helpers/utilities/common.util';

interface FileInputModuleInterface {
    value: any;
    fileInputOnChangeHandler: (value: any, fieldName?: string) => void;
    fieldName?: string;
    label?: string;
    error?: boolean;
    isRequired?: boolean;
    removeSelection?: () => void;
    multipleFiles?: boolean;
    fileName?: string;
    className?: string;
}

export const FileInputModule = ({
    value,
    fileInputOnChangeHandler,
    fieldName,
    label,
    error,
    isRequired,
    removeSelection,
    multipleFiles,
    fileName,
    className,
}: FileInputModuleInterface) => {
    return (
        <div className={`${styles.fileInput} ${className}`}>
            <div className={`${styles.inputContainer}`}>
                <div className={`${styles.dummyContainer} ${error && styles.error}`}>
                    {(value || fileName) && <MdFilePresent className={`${styles.fileIcon}`} />}
                    <p className={`${global.bodyLarge} ${styles.label}`}>
                        {commonUtils.starMarked(
                            (fileName && fileName) ||
                                (value?.name && `File : ${value?.name}`) ||
                                value ||
                                label ||
                                startCase(fieldName || ''),
                            isRequired,
                        )}
                    </p>
                    {(fileName || value) && (
                        <MdOutlineCancel
                            onClick={() => {
                                const fileInput = document.getElementById('fileInput') as HTMLInputElement;
                                fileInput.value = '';
                                removeSelection && removeSelection();
                            }}
                            className={`${styles.cancelIcon}`}
                        />
                    )}
                </div>
                <input
                    onChange={(e) => fileInputOnChangeHandler(e.target.files && e.target.files)}
                    type="file"
                    id="fileInput"
                    multiple={multipleFiles || false}
                    className={`${styles.inputField} ${(fileName || value) && styles.inactive}`}
                />
            </div>
        </div>
    );
};
