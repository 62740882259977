import { cellUtils } from 'helpers/utilities/cell.util';

import { CurrentClassInterface } from 'interfaces/user.interface';

import { generateCurrentClassCells } from 'data/generateCells/generateCurrentClassCells.data';

export const currentClassDetailsGridData = (liveClasses: CurrentClassInterface) => {
    const cell = generateCurrentClassCells(liveClasses);
    const emptyCell = cellUtils.emptyTableCell;

    return {
        heading: 'Class Details',
        rows: [
            [cell.started, cell.isLocked, cell.isInSlideshow],
            [cell.currentSlideState, cell.isAllowGuests, cell.isSaveGuests],
            [cell.isAudienceSlideViewerEnabled, cell.classLimit, emptyCell],
        ],
    };
};
