import axios from 'axios';

import { logger } from '../logger.service';

export const miscApi = {
    aiAccess: async (emails: string): Promise<string> => {
        try {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const response = await axios.post(
                `${process.env.REACT_APP_API}/admin/grant-ai-access?emailsString=${emails}`,
            );
            return 'Success';
        } catch (error: any) {
            logger.error('miscApi.aiAccess() error: ', error.response || error);
            return `Error : ${error.response.data.message}`;
        }
    },
};
