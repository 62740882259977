import { InputFieldsInterface } from 'interfaces/ui/inputFields.interface';

import { SelectModule } from './components/SelectModule';
import { SelectModuleMultiple } from './components/SelectModuleMultiple';
import { RadioModule } from './components/RadioModule';
import { DateModule } from './components/DateModule';
import { DateTimeModule } from './components/DateTimeModule';
import { FileInputModule } from './components/FileInputModule';
import { InputBoxModule } from './components/InputBoxModule';
import { CheckboxModule } from './components/CheckboxModule';
import { TextAreaModule } from './components/TextAreaModule';

interface InputInterface {
    inputFields: InputFieldsInterface[];
    inputDetails: any;
    inputIconsArray?: any;
    inputOnChangeHandler?: (value: string, fieldName?: string) => void;
    fileInputOnChangeHandler?: (value: File | null, fieldName?: string) => void;
    multipleSelectOnChangeHandler?: (value: string[], fieldName?: string) => void;
}

export const InputFields = ({
    inputFields,
    inputDetails,
    inputIconsArray,
    inputOnChangeHandler,
    fileInputOnChangeHandler,
    multipleSelectOnChangeHandler,
}: InputInterface) => {
    return (
        <>
            {inputFields.map((item, index) => {
                if (
                    (item.fieldType === 'select' || item.fieldType === 'selectBoolean') &&
                    !item.isMultipleSelect &&
                    inputOnChangeHandler
                )
                    return (
                        <SelectModule
                            key={index}
                            value={item.fieldName ? inputDetails[item.fieldName] : inputDetails}
                            inputOnChangeHandler={inputOnChangeHandler}
                            error={item.error}
                            fieldType={item.fieldType}
                            fieldName={item.fieldName}
                            multipleFields={item.multipleFields}
                            hideLabel={item.hideLabel}
                            label={item.label}
                            isRequired={item.isRequired}
                            fieldClassName={item.fieldClassName}
                            optionClassName={item.optionClassName}
                        />
                    );
                else if (item.isMultipleSelect && multipleSelectOnChangeHandler)
                    return (
                        <SelectModuleMultiple
                            key={index}
                            value={item.fieldName ? inputDetails[item.fieldName] : inputDetails}
                            multipleSelectOnChangeHandler={multipleSelectOnChangeHandler}
                            error={item.error}
                            fieldName={item.fieldName}
                            multipleFields={item.multipleFields}
                            hideLabel={item.hideLabel}
                            label={item.label}
                            isRequired={item.isRequired}
                            fieldClassName={item.fieldClassName}
                            optionClassName={item.optionClassName}
                        />
                    );
                else if ((item.fieldType === 'radio' || item.fieldType === 'radioBoolean') && inputOnChangeHandler)
                    return (
                        <RadioModule
                            key={index}
                            value={item.fieldName ? inputDetails[item.fieldName] : inputDetails}
                            inputOnChangeHandler={inputOnChangeHandler}
                            fieldType={item.fieldType}
                            fieldName={item.fieldName}
                            multipleFields={item.multipleFields}
                            hideLabel={item.hideLabel}
                            label={item.label}
                            isRequired={item.isRequired}
                            fieldClassName={item.fieldClassName}
                            optionClassName={item.optionClassName}
                        />
                    );
                else if (item.fieldType === 'checkbox' && inputOnChangeHandler)
                    return (
                        <CheckboxModule
                            key={index}
                            value={item.fieldName ? inputDetails[item.fieldName] : inputDetails}
                            inputOnChangeHandler={inputOnChangeHandler}
                            fieldName={item.fieldName}
                            label={item.label}
                            fieldClassName={item.fieldClassName}
                            optionClassName={item.optionClassName}
                        />
                    );
                else if (item.fieldType === 'date' && inputOnChangeHandler)
                    return (
                        <DateModule
                            key={index}
                            value={item.fieldName ? inputDetails[item.fieldName] : inputDetails}
                            inputOnChangeHandler={inputOnChangeHandler}
                            error={item.error}
                            fieldName={item.fieldName}
                            label={item.label}
                            isRequired={item.isRequired}
                            minDateTime={item.minDateTime}
                            maxDateTime={item.maxDateTime}
                            showOnlyValue={item.showOnlyValue}
                            className={item.fieldClassName}
                        />
                    );
                else if (item.fieldType === 'dateTime' && inputOnChangeHandler)
                    return (
                        <DateTimeModule
                            key={index}
                            value={item.fieldName ? inputDetails[item.fieldName] : inputDetails}
                            inputOnChangeHandler={inputOnChangeHandler}
                            error={item.error}
                            fieldName={item.fieldName}
                            label={item.label}
                            isRequired={item.isRequired}
                            minDateTime={item.minDateTime}
                            maxDateTime={item.maxDateTime}
                            showOnlyValue={item.showOnlyValue}
                            disableMinute={item.disableMinute}
                            className={item.fieldClassName}
                        />
                    );
                else if (item.fieldType === 'file' && fileInputOnChangeHandler)
                    return (
                        <FileInputModule
                            key={index}
                            value={item.fieldName ? inputDetails[item.fieldName] : inputDetails}
                            fileInputOnChangeHandler={fileInputOnChangeHandler}
                            error={item.error}
                            fieldName={item.fieldName}
                            label={item.label}
                            isRequired={item.isRequired}
                            removeSelection={item.removeSelection}
                            multipleFiles={item.multipleFiles}
                            fileName={item.fileName}
                            className={item.fieldClassName}
                        />
                    );
                else if (item.fieldType === 'textArea' && inputOnChangeHandler)
                    return (
                        <TextAreaModule
                            key={index}
                            value={item.fieldName ? inputDetails[item.fieldName] : inputDetails}
                            inputOnChangeHandler={inputOnChangeHandler}
                            fieldName={item.fieldName}
                            minRows={item.minRows}
                            label={item.label}
                            isRequired={item.isRequired}
                            className={item.fieldClassName}
                        />
                    );
                else if (inputOnChangeHandler)
                    return (
                        <InputBoxModule
                            key={index}
                            value={item.fieldName ? inputDetails[item.fieldName] : inputDetails}
                            inputOnChangeHandler={inputOnChangeHandler}
                            error={item.error}
                            fieldName={item.fieldName}
                            maxLength={item.maxLength}
                            type={item.inputType}
                            inputIconsArray={inputIconsArray}
                            label={item.label}
                            isRequired={item.isRequired}
                            isReadOnly={item.isReadOnly}
                            showOnlyValue={item.showOnlyValue}
                            fieldClassName={item.fieldClassName}
                            optionClassName={item.optionClassName}
                            canHideValue={item.canHideValue}
                            isValueHidden={item.isValueHidden}
                            hiddenValueFieldName={item.hiddenValueFieldName}
                            disableAutoComplete={item.disableAutoComplete}
                            variant={item.variant}
                        />
                    );
                else return null;
            })}
        </>
    );
};
