import { ReactNode } from 'react';

import global from 'theme/global/Global.module.scss';
import styles from './TopBarLayout.template.module.scss';

import { useWindowDimensions } from 'helpers/customHooks/useWindowDimensions.hook';

import { MobileTopBar } from 'components/common/mobileTopBar/MobileTopBar';

interface TopBarLayoutInterface {
    topBar?: ReactNode;
    content?: ReactNode;
    className?: string;
}

export const TopBarLayout = ({ topBar, content, className }: TopBarLayoutInterface) => {
    const windowWidth = useWindowDimensions().width;

    return (
        <div className={`${global.componentWrapper} ${styles.topBarLayoutTemplate} ${className || ''}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    {windowWidth < 768 && windowWidth > 0 && (
                        <div className={`${styles.mobileNav}`}>
                            <MobileTopBar />
                        </div>
                    )}

                    <div className={`${styles.mainContainer} ${windowWidth < 768 && styles.mobileTopBarGap}`}>
                        {topBar && <div className={`${styles.topBarContainer}`}>{topBar}</div>}
                        {content && <div className={`${styles.bodyContainer}`}>{content}</div>}
                    </div>
                </div>
            </div>
        </div>
    );
};
