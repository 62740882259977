import { startCase } from 'lodash';

import { TextArea } from 'theme/mui/textArea.theme';

interface TextAreaModuleInterface {
    value: string | number;
    inputOnChangeHandler: (value: string, fieldName?: string) => void;
    fieldName?: string;
    minRows?: number;
    label?: string;
    isRequired?: boolean;
    className?: string;
}

export const TextAreaModule = ({
    value,
    inputOnChangeHandler,
    fieldName,
    minRows,
    label,
    isRequired,
    className,
}: TextAreaModuleInterface) => {
    return (
        <TextArea
            onChange={(e) => {
                fieldName ? inputOnChangeHandler(e.target.value, fieldName) : inputOnChangeHandler(e.target.value);
            }}
            value={value || ''}
            minRows={minRows || 5}
            placeholder={label || startCase(fieldName || '')}
            className={`${isRequired ? 'isRequired' : ''} ${className || ''}`}
        />
    );
};
