import global from 'theme/global/Global.module.scss';
import styles from './UserDetailsModules.module.scss';

import { DataTable } from 'template/dataTable/DataTable.template';

import { UserInterface } from 'interfaces/user.interface';

import { savedClassesTableData } from 'data/user/userDetails/details/savedClasses.tableData';

interface SavedClassesDetailsInterface {
    user: UserInterface;
}

export const SavedClassesDetails = ({ user }: SavedClassesDetailsInterface) => {
    const savedClassesData = savedClassesTableData(user);

    return (
        <div className={`${global.moduleWrapper} ${styles.savedClassesModule}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    <DataTable data={savedClassesData} />
                </div>
            </div>
        </div>
    );
};
