import { BtnInfo } from 'theme/mui/buttons.theme';

import global from 'theme/global/Global.module.scss';
import styles from './CreateAffiliateForm.module.scss';

import { Block } from 'template/block/Block.template';
import { InputFields } from 'template/inputFields/InputFields.template';

import { CreateAffiliateInterface } from 'interfaces/affiliate.interface';

import { createAffiliateInput, createAffiliateInputIconsArray } from './createAffiliateInputFields';

interface CreateKeyFormInterface {
    isLoading: boolean;
    inputDetails: CreateAffiliateInterface;
    inputOnChangeHandler: (value: string, fieldName?: string) => void;
    validateDetailsHandler: (arg: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const CreateAffiliateForm = ({
    isLoading,
    inputDetails,
    inputOnChangeHandler,
    validateDetailsHandler,
}: CreateKeyFormInterface) => {
    return (
        <div className={`${global.moduleWrapper} ${styles.createKeyDetailsModule}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    <Block
                        heading={'Create Affiliate'}
                        content={
                            <div className={styles.formContainer}>
                                <form className={styles.form}>
                                    <InputFields
                                        inputFields={createAffiliateInput(inputDetails)}
                                        inputDetails={inputDetails}
                                        inputIconsArray={createAffiliateInputIconsArray}
                                        inputOnChangeHandler={inputOnChangeHandler}
                                    />
                                </form>
                                <BtnInfo
                                    onClick={(e) => validateDetailsHandler(e)}
                                    loading={isLoading}
                                    type="submit"
                                    className={`${styles.createBtn}`}
                                >
                                    Create
                                </BtnInfo>
                                {(inputDetails.error || inputDetails.success) && (
                                    <p
                                        className={`${inputDetails.error ? styles.error : styles.success} ${
                                            global.bodyLarge
                                        }`}
                                    >
                                        {inputDetails.error || inputDetails.success}
                                    </p>
                                )}
                            </div>
                        }
                    />
                </div>
            </div>
        </div>
    );
};
