import { BiGlobeAlt, BiNote } from 'react-icons/bi';
import { IoMdGlobe } from 'react-icons/io';
import { FaInstagram, FaTiktok, FaTwitter, FaYoutube } from 'react-icons/fa';
import {
    MdAccountCircle,
    MdAlternateEmail,
    MdDomain,
    MdFacebook,
    MdInfoOutline,
    MdLocationOn,
    MdOutlineLocalPhone,
} from 'react-icons/md';
import { AiOutlineFileAdd } from 'react-icons/ai';

import styles from './EditCctModalContent.module.scss';

import { InputFieldsInterface } from 'interfaces/ui/inputFields.interface';

export const editCctModalInputFieldsData = (removeFileSelectionHandler: () => void): InputFieldsInterface[] => {
    return [
        {
            fieldName: 'displayName',
            fieldClassName: `${styles.inputField}`,
        },
        {
            fieldName: 'organization',
            fieldClassName: `${styles.inputField}`,
        },
        {
            fieldName: 'address',
            fieldClassName: `${styles.inputField}`,
        },
        {
            fieldName: 'phone',
            fieldClassName: `${styles.inputField}`,
        },
        {
            fieldName: 'country',
            fieldClassName: `${styles.inputField}`,
        },
        {
            fieldName: 'jobTitle',
            fieldClassName: `${styles.inputField}`,
        },
        {
            fieldName: 'subjects',
            fieldClassName: `${styles.inputField}`,
        },
        {
            fieldName: 'contactEmail',
            fieldClassName: `${styles.inputField}`,
        },
        // {
        //     fieldName: 'bio',
        //     fieldClassName: `${styles.inputField}`,
        // },
        {
            fieldName: 'twitter',
            fieldClassName: `${styles.inputField}`,
        },
        {
            fieldName: 'facebook',
            fieldClassName: `${styles.inputField}`,
        },
        {
            fieldName: 'website',
            fieldClassName: `${styles.inputField}`,
        },
        {
            fieldName: 'instagram',
            fieldClassName: `${styles.inputField}`,
        },
        {
            fieldName: 'youtube',
            fieldClassName: `${styles.inputField}`,
        },
        {
            fieldName: 'tiktok',
            fieldClassName: `${styles.inputField}`,
        },
        {
            fieldName: 'photoUrl',
            fieldType: 'file',
            label: 'Upload Image',
            removeSelection: () => removeFileSelectionHandler(),
            fieldClassName: `${styles.inputField}`,
        },
    ];
};

export const editKeyInputIconArray = {
    displayName: <MdAccountCircle />,
    organization: <MdDomain />,
    address: <MdLocationOn />,
    phone: <MdOutlineLocalPhone />,
    country: <BiGlobeAlt />,
    jobTitle: <MdInfoOutline />,
    subjects: <AiOutlineFileAdd />,
    contactEmail: <MdAlternateEmail />,
    bio: <BiNote />,
    twitter: <FaTwitter />,
    facebook: <MdFacebook />,
    website: <IoMdGlobe />,
    instagram: <FaInstagram />,
    youtube: <FaYoutube />,
    tiktok: <FaTiktok />,
};
