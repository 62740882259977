import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@material-ui/core';
import MaterialTable from 'material-table';
import { MdSync } from 'react-icons/md';

import { DefaultLayout } from 'template/defaultLayout/DefaultLayout.template';

import global from 'theme/global/Global.module.scss';
import styles from './LiveSessions.module.scss';

import { sessionApi } from 'services/api/session.api';

import { tableIcons } from './materialTableIcons';

interface SummaryInterface {
    region: string;
    numClasses: number;
    country: string | number;
    parentRegion?: string;
}

const LiveClassesSummary = () => {
    const navigate = useNavigate();

    const [isRetrievingData, setIsRetrievingData] = useState(false);
    const [summary, setSummary] = useState<SummaryInterface[]>();
    const [uniqueRegions, setUniqueRegions] = useState<string[]>([]);
    const [error, setError] = useState('');

    useEffect(() => {
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const init = async () => {
        try {
            setIsRetrievingData(true);
            await loadSummary();
            setIsRetrievingData(false);
        } catch (error) {
            setSummary(undefined);
        }
    };

    const loadSummary = async () => {
        const classesData = await sessionApi.getLiveClassSessions();
        if (typeof classesData === 'string') return setError(classesData);

        let summary: SummaryInterface[] = [];
        const uniqueRegions = classesData
            .map((c) => c.region)
            .filter((element, index, array) => array.indexOf(element) === index);
        setUniqueRegions(uniqueRegions);

        uniqueRegions.forEach((region) => {
            const classesDataInRegion = classesData.filter((c) => c.region === region);
            const numClassesInRegion = classesDataInRegion.length;
            const uniqueCountriesInRegion = classesDataInRegion
                .map((c) => c.country)
                .filter((element, index, array) => array.indexOf(element) === index);

            summary.push({
                region,
                numClasses: numClassesInRegion,
                country: uniqueCountriesInRegion.length,
            });

            uniqueCountriesInRegion.forEach((country) => {
                summary.push({
                    region: '',
                    numClasses: classesDataInRegion.filter((c) => c.country === country).length,
                    country,
                    parentRegion: region,
                });
            });
        });

        setSummary(summary);
    };

    return (
        <div className={`${global.componentWrapper} ${styles.liveSessionsComponent}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    <DefaultLayout
                        content={
                            <div className={`${styles.details}`}>
                                {isRetrievingData ? (
                                    <p>{error || 'Retrieving data...'}</p>
                                ) : !summary ? (
                                    <p>No liveClasses data</p>
                                ) : (
                                    <>
                                        <div style={{ maxWidth: '100%', margin: 'auto' }}>
                                            <MaterialTable
                                                title="Live classes summary"
                                                data={summary}
                                                columns={[
                                                    { title: 'region', field: 'region' },
                                                    { title: '# classes', field: 'numClasses' },
                                                    { title: 'country', field: 'country' },
                                                ]}
                                                parentChildData={(row: SummaryInterface, rows: SummaryInterface[]) =>
                                                    rows.find((a) => a.region === row.parentRegion)
                                                }
                                                options={{ emptyRowsWhenPaging: false, actionsColumnIndex: -1 }}
                                                actions={[
                                                    {
                                                        icon: () => (
                                                            <i className="material-icons">
                                                                <MdSync />
                                                            </i>
                                                        ),
                                                        tooltip: 'Reload live classes',
                                                        isFreeAction: true,
                                                        onClick: async () => loadSummary(),
                                                    },
                                                ]}
                                                icons={tableIcons as any}
                                            />
                                        </div>
                                        <div className={`${styles.viewRegion}`}>
                                            <p className={`${styles.text}`}>View region : </p>
                                            {uniqueRegions.map((region, index) => (
                                                <Button
                                                    key={index}
                                                    variant="outlined"
                                                    color="primary"
                                                    style={{ marginRight: '10px' }}
                                                    onClick={() => navigate('/session/live-session/' + region)}
                                                >
                                                    {region}
                                                </Button>
                                            ))}
                                        </div>
                                    </>
                                )}
                            </div>
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default LiveClassesSummary;
