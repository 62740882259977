import { AdminLoginInterface } from 'interfaces/admin.interface';
import { DATA_CONSTANT } from 'constants/data/data.constants';

export const loginValidations = {
    loginFormValidation: (adminDetails: AdminLoginInterface): string | string[] => {
        if (!adminDetails?.email?.trim()) return ['emailInvalid', 'Error : Email can not be empty'];
        else if (!adminDetails.email.trim().match(DATA_CONSTANT.REGEX.EMAIL)) {
            return ['emailInvalid', 'Error : Invalid email'];
        } else if (!adminDetails?.password) return ['passwordInvalid', 'Error : Password can not be empty'];
        else return '';
    },
};
