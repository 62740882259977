import { cellUtils } from 'helpers/utilities/cell.util';
import { dateTimeUtils } from 'helpers/utilities/dateTime.util';
import { commonUtils } from 'helpers/utilities/common.util';

import { CurrentClassInterface } from 'interfaces/user.interface';

export const generateCurrentClassCells = (liveClasses: CurrentClassInterface) => {
    const cell = cellUtils.tableCell;

    const calculateTotalStars = () => {
        let totalStarsAwarded = 0;

        for (let participants of liveClasses.onlineParticipantList) {
            totalStarsAwarded = totalStarsAwarded + participants.participantPoints;
        }
        for (let participants of liveClasses.offlineParticipantList) {
            totalStarsAwarded = totalStarsAwarded + participants.participantPoints;
        }

        return totalStarsAwarded;
    };

    return {
        started: cell('Class Started', dateTimeUtils.getDateTimeFromIso(liveClasses.started)),
        ended: cell('Class Ended', liveClasses.ended),
        classDuration: cell(
            'Class Duration',
            dateTimeUtils.timeDifferenceInWords(liveClasses.started, liveClasses.ended),
        ),
        starsAwarded: cell('Stars Awarded', calculateTotalStars()),
        activityType: cell('Current Activity', commonUtils.convertToString(liveClasses.currentActivity?.activityType)),
        onlineParticipantListLength: cell('Online Participant', liveClasses.onlineParticipantList.length),
        offlineParticipantListLength: cell('Offline Participant', liveClasses.offlineParticipantList.length),
        isInSlideshow: cell('Is In Slideshow', commonUtils.booleanToYesNo(liveClasses.isInSlideshow)),
        isLocked: cell('Is Locked', commonUtils.booleanToYesNo(liveClasses.isLocked)),
        isAllowGuests: cell('Is Guests Allowed', commonUtils.booleanToYesNo(liveClasses.isAllowGuests)),
        isSaveGuests: cell('Is Guests Saved', commonUtils.booleanToYesNo(liveClasses.isSaveGuests)),
        isAudienceSlideViewerEnabled: cell(
            'Is Audience Slide Viewer Enabled',
            commonUtils.booleanToYesNo(liveClasses.currentSlideshow.isAudienceSlideViewerEnabled),
        ),
        currentSlide: cell('Current Slide', liveClasses.currentSlideshow.currentSlide),
        totalSlideCount: cell('Total Slide Count', liveClasses.currentSlideshow.totalSlideCount),
        currentSlideState: cell(
            'Current Slide State',
            `${liveClasses.currentSlideshow.currentSlide}/${liveClasses.currentSlideshow.totalSlideCount}`,
        ),
        classLimit: cell('Class Limit', liveClasses.classLimit),
        isSubmissionClosed: cell(
            'Is Submission Closed',
            commonUtils.booleanToYesNo(liveClasses.currentActivity?.isSubmissionClosed),
        ),
        activityResponsesLength: cell(
            'Activity Responses Length',
            liveClasses.currentActivity?.activityResponses.length,
        ),
        toolbarActions_ink: cell('Ink', liveClasses.toolbarActions?.ink),
        toolbarActions_addShape: cell('Add Shape', liveClasses.toolbarActions?.addShape),
        toolbarActions_addTextbox: cell('Add TextBox', liveClasses.toolbarActions?.addTextbox),
        toolbarActions_dragObject: cell('Drag Object', liveClasses.toolbarActions?.dragObject),
        toolbarActions_addWhiteboard: cell('Add Whiteboard', liveClasses.toolbarActions?.addWhiteboard),
        toolbarActions_quickPoll: cell('Quick Poll', liveClasses.toolbarActions?.quickPoll),
        toolbarActions_startTimer: cell('Start Timer', liveClasses.toolbarActions?.startTimer),
        toolbarActions_openBrowserTab: cell('Open Browser Tab', liveClasses.toolbarActions?.openBrowserTab),
        toolbarActions_pickName: cell('Pick Name', liveClasses.toolbarActions?.pickName),
        toolbarActions_openLeaderboard: cell('Open LeaderBoard', liveClasses.toolbarActions?.openLeaderboard),
        toolbarActions_awardStar: cell('Award Star', liveClasses.toolbarActions?.awardStar),
        toolbarActions_deductStar: cell('Deduct Star', liveClasses.toolbarActions?.deductStar),
    };
};
