import { orderBy } from 'lodash';

import { InputFieldsInterface } from 'interfaces/ui/inputFields.interface';
import { CategoryAndTagInterface } from 'interfaces/content.interface';

export const createContentInput = (categoryAndTagDetails: CategoryAndTagInterface): InputFieldsInterface[] => {
    const categoryArray = categoryAndTagDetails.categories.map((data) => ({
        value: data.categoryName,
        label: data.categoryTitle,
    }));
    const tagArray = categoryAndTagDetails.tags.map((data) => ({ value: data.tagName, label: data.tagTitle }));

    return [
        { fieldName: 'title', isRequired: true },
        { fieldName: 'slug', isRequired: true },
        {
            fieldName: 'numberOfSlides',
            inputType: 'number',
            isRequired: true,
        },
        {
            fieldName: 'categories',
            fieldType: 'select',
            multipleFields: orderBy(categoryArray, ['label'], ['asc']),
            isRequired: true,
            isMultipleSelect: true,
        },
        {
            fieldName: 'tags',
            fieldType: 'select',
            multipleFields: orderBy(tagArray, ['label'], ['asc']),
            isRequired: true,
            isMultipleSelect: true,
        },
    ];
};
