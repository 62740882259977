import { orderBy } from 'lodash';

import { AiOutlinePercentage } from 'react-icons/ai';
import { BiNote } from 'react-icons/bi';
import { HiOutlineUserCircle } from 'react-icons/hi';
import { HiOutlineUserPlus } from 'react-icons/hi2';
import { MdAlternateEmail } from 'react-icons/md';

import { InputFieldsInterface } from 'interfaces/ui/inputFields.interface';
import { CreateAffiliateInterface } from 'interfaces/affiliate.interface';
import { DATA_CONSTANT } from 'constants/data/data.constants';

export const createAffiliateInput = (inputDetails: CreateAffiliateInterface): InputFieldsInterface[] => {
    return [
        {
            fieldName: 'name',
            error: inputDetails.errorField?.includes('name'),
            isRequired: true,
        },
        {
            fieldName: 'email',
            error: inputDetails.errorField?.includes('email'),
            isRequired: true,
        },
        {
            fieldName: 'urlCode',
            error: inputDetails.errorField?.includes('urlCode'),
            isRequired: true,
        },
        {
            fieldName: 'countryCode',
            fieldType: 'select',
            multipleFields: orderBy(DATA_CONSTANT.OPTIONS.CREATE_AFFILIATE_COUNTRY, ['label'], ['asc']),
            error: inputDetails.errorField?.includes('countryCode'),
            isRequired: true,
        },
        {
            fieldName: 'discountPercentage',
            inputType: 'number',
            label: 'Discount (%)',
            error: inputDetails.errorField?.includes('discountPercentage'),
            isRequired: true,
        },
        {
            fieldName: 'commission',
            inputType: 'number',
            label: 'Commission (%)',
            error: inputDetails.errorField?.includes('commission'),
            isRequired: true,
        },
        {
            fieldName: 'adminEmail',
            isReadOnly: true,
        },
        {
            fieldName: 'links',
        },
    ];
};

export const createAffiliateInputIconsArray = {
    name: <HiOutlineUserPlus />,
    email: <MdAlternateEmail />,
    discountPercentage: <AiOutlinePercentage />,
    commission: <AiOutlinePercentage />,
    adminEmail: <HiOutlineUserCircle />,
    links: <BiNote />,
};
