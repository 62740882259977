import { BiError } from 'react-icons/bi';
import { BsCheckAll } from 'react-icons/bs';

import global from 'theme/global/Global.module.scss';
import styles from './InstantAlert.module.scss';

interface InstantAlertInterface {
    type: 'success' | 'error';
    text: string;
}

export const InstantAlert = ({ type, text }: InstantAlertInterface) => {
    return (
        <div className={`${global.componentWrapper} ${styles.instantAlertComponent}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content} ${styles[type]}`}>
                    <div className={`${styles.iconContainer}`}>
                        {type === 'error' ? (
                            <BiError className={`${styles.icon}`} />
                        ) : (
                            type === 'success' && <BsCheckAll className={`${styles.icon}`} />
                        )}
                    </div>
                    <p className={`${global.bodyXl} ${styles.text}`}>{text}</p>
                </div>
            </div>
        </div>
    );
};
