import { cellUtils } from 'helpers/utilities/cell.util';

import { KeyInterface } from 'interfaces/key.interface';
import { DATA_CONSTANT } from 'constants/data/data.constants';

import { generateKeyCells } from 'data/generateCells/generateKeyCells.data';

export const verifyApplyKeyUserGridData = (keyDetails: KeyInterface) => {
    const emptyCell = cellUtils.emptyTableCell;
    const cell = generateKeyCells(keyDetails);
    const planConstants = DATA_CONSTANT.PLAN_TYPE;
    const heading = 'Key Details';

    if (keyDetails.type === planConstants.ORGANIZATION) {
        return {
            heading,
            rows: [
                [cell.key, cell.type, cell.isPremium],
                [cell.isTrial, cell.validTo, cell.organization],
                [cell.planExpiryDate, cell.activatedToMaxRatio, cell.note],
            ],
        };
    } else {
        return {
            heading,
            rows: [
                [cell.key, cell.type, cell.isPremium],
                [cell.isTrial, cell.validTo, cell.duration],
                [cell.activatedToMaxRatio, cell.note, emptyCell],
            ],
        };
    }
};
