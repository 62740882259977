import { startCase } from 'lodash';

import { InputLabel, Select } from '@mui/material';

import { MuiForm } from 'theme/mui/form.theme';
import { SelectItem } from 'theme/mui/listItems.theme';

import global from 'theme/global/Global.module.scss';
import styles from './InputModules.template.module.scss';

import { commonUtils } from 'helpers/utilities/common.util';

interface SelectModuleInterface {
    value: string | boolean | undefined;
    inputOnChangeHandler: (value: string, fieldName?: string) => void;
    error?: boolean;
    fieldType: string;
    fieldName?: string;
    multipleFields?: {
        value: string;
        label?: string;
    }[];
    hideLabel?: boolean;
    label?: string;
    isRequired?: boolean;
    fieldClassName?: string;
    optionClassName?: string;
}

export const SelectModule = ({
    value,
    inputOnChangeHandler,
    error,
    fieldType,
    fieldName,
    multipleFields,
    hideLabel,
    label,
    isRequired,
    fieldClassName,
    optionClassName,
}: SelectModuleInterface) => {
    return (
        <MuiForm key={fieldName} className={`${styles.selectOption} ${fieldClassName || ''}`}>
            {!hideLabel && (
                <InputLabel className={`${isRequired ? 'isRequired' : ''}`}>
                    {commonUtils.starMarked(label || startCase(fieldName || ''), isRequired)}
                </InputLabel>
            )}
            <Select
                value={value?.toString() || ''}
                onChange={(e) => {
                    fieldName ? inputOnChangeHandler(e.target.value, fieldName) : inputOnChangeHandler(e.target.value);
                }}
                error={error}
                label={commonUtils.starMarked(label || startCase(fieldName || ''), isRequired)}
                className={`${global.customSelect}`}
            >
                {/* These two entries have to be separate because MUI Select does not support Fragment */}
                {fieldType === 'selectBoolean' && (
                    <SelectItem value={'false'} className={`${optionClassName || ''}`}>
                        No
                    </SelectItem>
                )}
                {fieldType === 'selectBoolean' && (
                    <SelectItem value={'true'} className={`${optionClassName || ''}`}>
                        Yes
                    </SelectItem>
                )}

                {multipleFields &&
                    multipleFields.map((option, index) => (
                        <SelectItem key={index} value={option.value} className={`${optionClassName || ''}`}>
                            {option.label || startCase(option.value)}
                        </SelectItem>
                    ))}
            </Select>
        </MuiForm>
    );
};
