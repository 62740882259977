import { IoMdGlobe } from 'react-icons/io';

import styles from './GetAllAffiliatesTopBar.module.scss';

import { InputFieldsInterface } from 'interfaces/ui/inputFields.interface';

export const filterInputFieldsData = (): InputFieldsInterface[] => {
    return [
        {
            fieldName: 'search',
            fieldClassName: `${styles.inputField}`,
        },
        {
            fieldName: 'countryCode',
            fieldClassName: `${styles.inputField}`,
        },
    ];
};

export const filterInputFieldsIconsArray = {
    country: <IoMdGlobe />,
};
