import { commonUtils } from './common.util';
import { dateTimeUtils } from './dateTime.util';

export const objectUtils = {
    getObjectLength: (objectValue: { [key: string]: any }): number | null => {
        const objectKeysLength = Object.keys(objectValue).length;
        if (objectKeysLength > 0) return objectKeysLength;
        else return null;
    },

    removeProperty: (objectValue: { [key: string]: any }, excludeValues = ['']): { [key: string]: any } => {
        const objectKeys = Object.keys(objectValue);
        let finalObject = {};
        for (let key of objectKeys) {
            if (!excludeValues.includes(key)) finalObject = { ...finalObject, [key]: objectValue[key] };
        }
        return finalObject;
    },

    trimSpace: (objectValue: { [key: string]: any }, arrayValues = ['']): { [key: string]: any } => {
        const objectKeys = (objectValue && Object.keys(objectValue)) || [];
        let finalObject = {};

        for (let key of objectKeys) {
            if (typeof objectValue[key] === 'object' && !Array.isArray(objectValue[key])) {
                finalObject = { ...finalObject, [key]: objectUtils.trimSpace(objectValue[key], arrayValues) };
            } else if (Array.isArray(objectValue[key])) {
                finalObject = {
                    ...finalObject,
                    [key]: objectValue[key].length > 0 ? commonUtils.trimArraySpace(objectValue[key]) : [],
                };
            } else if (arrayValues.includes(key) && objectValue[key]) {
                finalObject = {
                    ...finalObject,
                    [key]:
                        typeof objectValue[key] === 'string'
                            ? commonUtils.trimCommaSeparatedString(objectValue[key])
                            : '',
                };
            } else if (typeof objectValue[key] === 'string') {
                finalObject = { ...finalObject, [key]: objectValue[key].trim() };
            } else finalObject = { ...finalObject, [key]: objectValue[key] };
        }
        return finalObject;
    },

    removeEmptyField: (objectValue: { [key: string]: any }): { [key: string]: any } => {
        const objectKeys = Object.keys(objectValue);
        let finalObject = {};

        for (let key of objectKeys) {
            if (typeof objectValue[key] !== 'undefined' && objectValue[key] !== '') {
                finalObject = { ...finalObject, [key]: objectValue[key] };
            }
        }
        return finalObject;
    },

    removeEmptyFieldAndBlankArray: (objectValue: { [key: string]: any }): { [key: string]: any } => {
        const objectKeys = Object.keys(objectValue);
        let finalObject = {};

        for (let key of objectKeys) {
            if (typeof objectValue[key] === 'object' && !Array.isArray(objectValue[key])) {
                if (Object.values(objectValue[key]).length > 0) {
                    finalObject = { ...finalObject, [key]: objectValue[key] };
                }
            } else if (Array.isArray(objectValue[key])) {
                if (objectValue[key].length > 0) {
                    finalObject = { ...finalObject, [key]: objectValue[key] };
                }
            } else if (typeof objectValue[key] !== 'undefined' && objectValue[key] !== '') {
                finalObject = { ...finalObject, [key]: objectValue[key] };
            }
        }
        return finalObject;
    },

    updateObjectKeyValue: <T>(objectValue: T, updateObj: { [key: string]: any }): T => {
        const newObjectValue: any = objectValue;
        for (let key of Object.keys(updateObj)) {
            if (newObjectValue[key]) newObjectValue[key] = `${newObjectValue[key]}, ${updateObj[key]}`;
            else newObjectValue[key] = key === 'error' ? `Error : ${updateObj[key]}` : `${updateObj[key]}`;
        }
        return newObjectValue;
    },

    constructIsoFromLocalTimeInObject: <T>(objectValue: T, dateFields: string[]): T => {
        const newObjectValue: any = objectValue;
        for (let prop of dateFields) {
            if (newObjectValue[prop]) {
                newObjectValue[prop] = dateTimeUtils.constructIsoFromLocalTime(newObjectValue[prop]);
            }
        }
        return newObjectValue;
    },

    flatObject: (objectValue: { [key: string]: any }): { [key: string]: any } => {
        const objectKeys = Object.keys(objectValue);
        let finalObject = {};

        for (let key of objectKeys) {
            if (typeof objectValue[key] === 'object' && !Array.isArray(objectValue[key])) {
                finalObject = { ...finalObject, ...objectUtils.flatObject(objectValue[key]) };
            } else finalObject = { ...finalObject, [key]: objectValue[key] };
        }
        return finalObject;
    },
};
