import { ReactNode } from 'react';

import { BtnInfo } from 'theme/mui/buttons.theme';

import global from 'theme/global/Global.module.scss';
import styles from './SimpleSearchBox.template.module.scss';

import { InputFields } from 'template/inputFields/InputFields.template';

import { TopBarSimpleFormInterface } from 'interfaces/ui/topBar.interface';

interface SimpleSearchBoxInterface {
    details: TopBarSimpleFormInterface;
    inputOnChangeHandler: (value: string) => void;
    isLoading?: boolean;
    placeholder: string;
    maxLength?: number;
    inputIcon?: ReactNode;
    submitBtnText: string;
    className?: string;
}

export const SimpleSearchBox = ({
    details,
    inputOnChangeHandler,
    isLoading,
    placeholder,
    maxLength,
    inputIcon,
    submitBtnText,
    className,
}: SimpleSearchBoxInterface) => {
    return (
        <div className={`${global.moduleWrapper} ${styles.simpleSearchBoxTemplate} ${className || ''}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    <div className={`${styles.inputContainer}`}>
                        <InputFields
                            inputFields={[
                                {
                                    error: details.error ? true : false,
                                    maxLength: maxLength,
                                    fieldClassName: `${styles.inputField}`,
                                    label: placeholder,
                                    isRequired: true,
                                },
                            ]}
                            inputDetails={details.value}
                            inputIconsArray={inputIcon}
                            inputOnChangeHandler={inputOnChangeHandler}
                        />
                        {details.error && <p className={`${styles.error} ${global.body}`}>{details.error}</p>}
                    </div>

                    <BtnInfo loading={isLoading} type="submit" className={`${global.bodySmall} ${styles.submitBtn}`}>
                        {submitBtnText}
                    </BtnInfo>
                </div>
            </div>
        </div>
    );
};
