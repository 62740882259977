import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';

import { BtnInfo, IconBtn } from 'theme/mui/buttons.theme';

import styles from '../UserDetailsModules.module.scss';

import { InputFields } from 'template/inputFields/InputFields.template';
import { dateTimeUtils } from 'helpers/utilities/dateTime.util';

interface UsageLogsTableInputInterface {
    isLoading: boolean;
    getUsageLogs: (arg: string) => void;
    exportExcelFileHandler: () => void;
}

export const UsageLogsTableInput = ({
    isLoading,
    getUsageLogs,
    exportExcelFileHandler,
}: UsageLogsTableInputInterface) => {
    const [pickedDate, setPickedDate] = useState<string>(dateTimeUtils.pickInIso(dayjs()));

    const dateChangeHandler = (actionType: string) => {
        if (actionType === 'next') {
            const nextDay: any = dayjs(pickedDate).add(1, 'day');
            return setPickedDate(nextDay);
        } else {
            const previousDay: any = dayjs(pickedDate).subtract(1, 'day');
            return setPickedDate(previousDay);
        }
    };

    useEffect(() => {
        pickedDate && getUsageLogs(pickedDate);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pickedDate]);

    return (
        <div className={`${styles.usageLogsDatePicker}`}>
            <BtnInfo
                onClick={() => getUsageLogs(pickedDate)}
                loading={isLoading}
                className={`${styles.refreshDataBtn}`}
            >
                Refresh
            </BtnInfo>
            <IconBtn onClick={() => dateChangeHandler('previous')} className={`${styles.usageLogsDateArrowBtn}`}>
                <BsArrowLeft className={`${styles.arrowIcon}`} />
            </IconBtn>
            <div className={`${styles.usageLogsText}`}>
                <p className={`${styles.dateLabel}`}>Date :</p>
                <InputFields
                    inputFields={[
                        {
                            label: 'Select Date',
                            fieldType: 'date',
                            maxDateTime: new Date().toISOString(),
                            showOnlyValue: true,
                            fieldClassName: styles.usageLogsDateField,
                        },
                    ]}
                    inputDetails={pickedDate}
                    inputOnChangeHandler={(value: string) => setPickedDate(value)}
                />
            </div>
            <IconBtn onClick={() => dateChangeHandler('next')} className={`${styles.usageLogsDateArrowBtn}`}>
                <BsArrowRight className={`${styles.arrowIcon}`} />
            </IconBtn>
            <BtnInfo onClick={() => exportExcelFileHandler()} className={`${styles.exportCsvDataBtn}`}>
                Export CSV
            </BtnInfo>
        </div>
    );
};
