import { UserInterface } from 'interfaces/user.interface';
import { generateUserCells } from 'data/generateCells/generateUserCells.data';

export const userSummaryGridData = (user: UserInterface) => {
    const cell = generateUserCells(user);

    return {
        heading: 'User Overview',
        rows: [
            [cell.lastSignIn, cell.lastApiVer],
            [cell.cpVer, cell.country],
        ],
    };
};
