import { read, utils } from 'xlsx';

import { commonUtils } from './utilities/common.util';

import { CsvUploadFileInputInterface } from 'interfaces/common.interface';
import { NewCctInterface, NewCscInterface } from 'interfaces/classPointCertified.interface';

export const miscHelper = {
    convertCouponToSubscribeUrl: (value: string) => {
        // return `${process.env.REACT_APP_SUBSCRIBE_URL}/${btoa(JSON.stringify({ couponCode: value }))}`;

        const normalUrl = `${process.env.REACT_APP_WEB_APP_URL}/cp/checkout?cpn=${value}`;
        const hiddenUrl = `${process.env.REACT_APP_WEB_APP_URL}/cp/checkout?hcpn=${btoa(value)}`;

        return { normalUrl, hiddenUrl };
    },

    convertUrlCodeToSubscribeUrl: (value: string) => {
        return `${process.env.REACT_APP_SUBSCRIBE_URL}/?a=${value}`;
    },

    readCsvFileForCct: (
        fileDetails: any,
        setCsvFileDetails: (arg: CsvUploadFileInputInterface) => void,
        setCsvFileEncodedData: (arg: NewCctInterface[]) => void,
    ) => {
        const reader = new FileReader();
        reader.onload = function (e: any) {
            let fileData = read(e.target.result, { type: 'array' });

            // ===== Note : CSV column index to API body map =====
            // name: 'details.displayName',
            // country: 'details.country',
            // firstName: 'details.bio.firstName',
            // subject: 'details.subjects',
            // dateJoined: 'started',
            // expiryDate: 'titleExpiryDate',
            // organization: 'details.organization',
            // address: 'details.address',
            // phone: 'details.phone',
            // role: 'details.jobTitle',
            // twitter: 'details.links.twitter',
            // youTube: 'details.links.youtube',
            // instagram: 'details.links.instagram',
            // city: 'details.bio.city',
            // facebook: 'details.links.facebook',
            // linkedIn: 'details.bio.linkedin',
            // telegram: 'details.bio.telegram',
            // website: 'details.links.website',
            // gender: 'details.bio.gender',
            // lastRenewed: 'details.bio.lastRenewed',
            // tiktok: 'details.links.tiktok',
            // email: 'email',

            const dataArray = fileData.Sheets.Sheet1;
            if (!dataArray) {
                return setCsvFileDetails({ file: undefined, error: 'Error : Please select a valid CSV file' });
            }

            const jsonDataArray: { [key: string]: string }[] = utils.sheet_to_json(dataArray, { raw: false });
            const arrangedDataArray = [];

            for (let i = 0; i < jsonDataArray.length; i++) {
                const data = jsonDataArray[i];
                const arrangedData: any = { details: { links: {} } };

                // const updateBio = (value: string) => {
                //     if (arrangedData.details.bio) arrangedData.details.bio = `${arrangedData.details.bio}, ${value}`;
                //     else arrangedData.details.bio = value;
                // };

                const updateSocial = (fieldName: string, value: string) => {
                    arrangedData.details.links = { ...arrangedData.details.links, [fieldName]: value };
                };

                const convertToDate = (value: string) => {
                    const dateValueArray = value.split('/');
                    return `20${dateValueArray[2]}-${commonUtils.padDigits(dateValueArray[0])}-${commonUtils.padDigits(
                        dateValueArray[1],
                    )}T00:00:00.000Z`;
                };

                for (const [key, value] of Object.entries(data)) {
                    if (key === 'name') arrangedData.details.displayName = value;
                    else if (key === 'country') arrangedData.details.country = value;
                    // else if (key === 'firstName') updateBio(`First name : ${value}`);
                    else if (key === 'subject') arrangedData.details.subjects = value.split(',');
                    else if (key === 'dateJoined') arrangedData.started = convertToDate(value);
                    else if (key === 'expiryDate') arrangedData.titleExpiryDate = convertToDate(value);
                    else if (key === 'organization') arrangedData.details.organization = value;
                    else if (key === 'address') arrangedData.details.address = value;
                    else if (key === 'phone') arrangedData.details.phone = value;
                    else if (key === 'role') arrangedData.details.jobTitle = value;
                    else if (key === 'twitter') updateSocial('twitter', value);
                    else if (key === 'youTube') updateSocial('youtube', value);
                    else if (key === 'instagram') updateSocial('instagram', value);
                    else if (key === 'city') arrangedData.details.city = value;
                    else if (key === 'facebook') updateSocial('facebook', value);
                    // else if (key === 'linkedIn') updateBio(`LinkedIn : ${value}`);
                    // else if (key === 'telegram') updateBio(`Telegram : ${value}`);
                    else if (key === 'website') updateSocial('website', value);
                    else if (key === 'gender') arrangedData.details.gender = value.toLowerCase();
                    // else if (key === 'lastRenewed') updateBio(`Last Renewed : ${value}`);
                    else if (key === 'tiktok') updateSocial('tiktok', value);
                    else if (key === 'email') {
                        arrangedData.email = value.toLowerCase().trim();
                        arrangedData.details.contactEmail = value.toLowerCase().trim();
                    }
                    // else updateBio(`Others : ${value}`);
                }

                arrangedDataArray.push(arrangedData);
            }

            setCsvFileEncodedData(arrangedDataArray);
        };

        fileDetails && reader.readAsArrayBuffer(fileDetails);
    },

    readCsvFileForCsc: (
        fileDetails: any,
        setCsvFileDetails: (arg: CsvUploadFileInputInterface) => void,
        setCsvFileEncodedData: (arg: NewCscInterface[]) => void,
    ) => {
        const reader = new FileReader();
        reader.onload = function (e: any) {
            let fileData = read(e.target.result, { type: 'array' });

            // ===== Note : CSV column index to API body map =====
            // email: 'email',
            // 'applicationPostUrls': 'applicationPostUrls',
            // 'profileUrls': 'profileUrls',

            const dataArray = fileData.Sheets.Sheet1;
            if (!dataArray) {
                return setCsvFileDetails({ file: undefined, error: 'Error : Please select a valid CSV file' });
            }

            const jsonDataArray: { [key: string]: string }[] = utils.sheet_to_json(dataArray, { raw: false });
            const arrangedDataArray: NewCscInterface[] = [];

            for (let i = 0; i < jsonDataArray.length; i++) {
                const data = jsonDataArray[i];
                const arrangedData: NewCscInterface = {
                    email: '',
                    applicationPostUrls: [],
                    profileUrls: [],
                };

                for (const [key, value] of Object.entries(data)) {
                    if (key === 'email') arrangedData.email = value.toString().trim();
                    else if (key === 'applicationPostUrls') {
                        arrangedData.applicationPostUrls = value
                            .toString()
                            .split(/,|;| /)
                            .filter((url: string) => url);
                    } else if (key === 'profileUrls') {
                        arrangedData.profileUrls = value.split(',');
                    }
                }

                arrangedDataArray.push(arrangedData);
            }

            setCsvFileEncodedData(arrangedDataArray);
        };

        fileDetails && reader.readAsArrayBuffer(fileDetails);
    },
};
