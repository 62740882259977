import { useEffect, useState } from 'react';

import global from 'theme/global/Global.module.scss';
import styles from './ActivateTrial.module.scss';

import { BtnList } from 'theme/mui/buttons.theme';

import { subscriptionApi } from 'services/api/subscription.api';

import { UserInterface } from 'interfaces/user.interface';

interface ActivateTrialInterface {
    user: UserInterface;
    refreshUserDetails: () => void;
}

export const ActivateTrial = ({ user, refreshUserDetails }: ActivateTrialInterface) => {
    const [isLoading, setIsLoading] = useState(false);
    const [statusMessage, setStatusMessage] = useState<{ isSuccessful: boolean; message: string } | undefined>();

    const activateTrialHandler = async () => {
        setIsLoading(true);
        const checkTrialEligibility = await subscriptionApi.checkTrial(user.email.trim());

        if (checkTrialEligibility === 'Success') {
            const activateTrial = await subscriptionApi.activateTrial(user.email.trim());
            if (activateTrial === 'Success') {
                setStatusMessage({ isSuccessful: true, message: 'Success : Trial activated successfully' });
                setTimeout(() => refreshUserDetails(), 1500);
            } else setStatusMessage({ isSuccessful: false, message: activateTrial });
        } else setStatusMessage({ isSuccessful: false, message: checkTrialEligibility });

        setIsLoading(false);
    };

    useEffect(() => {
        if (statusMessage) setTimeout(() => setStatusMessage(undefined), 2500);
    }, [statusMessage]);

    return (
        <div className={`${global.moduleWrapper} ${styles.activateTrialModule}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    <BtnList
                        onClick={() => activateTrialHandler()}
                        loading={isLoading}
                        className={`${styles.userActionListBtn}`}
                    >
                        Activate Trial
                    </BtnList>
                    {statusMessage?.message && (
                        <p
                            className={`${global.body} ${
                                statusMessage?.isSuccessful ? styles.successMessage : styles.errorMessage
                            }`}
                        >
                            {statusMessage.message}
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
};
