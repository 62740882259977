import { cellUtils } from 'helpers/utilities/cell.util';

import { UserInterface } from 'interfaces/user.interface';

import { generateUserCells } from 'data/generateCells/generateUserCells.data';

export const userStatsGridData = (user: UserInterface) => {
    const cell = generateUserCells(user);
    const emptyCell = cellUtils.emptyTableCell;

    return {
        heading: 'User Stats',
        rows: [
            [cell.userCreated, cell.firstSignIn, cell.firstCp2SignIn],
            [cell.addedFirstActivityButton, cell.firstSlideshowStarted, cell.firstClassSessionStarted],
            [cell.lastSignIn, cell.lastClassSessionStarted, emptyCell],
            [cell.signupOs, cell.officeVer, cell.winVer],
        ],
    };
};
