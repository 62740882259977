import { styled, Checkbox } from '@mui/material';

// ========== CheckBoxes ==========
export const CheckBox = styled(Checkbox)({
    padding: '0.3rem',
    color: 'var(--clr_text_neutral_dark)',

    '&.Mui-checked': {
        color: 'var(--clr_text_info)',
        '&:hover': {
            color: 'var(--clr_text_info_dark)',
        },
    },

    '&:hover': {
        color: 'var(--clr_text_primary)',
    },
});

export const CheckBoxLight = styled(Checkbox)({
    color: 'var(--clr_white_alpha_70)',

    '&.Mui-checked': {
        color: 'var(--clr_white)',
    },

    '&:hover': {
        color: 'var(--clr_black_fade)',
    },
});
