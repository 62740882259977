import { FormEvent, useEffect, useRef, useState } from 'react';

import global from 'theme/global/Global.module.scss';
import styles from './SubscribeLink.module.scss';

import { TopBarLayout } from 'template/topBarLayout/TopBarLayout.template';

import { couponApi } from 'services/api/coupon.api';
import { miscHelper } from 'helpers/misc.helper';
import { miscValidations } from 'helpers/validation/misc.validation';

import { SubscribeLinkDetailsInterface, SubscribeLinkInputInterface } from 'interfaces/misc.interface';

import { SubscribeLinkTopBar } from './components/subscribeLinkTopBar/SubscribeLinkTopBar';
import { SubscribeLinkDetails } from './components/subscribeLinkDetails/SubscribeLinkDetails';
import { InstantAlert } from 'components/common/instantAlert/InstantAlert';

export const SubscribeLink = () => {
    const showNotification = useRef<any>();

    const [showInstantAlert, setShowInstantAlert] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [inputDetails, setInputDetails] = useState<SubscribeLinkInputInterface>({ value: '' });
    const [subscribeLinkDetails, setSubscribeLinkDetails] = useState<SubscribeLinkDetailsInterface[]>();

    const inputOnChangeHandler = (value: string) => {
        setInputDetails((prevState) => ({ ...prevState, value: value.toUpperCase().trim(), error: '' }));
    };

    const generateSubscribeLink = async () => {
        setIsLoading(true);

        const subscribeLink = miscHelper.convertCouponToSubscribeUrl(inputDetails.value);
        const couponDetails = await couponApi.verifyCoupon(inputDetails.value.trim());
        setIsLoading(false);

        if (typeof couponDetails === 'string') {
            return setInputDetails((prevState) => ({ ...prevState, error: 'Error in fetching coupon details' }));
        }

        const dataObject = {
            coupon: inputDetails.value,
            link: subscribeLink.normalUrl,
            hiddenCouponLink: subscribeLink.hiddenUrl,
            discountBy: couponDetails.discountBy,
            discountValue: couponDetails.discountValue,
        };

        if (!subscribeLinkDetails) return setSubscribeLinkDetails([dataObject]);
        else return setSubscribeLinkDetails([...subscribeLinkDetails, dataObject]);
    };

    const validateDetails = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const isFormDetailsValid = miscValidations.convertCouponToLinkFormValidation(inputDetails.value);
        if (isFormDetailsValid) setInputDetails((prevState) => ({ ...prevState, error: isFormDetailsValid }));
        else generateSubscribeLink();
    };

    useEffect(() => {
        clearTimeout(showNotification.current);
        showNotification.current = setTimeout(() => setShowInstantAlert(false), 2000);
        return () => clearTimeout(showNotification.current);
    }, [showInstantAlert]);

    return (
        <div className={`${global.componentWrapper} ${styles.searchKeyComponent}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    {showInstantAlert && <InstantAlert type="success" text="Successfully copied" />}
                    <TopBarLayout
                        topBar={
                            <SubscribeLinkTopBar
                                inputDetails={inputDetails}
                                inputOnChangeHandler={inputOnChangeHandler}
                                isLoading={isLoading}
                                validateDetails={validateDetails}
                            />
                        }
                        content={
                            <SubscribeLinkDetails
                                subscribeLinkDetails={subscribeLinkDetails}
                                setShowInstantAlert={setShowInstantAlert}
                            />
                        }
                    />
                </div>
            </div>
        </div>
    );
};
