import { dateTimeUtils } from 'helpers/utilities/dateTime.util';
import { UserInterface } from 'interfaces/user.interface';

export const subscriptionHistoryTableData = (user: UserInterface) => {
    const tableRows = user.userSubscription.subscriptionHistory?.map((subscriptionHistory) => {
        return {
            Time: dateTimeUtils.getDateTimeFromIso(subscriptionHistory.time),
            Event: subscriptionHistory.event,
        };
    });

    return {
        heading: 'Subscription History',
        rows: tableRows || [],
    };
};
