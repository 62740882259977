import { useEffect, useRef, useState } from 'react';

import { BtnErrorHighlight } from 'theme/mui/buttons.theme';

import global from 'theme/global/Global.module.scss';
import styles from './DeleteUserModalContent.module.scss';

import { InputFields } from 'template/inputFields/InputFields.template';

import { commonUtils } from 'helpers/utilities/common.util';
import { useAppSelector } from 'helpers/customHooks/useRedux.hook';
import { userApi } from 'services/api/user.api';

import { InstantAlert } from 'components/common/instantAlert/InstantAlert';

interface DeleteUserModalContentInterface {
    email: string;
    deleteUserAlertHandler: (response: any) => void;
}

export const DeleteUserModalContent = ({ email, deleteUserAlertHandler }: DeleteUserModalContentInterface) => {
    const adminEmail = useAppSelector((state) => state.admin.email);

    const showNotification = useRef<any>();

    const [isLoading, setIsLoading] = useState(false);
    const [showInstantAlertError, setShowInstantAlertError] = useState(false);
    const [error, setError] = useState('');
    const [deleteUserData, setDeleteUserData] = useState({
        adminPassword: '',
        showPassword: false,
    });

    const inputOnChangeHandler = (value: string, fieldName?: string) => {
        if (!fieldName) return;
        if (fieldName === 'showPassword') {
            const result = commonUtils.convertToBoolean(value);
            const booleanValue = typeof result === 'boolean' ? result : false;
            return setDeleteUserData((prevState) => ({ ...prevState, [fieldName]: booleanValue }));
        } else return setDeleteUserData((prevState) => ({ ...prevState, [fieldName]: value }));
    };

    const deleteUserHandler = async () => {
        if (!adminEmail) return;
        setIsLoading(true);

        const deleteUsersData = await userApi.deleteUser(adminEmail, deleteUserData.adminPassword, email);

        if (typeof deleteUsersData === 'string') {
            setError(deleteUsersData);
            setShowInstantAlertError(true);
        } else {
            deleteUserAlertHandler(deleteUsersData);
        }

        setIsLoading(false);
    };

    useEffect(() => {
        clearTimeout(showNotification.current);
        showNotification.current = setTimeout(() => {
            setShowInstantAlertError(false);
        }, 2000);
        return () => clearTimeout(showNotification.current);
    }, [showInstantAlertError]);

    return (
        <div className={`${global.moduleWrapper} ${styles.deleteUserContentModule}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    {showInstantAlertError && <InstantAlert type="error" text={error} />}

                    <div className={`${styles.headContainer}`}>
                        <h4 className={`${global.head4} ${styles.head}`}>Delete User</h4>
                    </div>

                    <div className={`${styles.inputFieldContainer}`}>
                        <InputFields
                            inputFields={[
                                {
                                    fieldName: 'adminPassword',
                                    inputType: deleteUserData.showPassword ? 'text' : 'password',
                                    isRequired: true,
                                    label: 'Confirm admin password',
                                    canHideValue: true,
                                    isValueHidden: deleteUserData.showPassword,
                                    hiddenValueFieldName: 'showPassword',
                                    disableAutoComplete: true,
                                    fieldClassName: `${styles.inputField}`,
                                },
                            ]}
                            inputDetails={deleteUserData}
                            inputOnChangeHandler={inputOnChangeHandler}
                        />
                    </div>

                    <div className={`${styles.btnContainer}`}>
                        <BtnErrorHighlight
                            loading={isLoading}
                            onClick={() => deleteUserHandler()}
                            className={`${styles.btn}`}
                        >
                            Delete
                        </BtnErrorHighlight>
                    </div>
                </div>
            </div>
        </div>
    );
};
