import { BiError } from 'react-icons/bi';

import global from 'theme/global/Global.module.scss';
import styles from './ComingSoon.module.scss';

interface CustomErrorInterface {
    message?: string;
}

export const CustomError = ({ message }: CustomErrorInterface) => {
    return (
        <div className={`${global.componentWrapper} ${styles.customErrorComponent}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    <div className={`${styles.iconContainer}`}>
                        <BiError className={`${styles.icon}`} />
                    </div>
                    <p className={`${global.bodyXl} ${styles.text}`}>
                        {message || 'Error : Please try after some time'}
                    </p>
                </div>
            </div>
        </div>
    );
};
