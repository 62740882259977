import { FormEvent, useEffect, useRef, useState } from 'react';

import global from 'theme/global/Global.module.scss';
import styles from './SearchUsers.module.scss';

import { TopBarLayout } from 'template/topBarLayout/TopBarLayout.template';

import { commonUtils } from 'helpers/utilities/common.util';
import { userValidations } from 'helpers/validation/user.validation';
import { userApi } from 'services/api/user.api';

import { TopBarSimpleFormInterface } from 'interfaces/ui/topBar.interface';
import { CheckUserApiResponseInterface } from 'interfaces/user.interface';

import { SearchUsersTopBar } from './components/searchUsersTopBar/SearchUsersTopBar';
import { SearchUsersDetails } from './components/searchUsersDetails/SearchUsersDetails';
import { InstantAlert } from 'components/common/instantAlert/InstantAlert';
import { UserDetails } from '../userDetails/UserDetails';

export const SearchUsers = () => {
    const showNotification = useRef<any>();

    const [checkUsersDetails, setCheckUsersDetails] = useState<CheckUserApiResponseInterface>();
    const [inputDetails, setInputDetails] = useState<TopBarSimpleFormInterface>({
        value: '',
        error: '',
    });
    const [isLoading, setIsLoading] = useState(false);
    const [showInstantAlert, setShowInstantAlert] = useState(false);
    const [singleUserDetailsEmail, setSingleUserDetailsEmail] = useState<string | undefined>();
    const [singleUserDetailsError, setSingleUserDetailsError] = useState('');

    const inputOnChangeHandler = (value: string) => {
        setInputDetails((prevState) => ({ ...prevState, value: value.toLowerCase(), error: '' }));
    };

    const getUsers = async () => {
        setCheckUsersDetails(undefined);
        const user = await userApi.checkUser(
            commonUtils.trimCommaAndSpaceSeparatedString(inputDetails.value).toString(),
        );
        setIsLoading(false);

        if (typeof user === 'string') setInputDetails((prevState) => ({ ...prevState, error: user }));
        else setCheckUsersDetails(user);
    };

    const validateDetails = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsLoading(true);

        const isFormDetailsValid = userValidations.checkUsersFormValidation(inputDetails.value);
        if (isFormDetailsValid !== '') {
            setInputDetails((prevState) => ({ ...prevState, error: isFormDetailsValid }));
            return setIsLoading(false);
        } else return getUsers();
    };

    const getSingleUserDetailsHandler = async (email: string) => {
        if (!email) return setSingleUserDetailsError('Email is required');
        setSingleUserDetailsEmail(email);
    };

    const singleUserDetailsErrorHandler = (error: string) => {
        setSingleUserDetailsError(error);
    };

    const tableBackBtnOnClickHandler = () => setSingleUserDetailsEmail(undefined);

    useEffect(() => {
        clearTimeout(showNotification.current);
        showNotification.current = setTimeout(() => {
            showInstantAlert && setShowInstantAlert(false);
            singleUserDetailsError && setSingleUserDetailsError('');
        }, 2000);
        return () => clearTimeout(showNotification.current);
    }, [showInstantAlert, singleUserDetailsError]);

    return singleUserDetailsEmail ? (
        <UserDetails
            singleUserDetailsEmail={singleUserDetailsEmail}
            tableBackBtnOnClickHandler={tableBackBtnOnClickHandler}
            singleUserDetailsErrorHandler={singleUserDetailsErrorHandler}
        />
    ) : (
        <div className={`${global.componentWrapper} ${styles.latestUsersComponent}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    {(showInstantAlert || singleUserDetailsError) && (
                        <InstantAlert
                            type={singleUserDetailsError ? 'error' : 'success'}
                            text={singleUserDetailsError ? singleUserDetailsError : 'Successfully copied'}
                        />
                    )}

                    <TopBarLayout
                        topBar={
                            <SearchUsersTopBar
                                isLoading={isLoading}
                                inputDetails={inputDetails}
                                inputOnChangeHandler={inputOnChangeHandler}
                                validateDetails={validateDetails}
                            />
                        }
                        content={
                            <SearchUsersDetails
                                setShowInstantAlert={setShowInstantAlert}
                                checkUsersDetails={checkUsersDetails}
                                getSingleUserDetailsHandler={getSingleUserDetailsHandler}
                            />
                        }
                    />
                </div>
            </div>
        </div>
    );
};
