import { FormEvent, useEffect, useState } from 'react';

import { BtnInfo } from 'theme/mui/buttons.theme';

import global from 'theme/global/Global.module.scss';
import styles from './Login.module.scss';

import { InputFields } from 'template/inputFields/InputFields.template';

import { ADMIN_ACTIONS } from 'context/features/adminSlice.redux';
import { useAppDispatch } from 'helpers/customHooks/useRedux.hook';
import { loginValidations } from 'helpers/validation/login.validation';
import { commonUtils } from 'helpers/utilities/common.util';
import { encodeValue } from 'services/security/cryptography.security';
import { securityLS } from 'services/localService/security.local';
import { adminApi } from 'services/api/admin.api';

import { AdminLoginInterface } from 'interfaces/admin.interface';
import { IMAGE_CONSTANT } from 'constants/image.constants';

import { loginInputFieldsData } from './loginInputFieldsData';

export const Login = () => {
    const dispatch = useAppDispatch();

    const year = new Date().getFullYear();

    const [isLoading, setIsLoading] = useState(false);
    const [loginFormDetails, setLoginFormDetails] = useState<AdminLoginInterface>({});

    const inputOnChangeHandler = (value: string, fieldName?: string) => {
        if (!fieldName) return;

        if (fieldName === 'email') {
            setLoginFormDetails((prevState) => ({
                ...prevState,
                [fieldName]: value.trim().toLowerCase(),
                [`${fieldName}Invalid`]: false,
                error: '',
            }));
        } else if (fieldName === 'showPassword') {
            const booleanValue = commonUtils.convertToBoolean(value);
            setLoginFormDetails((prevState) => ({
                ...prevState,
                [fieldName]: typeof booleanValue === 'boolean' ? booleanValue : false,
            }));
        } else {
            setLoginFormDetails((prevState) => ({
                ...prevState,
                [fieldName]: value,
                [`${fieldName}Invalid`]: false,
                error: '',
            }));
        }
    };

    const getAdmin = async () => {
        if (!loginFormDetails?.email || !loginFormDetails?.password) return;
        const admin = await adminApi.signIn(loginFormDetails.email.trim(), loginFormDetails.password.trim());

        if (typeof admin === 'string')
            setLoginFormDetails((prevState) => ({
                ...prevState,
                error: admin,
            }));
        else {
            dispatch(
                ADMIN_ACTIONS.SET_ADMIN({
                    email: admin.email.trim(),
                    userDisplayName: admin.displayName,
                    roles: admin.adminRoles,
                    displayAdminRole: admin.displayAdminRole,
                    adminAvatar: admin.avatar,
                }),
            );
        }

        setIsLoading(false);
    };

    const validateCredentials = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsLoading(true);

        const isFormDetailsValid = loginValidations.loginFormValidation(loginFormDetails);
        if (isFormDetailsValid !== '') {
            setLoginFormDetails((prevState: AdminLoginInterface) => ({
                ...prevState,
                [isFormDetailsValid[0]]: true,
                error: isFormDetailsValid[1],
            }));
            return setIsLoading(false);
        } else return getAdmin();
    };

    useEffect(() => {
        const firstTimeLogin = encodeValue('WelcomeToClassPoint', 'cp2');
        securityLS.setSessionExpiryData(firstTimeLogin.toString());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={`${global.componentWrapper} ${styles.loginComponent}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    <div className={`${styles.detailsContainer}`}>
                        <div className={`${styles.logoContainer}`}>
                            <img
                                src={IMAGE_CONSTANT.IMAGE.LOGO_CIRCULAR}
                                className={`${styles.logo}`}
                                alt="ClassPoint"
                            />
                        </div>

                        <h1 className={`${styles.head} ${global.head3}`}>ClassPoint Admin</h1>

                        <form onSubmit={(e) => validateCredentials(e)} className={`${styles.form}`}>
                            <div className={`${styles.inputContainer}`}>
                                <InputFields
                                    inputFields={loginInputFieldsData(loginFormDetails)}
                                    inputDetails={loginFormDetails}
                                    inputOnChangeHandler={inputOnChangeHandler}
                                />
                            </div>

                            {loginFormDetails?.error && (
                                <p className={`${styles.error} ${global.body}`}>{loginFormDetails.error}</p>
                            )}

                            <BtnInfo loading={isLoading} type="submit" className={`${styles.submitBtn}`}>
                                Login
                            </BtnInfo>

                            <p className={`${global.bodySmall} ${styles.copyright}`}>
                                ©Inknoe {year}. All rights reserved.
                            </p>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};
