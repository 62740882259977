import { useState } from 'react';

import global from 'theme/global/Global.module.scss';
import styles from './ViewEditContent.module.scss';

import { TopBarLayout } from 'template/topBarLayout/TopBarLayout.template';

import { contentApi } from 'services/api/content.api';

import { ViewContentResponseInterface, ViewEditContentFormInterface } from 'interfaces/content.interface';

import { ViewEditTopBar } from './components/viewEditContentTopBar/ViewEditTopBar';
import { ViewEditContentDetails } from './components/viewEditContentDetails/ViewEditContentDetails';
import { CreateContent } from '../create/CreateContent';

export const ViewEditContent = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [inputDetails, setInputDetails] = useState<ViewEditContentFormInterface>({ value: '' });
    const [contentDetails, setContentDetails] = useState<ViewContentResponseInterface>();

    const toggleEditMode = () => setIsEditMode((prev) => !prev);

    const inputOnChangeHandler = (value: string) => {
        const alphaNumericRegex = /^[a-zA-Z0-9_-]*$/;
        if (!value.match(alphaNumericRegex)) return;
        setInputDetails((prevState) => ({ ...prevState, value: value.trim(), error: '' }));
    };

    const getContentDetails = async () => {
        setIsLoading(true);
        const contentResponse = await contentApi.getContent(inputDetails.value);
        setIsLoading(false);
        if (typeof contentResponse === 'string') setInputDetails((prev) => ({ ...prev, error: contentResponse }));
        else setContentDetails(contentResponse);
    };

    const refreshData = () => {
        getContentDetails();
        setIsEditMode(false);
    };

    return (
        <div className={`${global.componentWrapper} ${styles.viewEditContentComponent}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    <TopBarLayout
                        topBar={
                            <ViewEditTopBar
                                inputDetails={inputDetails}
                                inputOnChangeHandler={inputOnChangeHandler}
                                isLoading={isLoading}
                                getContentDetails={getContentDetails}
                            />
                        }
                        content={
                            <>
                                {contentDetails && !isEditMode && (
                                    <ViewEditContentDetails content={contentDetails} toggleEditMode={toggleEditMode} />
                                )}
                                {isEditMode && <CreateContent content={contentDetails} refreshData={refreshData} />}
                            </>
                        }
                    />
                </div>
            </div>
        </div>
    );
};
