import { useEffect, useRef, useState } from 'react';

import global from 'theme/global/Global.module.scss';
import styles from './CreateAffiliate.module.scss';

import { DefaultLayout } from 'template/defaultLayout/DefaultLayout.template';

import { objectUtils } from 'helpers/utilities/object.util';
import { useAppSelector } from 'helpers/customHooks/useRedux.hook';
import { affiliateValidations } from 'helpers/validation/affiliate.validation';
import { affiliateApi } from 'services/api/affiliate.api';

import { CreateAffiliateSummary } from './components/createAffiliateSummary/CreateAffiliateSummary';
import { CreateAffiliateForm } from './components/createAffiliateForm/CreateAffiliateForm';
import { InstantAlert } from 'components/common/instantAlert/InstantAlert';
import { CreateAffiliateInterface, CreatedAffiliateFullInterface } from 'interfaces/affiliate.interface';

export const CreateAffiliate = () => {
    const adminStore = useAppSelector((state) => state).admin;

    const showNotification = useRef<any>();

    const [isLoading, setIsLoading] = useState(false);
    const [showInstantAlert, setShowInstantAlert] = useState(false);
    const [inputDetails, setInputDetails] = useState<CreateAffiliateInterface>({
        adminEmail: adminStore.email || '',
        discountPercentage: 10,
        commission: 30,
    });
    const [createdAffiliateDetails, setCreatedAffiliateDetails] = useState<CreatedAffiliateFullInterface[]>([]);

    const inputOnChangeHandler = (value: string, fieldName?: string) => {
        if (!fieldName) return;
        let processedValue: string | number;

        switch (fieldName) {
            case 'discountPercentage':
            case 'commission': {
                if (value) processedValue = parseInt(value);
                else processedValue = '';
                break;
            }
            case 'urlCode': {
                if (!value.match(/^\S*$/)) return;
                processedValue = value.toUpperCase();
                break;
            }
            default: {
                processedValue = value;
                break;
            }
        }
        setInputDetails((prevState) => ({ ...prevState, [fieldName]: processedValue, error: '' }));
    };

    const restructureObject = (finalObject: CreateAffiliateInterface): any => {
        const postBody = objectUtils.removeProperty(finalObject, ['error', 'errorField', 'success']);
        const trimmedFormDetails = objectUtils.trimSpace(postBody, ['links']);
        return trimmedFormDetails;
    };

    const createAffiliateHandler = async (trimmedDetails: any) => {
        const createAffiliate = await affiliateApi.createAffiliate(trimmedDetails);

        if (typeof createAffiliate === 'string') {
            setInputDetails((prevState) => ({ ...prevState, error: createAffiliate }));
        } else {
            setInputDetails((prevState) => ({ ...prevState, success: 'Success : Affiliate created successfully' }));
            const createdAffiliateDetailsBody = restructureObject(inputDetails);
            setCreatedAffiliateDetails([
                ...createdAffiliateDetails,
                {
                    ...createdAffiliateDetailsBody,
                    couponCode: createAffiliate.couponCode,
                    url: createAffiliate.url,
                    urlWithCode: `${process.env.REACT_APP_SUBSCRIBE_URL}/?a=${createdAffiliateDetailsBody.urlCode}`,
                },
            ]);
        }

        setIsLoading(false);
    };

    const validateDetailsHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        setIsLoading(true);

        const isFormDetailsValid = affiliateValidations.createAffiliateFormValidation(inputDetails);
        if (typeof isFormDetailsValid !== 'string') {
            setInputDetails((prevState) => ({
                ...prevState,
                error: isFormDetailsValid.error,
                errorField: isFormDetailsValid.errorField,
            }));
            return setIsLoading(false);
        } else {
            const finalObject = {
                ...inputDetails,
                commission: inputDetails.commission ? inputDetails.commission / 100 : 0,
            };
            const postBody = restructureObject(finalObject);
            createAffiliateHandler(postBody);
        }
    };

    useEffect(() => {
        if (inputDetails.success) {
            setTimeout(() => setInputDetails((prevState) => ({ ...prevState, success: '' })), 3000);
        }
    }, [inputDetails.success]);

    useEffect(() => {
        clearTimeout(showNotification.current);
        showNotification.current = setTimeout(() => setShowInstantAlert(false), 2000);
        return () => clearTimeout(showNotification.current);
    }, [showInstantAlert]);

    return (
        <div className={`${global.componentWrapper} ${styles.createAffiliateComponent}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    {showInstantAlert && <InstantAlert type="success" text="Successfully copied" />}
                    <DefaultLayout
                        content={
                            <div className={`${styles.createAffiliateContent}`}>
                                <CreateAffiliateForm
                                    isLoading={isLoading}
                                    inputDetails={inputDetails}
                                    inputOnChangeHandler={inputOnChangeHandler}
                                    validateDetailsHandler={validateDetailsHandler}
                                />
                                <CreateAffiliateSummary
                                    createdAffiliateDetails={createdAffiliateDetails}
                                    setShowInstantAlert={setShowInstantAlert}
                                />
                            </div>
                        }
                    />
                </div>
            </div>
        </div>
    );
};
