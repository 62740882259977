import { useState } from 'react';

import global from 'theme/global/Global.module.scss';
import styles from './Avatar.module.scss';

import { commonUtils } from 'helpers/utilities/common.util';

interface AvatarInterface {
    url?: string | null;
    text: string;
    globalFontSize: string;
    className?: string;
}

export const Avatar = ({ url, text, globalFontSize, className }: AvatarInterface) => {
    const [isImageValid, setIsImageValid] = useState(url ? true : false);

    return (
        <div
            style={{
                background: isImageValid ? 'transparent' : commonUtils.getColorForString(text),
            }}
            className={`${styles.generateAvatar} ${className && className}`}
        >
            {isImageValid ? (
                <img
                    src={url || ''}
                    className={`${styles.image}`}
                    onError={() => setIsImageValid(false)}
                    alt="ClassPoint"
                />
            ) : (
                <p className={`${globalFontSize && global[globalFontSize]} ${styles.text}`}>
                    {commonUtils.getNameInitials(text)}
                </p>
            )}
        </div>
    );
};
