import styles from './ApplyKeyTopBar.module.scss';

import { InputFieldsInterface } from 'interfaces/ui/inputFields.interface';

export const applyKeyInputFieldsData: InputFieldsInterface[] = [
    {
        fieldName: 'email',
        fieldClassName: `${styles.inputField}`,
        isRequired: true,
    },
    {
        fieldName: 'key',
        fieldClassName: `${styles.inputField}`,
        isRequired: true,
    },
];
