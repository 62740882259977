import axios from 'axios';

import { logger } from '../logger.service';

export const subscriptionApi = {
    checkTrial: async (email: string): Promise<string> => {
        try {
            await axios.get(`${process.env.REACT_APP_API}/subscriptions/trial/check?email=${email}`);
            return 'Success';
        } catch (error: any) {
            logger.error('subscriptionApi.checkTrial() error: ', error.response || error);
            return `Error : ${error.response.data.message}`;
        }
    },

    activateTrial: async (email: string): Promise<string> => {
        try {
            await axios.post(`${process.env.REACT_APP_API}/subscriptions/trial/start?email=${email}`);
            return 'Success';
        } catch (error: any) {
            logger.error('subscriptionApi.startTrial() error: ', error.response || error);
            return `Error : ${error.response.data.message}`;
        }
    },

    togglePremium: async (email: string, isPremium: boolean): Promise<string> => {
        try {
            await axios.patch(
                `${process.env.REACT_APP_API}/subscriptions/premium/toggle?email=${email}&isPremium=${isPremium}`,
            );
            return 'Success';
        } catch (error: any) {
            logger.error('subscriptionApi.togglePremium() error: ', error.response || error);
            return `Error : ${error.response.data.message}`;
        }
    },
};
