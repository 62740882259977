import global from 'theme/global/Global.module.scss';
import styles from './Login.page.module.scss';

import { Login } from 'components/auth/Login';

const LoginPage = () => {
    return (
        <main className={`${global.pageWrapper} ${styles.loginPage}`}>
            <section className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    <Login />
                </div>
            </section>
        </main>
    );
};

export default LoginPage;
