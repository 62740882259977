import { UserInterface } from 'interfaces/user.interface';
import { DATA_CONSTANT } from 'constants/data/data.constants';
import { generateUserCells } from 'data/generateCells/generateUserCells.data';

export const subscriptionSummaryGridData = (user: UserInterface) => {
    const cell = generateUserCells(user);

    const planType = user.userSubscription.planType;
    const planConstants = DATA_CONSTANT.PLAN_TYPE;
    const heading = 'Subscription Overview';

    const basicTableRows = [
        [cell.planType, cell.hasGonePro],
        [cell.savedKeys, cell.usedKeys],
        [cell.refereesCount, cell.referrer],
    ];

    const standardTableRows = [
        [cell.planType, cell.isTrial],
        [cell.currentKey, cell.planExpiry],
        [cell.refereesCount, cell.referrer],
    ];

    const subscriptionTableRows = [
        [cell.planType, cell.billingPeriod],
        [cell.currencyCode, cell.priceAmount],
        [cell.nextBillingDate, cell.isCanceled],
        [cell.refereesCount, cell.referrer],
    ];

    const rowDataObject = {
        [planConstants.BASIC]: basicTableRows,
        [planConstants.STANDARD]: standardTableRows,
        [planConstants.ORGANIZATION]: standardTableRows,
        [planConstants.SUBSCRIPTION]: subscriptionTableRows,
    };

    return {
        heading,
        rows: planType ? rowDataObject[planType] : [],
    };
};
