import { commonUtils } from 'helpers/utilities/common.util';
import { cellUtils } from 'helpers/utilities/cell.util';
import { miscHelper } from 'helpers/misc.helper';

import { AffiliateInterface } from 'interfaces/affiliate.interface';

export const generateAffiliateCells = (affiliateDetails: AffiliateInterface) => {
    const cell = cellUtils.tableCell;

    return {
        id: cell('ID', affiliateDetails._id),
        name: cell('Name', affiliateDetails.name),
        email: cell('Email', affiliateDetails.email),
        countryCode: cell('Country Code', affiliateDetails.countryCode),
        couponCode: cell('Coupon Code', affiliateDetails.couponCode),
        discountPercentage: cell('Discount Percentage', affiliateDetails.discountPercentage),
        commission: cell('Commission Percentage', affiliateDetails.commission * 100),
        urlCode: cell('Url Code', affiliateDetails.urlCode),
        urlWithCode: cell('Url With Code', miscHelper.convertUrlCodeToSubscribeUrl(affiliateDetails.urlCode)),
        urlWithCoupon: cell('Url With Coupon', miscHelper.convertCouponToSubscribeUrl(affiliateDetails.couponCode)),
        adminEmail: cell('Admin Email', affiliateDetails.adminEmail),
        links: cell(
            'Affiliate Profile Link',
            affiliateDetails.links && commonUtils.separateByComma(affiliateDetails.links),
        ),
    };
};
