import axios from 'axios';

import { logger } from '../logger.service';

import {
    NewCctApiResponseInterface,
    NewCctInterface,
    CctInterface,
    NewCscInterface,
} from 'interfaces/classPointCertified.interface';

export const classPointCertifiedApi = {
    getCctInfoByEmailOrId: async (value: string, type: 'email' | 'userId'): Promise<CctInterface | string> => {
        try {
            const baseUrl = process.env.REACT_APP_API;
            const response = await axios.get(
                type === 'email' ? `${baseUrl}/cct?email=${value}` : `${baseUrl}/cct?userId=${value}`,
            );
            return response.data;
        } catch (error: any) {
            logger.error('classPointCertifiedApi.getCctInfoByEmailOrId() error: ', error.response || error);
            return `Error : ${error.response.data.message}`;
        }
    },

    getAllCctInfo: async (): Promise<NewCctApiResponseInterface[] | string> => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/cct/all/admin`);
            return response.data;
        } catch (error: any) {
            logger.error('classPointCertifiedApi.cctInfo() error: ', error.response || error);
            return `Error : ${error.response.data.message}`;
        }
    },

    postCctInfo: async (data: NewCctInterface): Promise<string[] | string> => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API}/cct`, data);
            return response.data;
        } catch (error: any) {
            logger.error('classPointCertifiedApi.postCctInfo() error: ', error.response || error);
            return `Error : ${error.response.data.message}`;
        }
    },

    updateCct: async (email: string, cctDetails: { [key: string]: any }): Promise<CctInterface | string> => {
        try {
            const url = `${process.env.REACT_APP_API}/cct?email=${email}`;
            const response = await axios.patch(url, cctDetails);
            return response.data;
        } catch (error: any) {
            logger.error('classPointCertifiedApi.updateCct() error: ', error.response || error);
            return `Error : ${error.response ? error.response.data.message : error}`;
        }
    },

    renewCct: async (
        email: string,
        newExpiryDate: string,
        notifyUser: boolean,
    ): Promise<[string, CctInterface] | string> => {
        try {
            const url = `${process.env.REACT_APP_API}/cct/renew?email=${email}&skipFlow=${notifyUser}`;
            const response = await axios.patch(url, { titleExpiryDate: newExpiryDate });
            return ['Success', response.data];
        } catch (error: any) {
            logger.error('classPointCertifiedApi.renewCct() error: ', error.response || error);
            return `Error : ${error.response ? error.response.data.message : error}`;
        }
    },

    postCscInfo: async (data: NewCscInterface): Promise<{ key: string } | string> => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API}/cscs`, data);
            return response.data;
        } catch (error: any) {
            logger.error('classPointCertifiedApi.postCscInfo() error: ', error.response || error);
            return `Error : ${error.response.data.message}`;
        }
    },
};
