import { ViewContentResponseInterface } from 'interfaces/content.interface';
import { generateContentCells } from 'data/generateCells/generateContentCells.data';

export const contentDetailsGridData = (content: ViewContentResponseInterface) => {
    const cell = generateContentCells(content);

    return {
        heading: 'Content Details',
        rows: [
            [cell.title, cell.slug, cell.created],
            [cell.numberOfSlides, cell.categories, cell.tags],
            [cell.fileUrl, cell.coverUrl, cell.imageUrls],
            [cell.description],
        ],
    };
};
