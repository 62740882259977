import { createSlice } from '@reduxjs/toolkit';

import { storeLS } from 'services/localService/store.local';
import { UiInterface } from '../../interfaces/store/ui.interfaces';

const getCachedErrorState = () => {
    const storeCached = storeLS.getStoreData();
    if (storeCached && storeCached.error) return storeCached.ui;
    return undefined;
};

const initialState: UiInterface = getCachedErrorState() || {
    isMobileNavOpen: false,
};

const uiSlice = createSlice({
    name: 'UI',
    initialState,
    reducers: {
        TOGGLE_MOBILE_NAV: (state) => {
            state.isMobileNavOpen = !state.isMobileNavOpen;
        },
    },
});

export const UI_REDUCERS = uiSlice.reducer;
export const UI_ACTIONS = uiSlice.actions;
