import { useState } from 'react';
import { orderBy } from 'lodash';
import parse from 'html-react-parser';

import { MdNorth } from 'react-icons/md';
import { BsArrowLeftShort } from 'react-icons/bs';

import { TableContainer } from '@mui/material';

import {
    CustomTable,
    CustomTableHead,
    CustomTableBody,
    CustomTableRow,
    CustomTableCell,
} from '../../theme/mui/tables.theme';
import { BtnInfo, IconBtn } from 'theme/mui/buttons.theme';

import global from 'theme/global/Global.module.scss';
import styles from './DataTable.template.module.scss';

import { commonUtils } from 'helpers/utilities/common.util';

import { TableCellPropsInterface, TableCellType } from 'interfaces/ui/dataTable.interface';

import { Avatar } from 'components/common/avatar/Avatar';

interface DataTableInterface {
    data: {
        heading: string;
        rows: { [tableKey: string]: any }[];
        cellProps?: { [tableKey: string]: TableCellPropsInterface };
    };
    customHeadingText?: string;
    headingTextWidth?: string;
    customHeadingElement?: JSX.Element;
    backBtnOnClickHandler?: any;
    noSerial?: boolean;
    isNotDefaultModule?: boolean;
    isCollapsible?: boolean;
    isMultiLayer?: boolean;
    className?: string;
}

export const DataTable = ({
    data,
    customHeadingText,
    headingTextWidth,
    customHeadingElement,
    backBtnOnClickHandler,
    noSerial,
    isNotDefaultModule,
    isCollapsible,
    isMultiLayer,
    className,
}: DataTableInterface) => {
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
    const [sortBy, setSortBy] = useState('');
    const [isCollapsed, setIsCollapsed] = useState(false);

    const cellProps = data.cellProps || {};
    const tableKeys = Object.keys(data.rows[0] || {});
    const ignoreTableKeys = ['RowProps'];
    const sortedRows = orderBy(data.rows, [sortBy], [sortOrder]);

    const changeOrderHandler = (sortKey: string) => {
        if (sortKey === sortBy) return setSortOrder((prevState) => (prevState === 'asc' ? 'desc' : 'asc'));
        else {
            setSortBy(sortKey);
            return setSortOrder('asc');
        }
    };

    const cellOnClickHandler = (tableKey: string, cellValue: any, row: { [tableKey: string]: any }) => {
        const getAllValueOnClick = cellProps[tableKey]?.getAllValueOnClick;
        const getSelectedValueOnClick = cellProps[tableKey]?.getSelectedValueOnClick;
        const functionArgument = cellProps[tableKey]?.functionArgument;
        const functionArgumentValue = functionArgument ? row[functionArgument] : row[tableKey];

        if (getAllValueOnClick) {
            getAllValueOnClick(row);
        } else if (getSelectedValueOnClick) {
            if (tableKey === 'Has Usage Logs') cellValue === 'Yes' && getSelectedValueOnClick(functionArgumentValue);
            else getSelectedValueOnClick(functionArgumentValue);
        }
    };

    const determineIsActiveLink = (tableKey: string, cellValue: any) => {
        const onClickAction = cellProps[tableKey]?.getAllValueOnClick || cellProps[tableKey]?.getSelectedValueOnClick;

        if (onClickAction) {
            if (tableKey === 'Has Usage Logs') {
                if (cellValue === 'Yes') return true;
                return false;
            }
            return true;
        }
        return false;
    };

    const textColorGenerator = (tableKey: string, row: { [tableKey: string]: any }) => {
        if (row.RowProps?.rowColor) return row.RowProps.rowColor;
        else if (cellProps[tableKey]?.color === 'cctStatusCustom') {
            if (row.Status === 'New') return '#5db625';
            if (row.Status === 'Active') return '#8192ff';
            if (row.Status === 'Expiring') return '#ff9800';
            if (row.Status === 'Expired') return '#808080';
        } else return '';
    };

    return (
        <div
            className={`${!isNotDefaultModule && global.defaultModule} ${global.moduleWrapper} ${
                styles.dataTableTemplate
            } ${className || ''}`}
        >
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    {!isMultiLayer && (
                        <div className={`${styles.headingContainer}`}>
                            <div className={`${styles.headingText}`}>
                                {backBtnOnClickHandler && (
                                    <IconBtn
                                        onClick={() => backBtnOnClickHandler()}
                                        className={`${styles.backBtnContainer}`}
                                    >
                                        <BsArrowLeftShort className={`${styles.icon}`} />
                                    </IconBtn>
                                )}
                                <h6
                                    style={{ width: headingTextWidth || 'auto' }}
                                    className={`${global.head6} ${styles.head} ${
                                        backBtnOnClickHandler && styles.leftPadding
                                    }`}
                                >
                                    {customHeadingText
                                        ? customHeadingText
                                        : `${data.heading} ${
                                              !noSerial && sortedRows.length > 0 ? `(${sortedRows.length})` : ''
                                          }`}
                                </h6>
                            </div>
                            {customHeadingElement}
                            {isCollapsible && (
                                <BtnInfo
                                    onClick={() => setIsCollapsed((prevState) => !prevState)}
                                    className={`${styles.collapseBtn}`}
                                >
                                    <MdNorth className={`${styles.icon} ${isCollapsed && styles.collapsed}`} />
                                </BtnInfo>
                            )}
                        </div>
                    )}

                    {sortedRows.length > 0 && !isCollapsed && (
                        <TableContainer className={`${styles.tableContainer}`}>
                            <CustomTable className={`${styles.table}`}>
                                <CustomTableHead className={`${styles.tableHead}`}>
                                    <CustomTableRow className={`${styles.tableRow}`}>
                                        {!noSerial && tableKeys.length > 0 && <CustomTableCell>Sl</CustomTableCell>}

                                        {tableKeys.map((tableKey, index) => {
                                            if (ignoreTableKeys.includes(tableKey)) return '';
                                            if (cellProps[tableKey]?.isHidden) return '';

                                            return (
                                                <CustomTableCell
                                                    key={index}
                                                    onClick={() => changeOrderHandler(tableKey)}
                                                    className={`${global.body} ${styles.tableData} ${
                                                        (cellProps[tableKey]?.getAllValueOnClick ||
                                                            cellProps[tableKey]?.getSelectedValueOnClick) &&
                                                        styles.clickable
                                                    }`}
                                                >
                                                    {tableKey === sortBy && (
                                                        <MdNorth
                                                            className={`${styles.icon} ${
                                                                sortOrder === 'desc' && styles.descOrder
                                                            }`}
                                                        />
                                                    )}
                                                    {tableKey}
                                                </CustomTableCell>
                                            );
                                        })}
                                    </CustomTableRow>
                                </CustomTableHead>

                                <CustomTableBody className={`${styles.tableBody}`}>
                                    {sortedRows.map((row, index) => (
                                        <CustomTableRow key={index} className={`${styles.tableRow}`}>
                                            {!noSerial && (
                                                <CustomTableCell className={`${styles.tableData}`}>
                                                    {index + 1}
                                                </CustomTableCell>
                                            )}

                                            {tableKeys.map((tableKey) => {
                                                if (ignoreTableKeys.includes(tableKey)) return '';
                                                if (cellProps[tableKey]?.isHidden) return '';

                                                const cellValue = commonUtils.convertToString(row[tableKey]);
                                                let component;

                                                if (cellProps[tableKey]?.type === TableCellType.AVATAR) {
                                                    component = (
                                                        <div className={`${styles.imageContainer}`}>
                                                            <Avatar
                                                                url={row.Avatar}
                                                                text={row.AvatarText}
                                                                globalFontSize="bodyXs"
                                                            />
                                                        </div>
                                                    );
                                                } else {
                                                    component = parse(cellValue);
                                                }

                                                return (
                                                    <CustomTableCell
                                                        key={tableKey}
                                                        onClick={() => cellOnClickHandler(tableKey, cellValue, row)}
                                                        style={{
                                                            color: textColorGenerator(tableKey, row),
                                                        }}
                                                        className={`${styles.tableData} ${
                                                            cellProps[tableKey]?.isNoWrap && styles.noWrap
                                                        } ${
                                                            determineIsActiveLink(tableKey, cellValue) &&
                                                            styles.clickable
                                                        }`}
                                                    >
                                                        {component}
                                                    </CustomTableCell>
                                                );
                                            })}
                                        </CustomTableRow>
                                    ))}
                                </CustomTableBody>
                            </CustomTable>
                        </TableContainer>
                    )}
                </div>
            </div>
        </div>
    );
};
