import { styled } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

// ========== Date Time Picker ==========
export const DateInput = styled(DatePicker)({
    '&:hover': {
        '.MuiInputLabel-root': {
            color: 'var(--clr_text_primary)',
        },
    },

    '&.isRequired': {
        '.MuiInputLabel-root': {
            color: 'var(--clr_text_warn_light)',
        },
    },

    '&.showOnlyValue': {
        '.MuiInputLabel-root': {
            paddingInline: '10px',
            transform: 'none',
            inset: '0 0 0 0',
            margin: 'auto',

            '&.MuiInputLabel-shrink': {
                display: 'none',
            },
        },

        '.MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },

        '.MuiInputBase-root': {
            paddingInline: 0,
        },

        '.MuiInputAdornment-root': {
            position: 'absolute',
            inset: '0 0 0 0',
            width: '100%',
            height: '100%',

            '.MuiButtonBase-root': {
                position: 'absolute',
                inset: '0 0 0 0',
                width: '100%',
                height: '100%',
                borderRadius: '0',

                '.MuiSvgIcon-root': {
                    display: 'none',
                },
            },
        },
    },

    '.MuiInputLabel-root': {
        fontSize: '14px',
        color: 'var(--clr_text_neutral)',

        '&.Mui-focused': {
            color: 'var(--clr_text_info)',
        },

        '&.MuiInputLabel-shrink': {
            top: '1px',
        },
    },

    '.MuiInputBase-root': {
        fontSize: '14px',
        color: 'var(--clr_text_primary)',

        '&:not(.MuiInputLabel-shrink)': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',

            width: '100%',
            height: '100%',
        },

        '.MuiInputBase-input': {
            paddingBlock: 0,
        },

        '.MuiOutlinedInput-notchedOutline': {
            top: '-4px',
            borderWidth: '2px',
            borderColor: 'var(--clr_text_neutral_light)',
        },

        '&:hover': {
            '.MuiOutlinedInput-notchedOutline': {
                borderColor: 'var(--clr_text_neutral_dark)',
            },
        },

        '&.Mui-error': {
            '.MuiOutlinedInput-notchedOutline': {
                borderColor: 'var(--clr_text_error)',
            },
        },

        '&.Mui-focused': {
            '.MuiOutlinedInput-notchedOutline': {
                borderColor: 'var(--clr_text_info)',
            },
        },
    },
});

export const DateTimeInput = styled(DateTimePicker)({
    '&:hover': {
        '.MuiInputLabel-root': {
            color: 'var(--clr_text_primary)',
        },
    },

    '&.isRequired': {
        '.MuiInputLabel-root': {
            color: 'var(--clr_text_warn_light)',
        },
    },

    '&.showOnlyValue': {
        '.MuiInputLabel-root': {
            paddingInline: '10px',
            transform: 'none',
            inset: '0 0 0 0',
            margin: 'auto',

            '&.MuiInputLabel-shrink': {
                display: 'none',
            },
        },

        '.MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },

        '.MuiInputBase-root': {
            paddingInline: 0,
        },

        '.MuiInputAdornment-root': {
            position: 'absolute',
            inset: '0 0 0 0',
            width: '100%',
            height: '100%',

            '.MuiButtonBase-root': {
                position: 'absolute',
                inset: '0 0 0 0',
                width: '100%',
                height: '100%',
                borderRadius: '0',

                '.MuiSvgIcon-root': {
                    display: 'none',
                },
            },
        },
    },

    '.MuiInputLabel-root': {
        fontSize: '14px',
        color: 'var(--clr_text_neutral)',

        '&.Mui-focused': {
            color: 'var(--clr_text_info)',
        },

        '&.MuiInputLabel-shrink': {
            top: '4px',
        },
    },

    '.MuiInputBase-root': {
        fontSize: '14px',
        color: 'var(--clr_text_primary)',

        '&:not(.MuiInputLabel-shrink)': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',

            width: '100%',
            height: '100%',
        },

        '.MuiInputBase-input': {
            paddingBlock: 0,
        },

        '.MuiOutlinedInput-notchedOutline': {
            top: '-4px',
            borderWidth: '2px',
            borderColor: 'var(--clr_text_neutral_light)',
        },

        '&:hover': {
            '.MuiOutlinedInput-notchedOutline': {
                borderColor: 'var(--clr_text_neutral_dark)',
            },
        },

        '&.Mui-error': {
            '.MuiOutlinedInput-notchedOutline': {
                borderColor: 'var(--clr_text_error)',
            },
        },

        '&.Mui-focused': {
            '.MuiOutlinedInput-notchedOutline': {
                borderColor: 'var(--clr_text_info)',
            },
        },
    },
});
