import { useState } from 'react';

import global from 'theme/global/Global.module.scss';
import styles from './LatestUsersDetails.module.scss';

import { DataTable } from 'template/dataTable/DataTable.template';
// import { CommonModal } from 'template/modal/common/CommonModal.template';

import { LatestUsersInterface, LatestUsersTableOptionsInterface } from 'interfaces/user.interface';

import { LatestUsersTableInput } from './LatestUsersTableInput';
// import { UsageLogs } from 'components/home/user/userDetails/components/userDetailsModules/UsageLogs';
import { LatestUsersTableOptions } from './LatestUsersTableOptions';

import { latestUsersTableData } from 'data/user/latestUsers/latestUsers.tableData';
import { commonUtils } from 'helpers/utilities/common.util';

interface LatestUsersDetailsInterface {
    isLoading: boolean;
    latestUsersDetails: LatestUsersInterface[] | undefined;
    error: string;
    pageNumber: number;
    rowsPerPage: number;
    changeTableProperty: (action: 'add1Page' | 'sub1Page' | 'setPage' | 'setRow', value?: number) => void;
    getSingleUserDetailsHandler: (email: string) => void;
}

export const LatestUsersDetails = ({
    isLoading,
    latestUsersDetails,
    error,
    pageNumber,
    rowsPerPage,
    changeTableProperty,
    getSingleUserDetailsHandler,
}: LatestUsersDetailsInterface) => {
    // const [isUsageLogsModalOpen, setIsUsageLogsModalOpen] = useState(false);
    // const [pickedUserEmail, setPickedUserEmail] = useState('');
    const [showTableOptions, setShowTableOptions] = useState<Partial<LatestUsersTableOptionsInterface>>({
        created: true,
        firstCp2SignIn: true,
        addedFirstActivityButton: true,
        firstSlideshowStarted: true,
        firstClassSessionStarted: true,
        planStatus: true,
    });

    const showTableOptionsToggleHandler = async (value: string, fieldName?: string) => {
        if (!fieldName) return;
        setShowTableOptions((prevState) => ({ ...prevState, [fieldName]: commonUtils.convertToBoolean(value) }));
    };

    const visibleRangeCalculator = () => {
        if (latestUsersDetails) {
            if (pageNumber > 0) return `(${pageNumber * rowsPerPage + 1} - ${(pageNumber + 1) * rowsPerPage})`;
            return `(1 - ${rowsPerPage})`;
        }
        return '';
    };

    // const getUsageLogsHandler = async (email: string) => {
    //     if (!email) return;
    //     setIsUsageLogsModalOpen(true);
    //     setPickedUserEmail(email);
    // };

    const latestUsersDataInTableFormat = latestUsersTableData(
        latestUsersDetails,
        getSingleUserDetailsHandler,
        // getUsageLogsHandler,
        showTableOptions,
    );

    return (
        <div
            className={`${global.moduleWrapper} ${styles.latestUsersDetailsModule} ${
                latestUsersDetails && styles.removeMinContentHeight
            }`}
        >
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    {/* <CommonModal
                        isModalOpen={isUsageLogsModalOpen}
                        modalCloseHandler={() => setIsUsageLogsModalOpen(false)}
                        modalContent={
                            <div className={`${styles.usageLogsJsonViewModalContent}`}>
                                {pickedUserEmail && <UsageLogs userEmail={pickedUserEmail} />}
                            </div>
                        }
                    /> */}

                    <LatestUsersTableOptions
                        showTableOptions={showTableOptions}
                        showTableOptionsToggleHandler={showTableOptionsToggleHandler}
                    />

                    <DataTable
                        data={latestUsersDataInTableFormat}
                        customHeadingText={`Latest Users ${visibleRangeCalculator()} ${
                            isLoading
                                ? '| Data loading...'
                                : !latestUsersDetails
                                ? '| No usage logs'
                                : error
                                ? `| ${error}`
                                : ''
                        }`}
                        customHeadingElement={
                            <LatestUsersTableInput
                                pageNumber={pageNumber}
                                rowsPerPage={rowsPerPage}
                                changeTableProperty={changeTableProperty}
                            />
                        }
                        noSerial={true}
                    />
                </div>
            </div>
        </div>
    );
};
