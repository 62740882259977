import { useEffect, useState } from 'react';

import global from 'theme/global/Global.module.scss';
import styles from './TogglePremium.module.scss';

import { BtnList } from 'theme/mui/buttons.theme';

import { subscriptionApi } from 'services/api/subscription.api';

import { UserInterface } from 'interfaces/user.interface';

interface TogglePremiumInterface {
    user: UserInterface;
    refreshUserDetails: () => void;
}

export const TogglePremium = ({ user, refreshUserDetails }: TogglePremiumInterface) => {
    const [isLoading, setIsLoading] = useState(false);
    const [statusMessage, setStatusMessage] = useState<{ isSuccessful: boolean; message: string } | undefined>();

    const togglePremiumHandler = async () => {
        setIsLoading(true);

        const currentPremiumStatus = user.userSubscription.isPremium || false;
        const togglePremium = await subscriptionApi.togglePremium(user.email.trim(), !currentPremiumStatus);
        if (togglePremium === 'Success') {
            setStatusMessage({
                isSuccessful: true,
                message: `Success : Premium ${currentPremiumStatus ? 'deactivated' : 'activated'} successfully`,
            });
            setTimeout(() => refreshUserDetails(), 1500);
        } else setStatusMessage({ isSuccessful: false, message: togglePremium });

        setIsLoading(false);
    };

    useEffect(() => {
        if (statusMessage) setTimeout(() => setStatusMessage(undefined), 2500);
    }, [statusMessage]);

    return (
        <div className={`${global.moduleWrapper} ${styles.activateTrialModule}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    <BtnList
                        onClick={() => togglePremiumHandler()}
                        loading={isLoading}
                        className={`${styles.userActionListBtn}`}
                    >
                        Toggle Premium
                    </BtnList>
                    {statusMessage?.message && (
                        <p
                            className={`${global.body} ${
                                statusMessage?.isSuccessful ? styles.successMessage : styles.errorMessage
                            }`}
                        >
                            {statusMessage.message}
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
};
