import { useState } from 'react';

import global from 'theme/global/Global.module.scss';
import styles from './UserDetailsModules.module.scss';

import { DataTable } from 'template/dataTable/DataTable.template';

import { dateTimeUtils } from 'helpers/utilities/dateTime.util';
import { userApi } from 'services/api/user.api';
import { UsageLogsInterface } from 'interfaces/user.interface';

import { UsageLogsTableInput } from './components/UsageLogsTableInput';
import { usageLogsTableData } from 'data/user/userDetails/details/usageLogs.tableData';
import { generateExcelFile } from 'data/generateExcelFile/generateExcelFile.data';

interface UsageLogsComponentInterface {
    userEmail: string;
}

export const UsageLogs = ({ userEmail }: UsageLogsComponentInterface) => {
    const [usageLogs, setUsageLogs] = useState<UsageLogsInterface[] | undefined>();
    const [isLoading, setIsLoading] = useState(false);

    const usageLogsDataInTableFormat = usageLogsTableData(usageLogs);

    const getUsageLogs = async (logsDate: string) => {
        setIsLoading(true);
        setUsageLogs(undefined);

        const getTimeValues = dateTimeUtils.getTimeValues(logsDate);
        const usageLogsApiData = await userApi.usageLogs(
            userEmail.trim(),
            `${getTimeValues.year}${getTimeValues.month}${getTimeValues.day}`,
        );
        if (typeof usageLogsApiData !== 'string') setUsageLogs(usageLogsApiData);

        setIsLoading(false);
    };

    const exportExcelFileHandler = () => {
        if (!usageLogs) return;
        let dataArray: [{ [key: string]: any }] = [{}];
        usageLogs.map((item, index) => dataArray.push({ No: `${index + 1}`, Time: item.time, Event: item.event }));
        generateExcelFile(dataArray, `ClassPoint Usage Logs (${userEmail})`);
    };

    return (
        <div
            className={`${global.moduleWrapper} ${styles.usageLogsModule} ${
                usageLogs && styles.removeMinContentHeight
            }`}
        >
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content} ${styles.usageLogsContent}`}>
                    <DataTable
                        data={usageLogsDataInTableFormat}
                        noSerial={true}
                        customHeadingText={`${
                            isLoading
                                ? 'Usage logs | Data loading...'
                                : !usageLogs
                                ? `No usage logs | ${userEmail}`
                                : `Usage logs | ${userEmail}`
                        }`}
                        headingTextWidth="300px"
                        customHeadingElement={
                            <UsageLogsTableInput
                                isLoading={isLoading}
                                getUsageLogs={getUsageLogs}
                                exportExcelFileHandler={exportExcelFileHandler}
                            />
                        }
                    />
                </div>
            </div>
        </div>
    );
};
