import { dateTimeUtils } from 'helpers/utilities/dateTime.util';

import { SessionActivitiesInterface } from 'interfaces/session.interface';

export const singleSessionActivitiesTableData = (
    singleSessionActivitiesDetails: SessionActivitiesInterface[] | undefined,
    getActivityDetails: (activityId: string) => void,
) => {
    const tableRows = singleSessionActivitiesDetails?.map((activity) => {
        return {
            'Activity Id': activity.activityId,
            'Activity Type': activity.activityType,
            'Activity Created On': dateTimeUtils.getDateTimeFromIso(activity.activityCreatedOn),
            'Submission Duration': `${activity.submissionDuration}s`,
            'Responses Count': activity.responsesCount,
        };
    });

    return {
        heading: 'Session Activities',
        rows: tableRows || [],
        cellProps: {
            'Activity Id': { getSelectedValueOnClick: getActivityDetails },
        },
    };
};
