import { commonUtils } from './common.util';

interface GetTimeValuesInterface {
    year: string;
    month: string;
    day: string;
    hour: string;
    minute: string;
    second: string;
}

export const dateTimeUtils = {
    getTimeValues: (date: string | number | Date): GetTimeValuesInterface => {
        const isoValue = new Date(date).toISOString();
        const year = isoValue.slice(0, 4);
        const month = isoValue.slice(5, 7);
        const day = isoValue.slice(8, 10);
        const hour = isoValue.slice(11, 13);
        const minute = isoValue.slice(14, 16);
        const second = isoValue.slice(17, 19);

        return { year: year, month: month, day: day, hour: hour, minute: minute, second: second };
    },

    getDateFromIso: (date: string | number | Date | undefined): string => {
        if (!date) return commonUtils.convertToString(date);
        return new Date(date).toISOString().slice(0, 10);
    },

    getDateTimeFromIso: (date: string | number | Date | undefined): string => {
        if (!date) return commonUtils.convertToString(date);
        const isoDateString = new Date(date).toISOString();
        const dateString = isoDateString.slice(0, 10);
        const timeString = isoDateString.slice(11, 16);
        return `${dateString} / ${timeString}`;
    },

    convertToIso: (date: string | number | Date | undefined): string => {
        if (!date) return commonUtils.convertToString(date);
        return new Date(date).toISOString();
    },

    pickInIso: (event: any, includeTime?: boolean): string => {
        const year = event.$y;
        const month = commonUtils.padDigits(event.$M + 1);
        const day = commonUtils.padDigits(event.$D);
        const hour = includeTime ? commonUtils.padDigits(event.$H) : `00`;
        const minute = includeTime ? commonUtils.padDigits(event.$m) : `00`;
        return `${year}-${month}-${day}T${hour}:${minute}:00.000Z`;
    },

    constructIsoFromLocalTime: (date: string | number | Date | undefined): string => {
        if (!date) return commonUtils.convertToString(date);
        const localTime = new Date(date);
        const year = localTime.getFullYear();
        const month = commonUtils.padDigits(localTime.getMonth() + 1);
        const day = commonUtils.padDigits(localTime.getDate());
        const hour = commonUtils.padDigits(localTime.getHours());
        const minute = commonUtils.padDigits(localTime.getMinutes());
        return `${year}-${month}-${day}T${hour}:${minute}:00.000Z`;
    },

    timeDifference: (timeFrom: string | number | Date | undefined, timeTo: string | number | Date | undefined) => {
        const finalTimeFrom = timeFrom ? new Date(timeFrom).getTime() : new Date().getTime();
        const finalTimeTo = timeTo ? new Date(timeTo).getTime() : new Date().getTime();
        const milliSeconds = Math.abs(finalTimeTo - finalTimeFrom);
        const isNegativeValue = finalTimeTo < finalTimeFrom;

        let seconds = Math.floor(milliSeconds / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        let days = Math.floor(hours / 24);
        let months = Math.floor(days / 30);
        const years = Math.floor(months / 12);

        seconds = seconds % 60;
        minutes = minutes % 60;
        hours = hours % 24;
        days = days % 30;
        months = months % 12;

        return {
            years: isNegativeValue ? years * -1 : years,
            months: isNegativeValue ? months * -1 : months,
            days: isNegativeValue ? days * -1 : days,
            hours: isNegativeValue ? hours * -1 : hours,
            minutes: isNegativeValue ? minutes * -1 : minutes,
            seconds: isNegativeValue ? seconds * -1 : seconds,
        };
    },

    timeDifferenceInWords: (
        timeFrom: string | number | Date | undefined,
        timeTo: string | number | Date | undefined,
    ) => {
        const difference = dateTimeUtils.timeDifference(timeFrom, timeTo);

        return `${difference?.years ? `${difference.years}yr ` : ''}
        ${difference?.months ? `${difference.months}mo ` : ''}
        ${difference?.days ? `${difference.days}day ` : ''}
        ${difference?.hours ? `${difference.hours}hr ` : ''}
        ${difference?.minutes ? `${difference.minutes}min ` : ''}
        ${difference?.seconds ? `${difference.seconds}sec ` : ''}`;
    },

    timeDifferenceInGivenUnit: (
        timeFrom: string | number | Date | undefined,
        timeTo: string | number | Date | undefined,
        unit: 'year' | 'month' | 'day' | 'hour' | 'minute' | 'second',
    ) => {
        const finalTimeFrom = timeFrom ? new Date(timeFrom).getTime() : new Date().getTime();
        const finalTimeTo = timeTo ? new Date(timeTo).getTime() : new Date().getTime();
        const milliSeconds = Math.abs(finalTimeTo - finalTimeFrom);
        const isNegativeValue = finalTimeTo < finalTimeFrom;

        const seconds = milliSeconds / 1000;
        const minutes = milliSeconds / (1000 * 60);
        const hours = milliSeconds / (1000 * 60 * 60);
        const days = milliSeconds / (1000 * 60 * 60 * 24);
        const months = milliSeconds / (1000 * 60 * 60 * 24 * 30);
        const years = milliSeconds / (1000 * 60 * 60 * 24 * 30 * 12);

        const timeDifference = {
            year: isNegativeValue ? years * -1 : years,
            month: isNegativeValue ? months * -1 : months,
            day: isNegativeValue ? days * -1 : days,
            hour: isNegativeValue ? hours * -1 : hours,
            minute: isNegativeValue ? minutes * -1 : minutes,
            second: isNegativeValue ? seconds * -1 : seconds,
        };

        return timeDifference[unit];
    },

    timeDifferenceRoundUpValue: (
        startTime: string | number | Date | undefined,
        endTime: string | number | Date | undefined,
    ) => {
        if (!startTime || !endTime) return '--';
        const timeDifference = dateTimeUtils.timeDifference(startTime, endTime);
        for (let key in timeDifference) {
            if (timeDifference[key as keyof typeof timeDifference] !== 0) {
                return `${timeDifference[key as keyof typeof timeDifference]} ${key.slice(0, 1)}`;
            }
        }
    },

    addOrSubtractTime: (
        date: string,
        value: number,
        type: 'year' | 'month' | 'day' | 'hour' | 'min' | 'sec',
    ): string => {
        if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(date)) return '--';
        if (isNaN(new Date(date).getTime())) return '--';

        const givenDateInMilliseconds = new Date(date).getTime();
        let previousDate;

        const previousDateCalculateObj = {
            year: givenDateInMilliseconds + value * 365 * 24 * 3600 * 1000,
            month: givenDateInMilliseconds + value * 30 * 24 * 3600 * 1000,
            day: givenDateInMilliseconds + value * 24 * 3600 * 1000,
            hour: givenDateInMilliseconds + value * 3600 * 1000,
            min: givenDateInMilliseconds + value * 60 * 1000,
            sec: givenDateInMilliseconds + value * 1000,
        };

        previousDate = previousDateCalculateObj[type];
        if (previousDate) return new Date(previousDate).toISOString();
        else return 'Invalid';
    },
};
