import AWS from 'aws-sdk';

import { logger } from '../logger.service';

import { DATA_CONSTANT } from 'constants/data/data.constants';

export const awsS3StorageApi = {
    uploadToStorage: async (selectedFile: File, objectKey: string): Promise<string | null> => {
        const s3 = new AWS.S3({
            accessKeyId: DATA_CONSTANT.AWS.ACCESS_KEY_ID,
            secretAccessKey: DATA_CONSTANT.AWS.SECRET_ACCESS_KEY,
            region: DATA_CONSTANT.AWS.REGION,
        });

        // const newDate = new Date();
        // const yearMonthDate = `${newDate.getFullYear()}${newDate.getMonth() + 1}${newDate.getDate()}`;
        // const timeFormat = `${newDate.getHours()}${newDate.getMinutes()}${newDate.getSeconds()}${newDate.getMilliseconds()}`;

        const bucketName = 'cp2-cpcontent';
        // const objectKey = `file/${yearMonthDate}/${timeFormat}/${selectedFile.name}`;
        const fileWithParams = { Bucket: bucketName, Key: objectKey, Body: selectedFile };

        const uploadFile = (): Promise<string | null> => {
            return new Promise((resolve, reject) => {
                s3.upload(fileWithParams, (error: any, data: any) => {
                    if (error) {
                        logger.error('Error : s3Upload()', error.response || error);
                        reject(null);
                    }
                    resolve(data.Location);
                });
            });
        };

        return await uploadFile();
    },
};
