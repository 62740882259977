import { createTheme } from '@mui/material/styles';

export const muiTheme = createTheme({
    typography: {
        fontFamily: ['Poppins', 'sans-serif'].join(','),
    },

    components: {
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                    h1: 'h1',
                    h2: 'h2',
                    h3: 'h3',
                    h4: 'h4',
                    h5: 'h5',
                    h6: 'h6',
                    p: 'p',
                },
            },
        },
    },
});

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        p: true;
    }
}
