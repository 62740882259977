import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';

import { IconBtn } from 'theme/mui/buttons.theme';

import styles from './PastSessionsDetails.module.scss';

import { InputFields } from 'template/inputFields/InputFields.template';

interface PastSessionsTableInputInterface {
    pageNumber: number;
    rowsPerPage: number;
    changeTableProperty: (action: 'add1Page' | 'sub1Page' | 'setPage' | 'setRow', value?: number) => void;
}

export const PastSessionsTableInput = ({
    pageNumber,
    rowsPerPage,
    changeTableProperty,
}: PastSessionsTableInputInterface) => {
    return (
        <div className={`${styles.pastSessionsPageNumber}`}>
            <div className={`${styles.rowsPerPageText}`}>
                <p className={`${styles.inputLabel}`}>Rows/Page :</p>
                <InputFields
                    inputFields={[
                        {
                            label: 'Rows Per Page',
                            inputType: 'number',
                            showOnlyValue: true,
                            fieldClassName: styles.rowsPerPageInputField,
                        },
                    ]}
                    inputDetails={rowsPerPage}
                    inputOnChangeHandler={(value: string) => changeTableProperty('setRow', parseInt(value))}
                />
            </div>
            <IconBtn
                onClick={() => pageNumber > 0 && changeTableProperty('sub1Page')}
                className={`${styles.pageNumberArrowBtn}`}
                disabled={pageNumber === 0}
            >
                <BsArrowLeft className={`${styles.arrowIcon}`} />
            </IconBtn>
            <div className={`${styles.pageNumberText}`}>
                <p className={`${styles.inputLabel}`}>Page :</p>
                <InputFields
                    inputFields={[
                        {
                            label: 'Page Number',
                            inputType: 'number',
                            showOnlyValue: true,
                            fieldClassName: styles.pageNumberInputField,
                        },
                    ]}
                    inputDetails={pageNumber}
                    inputOnChangeHandler={(value: string) => changeTableProperty('setPage', parseInt(value))}
                />
            </div>
            <IconBtn onClick={() => changeTableProperty('add1Page')} className={`${styles.pageNumberArrowBtn}`}>
                <BsArrowRight className={`${styles.arrowIcon}`} />
            </IconBtn>
        </div>
    );
};
