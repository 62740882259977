import { useEffect, useState } from 'react';

import { BtnInfo } from 'theme/mui/buttons.theme';

import global from 'theme/global/Global.module.scss';
import styles from './CreateKeyForm.module.scss';

import { Block } from 'template/block/Block.template';
import { InputFields } from 'template/inputFields/InputFields.template';

import { CreateKeyInterface } from 'interfaces/key.interface';
import { InputFieldsInterface } from 'interfaces/ui/inputFields.interface';

import { createKeyInput, createKeyInputIconsArray } from './createKeyInputFields';

interface CreateKeyFormInterface {
    isLoading: boolean;
    inputDetails: CreateKeyInterface;
    inputOnChangeHandler: (value: string, fieldName?: string) => void;
    validateDetailsHandler: (arg: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const CreateKeyForm = ({
    isLoading,
    inputDetails,
    inputOnChangeHandler,
    validateDetailsHandler,
}: CreateKeyFormInterface) => {
    const [inputFields, setInputFields] = useState<InputFieldsInterface[]>();

    const getInputFields = () => {
        const inputFieldsData = createKeyInput(inputDetails.type, inputDetails);
        setInputFields(inputFieldsData);
    };

    useEffect(() => {
        getInputFields();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputDetails]);

    return inputFields ? (
        <div className={`${global.moduleWrapper} ${styles.createKeyDetailsModule}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    <Block
                        heading={'Create Key'}
                        content={
                            <div className={styles.formContainer}>
                                <form className={styles.form}>
                                    <InputFields
                                        inputFields={inputFields}
                                        inputDetails={inputDetails}
                                        inputIconsArray={createKeyInputIconsArray}
                                        inputOnChangeHandler={inputOnChangeHandler}
                                    />
                                </form>
                                <BtnInfo
                                    onClick={(e) => validateDetailsHandler(e)}
                                    loading={isLoading}
                                    type="submit"
                                    className={`${styles.createBtn}`}
                                >
                                    Create
                                </BtnInfo>
                                {(inputDetails.error || inputDetails.success) && (
                                    <p
                                        className={`${inputDetails.error ? styles.error : styles.success} ${
                                            global.bodyLarge
                                        }`}
                                    >
                                        {inputDetails.error || inputDetails.success}
                                    </p>
                                )}
                            </div>
                        }
                    />
                </div>
            </div>
        </div>
    ) : (
        <div></div>
    );
};
