import { useCallback, useEffect, useRef, useState } from 'react';

import { BtnNeutral, BtnSuccess } from 'theme/mui/buttons.theme';

import global from 'theme/global/Global.module.scss';
import styles from './EditCctModalContent.module.scss';

import { InputFields } from 'template/inputFields/InputFields.template';

import { objectUtils } from 'helpers/utilities/object.util';
import { commonUtils } from 'helpers/utilities/common.util';
import { fileUtils } from 'helpers/utilities/file.util';
import { classPointCertifiedApi } from 'services/api/classPointCertified.api';
import { azureStorageApi } from 'services/api/azureStorage.api';

import { CctInterface, EditCctInterface } from 'interfaces/classPointCertified.interface';
import { DATA_CONSTANT } from 'constants/data/data.constants';

import { InstantAlert } from 'components/common/instantAlert/InstantAlert';
import TextBox from './modules/TextBox';

import { editKeyInputIconArray, editCctModalInputFieldsData } from './editCctModalInputFieldsData';

interface EditCctModalContentInterface {
    modalCloseHandler: () => void;
    email: string;
    cct: CctInterface | undefined;
    getCct: () => void;
}

export const EditCctModalContent = ({ modalCloseHandler, email, cct, getCct }: EditCctModalContentInterface) => {
    const showNotification = useRef<any>();

    const [isLoading, setIsLoading] = useState(false);
    const [showInstantAlertBioError, setShowInstantAlertBioError] = useState(false);
    const [bioError, setBioError] = useState('');
    const [editCctDetails, setEditCctDetails] = useState<EditCctInterface>({
        displayName: cct?.displayName || '',
        organization: cct?.organization || '',
        address: cct?.address || '',
        phone: cct?.phone || '',
        country: cct?.country || '',
        photoUrl: cct?.photoUrl || '',
        jobTitle: cct?.jobTitle || '',
        subjects: commonUtils.separateByComma(cct?.subjects?.toString()) || '',
        contactEmail: cct?.contactEmail || '',
        bio: cct?.bio || '',
        twitter: cct?.links?.twitter || '',
        facebook: cct?.links?.facebook || '',
        website: cct?.links?.website || '',
        instagram: cct?.links?.instagram || '',
        youtube: cct?.links?.youtube || '',
        tiktok: cct?.links?.tiktok || '',
    });

    const inputOnChangeHandler = (value: string, fieldName?: string) => {
        if (fieldName) {
            setEditCctDetails((prevState) => ({ ...prevState, [fieldName]: value }));
        }
    };

    const fileInputOnChangeHandler = (file: any) => {
        const fileInfo = file && file[0];
        if (!fileInfo) return;
        const isValidImageFile = fileUtils.isValidImage(fileInfo);
        setEditCctDetails((prevState) => ({ ...prevState, photoUrl: isValidImageFile ? fileInfo : '' }));
    };

    const removeFileSelectionHandler = () => {
        setEditCctDetails((prevState) => ({ ...prevState, photoUrl: '' }));
    };

    const editCctHandler = async () => {
        setIsLoading(true);

        let uploadedFileUrl = '';

        if (editCctDetails.photoUrl && typeof editCctDetails.photoUrl !== 'string') {
            const fileUrl = await azureStorageApi.uploadToStorage(
                editCctDetails.photoUrl,
                editCctDetails.photoUrl.name,
                DATA_CONSTANT.AZURE.CPCS_REGION,
                DATA_CONSTANT.AZURE.CONTAINER_NAME,
            );
            if (fileUrl) uploadedFileUrl = fileUrl;
        } else uploadedFileUrl = editCctDetails.photoUrl;

        const arrangedData = {
            displayName: editCctDetails.displayName,
            organization: editCctDetails.organization,
            address: editCctDetails.address,
            phone: editCctDetails.phone,
            country: editCctDetails.country,
            photoUrl: uploadedFileUrl,
            jobTitle: editCctDetails.jobTitle,
            subjects: editCctDetails.subjects,
            contactEmail: editCctDetails.contactEmail,
            bio: editCctDetails.bio,
            links: {
                twitter: editCctDetails.twitter,
                facebook: editCctDetails.facebook,
                website: editCctDetails.website,
                instagram: editCctDetails.instagram,
                youtube: editCctDetails.youtube,
                tiktok: editCctDetails.tiktok,
            },
        };

        const trimmedFormDetails = objectUtils.trimSpace(arrangedData, ['subjects']);
        const editCct = await classPointCertifiedApi.updateCct(email, trimmedFormDetails);
        setIsLoading(false);

        if (typeof editCct !== 'string') {
            getCct();
            modalCloseHandler();
        }
    };

    const checkValidity = useCallback(() => {
        setBioError('');

        const textValue = document.getElementsByClassName('ql-editor')[0].innerHTML;
        const cleanText = textValue.replace(/<\/?[^>]+(>|$)/g, '').trim();

        if (cleanText.length === 0 || cleanText === '<p><br></p>') {
            setBioError('Error : Invalid input');
            return false;
        }

        setEditCctDetails((prevState) => ({ ...prevState, bio: textValue }));
        return true;
    }, []);

    useEffect(() => {
        clearTimeout(showNotification.current);
        showNotification.current = setTimeout(() => {
            setShowInstantAlertBioError(false);
        }, 2000);
        return () => clearTimeout(showNotification.current);
    }, [showInstantAlertBioError]);

    return (
        <div className={`${global.moduleWrapper} ${styles.editCctModalContentModule}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    {showInstantAlertBioError && <InstantAlert type="error" text={bioError} />}

                    <div className={`${styles.headContainer}`}>
                        <h4 className={`${global.head4} ${styles.head}`}>Edit CCT Details</h4>
                    </div>

                    <div className={`${styles.inputFieldContainer}`}>
                        <div className={`${styles.fieldWrapper}`}>
                            <InputFields
                                inputFields={editCctModalInputFieldsData(removeFileSelectionHandler)}
                                inputDetails={editCctDetails}
                                inputIconsArray={editKeyInputIconArray}
                                inputOnChangeHandler={inputOnChangeHandler}
                                fileInputOnChangeHandler={fileInputOnChangeHandler}
                            />
                        </div>
                        <div className={`${styles.bioEditContainer}`}>
                            <h5 className={`${global.head6}`}>CCT : Update Bio</h5>
                            <TextBox value={cct?.bio} checkValidity={checkValidity} />
                        </div>
                    </div>

                    <div className={`${styles.btnContainer}`}>
                        <BtnNeutral onClick={() => modalCloseHandler()} className={`${styles.btn}`}>
                            Cancel
                        </BtnNeutral>
                        <BtnSuccess loading={isLoading} onClick={() => editCctHandler()} className={`${styles.btn}`}>
                            Save
                        </BtnSuccess>
                    </div>
                </div>
            </div>
        </div>
    );
};
