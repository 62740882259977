import { BtnInfo } from 'theme/mui/buttons.theme';

import global from 'theme/global/Global.module.scss';
import styles from './SearchUsersDetails.module.scss';

import { DataTable } from 'template/dataTable/DataTable.template';
import { DataGrid } from 'template/dataGrid/DataGrid.template';

import { commonUtils } from 'helpers/utilities/common.util';

import { CheckUserApiResponseInterface } from 'interfaces/user.interface';

import { searchUsersTableData } from 'data/user/searchUsers/searchUsers.tableData';
import { searchUsersNotMembersGridData } from 'data/user/searchUsers/searchUsersNotMembers.gridData';
import { generateExcelFile } from 'data/generateExcelFile/generateExcelFile.data';

interface SearchUsersDetailsInterface {
    checkUsersDetails: CheckUserApiResponseInterface | undefined;
    getSingleUserDetailsHandler: (email: string) => void;
    setShowInstantAlert: (arg: boolean) => void;
    tableHeading?: string;
}

export const SearchUsersDetails = ({
    checkUsersDetails,
    getSingleUserDetailsHandler,
    setShowInstantAlert,
    tableHeading,
}: SearchUsersDetailsInterface) => {
    const checkUsersDataInTableFormat = searchUsersTableData(
        checkUsersDetails?.users,
        getSingleUserDetailsHandler,
        tableHeading,
    );

    const copyEmailValue = (emailValue: string) => {
        navigator.clipboard.writeText(emailValue.replaceAll('<br/>', ' '));
        setShowInstantAlert(true);
    };

    const nonUsers =
        checkUsersDetails?.nonUsers &&
        checkUsersDetails?.nonUsers.length > 0 &&
        searchUsersNotMembersGridData(checkUsersDetails.nonUsers, copyEmailValue);

    const exportExcelFileHandler = () => {
        let dataArray: [{ [key: string]: any }] = [{}];
        const emails =
            checkUsersDetails?.nonUsers.toString() &&
            commonUtils.trimCommaSeparatedString(checkUsersDetails.nonUsers.toString());

        Array.isArray(emails) &&
            emails.map((item, index) =>
                dataArray.push({
                    No: `${index + 1}`,
                    Email: item,
                }),
            );

        generateExcelFile(dataArray, 'ClassPoint Non Users');
    };

    return (
        <div className={`${global.moduleWrapper} ${styles.latestUsersDetailsModule}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    <DataTable data={checkUsersDataInTableFormat} />
                    {nonUsers && (
                        <DataGrid
                            data={nonUsers}
                            customHeadingElement={
                                <BtnInfo
                                    onClick={() => exportExcelFileHandler()}
                                    className={`${global.bodyXs} ${styles.headBtn}`}
                                >
                                    Export
                                </BtnInfo>
                            }
                            className="checkUserDetails"
                        />
                    )}
                </div>
            </div>
        </div>
    );
};
