export interface TableCellPropsInterface {
    getAllValueOnClick?: (arg: { [key: string]: any }) => void;
    getSelectedValueOnClick?: (arg: any) => void;
    functionArgument?: string;
    color?: string;
    isHidden?: boolean;
    isNoWrap?: boolean;
    type?: TableCellType;
}

export enum TableCellType {
    AVATAR = 'AVATAR',
}
