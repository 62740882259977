import { UserInterface } from 'interfaces/user.interface';
import { generateUserCells } from 'data/generateCells/generateUserCells.data';

export const personalInfoGridData = (user: UserInterface) => {
    const cell = generateUserCells(user);

    return {
        heading: 'Personal Information',
        rows: [
            [cell.firstName, cell.lastName, cell.userId],
            [cell.organization, cell.jobRole, cell.subjects],
            [cell.city, cell.country, cell.language],
        ],
    };
};
