import { CctInterface } from 'interfaces/classPointCertified.interface';
import { generateCctCells } from 'data/generateCells/generateCctCells.data';

export const cctDetailsGridData = (cct: CctInterface | undefined) => {
    const cell = cct && generateCctCells(cct);

    const dataRows = cell && [
        [cell.started, cell.titleExpiryDate, cell.status],
        [cell.organization, cell.address, cell.phone],
        [cell.jobTitle, cell.subjects, cell.links],
    ];

    return {
        heading: 'CCT Details',
        rows: dataRows || [],
    };
};
