import { RiKey2Line } from 'react-icons/ri';

export const verifyApplyKeyInputFields = [
    {
        fieldName: 'key',
        isRequired: true,
    },
];

export const verifyApplyKeyInputIconsArray = {
    key: <RiKey2Line />,
};
