import { useCallback } from 'react';

import { BtnInfo } from 'theme/mui/buttons.theme';

import global from 'theme/global/Global.module.scss';
import styles from './CreateContentForm.module.scss';

import { Block } from 'template/block/Block.template';
import { InputFields } from 'template/inputFields/InputFields.template';

import { CategoryAndTagInterface, CreateContentDataInterface } from 'interfaces/content.interface';

import TextBox from './modules/TextBox';
import { createContentInput } from './createContentInputFields';

interface CreateContentFormInterface {
    isLoading: boolean;
    isUpdate: boolean;
    categoryAndTagDetails: CategoryAndTagInterface;
    inputDetails: Partial<CreateContentDataInterface>;
    errors: string;
    setErrors: (value: string) => void;
    fileName: { fileName: string; coverName: string; imagesName: string };
    inputOnChangeHandler: (value: string, fieldName?: string) => void;
    details: string;
    setDetails: (value: string) => void;
    multipleSelectOnChangeHandler: (value: string[], fieldName?: string) => void;
    validateDetailsHandler: (arg: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    fileInputOnChangeHandler: (fileInfo: any) => void;
    removeFileSelectionHandler: () => void;
    coverInputOnChangeHandler: (fileInfo: any) => void;
    removeCoverFileSelectionHandler: () => void;
    imageInputOnChangeHandler: (fileInfo: any) => void;
    removeImageFileSelectionHandler: () => void;
}

export const CreateContentForm = ({
    isLoading,
    isUpdate,
    categoryAndTagDetails,
    inputDetails,
    errors,
    setErrors,
    fileName,
    inputOnChangeHandler,
    details,
    setDetails,
    multipleSelectOnChangeHandler,
    validateDetailsHandler,
    fileInputOnChangeHandler,
    removeFileSelectionHandler,
    coverInputOnChangeHandler,
    removeCoverFileSelectionHandler,
    imageInputOnChangeHandler,
    removeImageFileSelectionHandler,
}: CreateContentFormInterface) => {
    const checkValidity = useCallback(() => {
        const textValue = document.getElementsByClassName('ql-editor')[0].innerHTML;
        const cleanText = textValue.replace(/<\/?[^>]+(>|$)/g, '').trim();

        if (cleanText.length === 0 || cleanText === '<p><br></p>') {
            return false;
        }

        setDetails(textValue);
        setErrors('');
        return true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={`${global.moduleWrapper} ${styles.createContentDetailsModule}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    <Block
                        heading={'Create Content'}
                        content={
                            <div className={styles.formContainer}>
                                <form className={styles.form}>
                                    <InputFields
                                        inputFields={createContentInput(categoryAndTagDetails) || []}
                                        inputDetails={inputDetails}
                                        inputOnChangeHandler={inputOnChangeHandler}
                                        multipleSelectOnChangeHandler={multipleSelectOnChangeHandler}
                                    />
                                    <InputFields
                                        inputFields={[
                                            {
                                                fieldType: 'file',
                                                fieldName: 'file',
                                                label: 'Upload file',
                                                removeSelection: () => removeFileSelectionHandler(),
                                                isRequired: true,
                                                fileName: fileName.fileName,
                                                fieldClassName: `${styles.fileInputField}`,
                                            },
                                        ]}
                                        inputDetails={inputDetails}
                                        fileInputOnChangeHandler={fileInputOnChangeHandler}
                                    />
                                    <InputFields
                                        inputFields={[
                                            {
                                                fieldType: 'file',
                                                fieldName: 'file',
                                                label: 'Upload cover image',
                                                removeSelection: () => removeCoverFileSelectionHandler(),
                                                isRequired: true,
                                                fileName: fileName.coverName,
                                                fieldClassName: `${styles.fileInputField}`,
                                            },
                                        ]}
                                        inputDetails={inputDetails}
                                        fileInputOnChangeHandler={coverInputOnChangeHandler}
                                    />
                                    <InputFields
                                        inputFields={[
                                            {
                                                fieldType: 'file',
                                                fieldName: 'file',
                                                label: 'Upload images',
                                                removeSelection: () => removeImageFileSelectionHandler(),
                                                isRequired: true,
                                                fileName: fileName.imagesName,
                                                multipleFiles: true,
                                                fieldClassName: `${styles.fileInputField}`,
                                            },
                                        ]}
                                        inputDetails={inputDetails}
                                        fileInputOnChangeHandler={imageInputOnChangeHandler}
                                    />
                                    <div className={`${styles.descriptionEditContainer}`}>
                                        <TextBox value={undefined} checkValidity={checkValidity} />
                                    </div>
                                </form>
                                <BtnInfo
                                    onClick={(e) => validateDetailsHandler(e)}
                                    loading={isLoading}
                                    type="submit"
                                    className={`${styles.createBtn}`}
                                >
                                    {isUpdate ? 'Update' : 'Create'}
                                </BtnInfo>

                                {errors && <p className={`${styles.error} ${global.bodyLarge}`}>{errors}</p>}
                            </div>
                        }
                    />
                </div>
            </div>
        </div>
    );
};
