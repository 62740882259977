import { ReactNode } from 'react';

import { BsCaretDownFill, BsCaretUpFill } from 'react-icons/bs';

import { NavItemBtn } from 'theme/mui/listItems.theme';

interface ListItemInterface {
    children: ReactNode;
    onClickHandler: () => void;
    className?: string;
}

export const ListItem = ({ children, onClickHandler, className }: ListItemInterface) => {
    return (
        <NavItemBtn onClick={() => onClickHandler()} className={className || ''}>
            {children}
        </NavItemBtn>
    );
};

interface ExpandableListItemInterface {
    children: ReactNode;
    isOpen: boolean;
    onClickHandler: () => void;
    className?: string;
}

export const ExpandableListItem = ({ children, isOpen, onClickHandler, className }: ExpandableListItemInterface) => {
    return (
        <NavItemBtn onClick={() => onClickHandler()} className={className || ''}>
            {children}
            {isOpen ? <BsCaretUpFill className="expandCloseIcon" /> : <BsCaretDownFill className="expandOpenIcon" />}
        </NavItemBtn>
    );
};
