import { AES, enc } from 'crypto-js';

export const encodeValue = (value: any, key: string) => {
    const encryptedValue = process.env.REACT_APP_ENV === 'production' ? AES.encrypt(value, key) : value;
    return encryptedValue.toString();
};

export const decodeValue = (value: any, key: string) => {
    return process.env.REACT_APP_ENV === 'production' ? AES.decrypt(value, key).toString(enc.Utf8) : value;
};
