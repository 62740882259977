import global from 'theme/global/Global.module.scss';
import styles from './Loading.module.scss';

interface LoadingInterface {
    message?: string;
}

export const Loading = ({ message }: LoadingInterface) => {
    return (
        <div className={`${global.componentWrapper} ${styles.loadingComponent}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    <div className={`${styles.loaderContainer}`}>
                        <div className={`${styles.loader}`}>
                            <svg viewBox="0 0 80 80">
                                <circle id="test" cx="40" cy="40" r="32"></circle>
                            </svg>
                        </div>
                        <div className={`${styles.loader} ${styles.triangle}`}>
                            <svg viewBox="0 0 86 80">
                                <polygon points="43 8 79 72 7 72"></polygon>
                            </svg>
                        </div>
                        <div className={`${styles.loader}`}>
                            <svg viewBox="0 0 80 80">
                                <rect x="8" y="8" width="64" height="64"></rect>
                            </svg>
                        </div>
                    </div>
                    <p className={`${global.subHead} ${styles.text}`}>{message || '...Loading...'}</p>
                </div>
            </div>
        </div>
    );
};
