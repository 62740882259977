import { styled, TextField } from '@mui/material';

// ========== Input Fields ==========
export const InputBox = styled(TextField)({
    '&:hover': {
        '.MuiInputLabel-root': {
            color: 'var(--clr_text_primary)',
        },
    },

    '&.isRequired': {
        '.MuiInputLabel-root': {
            '&.MuiInputLabel-shrink': {
                color: 'var(--clr_text_warn_light)',
            },
        },
    },

    '&.showOnlyValue': {
        '.MuiInputLabel-root': {
            paddingInline: '10px',
            transform: 'none',
            inset: '0 0 0 0',
            margin: 'auto',

            '&.MuiInputLabel-shrink': {
                display: 'none',
            },
        },

        '.MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
    },

    '.MuiInputLabel-root': {
        fontSize: '14px',
        color: 'var(--clr_text_neutral)',

        '&.Mui-focused': {
            color: 'var(--clr_text_info) !important',
        },
    },

    '.MuiInputBase-root': {
        color: 'var(--clr_text_primary)',

        '&:not(.MuiInputLabel-shrink)': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',

            width: '100%',
            height: '100%',
        },

        '.MuiInputBase-input': {
            paddingBlock: 0,
        },
    },

    '&.inputLine': {
        '.MuiInputBase-root': {
            borderBlockEndWidth: '2px',

            '&:hover': {
                ':not(.Mui-disabled)': {
                    '&::before': {
                        borderBlockEndColor: 'var(--clr_text_neutral_dark)',
                    },
                },
            },

            '&::before': {
                borderBlockEndColor: 'var(--clr_text_neutral)',
            },
            '&::after': {
                borderBlockEndColor: 'var(--clr_text_info)',
            },

            '&.Mui-error': {
                '&::after': {
                    borderBlockEndColor: 'var(--clr_text_error_dark)',
                },
            },
        },
    },

    '&.inputBox': {
        '.MuiInputLabel-shrink': {
            top: '4px',
        },

        '.MuiInputBase-root': {
            fontSize: '14px',

            '.MuiOutlinedInput-notchedOutline': {
                top: '-4px',
                borderWidth: '2px',
                borderColor: 'var(--clr_text_neutral_light)',
            },

            '&:hover': {
                '.MuiOutlinedInput-notchedOutline': {
                    borderColor: 'var(--clr_text_neutral_dark)',
                },
            },

            '&.Mui-error': {
                '.MuiOutlinedInput-notchedOutline': {
                    borderColor: 'var(--clr_text_error)',
                },
            },

            '&.Mui-focused': {
                '.MuiInputAdornment-root': {
                    color: 'var(--clr_text_info)',
                },

                '.MuiOutlinedInput-notchedOutline': {
                    borderColor: 'var(--clr_text_info)',
                },
            },

            '.MuiInputAdornment-root': {
                color: 'var(--clr_text_neutral)',
            },
        },
    },
});
