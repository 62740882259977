import { useState } from 'react';

import global from 'theme/global/Global.module.scss';
import styles from './UserSummary.module.scss';

import Select from '@mui/material/Select';
import { SelectItem } from 'theme/mui/listItems.theme';
import { MuiForm } from 'theme/mui/form.theme';

import { BtnError, BtnList } from 'theme/mui/buttons.theme';
import { DataGrid } from 'template/dataGrid/DataGrid.template';
import { CommonModal } from 'template/modal/common/CommonModal.template';

import { userUtils } from 'helpers/utilities/user.util';
import { useAppSelector } from 'helpers/customHooks/useRedux.hook';

import { UserInterface } from 'interfaces/user.interface';
import { CctInterface } from 'interfaces/classPointCertified.interface';
import { DATA_CONSTANT } from 'constants/data/data.constants';

import { VerifyApplyKeyModal } from './components/verifyApplyKey/VerifyApplyKeyModal';
import { UserCard } from './components/userCard/UserCard';
import { ActivateTrial } from './components/activateTrial/ActivateTrial';
import { TogglePremium } from './components/togglePremium/TogglePremium';
import { EditCctModalContent } from './components/editCct/EditCctModalContent';
import { RenewCctModalContent } from './components/renewCct/RenewCctModalContent';
import { ChangeEmailModalContent } from './components/changeEmail/ChangeEmailModalContent';
import { DeleteUserModalContent } from './components/deleteUser/DeleteUserModalContent';

import { userSummaryGridData } from 'data/user/userDetails/summary/userSummary.gridData';
import { subscriptionSummaryGridData } from 'data/user/userDetails/summary/subscriptionSummary.gridData';
import { ClearUserLocationModalContent } from './components/clearUserLocation/ClearUserLocationModalContent';

interface UserSummaryInterface {
    user: UserInterface;
    getUser: (value?: string, type?: 'email' | 'userId') => void;
    cct: CctInterface | undefined;
    getCct: () => void;
    refreshUserDetails: (email?: string) => void;
    deleteUserAlertHandler: (response: any) => void;
    changeEmailAlertHandler: (response: any, newEmail: string) => void;
    clearUserLocationAlertHandler: (response: any) => void;
}

export const UserSummary = ({
    user,
    getUser,
    cct,
    getCct,
    refreshUserDetails,
    deleteUserAlertHandler,
    changeEmailAlertHandler,
    clearUserLocationAlertHandler,
}: UserSummaryInterface) => {
    const adminType = useAppSelector((state) => state.admin.roles);

    const userSummary = userSummaryGridData(user);
    const subscriptionSummary = subscriptionSummaryGridData(user);

    const [isVerifyApplyKeyModalOpen, setIsVerifyApplyKeyModalOpen] = useState(false);
    const [isEditCctModalOpen, setIsEditCctModalOpen] = useState(false);
    const [isRenewCctModalOpen, setIsRenewCctModalOpen] = useState(false);
    const [isChangeEmailModalOpen, setIsChangeEmailModalOpen] = useState(false);
    const [isDeleteUserModalOpen, setIsDeleteUserModalOpen] = useState(false);
    const [isClearUserLocationModalOpen, setIsClearUserLocationModalOpen] = useState(false);

    const userSubscriptionType = userUtils.getUserSubscriptionType(user);

    return (
        <div className={`${global.moduleWrapper} ${styles.userSummaryModule}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    <VerifyApplyKeyModal
                        isVerifyApplyKeyModalOpen={isVerifyApplyKeyModalOpen}
                        setIsVerifyApplyKeyModalOpen={setIsVerifyApplyKeyModalOpen}
                        user={user}
                        getUser={getUser}
                    />

                    <CommonModal
                        isModalOpen={isEditCctModalOpen}
                        modalCloseHandler={() => setIsEditCctModalOpen(false)}
                        modalContent={
                            <EditCctModalContent
                                modalCloseHandler={() => setIsEditCctModalOpen(false)}
                                email={user.email}
                                cct={cct}
                                getCct={getCct}
                            />
                        }
                    />

                    <CommonModal
                        isModalOpen={isRenewCctModalOpen}
                        modalCloseHandler={() => setIsRenewCctModalOpen(false)}
                        modalContent={
                            <RenewCctModalContent
                                modalCloseHandler={() => setIsRenewCctModalOpen(false)}
                                email={user.email}
                                expiryDate={cct?.titleExpiryDate}
                                refreshUserDetails={refreshUserDetails}
                            />
                        }
                    />

                    <CommonModal
                        isModalOpen={isChangeEmailModalOpen}
                        modalCloseHandler={() => setIsChangeEmailModalOpen(false)}
                        modalContent={
                            <ChangeEmailModalContent
                                oldEmail={user.email}
                                changeEmailAlertHandler={changeEmailAlertHandler}
                            />
                        }
                    />

                    <CommonModal
                        isModalOpen={isDeleteUserModalOpen}
                        modalCloseHandler={() => setIsDeleteUserModalOpen(false)}
                        modalContent={
                            <DeleteUserModalContent
                                email={user.email}
                                deleteUserAlertHandler={deleteUserAlertHandler}
                            />
                        }
                    />

                    <CommonModal
                        isModalOpen={isClearUserLocationModalOpen}
                        modalCloseHandler={() => setIsClearUserLocationModalOpen(false)}
                        modalContent={
                            <ClearUserLocationModalContent
                                email={user.email}
                                clearUserLocationAlertHandler={clearUserLocationAlertHandler}
                            />
                        }
                    />

                    <UserCard user={user} />
                    <DataGrid data={userSummary} />
                    <DataGrid data={subscriptionSummary} />

                    <MuiForm className={`${styles.options}`}>
                        <Select value="User actions" className={`${global.customSelect} ${styles.selectWrapper}`}>
                            <SelectItem className={`${styles.userActionList}`}>
                                <BtnList
                                    onClick={() => setIsVerifyApplyKeyModalOpen(true)}
                                    className={`${styles.userActionListBtn}`}
                                >
                                    Verify/Apply Key
                                </BtnList>
                            </SelectItem>
                            <SelectItem
                                disabled={userSubscriptionType !== DATA_CONSTANT.PLAN_CATEGORY.BASIC}
                                className={`${styles.userActionList}`}
                            >
                                <ActivateTrial user={user} refreshUserDetails={refreshUserDetails} />
                            </SelectItem>
                            <SelectItem
                                disabled={
                                    userSubscriptionType === DATA_CONSTANT.PLAN_CATEGORY.BASIC ||
                                    userSubscriptionType === DATA_CONSTANT.PLAN_CATEGORY.TRIAL
                                }
                                className={`${styles.userActionList}`}
                            >
                                <TogglePremium user={user} refreshUserDetails={refreshUserDetails} />
                            </SelectItem>

                            <SelectItem disabled={!cct} className={`${styles.userActionList}`}>
                                <BtnList
                                    onClick={() => setIsEditCctModalOpen(true)}
                                    className={`${styles.userActionListBtn}`}
                                >
                                    Edit CCT
                                </BtnList>
                            </SelectItem>
                            <SelectItem disabled={!cct} className={`${styles.userActionList}`}>
                                <BtnList
                                    onClick={() => setIsRenewCctModalOpen(true)}
                                    className={`${styles.userActionListBtn}`}
                                >
                                    Renew CCT
                                </BtnList>
                            </SelectItem>

                            <SelectItem
                                disabled={!adminType.includes('owner') && !adminType.includes('adminManager')}
                                className={`${styles.userActionList}`}
                            >
                                <BtnList
                                    onClick={() => setIsChangeEmailModalOpen(true)}
                                    className={`${styles.userActionListBtn}`}
                                >
                                    Change Email
                                </BtnList>
                            </SelectItem>
                            <SelectItem
                                disabled={!adminType.includes('owner') && !adminType.includes('adminManager')}
                                className={`${styles.userActionList}`}
                            >
                                <BtnList
                                    onClick={() => setIsClearUserLocationModalOpen(true)}
                                    className={`${styles.userActionListBtn}`}
                                >
                                    Clear Location
                                </BtnList>
                            </SelectItem>
                            <SelectItem
                                disabled={!adminType.includes('owner') && !adminType.includes('adminManager')}
                                className={`${styles.userActionList}`}
                            >
                                <BtnError
                                    onClick={() => setIsDeleteUserModalOpen(true)}
                                    className={`${styles.userActionListBtn}`}
                                >
                                    Delete User
                                </BtnError>
                            </SelectItem>
                        </Select>
                    </MuiForm>
                </div>
            </div>
        </div>
    );
};
