import { BsDoorOpen } from 'react-icons/bs';
import { HiOutlineUserCircle } from 'react-icons/hi';
import { RiKey2Line, RiShieldCheckLine } from 'react-icons/ri';
import { MdPublishedWithChanges, MdOutlineCloudUpload, MdOutlineGrade } from 'react-icons/md';
import { TbChartCandle } from 'react-icons/tb';

import styles from './Navigation.module.scss';

export const navigationData = [
    [
        {
            icon: <HiOutlineUserCircle className={`${styles.icon}`} />,
            content: 'User',
            active: 'user',
            subLevelLinks: [
                {
                    content: 'User Details',
                    address: 'details',
                },
                {
                    content: 'Search User(s)',
                    address: 'search',
                },
                {
                    content: 'Latest Users',
                    address: 'latest',
                },
                {
                    content: 'Webinar Report',
                    address: 'webinar-report',
                },
            ],
        },
        {
            icon: <MdOutlineGrade className={`${styles.icon}`} />,
            content: 'Session',
            active: 'session',
            subLevelLinks: [
                {
                    content: 'Live Session',
                    address: 'live-session',
                },
                {
                    content: 'Past Sessions',
                    address: 'past-session',
                },
            ],
        },
        {
            icon: <RiKey2Line className={`${styles.icon}`} />,
            content: 'Key',
            active: 'key',
            subLevelLinks: [
                {
                    content: 'Create Key',
                    address: 'create',
                },
                {
                    content: 'Search Key(s)',
                    address: 'search',
                },
                {
                    content: 'View/Edit Keys',
                    address: 'view-edit',
                },
                {
                    content: 'Apply Key',
                    address: 'apply',
                },
            ],
        },
        {
            icon: <TbChartCandle className={`${styles.icon}`} />,
            content: 'Affiliate',
            active: 'affiliate',
            subLevelLinks: [
                {
                    content: 'Create Affiliate',
                    address: 'create',
                },
                {
                    content: 'Get Affiliate',
                    address: 'get',
                },
                {
                    content: 'All Affiliates',
                    address: 'all',
                },
            ],
        },
        {
            icon: <RiShieldCheckLine className={`${styles.icon}`} />,
            content: 'CCT / CCE / CSC',
            active: 'classpoint-certified',
            subLevelLinks: [
                {
                    content: 'CCT',
                    address: 'cct',
                },
                // {
                //     content: 'CSC',
                //     address: 'csc',
                // },
            ],
        },
        {
            icon: <MdOutlineCloudUpload className={`${styles.icon}`} />,
            content: 'Content',
            active: 'content',
            subLevelLinks: [
                {
                    content: 'Create',
                    address: 'create',
                },
                {
                    content: 'View/Edit Content',
                    address: 'view-edit',
                },
            ],
        },
        {
            icon: <MdPublishedWithChanges className={`${styles.icon}`} />,
            content: 'Misc',
            active: 'misc',
            subLevelLinks: [
                {
                    content: 'Subscribe Link',
                    address: 'subscribe-link',
                },
                {
                    content: 'AI Access',
                    address: 'ai-access',
                },
                {
                    content: 'Upload File',
                    address: 'upload-file',
                },
            ],
        },
    ],
    [
        {
            icon: <BsDoorOpen className={`${styles.icon}`} />,
            content: 'Logout',
            active: 'logout',
            subLevelLinks: [],
        },
    ],
];
