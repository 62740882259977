import { orderBy } from 'lodash';

import { HiOutlineUserPlus } from 'react-icons/hi2';
import { IoMdGlobe } from 'react-icons/io';

import styles from './LatestUsersTopBar.module.scss';

import { LatestUsersFilterDataInterface } from 'interfaces/user.interface';
import { InputFieldsInterface } from 'interfaces/ui/inputFields.interface';
import { DATA_CONSTANT } from 'constants/data/data.constants';

export const filterInputFieldsData = (filterDetails: LatestUsersFilterDataInterface): InputFieldsInterface[] => {
    return [
        {
            fieldName: 'template',
            fieldType: 'select',
            multipleFields: orderBy(
                Object.values(DATA_CONSTANT.OPTIONS.LATEST_USERS_FILTER_TEMPLATE).map((data) => {
                    return { value: data, label: data };
                }),
                ['label'],
                ['asc'],
            ),
            fieldClassName: `${styles.selectOption}`,
        },
        {
            fieldName: 'email',
            fieldClassName: `${styles.inputField}`,
        },
        {
            fieldName: 'country',
            fieldClassName: `${styles.inputField}`,
        },
        {
            fieldName: 'planStatus',
            fieldType: 'select',
            multipleFields: orderBy(
                Object.values(DATA_CONSTANT.OPTIONS.FILTER_LATEST_USERS).map((data) => {
                    return { value: data };
                }),
                ['value'],
                ['asc'],
            ),
            isMultipleSelect: true,
            fieldClassName: `${styles.selectOption}`,
        },
        // {
        //     fieldName: 'hasEnteredSlideshow',
        //     fieldType: 'selectBoolean',
        //     fieldClassName: `${styles.selectOption}`,
        // },
        {
            fieldName: 'hasSignedIn',
            fieldType: 'selectBoolean',
            fieldClassName: `${styles.selectOption}`,
        },
        {
            fieldName: 'signInFrom',
            fieldType: 'date',
            label: 'Sign In From (UTC)',
            maxDateTime: filterDetails.signInTo,
        },
        {
            fieldName: 'signInTo',
            fieldType: 'date',
            label: 'Sign In To (UTC)',
            minDateTime: filterDetails.signInFrom,
        },
    ];
};

export const filterInputFieldsIconsArray = {
    email: <HiOutlineUserPlus />,
    country: <IoMdGlobe />,
};
