import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { storeLS } from 'services/localService/store.local';
import { AdminInterface } from '../../interfaces/store/admin.interfaces';

const getCachedUserState = () => {
    const storeCached = storeLS.getStoreData();
    if (storeCached && storeCached.admin) return storeCached.admin;
    return undefined;
};

const initialState: AdminInterface = getCachedUserState() || {
    email: undefined,
    userDisplayName: undefined,
    roles: [],
    displayAdminRole: undefined,
    adminAvatar: undefined,
};

const adminSlice = createSlice({
    name: 'ADMIN',
    initialState,
    reducers: {
        SET_ADMIN: (state, action: PayloadAction<AdminInterface>) => {
            state.email = action.payload.email;
            state.userDisplayName = action.payload.userDisplayName;
            state.roles = action.payload.roles;
            state.displayAdminRole = action.payload.displayAdminRole;
            state.adminAvatar = action.payload.adminAvatar;
        },
        LOGOUT_ADMIN: (state) => {
            state.email = undefined;
        },
    },
});

export const ADMIN_REDUCERS = adminSlice.reducer;
export const ADMIN_ACTIONS = adminSlice.actions;
