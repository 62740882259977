import { objectUtils } from 'helpers/utilities/object.util';

import { CreateAffiliateInterface } from 'interfaces/affiliate.interface';
import { ValidationErrorInterface } from 'interfaces/validation.interface';
import { DATA_CONSTANT } from 'constants/data/data.constants';

export const affiliateValidations = {
    createAffiliateFormValidation: (inputDetails: CreateAffiliateInterface): ValidationErrorInterface | string => {
        let errorObj = { errorField: '', error: '' };

        if (!inputDetails.name?.trim()) {
            errorObj = objectUtils.updateObjectKeyValue(errorObj, {
                errorField: 'name',
                error: 'Please provide a name',
            });
        }
        if (!inputDetails.email?.trim()) {
            errorObj = objectUtils.updateObjectKeyValue(errorObj, {
                errorField: 'email',
                error: 'Please provide an email',
            });
        }
        if (!inputDetails.urlCode?.trim()) {
            errorObj = objectUtils.updateObjectKeyValue(errorObj, {
                errorField: 'urlCode',
                error: 'Please provide an URL code',
            });
        }
        if (!inputDetails.countryCode?.trim()) {
            errorObj = objectUtils.updateObjectKeyValue(errorObj, {
                errorField: 'countryCode',
                error: 'Please select a country',
            });
        }
        if (!inputDetails.discountPercentage?.toString().trim()) {
            errorObj = objectUtils.updateObjectKeyValue(errorObj, {
                errorField: 'discountPercentage',
                error: 'Please specify discount percentage',
            });
        }
        if (!inputDetails.commission?.toString().trim()) {
            errorObj = objectUtils.updateObjectKeyValue(errorObj, {
                errorField: 'commission',
                error: 'Please specify commission percentage',
            });
        }

        if (errorObj.error) return errorObj;
        else return '';
    },

    searchAffiliateFormValidation: (email: string): string => {
        if (!email.trim().match(DATA_CONSTANT.REGEX.EMAIL)) return 'Error : Invalid email';
        else return '';
    },
};
