import { startCase } from 'lodash';

import { FormLabel, RadioGroup } from '@mui/material';

import { MuiForm } from 'theme/mui/form.theme';
import { RadioOption } from 'theme/mui/radio.theme';

import { commonUtils } from 'helpers/utilities/common.util';

interface RadioModuleInterface {
    value: string | boolean | undefined;
    inputOnChangeHandler: (value: string, fieldName?: string) => void;
    fieldType: string;
    fieldName?: string;
    multipleFields?: {
        value: string;
        label?: string;
    }[];
    hideLabel?: boolean;
    label?: string;
    isRequired?: boolean;
    fieldClassName?: string;
    optionClassName?: string;
}

export const RadioModule = ({
    value,
    inputOnChangeHandler,
    fieldType,
    fieldName,
    multipleFields,
    hideLabel,
    label,
    isRequired,
    fieldClassName,
    optionClassName,
}: RadioModuleInterface) => {
    return (
        <MuiForm className={fieldClassName || ''}>
            {!hideLabel && (
                <FormLabel className={`${isRequired ? 'isRequired' : ''}`}>
                    {commonUtils.starMarked(label || startCase(fieldName || ''), isRequired)}
                </FormLabel>
            )}
            <RadioGroup
                value={value?.toString() || ''}
                onChange={(e) => {
                    fieldName ? inputOnChangeHandler(e.target.value, fieldName) : inputOnChangeHandler(e.target.value);
                }}
                defaultValue=""
                row
            >
                {/* These two entries have to be separate because MUI Radio does not support Fragment */}
                {fieldType === 'radioBoolean' && (
                    <RadioOption value="false" label="No" className={`${optionClassName || ''}`} />
                )}
                {fieldType === 'radioBoolean' && (
                    <RadioOption value="true" label="Yes" className={`${optionClassName || ''}`} />
                )}

                {multipleFields &&
                    multipleFields.map((option) => (
                        <RadioOption
                            key={option.value}
                            value={option.value}
                            label={option.label || option.value}
                            className={`${optionClassName || ''}`}
                        />
                    ))}
            </RadioGroup>
        </MuiForm>
    );
};
