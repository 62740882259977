import global from 'theme/global/Global.module.scss';
import styles from './CreateAffiliateSummary.module.scss';

import { DataGrid } from 'template/dataGrid/DataGrid.template';

import { CreatedAffiliateFullInterface } from 'interfaces/affiliate.interface';

import { createAffiliateGridData } from 'data/affiliate/createAffiliate/createAffiliate.gridData';

interface CreateAffiliateSummaryInterface {
    createdAffiliateDetails: CreatedAffiliateFullInterface[];
    setShowInstantAlert: (arg: boolean) => void;
}

export const CreateAffiliateSummary = ({
    createdAffiliateDetails,
    setShowInstantAlert,
}: CreateAffiliateSummaryInterface) => {
    const copyValue = (value: string) => {
        navigator.clipboard.writeText(value);
        setShowInstantAlert(true);
    };

    return (
        <div className={`${global.moduleWrapper} ${styles.createKeySummaryModule}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    {createdAffiliateDetails
                        ?.map((data, index) => {
                            const affiliateSummary = createAffiliateGridData(data, copyValue);
                            return (
                                affiliateSummary && (
                                    <DataGrid
                                        key={index}
                                        data={affiliateSummary}
                                        isFullHeight={true}
                                        displaySingleLine={true}
                                    />
                                )
                            );
                        })
                        .reverse()}
                </div>
            </div>
        </div>
    );
};
