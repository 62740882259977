import { dateTimeUtils } from 'helpers/utilities/dateTime.util';
import { ActivatedUserApiInterface } from 'interfaces/key.interface';

export const activatedUsersTableData = (activatedUsersDetails: ActivatedUserApiInterface[] | undefined) => {
    const tableRows = activatedUsersDetails?.map((user) => ({
        User: user.user,
        Activated: dateTimeUtils.getDateTimeFromIso(user.activated),
    }));

    return {
        heading: 'Activated Users',
        rows: tableRows || [],
    };
};
