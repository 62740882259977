import global from 'theme/global/Global.module.scss';
import styles from './GetAllAffiliatesDetails.module.scss';

import { DataTable } from 'template/dataTable/DataTable.template';

import { AffiliateInterface } from 'interfaces/affiliate.interface';

import { getAllAffiliatesTableData } from 'data/affiliate/getAllAffiliates/getAllAffiliates.tableData';
import { BtnInfo } from 'theme/mui/buttons.theme';

interface GetAllAffiliatesDetailsInterface {
    allAffiliatesDetails: AffiliateInterface[];
    getSingleAffiliateHandler: (email: string) => void;
    isSingleAffiliateLoading: boolean;
}

export const GetAllAffiliatesDetails = ({
    allAffiliatesDetails,
    getSingleAffiliateHandler,
    isSingleAffiliateLoading,
}: GetAllAffiliatesDetailsInterface) => {
    const allAffiliatesDetailsTableData = getAllAffiliatesTableData(allAffiliatesDetails, getSingleAffiliateHandler);

    return (
        <div className={`${global.moduleWrapper} ${styles.keyDetailsModule}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    <DataTable
                        data={allAffiliatesDetailsTableData}
                        customHeadingElement={
                            <div className={`${styles.loadingContainer}`}>
                                {isSingleAffiliateLoading && (
                                    <BtnInfo className={`${styles.loadingBtn}`}>Loading...</BtnInfo>
                                )}
                            </div>
                        }
                    />
                </div>
            </div>
        </div>
    );
};
