import { TableCellType } from 'interfaces/ui/dataTable.interface';
import { UserInterface } from 'interfaces/user.interface';

export const savedClassesTableData = (user: UserInterface) => {
    const tableRows = user.userProfile.savedClasses?.map((savedClass) => {
        return {
            Avatar: savedClass.savedClassAvatar,
            AvatarText: savedClass.savedClassName,
            Name: savedClass.savedClassName,
            Code: savedClass.savedClassCode,
            Participants: savedClass.participantsCount,
        };
    });

    const tableCellProps = {
        Avatar: {
            type: TableCellType.AVATAR,
        },
    };

    return {
        heading: 'Saved Classes',
        rows: tableRows || [],
        cellProps: tableCellProps,
    };
};
