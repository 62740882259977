import { cellUtils } from 'helpers/utilities/cell.util';
import { dateTimeUtils } from 'helpers/utilities/dateTime.util';

import { ClassSessionDetailsInterface } from 'interfaces/session.interface';

export const generateSessionDetailsCells = (singleSessionDetails: ClassSessionDetailsInterface) => {
    const cell = cellUtils.tableCell;

    return {
        classCode: cell('Class Code', singleSessionDetails.classCode),
        started: cell('Started', dateTimeUtils.getDateTimeFromIso(singleSessionDetails.started)),
        participantsCount: cell('Participants Count', singleSessionDetails.participants.length),
        activitiesCount: cell('Activities Count', singleSessionDetails.activities.length),
        responsesCount: cell(
            'Responses Count',
            singleSessionDetails.activities.reduce((acc, curr) => acc + curr.responsesCount, 0),
        ),
        stars: cell('stars', singleSessionDetails.sumPoints),
    };
};
