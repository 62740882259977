export const IMAGE_CONSTANT = {
    IMAGE: {
        LOGO_CIRCULAR: '/assets/images/common/classPointLogoCircle.svg',
        LOGO_FULL: '/assets/images/common/classPointLogoFull.svg',
        LOGO_FAVICON: '/assets/images/common/classPointLogoFavicon.png',
        LOGO_ICON: '/assets/images/common/classPointLogoIcon.ico',
        OFFICIAL_BANNER: '/assets/images/common/officialBanner.svg',
    },

    BADGE: {
        CCT: '/assets/images/badge/cctBadge.svg',
        CCE: '/assets/images/badge/cceBadge.svg',
        CSC: '/assets/images/badge/cscBadge.svg',
    },
};
