import { useEffect, useRef, useState } from 'react';

import { BtnInfo } from 'theme/mui/buttons.theme';

import global from 'theme/global/Global.module.scss';
import styles from './SingleSessionDetails.module.scss';

import { DataGrid } from 'template/dataGrid/DataGrid.template';
import { DataTable } from 'template/dataTable/DataTable.template';
import { CommonModal } from 'template/modal/common/CommonModal.template';
import { JsonViewer } from 'template/jsonViewer/JsonViewer.template';

import { sessionApi } from 'services/api/session.api';

import { ClassSessionDetailsInterface } from 'interfaces/session.interface';

import { InstantAlert } from 'components/common/instantAlert/InstantAlert';

import { singleSessionDetailsGridData } from 'data/sessions/singleSessionDetails.gridData';
import { singleSessionActivitiesTableData } from 'data/sessions/singleSessionActivities.tableData';

interface SingleSessionDetailsInterface {
    singleSessionDetails: ClassSessionDetailsInterface | undefined;
    goBackToSessionsList: () => void;
}

export const SingleSessionDetails = ({ singleSessionDetails, goBackToSessionsList }: SingleSessionDetailsInterface) => {
    const showNotification = useRef<any>();

    const [isLoading, setIsLoading] = useState(false);
    const [activityDetails, setActivityDetails] = useState<object>();
    const [error, setError] = useState('');

    const getActivityDetails = async (activityId: string) => {
        setIsLoading(true);

        const response = await sessionApi.getActivityDetails(activityId);
        if (typeof response === 'string') {
            setError(response);
            setIsLoading(false);
        } else setActivityDetails(response);

        return setIsLoading(false);
    };

    const sessionDetails = singleSessionDetails && singleSessionDetailsGridData(singleSessionDetails);
    const pastSessionsDataInTableFormat = singleSessionActivitiesTableData(
        singleSessionDetails?.activities,
        getActivityDetails,
    );

    useEffect(() => {
        clearTimeout(showNotification.current);
        showNotification.current = setTimeout(() => {
            error && setError('');
        }, 2000);
        return () => clearTimeout(showNotification.current);
    }, [error]);

    return (
        <div className={`${global.moduleWrapper} ${styles.pastSessionDetailsModule}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    {error && <InstantAlert type="error" text={error} />}

                    <CommonModal
                        isModalOpen={activityDetails ? true : false}
                        modalCloseHandler={() => setActivityDetails(undefined)}
                        modalContent={
                            <JsonViewer
                                jsonData={activityDetails || {}}
                                isCopyDisabled={true}
                                className={styles.activityDetails}
                            />
                        }
                    />

                    {sessionDetails && <DataGrid data={sessionDetails} />}
                    <DataTable
                        data={pastSessionsDataInTableFormat}
                        backBtnOnClickHandler={() => goBackToSessionsList()}
                        customHeadingElement={
                            <div className={`${styles.loadingContainer}`}>
                                {isLoading && <BtnInfo className={`${styles.loadingBtn}`}>Loading...</BtnInfo>}
                            </div>
                        }
                    />
                </div>
            </div>
        </div>
    );
};
