import { startCase } from 'lodash';

import { cellUtils } from 'helpers/utilities/cell.util';
import { commonUtils } from 'helpers/utilities/common.util';
import { dateTimeUtils } from 'helpers/utilities/dateTime.util';
import { keyHelper } from 'helpers/key.helper';

import { CellInterface } from 'interfaces/ui/cell.interface';
import { CreateKeyInterface } from 'interfaces/key.interface';
import { DATA_CONSTANT } from 'constants/data/data.constants';

export const createKeyGridData = (keyDetails: CreateKeyInterface | null, copyKeyValue: (keyValue: string) => void) => {
    if (!keyDetails) return null;

    const columnCount = 3;
    const objectKeys = Object.keys(keyDetails);

    let finalArray: CellInterface[][] = [];
    let singleRow: CellInterface[] = [];
    const cell = cellUtils.tableCell;
    const emptyCell = cellUtils.emptyTableCell;

    const checkLastArrayItem = () => {
        const emptyCellArray: CellInterface[] = [];
        [...Array(columnCount - singleRow.length)].map(() => emptyCellArray.push(emptyCell));

        if (finalArray.length === 0) finalArray = [[...singleRow, ...emptyCellArray]];
        else finalArray = [...finalArray, [...singleRow, ...emptyCellArray]];
    };

    const allMarkets = keyHelper.getAllMarkets();
    const allKeyCategories = keyHelper.getAllCategories();

    for (let i = 0; i !== objectKeys.length; i++) {
        let cellValue: string;
        const fieldValue = keyDetails[objectKeys[i] as keyof CreateKeyInterface];

        if (typeof fieldValue === 'boolean') cellValue = commonUtils.booleanToYesNo(fieldValue);
        else if (
            (objectKeys[i] === 'validFrom' ||
                objectKeys[i] === 'validTo' ||
                objectKeys[i] === 'planExpiryDate' ||
                objectKeys[i] === 'soldDate') &&
            typeof fieldValue !== 'number'
        ) {
            cellValue = dateTimeUtils.getDateTimeFromIso(fieldValue);
        } else if (objectKeys[i] === 'key') {
            cellValue = commonUtils.insertLineBreak(fieldValue);
        } else if (objectKeys[i] === 'market') {
            cellValue = allMarkets.find((data) => data.value === fieldValue)?.label || '';
        } else if (objectKeys[i] === 'category') {
            cellValue = allKeyCategories.find((data) => data.value === fieldValue)?.label || '';
        } else if (objectKeys[i] === 'countries') {
            const requiredObject = DATA_CONSTANT.OPTIONS.CREATE_KEY_COUNTRY.find(
                (data) => data.value === fieldValue?.toString(),
            );
            cellValue = requiredObject?.label || startCase(requiredObject?.value) || '';
        } else cellValue = commonUtils.convertToString(fieldValue);

        const cellFormatData =
            objectKeys[i] === 'key'
                ? cell(startCase(objectKeys[i]), cellValue)
                : objectKeys[i] === 'duration'
                ? cell('Duration (Days)', cellValue)
                : cell(startCase(objectKeys[i]), cellValue);

        if (i === 0) {
            singleRow.push(cellFormatData);
            if (i === objectKeys.length - 1) checkLastArrayItem();
        } else if (i % columnCount !== 0) {
            singleRow.push(cellFormatData);
            if (i === objectKeys.length - 1) checkLastArrayItem();
        } else {
            checkLastArrayItem();
            singleRow = [cellFormatData];
            if (i === objectKeys.length - 1) checkLastArrayItem();
        }
    }

    const keyCount = commonUtils.trimCommaSeparatedString(keyDetails.key || '').length;

    return {
        heading: keyCount > 1 ? `${keyDetails.type} (${keyCount} Keys)` : `${keyDetails.type} - ${keyDetails.key}`,
        rows: finalArray,
        cellProps: {
            Key: { getSelectedValueOnClick: copyKeyValue },
        },
    };
};
