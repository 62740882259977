import { useState } from 'react';

import { BtnNeutral, BtnSuccess } from 'theme/mui/buttons.theme';

import global from 'theme/global/Global.module.scss';
import styles from './EditKeyModalContent.module.scss';

import { InputFields } from 'template/inputFields/InputFields.template';

import { keyApi } from 'services/api/key.api';
import { objectUtils } from 'helpers/utilities/object.util';
import { commonUtils } from 'helpers/utilities/common.util';

import { EditKeyInterface, KeyInterface } from 'interfaces/key.interface';
import { DATA_CONSTANT } from 'constants/data/data.constants';

import { editKeyInputIconArray, editKeyModalInputFieldsData } from './editKeyModalInputFieldsData';

interface EditKeyModalContentInterface {
    modalCloseHandler: () => void;
    keyDetails: KeyInterface;
    refreshKeyDetails: (arg?: string) => void;
}

export const EditKeyModalContent = ({
    modalCloseHandler,
    keyDetails,
    refreshKeyDetails,
}: EditKeyModalContentInterface) => {
    const isOrganization = keyDetails.type === DATA_CONSTANT.PLAN_TYPE.ORGANIZATION ? true : false;

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [editKeyDetails, setEditKeyDetails] = useState<EditKeyInterface>({
        validFrom: keyDetails.validFrom,
        validTo: keyDetails.validTo,
        soldDate: keyDetails.soldDate,
        domains: keyDetails.domains,
        countries: keyDetails.countries,
        maxActivations: keyDetails.maxActivations,
        note: keyDetails.note,
        planExpiryDate: keyDetails.planExpiryDate,
        organization: keyDetails.organization,
        isPremium: keyDetails.isPremium,
    });

    const inputOnChangeHandler = (value: string, fieldName?: string) => {
        if (fieldName) {
            if (fieldName === 'maxActivations') {
                setEditKeyDetails((prevState) => ({ ...prevState, [fieldName]: parseInt(value) }));
            } else if (fieldName === 'isPremium') {
                const processedValue = commonUtils.convertToBoolean(value);
                setEditKeyDetails((prevState) => ({
                    ...prevState,
                    [fieldName]: typeof processedValue === 'boolean' ? processedValue : false,
                }));
            } else setEditKeyDetails((prevState) => ({ ...prevState, [fieldName]: value }));
            setError('');
        }
    };

    const editKeyHandler = async () => {
        setIsLoading(true);

        const trimmedFormDetails = objectUtils.trimSpace(editKeyDetails, ['domains', 'countries']);
        const formDetailsWithConstructedIsoDateFromLocalTime = objectUtils.constructIsoFromLocalTimeInObject(
            trimmedFormDetails,
            ['validFrom', 'validTo', 'soldDate', 'planExpiryDate'],
        );
        const editKey = await keyApi.editKey(keyDetails.key.trim(), formDetailsWithConstructedIsoDateFromLocalTime);
        setIsLoading(false);

        if (typeof editKey !== 'string') {
            refreshKeyDetails('refresh');
            modalCloseHandler();
        } else setError(editKey);
    };

    return (
        <div className={`${global.moduleWrapper} ${styles.editModalContentModule}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    <div className={`${styles.headContainer}`}>
                        <h4 className={`${global.head4} ${styles.head}`}>Edit Key Details</h4>
                    </div>

                    <div className={`${styles.inputFieldContainer}`}>
                        <InputFields
                            inputFields={editKeyModalInputFieldsData(isOrganization, keyDetails)}
                            inputDetails={editKeyDetails}
                            inputIconsArray={editKeyInputIconArray}
                            inputOnChangeHandler={inputOnChangeHandler}
                        />
                        {error && <p className={`${global.bodyLg} ${styles.error}`}>{error}</p>}
                    </div>

                    <div className={`${styles.btnContainer}`}>
                        <BtnNeutral onClick={() => modalCloseHandler()} className={`${styles.btn}`}>
                            Cancel
                        </BtnNeutral>
                        <BtnSuccess loading={isLoading} onClick={() => editKeyHandler()} className={`${styles.btn}`}>
                            Save
                        </BtnSuccess>
                    </div>
                </div>
            </div>
        </div>
    );
};
