import { dateTimeUtils } from 'helpers/utilities/dateTime.util';
import { UserInterface } from 'interfaces/user.interface';

export const goProTriggersTableData = (user: UserInterface) => {
    const tableRows = user.userStats.goproTriggers?.map((data) => {
        return {
            Time: dateTimeUtils.getDateTimeFromIso(data.timestamp),
            Trigger: data.trigger,
        };
    });

    return {
        heading: 'Go Pro Triggers',
        rows: tableRows || [],
    };
};
