import { cellUtils } from 'helpers/utilities/cell.util';

import { KeyInterface } from 'interfaces/key.interface';
import { DATA_CONSTANT } from 'constants/data/data.constants';

import { generateKeyCells } from 'data/generateCells/generateKeyCells.data';

export const searchKeysDetailsGridData = (keyDetails: KeyInterface) => {
    const cell = generateKeyCells(keyDetails);
    const planConstants = DATA_CONSTANT.PLAN_TYPE;
    const emptyCell = cellUtils.emptyTableCell;
    const heading = 'Key Details';

    if (keyDetails.type === planConstants.ORGANIZATION) {
        return {
            heading,
            rows: [
                [cell.createdBy, cell.created, cell.category],
                [cell.organization, cell.planExpiryDate, cell.activatedToMaxRatio],
                [cell.validFrom, cell.note, cell.countries],
                [cell.domains, emptyCell, emptyCell],
            ],
        };
    } else {
        return {
            heading,
            rows: [
                [cell.createdBy, cell.created, cell.category],
                [cell.duration, cell.activatedToMaxRatio, cell.validFrom],
                [cell.soldDate, cell.note, cell.countries],
                [cell.domains, emptyCell, emptyCell],
            ],
        };
    }
};
