import { styled, FormControlLabel, Radio } from '@mui/material';

// ========== Buttons ==========
export const RadioInput = styled(FormControlLabel)({
    '.MuiRadio-root': {
        '&.Mui-checked': {
            color: 'var(--clr_text_info)',
        },
    },
});

interface RadioOptionInterface {
    value: string;
    label: string;
    className: string;
}

export const RadioOption = ({ value, label, className }: RadioOptionInterface) => {
    return <RadioInput value={value} label={label} className={className} control={<Radio />} />;
};
