import { dateTimeUtils } from 'helpers/utilities/dateTime.util';
import { classPointCertifiedHelper } from 'helpers/classPointCertified.helper';

import { NewCctApiResponseInterface } from 'interfaces/classPointCertified.interface';
import { TableCellType } from 'interfaces/ui/dataTable.interface';

export const cctInfoTableData = (
    cctInfo: NewCctApiResponseInterface[] | undefined,
    getSingleUserDetailsHandler: (userId: string) => void,
) => {
    const tableRows = cctInfo?.map((item) => ({
        userId: item.userId,
        Avatar: item.photoUrl,
        AvatarText: item.displayName || '-- --',
        'Display Name': item.displayName || undefined,
        Started: dateTimeUtils.getDateTimeFromIso(item.started),
        'Title Expiry Date': dateTimeUtils.getDateTimeFromIso(item.titleExpiryDate),
        Country: item.country || undefined,
        Role: item.jobTitle || undefined,
        Status: classPointCertifiedHelper.statusGenerator(item.started, item.titleExpiryDate),
    }));

    const tableCellProps = {
        userId: { isHidden: true },
        Avatar: { type: TableCellType.AVATAR },
        AvatarText: { isHidden: true },
        'Display Name': { getSelectedValueOnClick: getSingleUserDetailsHandler, functionArgument: 'userId' },
        Status: { color: 'cctStatusCustom' },
    };

    return {
        heading: 'CCT Info',
        rows: tableRows || [],
        cellProps: tableCellProps,
    };
};
