import { orderBy } from 'lodash';
import { read } from 'xlsx';

import { commonUtils } from './utilities/common.util';
import { createKeyLS } from 'services/localService/createKey.local';

import { ApplyKeyFormInterface, MarketAndCategoryInterface } from 'interfaces/key.interface';
import { CsvUploadFileInputInterface } from 'interfaces/common.interface';
import { DATA_CONSTANT } from 'constants/data/data.constants';

export const keyHelper = {
    getAllMarkets: (): MarketAndCategoryInterface[] | [] => {
        const allKeyCategoriesJSON = createKeyLS.getCreateKeyCategory();
        const keys = allKeyCategoriesJSON && Object.keys(allKeyCategoriesJSON).filter((data) => data !== 'business');
        const allMarkets: MarketAndCategoryInterface[] = [];

        if (allKeyCategoriesJSON && keys) {
            for (let i = 0; i < keys.length; i++) {
                allMarkets.push({
                    value: keys[i],
                    label: allKeyCategoriesJSON[keys[i]].name,
                });
            }
        }

        const orderedMarkets = orderBy(allMarkets, ['label'], ['asc']);
        return orderedMarkets;
    },

    getAllCategories: (): MarketAndCategoryInterface[] | [] => {
        const allKeyCategoriesJSON = createKeyLS.getCreateKeyCategory();
        const allMarkets =
            allKeyCategoriesJSON && Object.keys(allKeyCategoriesJSON).filter((data) => data !== 'business');
        const allKeyCategories: MarketAndCategoryInterface[] = [];

        if (allKeyCategoriesJSON && allMarkets) {
            for (let i = 0; i < allMarkets.length; i++) {
                for (let j = 0; j < allKeyCategoriesJSON[allMarkets[i]].categories.length; j++) {
                    allKeyCategories.push({
                        value: allKeyCategoriesJSON[allMarkets[i]].categories[j].value,
                        label: allKeyCategoriesJSON[allMarkets[i]].categories[j].label,
                    });
                }
            }
        }

        const uniqueKeyCategories: MarketAndCategoryInterface[] = [];
        // eslint-disable-next-line array-callback-return
        allKeyCategories.map((data) => {
            const isUnique = uniqueKeyCategories.find((item: any) => item.label === data.label) ? false : true;
            if (isUnique) uniqueKeyCategories.push(data);
        });

        const orderedKeyCategories = orderBy(uniqueKeyCategories, ['label'], ['asc']);
        return orderedKeyCategories;
    },

    generateErrorMessage: (emailArray: string[], type: 'duplicate' | 'invalid') => {
        let errorMessage = type === 'duplicate' ? 'Duplicate emails found : ' : 'Invalid emails found : ';
        let totalCount = 0;

        if (emailArray.length > 0) {
            while (emailArray.length > 0) {
                // eslint-disable-next-line
                const emailArrayLength = emailArray.filter((email) => email === emailArray[0]).length;
                const firstEntryDuplicateCount = type === 'duplicate' ? emailArrayLength : emailArrayLength - 1;

                let duplicatedTimes = '';
                if (errorMessage.includes('times')) {
                    duplicatedTimes = ` ; ${emailArray[0]} - ${firstEntryDuplicateCount + 1} times`;
                } else duplicatedTimes = `${emailArray[0]} - ${firstEntryDuplicateCount + 1} times`;

                totalCount = totalCount + 1;
                errorMessage = errorMessage + duplicatedTimes;
                // eslint-disable-next-line
                emailArray = emailArray.filter((email) => email !== emailArray[0]);
            }
            return `${totalCount} ${errorMessage}`;
        }
        return '';
    },

    readCsvFile: (
        fileDetails: any,
        setCsvFileDetails: (arg: CsvUploadFileInputInterface) => void,
        setInputDetails: (arg: (prevState: ApplyKeyFormInterface) => ApplyKeyFormInterface) => void,
    ) => {
        const reader = new FileReader();
        reader.onload = function (e: any) {
            let fileData = read(e.target.result, { type: 'array' });

            const dataArray = fileData.Sheets.Sheet1;
            if (!dataArray) {
                return setCsvFileDetails({ file: undefined, error: 'Error : Please select a valid CSV file' });
            }

            const allValuesInDataArray = Object.values(dataArray).map((value) => value.v?.toString().toLowerCase());
            const validEmails = allValuesInDataArray.filter((value) => value?.match(DATA_CONSTANT.REGEX.EMAIL));
            setInputDetails((prevState) => ({
                ...prevState,
                email: commonUtils.separateByComma(validEmails),
                error: '',
            }));
        };

        fileDetails && reader.readAsArrayBuffer(fileDetails);
    },
};
