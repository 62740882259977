import { FormEvent } from 'react';
import { RiKey2Line } from 'react-icons/ri';

import global from 'theme/global/Global.module.scss';
import styles from './SubscribeLinkTopBar.module.scss';

import { SimpleSearchBox } from 'template/searchBox/simple/SimpleSearchBox.template';

import { SearchKeyFormInterface } from 'interfaces/key.interface';

interface SearchKeyTopBarInterface {
    inputDetails: SearchKeyFormInterface;
    inputOnChangeHandler: (value: string) => void;
    isLoading: boolean;
    validateDetails: (arg: FormEvent<HTMLFormElement>) => void;
}

export const SubscribeLinkTopBar = ({
    inputDetails,
    inputOnChangeHandler,
    isLoading,
    validateDetails,
}: SearchKeyTopBarInterface) => {
    return (
        <div className={`${global.moduleWrapper} ${styles.topBarModule}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    <form onSubmit={(e) => validateDetails(e)} className={`${styles.form}`}>
                        <SimpleSearchBox
                            details={inputDetails}
                            inputOnChangeHandler={inputOnChangeHandler}
                            isLoading={isLoading}
                            placeholder={`Enter coupon`}
                            inputIcon={<RiKey2Line className={`${styles.inputIcon}`} />}
                            submitBtnText="Get link"
                        />
                    </form>
                </div>
            </div>
        </div>
    );
};
