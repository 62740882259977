import { cellUtils } from 'helpers/utilities/cell.util';
import { UserInterface } from 'interfaces/user.interface';
import { userUtils } from 'helpers/utilities/user.util';
import { DATA_CONSTANT } from 'constants/data/data.constants';

import { generateUserCells } from 'data/generateCells/generateUserCells.data';

export const subscriptionDetailsGridData = (user: UserInterface) => {
    const cell = generateUserCells(user);

    const emptyCell = cellUtils.emptyTableCell;
    const planType = user.userSubscription.planType;
    const planConstants = DATA_CONSTANT.PLAN_TYPE;

    const userSubscriptionTypeCell = cellUtils.tableCell('Subscription Type', userUtils.getUserSubscriptionType(user));
    const heading = 'Subscription Details';

    const basicTableRows = [
        [userSubscriptionTypeCell, cell.planType, cell.hasGonePro],
        [cell.usedKeys, cell.savedKeys, cell.packageInfo],
    ];

    const standardTableRows = [
        [userSubscriptionTypeCell, cell.planType, cell.isTrial],
        [cell.planExpiry, cell.currentKey, cell.usedKeys],
        [cell.savedKeys, cell.packageInfo, emptyCell],
    ];

    const subscriptionTableRows = [
        [userSubscriptionTypeCell, cell.planType, cell.billingPeriod],
        [cell.subscriptionId, cell.currencyCode, cell.priceAmount],
        [cell.cardLastDigits, cell.nextBillingDate, cell.isCanceled],
        [cell.packageInfo, cell.currentKey, cell.usedKeys],
        [cell.savedKeys, emptyCell, emptyCell],
    ];

    const rowDataObject = {
        [planConstants.BASIC]: basicTableRows,
        [planConstants.STANDARD]: standardTableRows,
        [planConstants.ORGANIZATION]: standardTableRows,
        [planConstants.SUBSCRIPTION]: subscriptionTableRows,
    };

    return {
        heading,
        rows: planType ? rowDataObject[planType] : [],
    };
};
