import { useEffect, useRef, useState } from 'react';

import { BtnWarnHighlight } from 'theme/mui/buttons.theme';

import global from 'theme/global/Global.module.scss';
import styles from './ChangeEmailModalContent.module.scss';

import { InputFields } from 'template/inputFields/InputFields.template';

import { commonUtils } from 'helpers/utilities/common.util';
import { useAppSelector } from 'helpers/customHooks/useRedux.hook';
import { userApi } from 'services/api/user.api';

import { DATA_CONSTANT } from 'constants/data/data.constants';

import { InstantAlert } from 'components/common/instantAlert/InstantAlert';

interface ChangeEmailModalContentInterface {
    oldEmail: string;
    changeEmailAlertHandler: (response: string, newEmail: string) => void;
}

export const ChangeEmailModalContent = ({ oldEmail, changeEmailAlertHandler }: ChangeEmailModalContentInterface) => {
    const adminEmail = useAppSelector((state) => state.admin.email);

    const showNotification = useRef<any>();

    const [isLoading, setIsLoading] = useState(false);
    const [showInstantAlertError, setShowInstantAlertError] = useState(false);
    const [error, setError] = useState('');
    const [changeEmailData, setChangeEmailData] = useState({
        newEmail: '',
        newEmailError: '',
        adminPassword: '',
        showPassword: false,
    });

    const inputOnChangeHandler = (value: string, fieldName?: string) => {
        if (!fieldName) return;
        if (fieldName === 'showPassword') {
            const result = commonUtils.convertToBoolean(value);
            const booleanValue = typeof result === 'boolean' ? result : false;
            return setChangeEmailData((prevState) => ({ ...prevState, [fieldName]: booleanValue }));
        } else return setChangeEmailData((prevState) => ({ ...prevState, [fieldName]: value, newEmailError: '' }));
    };

    const changeEmailHandler = async () => {
        if (!adminEmail) return;
        if (!changeEmailData.newEmail.trim().match(DATA_CONSTANT.REGEX.EMAIL)) {
            setChangeEmailData((prevState) => ({ ...prevState, newEmailError: 'Invalid email' }));
            return setShowInstantAlertError(true);
        }

        setIsLoading(true);

        const changeEmailResponse = await userApi.changeUserEmail(
            adminEmail,
            changeEmailData.adminPassword,
            oldEmail,
            changeEmailData.newEmail,
        );

        if (typeof changeEmailResponse === 'string') {
            setError(changeEmailResponse);
            setShowInstantAlertError(true);
        } else {
            changeEmailAlertHandler(changeEmailResponse[1], changeEmailData.newEmail);
        }

        setIsLoading(false);
    };

    useEffect(() => {
        clearTimeout(showNotification.current);
        showNotification.current = setTimeout(() => {
            setShowInstantAlertError(false);
        }, 2000);
        return () => clearTimeout(showNotification.current);
    }, [showInstantAlertError]);

    return (
        <div className={`${global.moduleWrapper} ${styles.changeEmailModule}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    {showInstantAlertError && (
                        <InstantAlert type="error" text={error || changeEmailData.newEmailError} />
                    )}

                    <div className={`${styles.headContainer}`}>
                        <h4 className={`${global.head4} ${styles.head}`}>Change Email</h4>
                    </div>

                    <div className={`${styles.inputFieldContainer}`}>
                        <InputFields
                            inputFields={[
                                {
                                    fieldName: 'newEmail',
                                    isRequired: true,
                                    error: !!changeEmailData.newEmailError,
                                    label: 'New email',
                                    fieldClassName: `${styles.inputField}`,
                                    disableAutoComplete: true,
                                },
                                {
                                    fieldName: 'adminPassword',
                                    inputType: changeEmailData.showPassword ? 'text' : 'password',
                                    isRequired: true,
                                    label: 'Confirm admin password',
                                    canHideValue: true,
                                    isValueHidden: changeEmailData.showPassword,
                                    hiddenValueFieldName: 'showPassword',
                                    disableAutoComplete: true,
                                    fieldClassName: `${styles.inputField}`,
                                },
                            ]}
                            inputDetails={changeEmailData}
                            inputOnChangeHandler={inputOnChangeHandler}
                        />
                    </div>

                    <div className={`${styles.btnContainer}`}>
                        <BtnWarnHighlight
                            loading={isLoading}
                            onClick={() => changeEmailHandler()}
                            className={`${styles.btn}`}
                        >
                            Confirm
                        </BtnWarnHighlight>
                    </div>
                </div>
            </div>
        </div>
    );
};
