import { utils, writeFileXLSX } from 'xlsx';

export const generateExcelFile = (data: [{ [key: string]: any }], fileName = 'ClassPoint') => {
    const newWorkBook = utils.book_new();
    const jsonToSheet = utils.json_to_sheet(data);

    jsonToSheet['!cols'] = [{ wch: 5 }, { wch: 15 }, { wch: 15 }, { wch: 15 }];

    utils.book_append_sheet(newWorkBook, jsonToSheet, 'Key Codes');
    writeFileXLSX(newWorkBook, `${fileName}.xlsx`);
};
