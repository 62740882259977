import { commonUtils } from 'helpers/utilities/common.util';

import { NewCctInterface, NewCscInterface } from 'interfaces/classPointCertified.interface';
import { DATA_CONSTANT } from 'constants/data/data.constants';

export const miscValidations = {
    convertCouponToLinkFormValidation: (coupon: string): string => {
        if (!coupon.trim()) return 'Error : Please enter a coupon';
        else if (commonUtils.trimCommaAndSpaceSeparatedString(coupon).length > 1) {
            return 'Error : Please enter one coupon at a time';
        } else return '';
    },

    grantAiAccessFormValidation: (email: string): string => {
        if (!email.trim()) return 'Error : Email can not be empty';
        else {
            const emailArray = commonUtils.trimCommaAndSpaceSeparatedString(email);
            for (let email of emailArray) {
                if (!email.trim().match(DATA_CONSTANT.REGEX.EMAIL)) return 'Error : Invalid email';
            }
            return '';
        }
    },
};
