import { ReactNode } from 'react';
import { startCase } from 'lodash';

import { BsEyeFill, BsEyeSlashFill } from 'react-icons/bs';

import { InputAdornment } from '@mui/material';

import { CheckBox } from 'theme/mui/checkBoxes.theme';
import { InputBox } from 'theme/mui/inputFields.theme';

import styles from './InputModules.template.module.scss';

import { useWindowDimensions } from 'helpers/customHooks/useWindowDimensions.hook';
import { commonUtils } from 'helpers/utilities/common.util';

interface InputBoxModuleInterface {
    value: string | number;
    inputOnChangeHandler: (value: string, fieldName?: string) => void;
    fieldName?: string;
    error?: boolean;
    maxLength?: number;
    type?: string;
    acceptNegativeNumber?: boolean;
    inputIconsArray?: ReactNode;
    label?: string;
    isRequired?: boolean;
    isReadOnly?: boolean;
    showOnlyValue?: boolean;
    fieldClassName?: string;
    optionClassName?: string;
    canHideValue?: boolean;
    isValueHidden?: boolean;
    hiddenValueFieldName?: string;
    disableAutoComplete?: boolean;
    variant?: 'outlined' | 'standard';
}

export const InputBoxModule = ({
    value,
    inputOnChangeHandler,
    fieldName,
    error,
    maxLength,
    type,
    acceptNegativeNumber,
    inputIconsArray,
    label,
    isRequired,
    isReadOnly = false,
    showOnlyValue,
    fieldClassName,
    optionClassName,
    canHideValue,
    isValueHidden,
    hiddenValueFieldName,
    disableAutoComplete,
    variant,
}: InputBoxModuleInterface) => {
    const windowWidth = useWindowDimensions().width;

    if (canHideValue) {
        return (
            <div className={`${styles.hideValue} ${fieldClassName}`}>
                <InputBox
                    type={type || 'text'}
                    onKeyDown={(e) => {
                        if (
                            type === 'number' &&
                            !acceptNegativeNumber &&
                            (e.key === '+' || e.key === '-' || e.key === 'e' || e.key === 'E')
                        )
                            e.preventDefault();
                    }}
                    onChange={(e) => {
                        fieldName
                            ? inputOnChangeHandler(e.target.value, fieldName)
                            : inputOnChangeHandler(e.target.value);
                    }}
                    value={value === 0 || value ? value.toString() : ''}
                    error={error}
                    inputProps={{ maxLength: maxLength || 'none' }}
                    InputProps={{
                        startAdornment: inputIconsArray && windowWidth > 767 && (
                            <InputAdornment position="start">
                                {fieldName
                                    ? inputIconsArray[fieldName as keyof typeof inputIconsArray]
                                    : inputIconsArray}
                            </InputAdornment>
                        ),
                        readOnly: isReadOnly,
                        autoComplete: disableAutoComplete ? 'off' : 'on',
                    }}
                    label={commonUtils.starMarked(label || startCase(fieldName || ''), isRequired)}
                    placeholder={label || startCase(fieldName || '')}
                    className={`${variant === 'standard' ? 'inputLine' : 'inputBox'} ${
                        showOnlyValue ? 'showOnlyValue' : ''
                    } ${optionClassName || ''}`}
                    variant={variant || 'outlined'}
                />
                <CheckBox
                    onChange={(e) => inputOnChangeHandler(e.target.checked.toString(), hiddenValueFieldName)}
                    value={isValueHidden}
                    icon={<BsEyeSlashFill className={styles.icon} />}
                    checkedIcon={<BsEyeFill className={styles.icon} />}
                    className={styles.checkbox}
                />
            </div>
        );
    } else {
        return (
            <InputBox
                type={type || 'text'}
                onKeyDown={(e) => {
                    if (
                        type === 'number' &&
                        !acceptNegativeNumber &&
                        (e.key === '+' || e.key === '-' || e.key === 'e' || e.key === 'E')
                    )
                        e.preventDefault();
                }}
                onChange={(e) => {
                    fieldName ? inputOnChangeHandler(e.target.value, fieldName) : inputOnChangeHandler(e.target.value);
                }}
                value={value === 0 || value ? value.toString() : ''}
                error={error}
                inputProps={{ maxLength: maxLength || 'none' }}
                InputProps={{
                    startAdornment: inputIconsArray && windowWidth > 767 && (
                        <InputAdornment position="start">
                            {fieldName ? inputIconsArray[fieldName as keyof typeof inputIconsArray] : inputIconsArray}
                        </InputAdornment>
                    ),
                    readOnly: isReadOnly,
                    autoComplete: disableAutoComplete ? 'off' : 'on',
                }}
                label={commonUtils.starMarked(label || startCase(fieldName || ''), isRequired)}
                placeholder={label || startCase(fieldName || '')}
                className={`${variant === 'standard' ? 'inputLine' : 'inputBox'} ${
                    showOnlyValue ? 'showOnlyValue' : ''
                } ${isRequired ? 'isRequired' : ''} ${fieldClassName || ''}`}
                variant={variant || 'outlined'}
            />
        );
    }
};
