import { HiOutlineUserPlus } from 'react-icons/hi2';

import styles from './WebinarReportTopBar.module.scss';

import { InputFieldsInterface } from 'interfaces/ui/inputFields.interface';
import { WebinarReportFormInterface } from 'interfaces/user.interface';

export const webinarReportInputFieldsData = (fieldsData: WebinarReportFormInterface): InputFieldsInterface[] => [
    {
        fieldName: 'email',
        fieldClassName: `${styles.inputField}`,
        isRequired: true,
        error: fieldsData.errorField?.includes('email'),
    },
    {
        fieldName: 'webinarTime',
        fieldType: 'dateTime',
        label: 'Webinar Time (UTC)',
        maxDateTime: new Date().toISOString(),
        isRequired: true,
        error: fieldsData.errorField?.includes('webinarTime'),
    },
];

export const inputFieldsIconsArray = {
    email: <HiOutlineUserPlus />,
};
