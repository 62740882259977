import { BtnInfo } from 'theme/mui/buttons.theme';

import global from 'theme/global/Global.module.scss';
import styles from './CreateKeySummary.module.scss';

import { DataGrid } from 'template/dataGrid/DataGrid.template';

import { commonUtils } from 'helpers/utilities/common.util';

import { CreateKeyInterface } from 'interfaces/key.interface';

import { createKeyGridData } from 'data/key/createKey/createKey.gridData';
import { generateExcelFile } from 'data/generateExcelFile/generateExcelFile.data';

interface CreateKeySummaryInterface {
    createdKeyDetails: CreateKeyInterface[];
    setShowInstantAlert: (arg: boolean) => void;
}

export const CreateKeySummary = ({ createdKeyDetails, setShowInstantAlert }: CreateKeySummaryInterface) => {
    const copyKeyValue = (keyValue: string) => {
        navigator.clipboard.writeText(keyValue.replaceAll('<br/>', ' '));
        setShowInstantAlert(true);
    };

    const exportExcelFileHandler = (data: CreateKeyInterface) => {
        let dataArray: [{ [key: string]: any }] = [{}];
        const keys = data.key && commonUtils.trimCommaSeparatedString(data.key);

        Array.isArray(keys) &&
            keys.map((item, index) =>
                dataArray.push({
                    No: `${index + 1}`,
                    Key: item,
                    'Valid To': data.validTo?.slice(0, 10),
                    'Duration (Days)': data.duration,
                }),
            );

        generateExcelFile(dataArray, 'ClassPoint Pro Keys');
    };

    return (
        <div className={`${global.moduleWrapper} ${styles.createKeySummaryModule}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    {createdKeyDetails
                        ?.map((data, index) => {
                            const keySummary = createKeyGridData(data, copyKeyValue);
                            return (
                                keySummary && (
                                    <DataGrid
                                        key={index}
                                        data={keySummary}
                                        customHeadingElement={
                                            <BtnInfo
                                                onClick={() => exportExcelFileHandler(data)}
                                                className={`${global.bodyXs} ${styles.headBtn}`}
                                            >
                                                Export
                                            </BtnInfo>
                                        }
                                        isFullHeight={true}
                                    />
                                )
                            );
                        })
                        .reverse()}
                </div>
            </div>
        </div>
    );
};
