import { startCase } from 'lodash';

import FormControlLabel from '@mui/material/FormControlLabel';

import { MuiForm } from 'theme/mui/form.theme';
import { CheckBox } from 'theme/mui/checkBoxes.theme';

interface CheckboxModuleInterface {
    value: string | boolean | undefined;
    inputOnChangeHandler: (value: string, fieldName?: string) => void;
    fieldName?: string;
    label?: string;
    fieldClassName?: string;
    optionClassName?: string;
}

export const CheckboxModule = ({
    value,
    inputOnChangeHandler,
    fieldName,
    label,
    fieldClassName,
    optionClassName,
}: CheckboxModuleInterface) => {
    return (
        <MuiForm className={fieldClassName || ''}>
            <FormControlLabel
                control={
                    <CheckBox
                        checked={value === 'true' || value === true ? true : false}
                        onChange={(event) =>
                            fieldName
                                ? inputOnChangeHandler(event.target.checked.toString(), fieldName)
                                : inputOnChangeHandler(event.target.checked.toString())
                        }
                        inputProps={{ 'aria-label': 'controlled' }}
                        className={optionClassName || ''}
                    />
                }
                label={label || startCase(fieldName)}
            />
        </MuiForm>
    );
};
