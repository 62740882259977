import global from 'theme/global/Global.module.scss';
import styles from './SubscribeLinkDetails.module.scss';

import { DataGrid } from 'template/dataGrid/DataGrid.template';

import { SubscribeLinkDetailsInterface } from 'interfaces/misc.interface';

import { subscribeLinkGridData } from 'data/misc/subscribeLink.gridData.';

interface SearchKeyDetailsInterface {
    subscribeLinkDetails: SubscribeLinkDetailsInterface[] | undefined;
    setShowInstantAlert: (arg: boolean) => void;
}

export const SubscribeLinkDetails = ({ subscribeLinkDetails, setShowInstantAlert }: SearchKeyDetailsInterface) => {
    const copyLinkValue = (details: string) => {
        navigator.clipboard.writeText(details.replaceAll('<br/>', ' '));
        setShowInstantAlert(true);
    };

    const subscribeLinkGridDetails = subscribeLinkGridData(subscribeLinkDetails, copyLinkValue);

    return (
        <div className={`${global.moduleWrapper} ${styles.subscribeLinkDetailsModule}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    {subscribeLinkGridDetails?.map((data, index) => (
                        <DataGrid key={index} data={data} />
                    ))}
                </div>
            </div>
        </div>
    );
};
