import { cellUtils } from 'helpers/utilities/cell.util';
import { dateTimeUtils } from 'helpers/utilities/dateTime.util';
import { commonUtils } from 'helpers/utilities/common.util';
import { classPointCertifiedHelper } from 'helpers/classPointCertified.helper';

import { CctInterface } from 'interfaces/classPointCertified.interface';

export const generateCctCells = (cct: CctInterface) => {
    const cell = cellUtils.tableCell;

    const linkFormatter = (link: string, linkName: string) => {
        const isHttpsPresent = link.includes('https') || link.includes('http');
        return `<span class="divider"><span class="bold">${linkName} -</span><a class="clickable" href=${
            isHttpsPresent ? link : `https://${link}`
        } target="_blank">${link}</a></span>`;
    };

    return {
        started: cell('Started', dateTimeUtils.getDateFromIso(cct.started)),
        titleExpiryDate: cell('Title Expiry Date', dateTimeUtils.getDateFromIso(cct.titleExpiryDate)),
        status: cell('Status', classPointCertifiedHelper.statusGenerator(cct.started, cct.titleExpiryDate)),
        organization: cell('Organization', cct.organization || undefined),
        address: cell('Address', cct.address || undefined),
        phone: cell('Phone', cct.phone || undefined),
        jobTitle: cell('Job Title', cct.jobTitle || undefined),
        subjects: cell('Subjects', commonUtils.separateByComma(cct.subjects) || undefined),
        links: cell(
            'Links',
            `${cct.links?.website ? linkFormatter(cct.links.website, 'Website') : ''} 
            ${cct.links?.facebook ? linkFormatter(cct.links.facebook, 'Facebook') : ''}
            ${cct.links?.instagram ? linkFormatter(cct.links.instagram, 'Instagram') : ''} 
            ${cct.links?.tiktok ? linkFormatter(cct.links.tiktok, 'TikTok') : ''} 
            ${cct.links?.twitter ? linkFormatter(cct.links.twitter, 'Twitter') : ''} 
            ${cct.links?.youtube ? linkFormatter(cct.links.youtube, 'YouTube') : ''}`,
        ),
    };
};
