import { cellUtils } from 'helpers/utilities/cell.util';
import { dateTimeUtils } from 'helpers/utilities/dateTime.util';
import { commonUtils } from 'helpers/utilities/common.util';

import { ViewContentResponseInterface } from 'interfaces/content.interface';

export const generateContentCells = (content: ViewContentResponseInterface) => {
    const cell = cellUtils.tableCell;

    const formatImageArray = () => {
        let finalValue = '';
        content.imageUrls.map((image, index) => (finalValue = `${finalValue} <b>${index + 1}.</b> ${image} <br/>`));
        return finalValue;
    };

    return {
        slug: cell('Slug', content.slug),
        created: cell('Created', dateTimeUtils.getDateTimeFromIso(content.created)),
        title: cell('Title', content.title),
        description: cell('Description', content.description),
        numberOfSlides: cell('Number Of Slides', content.numberOfSlides),
        fileUrl: cell('File Url', content.fileUrl),
        coverUrl: cell('Cover Url', content.coverUrl),
        imageUrls: cell('Image Urls', formatImageArray()),
        categories: cell('Categories', commonUtils.separateByComma(content.categories)),
        tags: cell('Tags', commonUtils.separateByComma(content.tags)),
    };
};
