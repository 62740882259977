import { orderBy } from 'lodash';

import { AiOutlineNumber, AiOutlineFieldTime } from 'react-icons/ai';
import { BsGlobe2 } from 'react-icons/bs';
import { BiNote } from 'react-icons/bi';
import { HiOutlineUserPlus, HiOutlineUserGroup } from 'react-icons/hi2';
import { IoMdGlobe } from 'react-icons/io';
import { TbStack } from 'react-icons/tb';

import { keyHelper } from 'helpers/key.helper';
import { createKeyLS } from 'services/localService/createKey.local';

import { CreateKeyInterface } from 'interfaces/key.interface';
import { InputFieldsInterface } from 'interfaces/ui/inputFields.interface';
import { DATA_CONSTANT } from 'constants/data/data.constants';

export const createKeyInput = (
    keyType: string,
    inputDetails: CreateKeyInterface,
): InputFieldsInterface[] | undefined => {
    const marketValues = keyHelper.getAllMarkets();
    const marketAndCategoryObject = createKeyLS.getCreateKeyCategory();
    const today = new Date();

    // ========== Common Fields ==========
    const type = {
        fieldName: 'type',
        fieldType: 'select',
        multipleFields: [
            {
                value: DATA_CONSTANT.PLAN_TYPE.ORGANIZATION,
            },
            {
                value: DATA_CONSTANT.PLAN_TYPE.STANDARD,
            },
        ],
        isRequired: true,
    };

    const isTrial = {
        fieldName: 'isTrial',
        fieldType: 'selectBoolean',
        error: inputDetails.errorField?.includes('isTrial'),
        isRequired: true,
    };

    const maxActivations = {
        fieldName: 'maxActivations',
        inputType: 'number',
    };

    const numberOfKeys = {
        fieldName: 'numberOfKeys',
        inputType: 'number',
        error: inputDetails.errorField?.includes('numberOfKeys'),
        isRequired: true,
    };

    const note = {
        fieldName: 'note',
    };

    const validFrom = {
        fieldName: 'validFrom',
        fieldType: 'date',
        label: 'Valid From (UTC)',
        maxDateTime: inputDetails.validTo,
        error: inputDetails.errorField?.includes('validFrom'),
    };

    const validTo = {
        fieldName: 'validTo',
        fieldType: 'date',
        label: 'Valid To (UTC)',
        minDateTime: inputDetails.validFrom || today,
        error: inputDetails.errorField?.includes('validTo'),
    };

    const soldDate = {
        fieldName: 'soldDate',
        fieldType: 'date',
        label: 'Sold Date (UTC)',
        minDateTime: inputDetails.validFrom || today,
        error: inputDetails.errorField?.includes('soldDate'),
    };

    const countries = {
        fieldName: 'countries',
        fieldType: 'select',
        multipleFields: orderBy(DATA_CONSTANT.OPTIONS.CREATE_KEY_COUNTRY, ['value'], ['asc']),
    };

    const market = {
        fieldName: 'market',
        fieldType: 'select',
        multipleFields: orderBy(marketValues, ['label'], ['asc']),
        error: inputDetails.errorField?.includes('market'),
        isRequired: true,
    };

    const domains = {
        fieldName: 'domains',
    };

    // ========== Standard Fields ==========
    const duration = {
        fieldName: 'duration',
        inputType: 'number',
        label: 'Duration (Days)',
        error: inputDetails.errorField?.includes('duration'),
        isRequired: true,
    };

    const selectedMarketCategories =
        inputDetails.market && marketAndCategoryObject
            ? marketAndCategoryObject[inputDetails.market]?.categories
            : undefined;

    const standardCategory = {
        fieldName: 'category',
        fieldType: 'select',
        multipleFields: orderBy(selectedMarketCategories, ['label'], ['asc']),
        error: inputDetails.errorField?.includes('category'),
        isRequired: true,
    };

    // ========== Organization Fields ==========
    const planExpiryDate = {
        fieldName: 'planExpiryDate',
        fieldType: 'date',
        label: 'Plan Expiry Date (UTC)',
        minDateTime: inputDetails.validTo || today,
        error: inputDetails.errorField?.includes('planExpiryDate'),
        isRequired: true,
    };

    const organization = {
        fieldName: 'organization',
        error: inputDetails.errorField?.includes('organization'),
        isRequired: true,
    };

    const organizationMarketCategories = marketAndCategoryObject
        ? marketAndCategoryObject['business']?.categories
        : undefined;

    const organizationCategory = {
        fieldName: 'category',
        fieldType: 'select',
        multipleFields: orderBy(organizationMarketCategories, ['label'], ['asc']),
        error: inputDetails.errorField?.includes('category'),
        isRequired: true,
    };

    const isPremium = {
        fieldName: 'isPremium',
        fieldType: 'selectBoolean',
    };

    if (keyType === DATA_CONSTANT.PLAN_TYPE.STANDARD) {
        return [
            type,
            market,
            standardCategory,
            duration,
            numberOfKeys,
            maxActivations,
            note,
            validFrom,
            validTo,
            soldDate,
            countries,
            domains,
        ];
    } else {
        return [
            type,
            market,
            organizationCategory,
            isTrial,
            planExpiryDate,
            organization,
            numberOfKeys,
            isPremium,
            maxActivations,
            note,
            validFrom,
            validTo,
            countries,
            domains,
        ];
    }
};

export const createKeyInputIconsArray = {
    numberOfKeys: <AiOutlineNumber />,
    createdBy: <HiOutlineUserPlus />,
    maxActivations: <HiOutlineUserGroup />,
    note: <BiNote />,
    duration: <AiOutlineFieldTime />,
    domains: <BsGlobe2 />,
    countries: <IoMdGlobe />,
    organization: <TbStack />,
};
