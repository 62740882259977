import { ReactNode } from 'react';
import parse from 'html-react-parser';

import { BtnInfo } from 'theme/mui/buttons.theme';

import global from 'theme/global/Global.module.scss';
import styles from './MultipleSearchBox.template.module.scss';

import { TopBarMultipleFormInterface } from 'interfaces/ui/topBar.interface';

interface MultipleSearchBoxInterface {
    details: TopBarMultipleFormInterface;
    isLoading?: boolean;
    submitBtnText?: string;
    inputContent: ReactNode;
    className?: string;
    inputContainerClassName?: string;
}

export const MultipleSearchBox = ({
    details,
    isLoading,
    submitBtnText,
    inputContent,
    className,
    inputContainerClassName,
}: MultipleSearchBoxInterface) => {
    return (
        <div className={`${global.moduleWrapper} ${styles.multipleSearchBoxTemplate} ${className || ''}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    <div className={`${styles.inputContainer} ${inputContainerClassName}`}>
                        <div className={`${styles.formFieldsContainer}`}>{inputContent}</div>
                        {(details.error || details.success) && (
                            <p className={`${global.body} ${details.error ? styles.error : styles.success}`}>
                                {parse(details.error || details.success)}
                            </p>
                        )}
                    </div>

                    {submitBtnText && (
                        <BtnInfo loading={isLoading} type="submit" className={`${styles.submitBtn}`}>
                            {submitBtnText}
                        </BtnInfo>
                    )}
                </div>
            </div>
        </div>
    );
};
