import { useEffect, useRef, useState } from 'react';

import global from 'theme/global/Global.module.scss';
import styles from './CreateKey.module.scss';

import { DefaultLayout } from 'template/defaultLayout/DefaultLayout.template';

import { objectUtils } from 'helpers/utilities/object.util';
import { contentApi } from 'services/api/content.api';

import {
    CategoryAndTagInterface,
    CreateContentDataInterface,
    ViewContentResponseInterface,
} from 'interfaces/content.interface';

import { InstantAlert } from 'components/common/instantAlert/InstantAlert';
import { CreateContentForm } from './createContentForm/CreateContentForm';
import { awsS3StorageApi } from 'services/api/awsS3Storage.api';
import { Loading } from 'components/common/loading/Loading';

interface CreateContentInterface {
    content?: ViewContentResponseInterface;
    refreshData?: () => void;
}

export const CreateContent = ({ content, refreshData }: CreateContentInterface) => {
    const showNotification = useRef<any>();

    const [isLoading, setIsLoading] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [showInstantAlert, setShowInstantAlert] = useState(false);
    const [errors, setErrors] = useState('');
    const [fileDetails, setFileDetails] = useState<File | null>();
    const [coverDetails, setCoverDetails] = useState<File | null>();
    const [imagesDetails, setImagesDetails] = useState<File[] | null>();
    const [inputDetails, setInputDetails] = useState<Partial<CreateContentDataInterface>>({});
    const [details, setDetails] = useState('');
    const [categoryAndTagDetails, setCategoryAndTagDetails] = useState<CategoryAndTagInterface>({
        categories: [],
        tags: [],
    });
    const [fileName, setFileName] = useState({
        fileName: '',
        coverName: '',
        imagesName: '',
    });

    const inputOnChangeHandler = (value: string, fieldName?: string) => {
        if (!fieldName) return;
        const alphaNumericRegex = /^[a-zA-Z0-9_-]*$/;
        const processedValue = fieldName === 'numberOfSlides' ? parseInt(value) : value;

        if (fieldName === 'slug' && !value.match(alphaNumericRegex)) return;
        setInputDetails((prevState) => ({ ...prevState, [fieldName]: processedValue }));
        setErrors('');
    };

    const multipleSelectOnChangeHandler = (value: string[], fieldName?: string) => {
        if (!fieldName) return;
        setInputDetails((prevState) => ({ ...prevState, [fieldName]: value }));
    };

    const fileInputOnChangeHandler = (file: any) => {
        const fileInfo = file && file[0];
        if (!fileInfo) return;
        setFileDetails(fileInfo);
        setErrors('');
    };

    const coverInputOnChangeHandler = (file: any) => {
        const fileInfo = file && file[0];
        if (!fileInfo) return;
        const fileExtension = fileInfo.name.split('.').pop();
        if (
            fileExtension !== 'jpg' &&
            fileExtension !== 'jpeg' &&
            fileExtension !== 'png' &&
            fileExtension !== 'webp' &&
            fileExtension !== 'svg'
        ) {
            return setErrors('File type not supported');
        }
        setCoverDetails(fileInfo);
        setErrors('');
    };

    const imageInputOnChangeHandler = (file: any) => {
        const fileInfo = file && [...file];
        if (fileInfo.length < 1) return;
        let isAllFileSupported = true;

        fileInfo.forEach((file: any) => {
            const fileExtension = file.name.split('.').pop();
            if (
                fileExtension !== 'jpg' &&
                fileExtension !== 'jpeg' &&
                fileExtension !== 'png' &&
                fileExtension !== 'webp' &&
                fileExtension !== 'svg'
            ) {
                isAllFileSupported = false;
                setErrors('File type not supported');
            }
        });

        if (isAllFileSupported) {
            setImagesDetails(fileInfo);
            setErrors('');
        }
    };

    const removeFileSelectionHandler = () => {
        setFileDetails(null);
        setFileName((prev) => ({ ...prev, fileName: '' }));
    };
    const removeCoverFileSelectionHandler = () => {
        setCoverDetails(null);
        setFileName((prev) => ({ ...prev, coverName: '' }));
    };
    const removeImageFileSelectionHandler = () => {
        setImagesDetails(null);
        setFileName((prev) => ({ ...prev, imagesName: '' }));
    };

    const fileUploadHandler = async () => {
        let fileUrl = '';
        let coverUrl = '';
        const imagesUrl = [];
        let error = '';

        if (fileDetails) {
            const fileObjectKey = `content-library/${inputDetails.slug}/${fileDetails.name}`;
            const response = await awsS3StorageApi.uploadToStorage(fileDetails, fileObjectKey);
            if (!response) error = `${error}, File upload failed (${fileDetails.name})`;
            else fileUrl = response;
        }

        if (coverDetails) {
            const fileExtension = coverDetails.name.split('.').pop();
            const coverObjectKey = `content-library/${inputDetails.slug}/cover.${fileExtension}`;
            const response = await awsS3StorageApi.uploadToStorage(coverDetails, coverObjectKey);
            if (!response) error = `${error}, Cover upload failed (${coverDetails.name})`;
            else coverUrl = response;
        }

        if (imagesDetails) {
            let startingIndex = 0;
            if (content && content.imageUrls.length > 0) {
                const lastArrayItem = content.imageUrls[content.imageUrls.length - 1];
                const lastIndex = lastArrayItem.split('/').pop()?.split('-').pop()?.split('.').shift();
                if (lastIndex) startingIndex = parseInt(lastIndex) + 1;
            }

            const endingIndex = startingIndex + imagesDetails.length;

            for (let i = startingIndex; i < endingIndex; i++) {
                const file = imagesDetails[i - startingIndex];
                const fileExtension = file.name.split('.').pop();
                const imageObjectKey = `content-library/${inputDetails.slug}/image-${i}.${fileExtension}`;
                const response = await awsS3StorageApi.uploadToStorage(file, imageObjectKey);
                if (!response) {
                    error = `${error}, Image upload failed (${file.name})`;
                } else imagesUrl.push(response);
            }
        }

        return { fileUrl, coverUrl, imagesUrl, error };
    };

    const createContentHandler = async () => {
        setIsLoading(true);

        const fileUrls = await fileUploadHandler();
        const contentData = {
            ...inputDetails,
            description: details,
            fileUrl: fileUrls.fileUrl,
            coverUrl: fileUrls.coverUrl,
            imageUrls: fileUrls.imagesUrl,
        };

        const categoryDetails = await contentApi.postContent(contentData);

        if (!categoryDetails) {
            setShowInstantAlert(true);
            setInputDetails({});
            setDetails('');
            setFileDetails(null);
            setCoverDetails(null);
            setImagesDetails(null);
        } else setErrors(`${fileUrls?.error}, ${categoryDetails}`);

        setIsLoading(false);
    };

    const updateContentHandler = async () => {
        if (!content) return;
        setIsLoading(true);

        const fileUrls = await fileUploadHandler();
        const postBody = objectUtils.removeProperty(inputDetails, ['slug', 'fileUrl', 'coverUrl', 'imageUrls']);

        const updatedUrls: { [key: string]: string | string[] } = {};
        if (fileDetails) updatedUrls.fileUrl = fileUrls.fileUrl;
        if (coverDetails) updatedUrls.coverUrl = fileUrls.coverUrl;
        if (imagesDetails) updatedUrls.imageUrls = fileUrls.imagesUrl;

        const contentData = {
            ...postBody,
            description: details,
            ...updatedUrls,
        };

        const updatedCategoryDetails = await contentApi.updateContent(content.slug, contentData);

        if (!updatedCategoryDetails) refreshData && refreshData();
        else setErrors(`${fileUrls?.error}, ${updatedCategoryDetails}`);

        setIsLoading(false);
    };

    const getCategoriesAndTags = async () => {
        setIsDataLoading(true);

        const categoryDetails = await contentApi.getCategories();
        const tagDetails = await contentApi.getTags();

        setIsDataLoading(false);
        setCategoryAndTagDetails({
            categories: categoryDetails || [],
            tags: tagDetails || [],
        });
    };

    const validateDetailsHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        const error: any = [];

        if (!inputDetails.title?.trim()) error.push(`Title is required`);
        if (!inputDetails.slug?.trim()) error.push(`Slug is required`);
        if (!inputDetails.numberOfSlides || inputDetails.numberOfSlides < 1) {
            error.push(`Number of slides has to be more than 0`);
        }
        if (!inputDetails.categories?.length) error.push(`Category is required`);
        if (!inputDetails.tags?.length) error.push(`Tag is required`);
        if (!details?.trim()) error.push(`Description is required`);
        if (!content && !fileDetails) error.push(`File is required`);
        if (!content && !coverDetails) error.push(`Cover is required`);

        if (error.length > 0) setErrors(`Error : ${error.toString().replaceAll(',', ', ')}`);
        else {
            if (content) updateContentHandler();
            else createContentHandler();
        }
    };

    useEffect(() => {
        let fileName = '';
        let coverName = '';
        let imagesName = '';

        fileName = fileDetails?.name || '';
        coverName = coverDetails?.name || '';

        imagesDetails?.forEach((file, index) => {
            if (imagesDetails.length === 1) imagesName = file?.name || '';
            else {
                if (index === 0) imagesName = `${file?.name || ''} + ${imagesDetails.length - 1} other`;
            }
        });

        const updatedValues: { [key: string]: string } = {};
        if (fileName) updatedValues.fileName = fileName;
        if (coverName) updatedValues.coverName = coverName;
        if (imagesName) updatedValues.imagesName = imagesName;

        setFileName((prev) => ({ ...prev, ...updatedValues }));
    }, [fileDetails, coverDetails, imagesDetails]);

    useEffect(() => {
        clearTimeout(showNotification.current);
        showNotification.current = setTimeout(() => setShowInstantAlert(false), 2000);
        return () => clearTimeout(showNotification.current);
    }, [showInstantAlert]);

    useEffect(() => {
        getCategoriesAndTags();
        if (content) {
            setInputDetails({
                title: content.title,
                slug: content.slug,
                numberOfSlides: content.numberOfSlides,
                categories: content.categories,
                tags: content.tags,
            });
            setDetails(content.description);
            setFileName({
                fileName: content.fileUrl?.split('/').pop() || '',
                coverName: content.coverUrl?.split('/').pop() || '',
                imagesName: `${content.imageUrls[0]?.split('/').pop() || ''} + ${content.imageUrls.length - 1} other`,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={`${global.componentWrapper} ${styles.createContentComponent}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content} ${isDataLoading && styles.fullScreen}`}>
                    {isDataLoading && <Loading />}
                    {showInstantAlert && <InstantAlert type="success" text="Successfully created" />}

                    {!isDataLoading && (
                        <DefaultLayout
                            noPadding={content ? true : false}
                            content={
                                <div className={`${styles.createKeyContent}`}>
                                    <CreateContentForm
                                        isLoading={isLoading}
                                        isUpdate={content ? true : false}
                                        categoryAndTagDetails={categoryAndTagDetails}
                                        inputDetails={inputDetails}
                                        errors={errors}
                                        setErrors={setErrors}
                                        fileName={fileName}
                                        inputOnChangeHandler={inputOnChangeHandler}
                                        details={details}
                                        setDetails={setDetails}
                                        multipleSelectOnChangeHandler={multipleSelectOnChangeHandler}
                                        validateDetailsHandler={validateDetailsHandler}
                                        fileInputOnChangeHandler={fileInputOnChangeHandler}
                                        coverInputOnChangeHandler={coverInputOnChangeHandler}
                                        imageInputOnChangeHandler={imageInputOnChangeHandler}
                                        removeFileSelectionHandler={removeFileSelectionHandler}
                                        removeCoverFileSelectionHandler={removeCoverFileSelectionHandler}
                                        removeImageFileSelectionHandler={removeImageFileSelectionHandler}
                                    />
                                </div>
                            }
                        />
                    )}
                </div>
            </div>
        </div>
    );
};
