import { createSlice } from '@reduxjs/toolkit';

import { storeLS } from 'services/localService/store.local';
import { ErrorInterface } from '../../interfaces/store/error.interfaces';

const getCachedErrorState = () => {
    const storeCached = storeLS.getStoreData();
    if (storeCached && storeCached.error) return storeCached.error;
    return undefined;
};

const initialState: ErrorInterface = getCachedErrorState() || {
    loginError: false,
    apiError: false,
};

const errorSlice = createSlice({
    name: 'ERROR',
    initialState,
    reducers: {
        LOGIN_ERROR: (state) => {
            state.loginError = true;
        },
    },
});

export const ERROR_REDUCERS = errorSlice.reducer;
export const ERROR_ACTIONS = errorSlice.actions;
