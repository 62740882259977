import global from 'theme/global/Global.module.scss';
import styles from './UserDetailsModules.module.scss';

import { DataGrid } from 'template/dataGrid/DataGrid.template';

import { UserInterface } from 'interfaces/user.interface';
import { CctInterface } from 'interfaces/classPointCertified.interface';

import { personalInfoGridData } from 'data/user/userDetails/details/personalInfo.gridData';
import { userStatsGridData } from 'data/user/userDetails/details/userStats.gridData';
import { cctDetailsGridData } from 'data/user/userDetails/details/cctDetails.gridData';

interface PersonalDetailsInterface {
    user: UserInterface;
    cct: CctInterface | undefined;
}

export const PersonalDetails = ({ user, cct }: PersonalDetailsInterface) => {
    const personalInfo = personalInfoGridData(user);
    const userStats = userStatsGridData(user);
    const cctDetails = cctDetailsGridData(cct);

    return (
        <div className={`${global.moduleWrapper} ${styles.personalDetailsModule}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    <DataGrid data={personalInfo} />
                    <DataGrid data={userStats} />
                    {cctDetails.rows.length > 0 && <DataGrid data={cctDetails} />}
                </div>
            </div>
        </div>
    );
};
