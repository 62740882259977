import axios from 'axios';

import { logger } from '../logger.service';

import {
    ActivatedUserApiInterface,
    CreateKeyCategoryInterface,
    CreateKeyInterface,
    EditKeyInterface,
    KeyInterface,
} from 'interfaces/key.interface';
import { URL_CONSTANT } from 'constants/url.constants';

export const keyApi = {
    getKey: async (key: string): Promise<KeyInterface | string> => {
        try {
            const url = `${process.env.REACT_APP_API}/keys?key=${key}`;
            const response = await axios.get(url);
            return response.data;
        } catch (error: any) {
            logger.error('keyApi.getKey() error: ', error.response || error);
            return `Error : ${error.response.data.message}`;
        }
    },

    getKeyMultiple: async (queryParameters: { [key: string]: string }): Promise<KeyInterface[] | string> => {
        try {
            let query = '';
            const queryKeys = Object.keys(queryParameters);

            for (let i = 0; i < queryKeys.length; i++) {
                const currentValue = queryParameters[queryKeys[i] as string];
                if (i !== queryKeys.length - 1) query = `${query}${queryKeys[i]}=${currentValue}&`;
                else query = `${query}${queryKeys[i]}=${currentValue}`;
            }

            const url = `${process.env.REACT_APP_API}/keys/multiple?${query}`;
            const response = await axios.get(url);
            return response.data;
        } catch (error: any) {
            logger.error('keyApi.getKeyMultiple() error: ', error.response || error);
            return `Error : ${error.response.data.message}`;
        }
    },

    activatedUsers: async (key: string): Promise<ActivatedUserApiInterface[] | string> => {
        try {
            const url = `${process.env.REACT_APP_API}/keys/activated-users?key=${key}`;
            const response = await axios.get(url);
            return response.data;
        } catch (error: any) {
            logger.error('keyApi.activatedUsers() error: ', error.response || error);
            return `Error : ${error.response.data.message}`;
        }
    },

    getCategoryJSON: async (): Promise<CreateKeyCategoryInterface | string> => {
        try {
            const url = `${URL_CONSTANT.CONTENT_URL}/internal/categories.json`;
            const response = await axios.get(url);
            return response.data;
        } catch (error: any) {
            logger.error('keyApi.getCategoryJSON() error: ', error.response || error);
            return `Error : ${error.response.data.message}`;
        }
    },

    createKey: async (body: CreateKeyInterface): Promise<{ [key: string]: any } | string> => {
        try {
            const url = `${process.env.REACT_APP_API}/keys`;
            const response = await axios.post(url, body);
            return response.data;
        } catch (error: any) {
            logger.error('keyApi.createKey() error: ', error.response || error);
            return `Error : ${error.response.data.message}`;
        }
    },

    createKeyMultiple: async (body: CreateKeyInterface, keyCount: number): Promise<string[] | string> => {
        try {
            const url = `${process.env.REACT_APP_API}/keys/multiple?numberOfKeys=${keyCount}`;
            const response = await axios.post(url, body);
            return response.data;
        } catch (error: any) {
            logger.error('keyApi.createKeyMultiple() error: ', error.response || error);
            return `Error : ${error.response.data.message}`;
        }
    },

    editKey: async (key: string, body: EditKeyInterface): Promise<KeyInterface | string> => {
        try {
            const url = `${process.env.REACT_APP_API}/keys?key=${key}`;
            const response = await axios.patch(url, body);
            return response.data;
        } catch (error: any) {
            logger.error('keyApi.editKey() error: ', error.response || error);
            return `Error : ${error.response.data.message}`;
        }
    },

    deleteKey: async (key: string): Promise<boolean | null> => {
        try {
            const url = `${process.env.REACT_APP_API}/keys?key=${key}`;
            await axios.delete(url);
            return true;
        } catch (error: any) {
            logger.error('keyApi.deleteKey() error: ', error.response || error);
            return null;
        }
    },

    verifyKey: async (email: string, key: string): Promise<{ [key: string]: any } | string> => {
        try {
            const url = `${process.env.REACT_APP_API}/subscriptions/keys/verify?email=${email}&key=${key}`;
            const response = await axios.get(url);
            return response.data;
        } catch (error: any) {
            logger.error('keyApi.verifyKey() error: ', error.response || error);
            return `Error : ${error.response.data.message}`;
        }
    },

    applyKey: async (body: { email: string; key: string }): Promise<{ [key: string]: any } | string> => {
        try {
            const url = `${process.env.REACT_APP_API}/subscriptions/keys/apply`;
            const response = await axios.post(url, body);
            return response.data;
        } catch (error: any) {
            logger.error('keyApi.applyKey() error: ', error.response || error);
            return `Error : ${error.response.data.message}`;
        }
    },
};
