import { memo, useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';
import Quill from 'quill';

import 'react-quill/dist/quill.snow.css';
import styles from './TextBox.module.scss';

const Delta = Quill.import('delta');

interface TextBoxInterface {
    value?: string;
    checkValidity: () => boolean;
}

const TextBox = ({ value, checkValidity }: TextBoxInterface) => {
    const quillRefs = useRef<any>(null);

    // * Quill modules to attach to editor
    // See https://quilljs.com/docs/modules/ for complete options

    const modules = {
        toolbar: { container: '#toolbar' },
        clipboard: {
            matchers: [
                [
                    Node.ELEMENT_NODE,
                    function (node: any, delta: any) {
                        const ops = delta.ops.map((op: any) => ({ insert: op.insert }));
                        return new Delta(ops);
                    },
                ],
            ],
        },
    };

    // * Quill editor formats
    // See https://quilljs.com/docs/formats/

    const formats = [
        'header',
        'font',
        'size',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'bullet',
        'indent',
        'link',
        'color',
    ];

    const CustomToolbar = () => (
        <div className={`${styles.toolbar}`} id="toolbar">
            <span className={`ql-formats ${styles.tools}`}>
                <button className={`ql-list ${styles.item}`}>List</button>
                <button className={`ql-link ${styles.item}`} />
                <button className={`ql-bold ${styles.item}`} />
                <button className={`ql-italic ${styles.item}`} />
                <button className={`ql-underline ${styles.item}`} />
            </span>
        </div>
    );

    const addDefaultClass = () => {
        const colorsElements = document.querySelectorAll('.ql-color');
        if (colorsElements.length === 0) return;

        const isActiveExist = Array.from(colorsElements).some((colorElm: any) => {
            return colorElm.classList.contains('ql-active');
        });

        if (!isActiveExist) colorsElements[0].classList.add('ql-active');
    };

    useEffect(() => {
        const innerContent = document.querySelector('.ql-editor');
        if (innerContent) innerContent.innerHTML = value || '';

        const colorsElement = document.querySelector('.ql-color');
        colorsElement?.classList.add('ql-active');

        const textElement = document.querySelector('.jodit_editor');
        if (!textElement) return;

        textElement.addEventListener('keydown', () => setTimeout(() => addDefaultClass(), 150));
        textElement.addEventListener('click', () => addDefaultClass());
        textElement.addEventListener('focusin', () => addDefaultClass());
        textElement.addEventListener('focusout', () => setTimeout(() => addDefaultClass(), 150));

        return () => {
            textElement.removeEventListener('keydown', () => setTimeout(() => addDefaultClass(), 150));
            textElement.removeEventListener('click', () => addDefaultClass());
            textElement.removeEventListener('focusin', () => addDefaultClass());
            textElement.removeEventListener('focusout', () => setTimeout(() => addDefaultClass(), 150));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={`${styles.textboxContainer}`}>
            <ReactQuill
                ref={quillRefs}
                onChange={() => {
                    checkValidity();
                    addDefaultClass();
                }}
                modules={modules}
                formats={formats}
                theme={'snow'}
                className={`${styles.textbox}`}
            />
            <CustomToolbar />
        </div>
    );
};

export default memo(TextBox);
