import global from 'theme/global/Global.module.scss';
import styles from './ViewEditContentDetails.module.scss';

import { DataGrid } from 'template/dataGrid/DataGrid.template';

import { ViewContentResponseInterface } from 'interfaces/content.interface';

import { contentDetailsGridData } from 'data/content/contentInfo.gridData';
import { BtnInfo } from 'theme/mui/buttons.theme';

interface ViewEditContentDetailsInterface {
    content: ViewContentResponseInterface;
    toggleEditMode: () => void;
}

export const ViewEditContentDetails = ({ content, toggleEditMode }: ViewEditContentDetailsInterface) => {
    const personalInfo = contentDetailsGridData(content);

    return (
        <div className={`${global.moduleWrapper} ${styles.viewEditContentDetailsModule}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    <DataGrid
                        data={personalInfo}
                        customHeadingElement={
                            <div>
                                <BtnInfo
                                    onClick={() => toggleEditMode()}
                                    className={`${global.body} ${styles.editBtn}`}
                                >
                                    Edit
                                </BtnInfo>
                            </div>
                        }
                    />
                </div>
            </div>
        </div>
    );
};
