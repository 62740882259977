import { dateTimeUtils } from 'helpers/utilities/dateTime.util';
import { UserInterface } from 'interfaces/user.interface';

export const userOnboardingTableData = (user: UserInterface) => {
    const tableRows = user.userOnboarding?.map((data) => {
        return {
            Time: dateTimeUtils.getDateTimeFromIso(data.timestamp),
            Event: data.event,
        };
    });

    return {
        heading: 'User Onboarding',
        rows: tableRows || [],
    };
};
