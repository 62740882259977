import { commonUtils } from 'helpers/utilities/common.util';
import { objectUtils } from 'helpers/utilities/object.util';

import { WebinarReportFormInterface } from 'interfaces/user.interface';
import { ValidationErrorInterface } from 'interfaces/validation.interface';
import { DATA_CONSTANT } from 'constants/data/data.constants';

export const userValidations = {
    getUserDetailsFormValidation: (email: string): string => {
        if (!email.trim()) return 'Error : Email can not be empty';
        else if (!email.trim().match(DATA_CONSTANT.REGEX.EMAIL)) return 'Error : Invalid email';
        else return '';
    },

    checkUsersFormValidation: (emailString: string): string => {
        if (!emailString.trim()) return 'Error : Email can not be empty';
        const emailArray = commonUtils.trimCommaAndSpaceSeparatedString(emailString);
        for (let email of emailArray) {
            if (!email.trim().match(DATA_CONSTANT.REGEX.EMAIL)) return 'Error : Invalid email';
        }
        return '';
    },

    webinarReportFormValidation: (inputDetails: WebinarReportFormInterface): ValidationErrorInterface | string[] => {
        let errorObj = { errorField: '', error: '' };

        if (!inputDetails.email?.trim()) {
            errorObj = objectUtils.updateObjectKeyValue(errorObj, {
                errorField: 'email',
                error: 'Email can not be empty',
            });
        }
        if (!inputDetails.webinarTime?.trim()) {
            errorObj = objectUtils.updateObjectKeyValue(errorObj, {
                errorField: 'webinarTime',
                error: 'Webinar time can not be empty',
            });
        }
        if (errorObj.error) return errorObj;
        else {
            // Note : Having "<" or ">" sign in email will cause error in the .match method
            const stringWithoutExceptionSymbols = commonUtils.removeCharactersFromString(
                inputDetails.email?.trim() || '',
                ['<', '>'],
            );
            const emailArray = commonUtils.trimCommaAndSpaceSeparatedString(stringWithoutExceptionSymbols);
            let invalidEmails = [];
            for (let email of emailArray) {
                if (!email.trim().match(DATA_CONSTANT.REGEX.EMAIL)) invalidEmails.push(email.trim());
            }
            return invalidEmails;
        }
    },
};
