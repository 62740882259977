import { CREATE_AFFILIATE_COUNTRY_CONSTANT, CREATE_KEY_COUNTRY_CONSTANT } from './country.constant';

export const DATA_CONSTANT = {
    PLAN_CATEGORY: {
        BASIC: 'BASIC',
        TRIAL: 'TRIAL',
        PRO: 'PRO',
        PREMIUM: 'PREMIUM',
    },

    PLAN_TYPE: {
        BASIC: 'Basic',
        TRIAL: 'Trial',
        STANDARD: 'Standard',
        ORGANIZATION: 'Organization',
        SUBSCRIPTION: 'Subscription',
    },

    PURCHASE_TYPE: {
        FREE: 'Free',
        PAID: 'Paid',
    },

    OPTIONS: {
        CREATE_KEY_COUNTRY: CREATE_KEY_COUNTRY_CONSTANT,

        CREATE_AFFILIATE_COUNTRY: CREATE_AFFILIATE_COUNTRY_CONSTANT,

        FILTER_LATEST_USERS: {
            BASIC: 'Basic',
            TRIAL: 'Trial',
            PRO: 'Pro',
            STANDARD: 'Standard',
            ORGANIZATION: 'Organization',
            SUBSCRIPTION: 'Subscription',
            AFTER_PRO: 'After Pro',
            AFTER_TRIAL: 'After Trial',
            CP1_TRIAL: 'CP1 Trial',
        },
        LATEST_USERS_FILTER_TEMPLATE: {
            INACTIVE_NEW_USER: 'Inactive new user',
            BASIC_FOR_2_DAYS: 'Basic for 2 days',
            GO_PRO_WITHIN_2_DAYS: 'Go Pro within 2 days',
        },

        FILTER_CCT: {
            NEW: 'New',
            EXPIRING: 'Expiring',
            EXPIRED: 'Expired',
            ACTIVE: 'Active',
        },
    },

    AZURE: {
        CPCS_REGION: 'dev-cpcs1101',
        CONTAINER_NAME: 'user',
    },

    TIME: {
        // Format = Hour * Seconds * MilliSeconds
        AUTO_LOGOUT: 12 * 3600 * 1000,
        GET_LATEST_USERS_API_CALL_DELAY: 1000,
        GET_PAST_SESSIONS_API_CALL_DELAY: 1000,
    },

    COLOR: {
        AVATAR: ['#f6a404', '#0c63be', '#dc3545', '#1f9436', '#d7441c', '#800080', '#b40199'],
    },

    REGEX: {
        //eslint-disable-next-line
        EMAIL: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
    },

    AWS: {
        ACCESS_KEY_ID: 'AKIASAUKMHIR2OFVCWO4',
        SECRET_ACCESS_KEY: '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',
        REGION: 'ap-southeast-1',
    },
};
