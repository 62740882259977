import global from 'theme/global/Global.module.scss';
import styles from './UserCard.module.scss';

import { userUtils } from 'helpers/utilities/user.util';
import { classPointCertifiedHelper } from 'helpers/classPointCertified.helper';

import { UserInterface } from 'interfaces/user.interface';

import { Avatar } from 'components/common/avatar/Avatar';
import { IMAGE_CONSTANT } from 'constants/image.constants';

interface UserCardInterface {
    user: UserInterface;
}

export const UserCard = ({ user }: UserCardInterface) => {
    const userSubscriptionType = userUtils.getUserSubscriptionType(user);

    const isCCT = classPointCertifiedHelper.getCertificationStatus(user.userProfile.cctExpiryDate);
    const isCCE = classPointCertifiedHelper.getCertificationStatus(user.userProfile.cceExpiryDate);
    const isCSC = classPointCertifiedHelper.getCertificationStatus(user.userProfile.cscExpiryDate);

    const data = {
        displayName: `${user.userProfile.firstName} ${user.userProfile.lastName}`,
        avatarUrl: user.userProfile.avatarUrl,
        email: user.email,
        planCategory: userSubscriptionType,
    };

    return (
        <div className={`${global.defaultModule} ${global.moduleWrapper} ${styles.userCardModule}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    <div className={`${styles.avatarContainer}`}>
                        <Avatar url={data.avatarUrl} text={data.displayName} globalFontSize="head5" />
                    </div>
                    <div className={`${styles.basicDetails}`}>
                        <h5 className={`${global.head5} ${styles.name}`}>{data.displayName}</h5>
                        <p className={`${global.bodySmall} ${styles.email}`}>{data.email}</p>

                        <div className={`${styles.badgeContainer}`}>
                            {isCCT && (
                                <div className={`${styles.badge}`}>
                                    <img src={IMAGE_CONSTANT.BADGE.CCT} className={`${styles.image}`} alt="CCT" />
                                    <p className={`${global.bodySmall} ${styles.text}`}>CCT</p>
                                </div>
                            )}
                            {isCCE && (
                                <div className={`${styles.badge}`}>
                                    <img src={IMAGE_CONSTANT.BADGE.CCE} className={`${styles.image}`} alt="CCE" />
                                    <p className={`${global.bodySmall} ${styles.text}`}>CCE</p>
                                </div>
                            )}
                            {isCSC && (
                                <div className={`${styles.badge}`}>
                                    <img src={IMAGE_CONSTANT.BADGE.CSC} className={`${styles.image}`} alt="CSC" />
                                    <p className={`${global.bodySmall} ${styles.text}`}>CSC</p>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={`${styles.accountTypeContainer}`}>
                        <p className={`${global.bodyXs} ${styles.accountType} ${styles[data.planCategory]}`}>
                            {data.planCategory}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};
