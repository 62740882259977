import { WebinarReportApiResponseInterface } from 'interfaces/user.interface';

import { generateWebinarReportCells } from 'data/generateCells/generateWebinarReportCells.data';

export const webinarReportGridData = (
    data: WebinarReportApiResponseInterface[],
    getClickCategoryUsers: (value: string, fieldName: string) => void,
) => {
    const cell = generateWebinarReportCells(data);

    return {
        heading: 'Webinar Report',
        rows: [
            [
                cell.totalEmails,
                cell.oldUserBasic,
                cell.oldUserPro,
                cell.oldUserBasicToPro,
                cell.newUserBasic,
                cell.newUserPro,
                cell.notUser,
            ],
            [cell.acquisition, cell.conversion],
        ],
        cellProps: {
            'Old User Basic': { getSelectedValueOnClick: (value: any) => getClickCategoryUsers(value, 'oldUserBasic') },
            'Old User Pro': { getSelectedValueOnClick: (value: any) => getClickCategoryUsers(value, 'oldUserPro') },
            'Old User Basic To Pro': {
                getSelectedValueOnClick: (value: any) => getClickCategoryUsers(value, 'oldUserBasicToPro'),
            },
            'New User Basic': { getSelectedValueOnClick: (value: any) => getClickCategoryUsers(value, 'newUserBasic') },
            'New User Pro': { getSelectedValueOnClick: (value: any) => getClickCategoryUsers(value, 'newUserPro') },
            'Not User': { getSelectedValueOnClick: (value: any) => getClickCategoryUsers(value, 'notUser') },
        },
    };
};
