import { FormEvent } from 'react';

import global from 'theme/global/Global.module.scss';
import styles from './LatestUsersTopBar.module.scss';

import { MultipleSearchBox } from 'template/searchBox/multiple/MultipleSearchBox.template';
import { InputFields } from 'template/inputFields/InputFields.template';

import { LatestUsersFilterDataInterface } from 'interfaces/user.interface';

import { filterInputFieldsData, filterInputFieldsIconsArray } from './filterInputFieldsData';

interface LatestUsersTopBarInterface {
    filterDetails: LatestUsersFilterDataInterface;
    inputOnChangeHandler: (value: string, fieldName?: string) => void;
    multipleSelectOnChangeHandler: (value: string[], fieldName?: string) => void;
    resetFilterHandler: (arg: FormEvent<HTMLFormElement>) => void;
}

export const LatestUsersTopBar = ({
    filterDetails,
    inputOnChangeHandler,
    multipleSelectOnChangeHandler,
    resetFilterHandler,
}: LatestUsersTopBarInterface) => {
    return (
        <div className={`${global.moduleWrapper} ${styles.topBarModule}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    <form onSubmit={(e) => resetFilterHandler(e)} className={`${styles.form}`}>
                        <MultipleSearchBox
                            details={filterDetails}
                            submitBtnText="Reset"
                            inputContent={
                                <div className={`${styles.inputFieldContainer}`}>
                                    <InputFields
                                        inputFields={filterInputFieldsData(filterDetails)}
                                        inputDetails={filterDetails}
                                        inputIconsArray={filterInputFieldsIconsArray}
                                        inputOnChangeHandler={inputOnChangeHandler}
                                        multipleSelectOnChangeHandler={multipleSelectOnChangeHandler}
                                    />
                                </div>
                            }
                        />
                    </form>
                </div>
            </div>
        </div>
    );
};
