import { dateTimeUtils } from 'helpers/utilities/dateTime.util';
import { AffiliateInvoiceInterface } from 'interfaces/affiliate.interface';

export const affiliateInvoicesTableData = (affiliateDetails: AffiliateInvoiceInterface[] | undefined) => {
    const tableRows = affiliateDetails?.map((invoice) => {
        return {
            invoiceNumber: invoice.invoiceNumber,
            productName: invoice.productName,
            amount: `${invoice.currencyCode} ${invoice.amount}`,
            userName: invoice.userName,
            userEmail: invoice.userEmail,
            userCountry: invoice.userCountry,
            couponCode: invoice.couponCode,
            created: dateTimeUtils.getDateTimeFromIso(invoice.created),
        };
    });

    return {
        heading: 'Invoices',
        rows: tableRows || [],
    };
};
