import global from 'theme/global/Global.module.scss';
import styles from './PastSessionsDetails.module.scss';

import { DataTable } from 'template/dataTable/DataTable.template';

import { PastClassSessionsByUserInterface } from 'interfaces/session.interface';

import { PastSessionsTableInput } from './PastSessionsTableInput';

import { pastSessionsTableData } from 'data/sessions/pastSessions.tableData';

interface PastSessionsDetailsInterface {
    // sessionType: 'user' | 'class' | undefined;
    // sessionsDetails: (PastClassSessionsByUserInterface | PastClassSessionsByClassInterface)[] | undefined;
    sessionsDetails: PastClassSessionsByUserInterface[] | undefined;
    getSingleSessionDetails: (classSessionId: string) => void;
    pageNumber: number;
    rowsPerPage: number;
    changeTableProperty: (action: 'add1Page' | 'sub1Page' | 'setPage' | 'setRow', value?: number) => void;
}

export const PastSessionsDetails = ({
    // sessionType,
    sessionsDetails,
    getSingleSessionDetails,
    pageNumber,
    rowsPerPage,
    changeTableProperty,
}: PastSessionsDetailsInterface) => {
    const pastSessionsDataInTableFormat = pastSessionsTableData(sessionsDetails, getSingleSessionDetails);

    return (
        <div className={`${global.moduleWrapper} ${styles.pastSessionDetailsModule}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    <DataTable
                        data={pastSessionsDataInTableFormat}
                        customHeadingElement={
                            <PastSessionsTableInput
                                pageNumber={pageNumber}
                                rowsPerPage={rowsPerPage}
                                changeTableProperty={changeTableProperty}
                            />
                        }
                    />
                </div>
            </div>
        </div>
    );
};
