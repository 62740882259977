import { useEffect, useState } from 'react';

import styles from './VerifyApplyKeyModal.module.scss';

import { CommonModal } from 'template/modal/common/CommonModal.template';

import { keyValidations } from 'helpers/validation/key.validation';
import { keyApi } from 'services/api/key.api';

import { UserInterface } from 'interfaces/user.interface';
import { ActivatedUserApiInterface, KeyInterface, VerifyApplyKeyInterface } from 'interfaces/key.interface';

import { VerifyApplyKeyInput } from './modalContent/verifyApplyKeyInput/VerifyApplyKeyInput';
import { VerifyApplyKeyDetails } from './modalContent/verifyApplyKeyDetails/VerifyApplyKeyDetails';

interface VerifyApplyKeyModalInterface {
    isVerifyApplyKeyModalOpen: boolean;
    setIsVerifyApplyKeyModalOpen: (arg: boolean) => void;
    user: UserInterface;
    getUser: (value?: string, type?: 'email' | 'userId') => void;
}

export const VerifyApplyKeyModal = ({
    isVerifyApplyKeyModalOpen,
    setIsVerifyApplyKeyModalOpen,
    user,
    getUser,
}: VerifyApplyKeyModalInterface) => {
    const [isLoading, setIsLoading] = useState({});
    const [isApplyActive, setIsApplyActive] = useState(false);
    const [inputDetails, setInputDetails] = useState<VerifyApplyKeyInterface>({});
    const [keyDetails, setKeyDetails] = useState<KeyInterface>();
    const [activatedUsersDetails, setActivatedUsersDetails] = useState<ActivatedUserApiInterface[]>();

    const inputOnChangeHandler = (value: string, fieldName?: string) => {
        if (fieldName) {
            setInputDetails((prevState) => ({ ...prevState, [fieldName]: value, error: '' }));
            setIsLoading((prevState) => ({ ...prevState, disableApplyBtn: false }));
        }
    };

    const getActivatedUsers = async () => {
        if (!keyDetails) return;
        setIsLoading((prevState) => ({ ...prevState, activatedUsers: true }));
        const activatedUsers = await keyApi.activatedUsers(keyDetails.key.trim());
        if (typeof activatedUsers !== 'string') setActivatedUsersDetails(activatedUsers);
        setIsLoading((prevState) => ({ ...prevState, activatedUsers: false }));
    };

    const verifyKeyHandler = async (email: string, key: string) => {
        setIsLoading((prevState) => ({ ...prevState, disableApplyBtn: false }));
        setInputDetails((prevState) => ({ ...prevState, error: '' }));

        const keyDetails = await keyApi.getKey(key.trim());
        const verifyKey = await keyApi.verifyKey(email.trim(), key.trim());

        if (typeof keyDetails === 'string') setInputDetails((prevState) => ({ ...prevState, error: keyDetails }));
        else {
            setKeyDetails(keyDetails);
            setActivatedUsersDetails(undefined);
        }

        if (typeof verifyKey === 'string') {
            setIsLoading((prevState) => ({ ...prevState, disableApplyBtn: true }));
            setInputDetails((prevState) => ({ ...prevState, error: verifyKey }));
        } else setInputDetails((prevState) => ({ ...prevState, success: `Success : Key can be applied` }));

        setIsApplyActive(false);
        setIsLoading((prevState) => ({ ...prevState, verify: false }));
    };

    const applyKeyHandler = async (email: string, key: string) => {
        const applyKey = await keyApi.applyKey({ email: email.trim(), key: key.trim() });
        if (typeof applyKey === 'string') {
            setInputDetails((prevState) => ({ ...prevState, error: applyKey }));
        } else {
            setInputDetails((prevState) => ({ ...prevState, success: `Success : Key successfully applied` }));
        }

        getUser(email);
        setIsApplyActive(true);
        setIsLoading((prevState) => ({ ...prevState, apply: false }));
    };

    const validateDetailsHandler = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, type: string) => {
        e.preventDefault();

        setIsLoading((prevState) => ({ ...prevState, [type]: true }));
        const isFormDetailsValid = keyValidations.verifyApplyKeyFormValidation(inputDetails);

        if (isFormDetailsValid !== '') {
            setInputDetails((prevState) => ({ ...prevState, error: isFormDetailsValid }));
            return setIsLoading((prevState) => ({ ...prevState, [type]: false }));
        } else if (inputDetails.key) {
            if (type === 'verify') await verifyKeyHandler(user.email, inputDetails.key);
            else if (type === 'apply') await applyKeyHandler(user.email, inputDetails.key);
        } else return;
    };

    const modalCloseHandler = () => {
        setIsLoading({});
        setInputDetails({});
        setKeyDetails(undefined);
        setIsApplyActive(false);
        setIsVerifyApplyKeyModalOpen(false);
    };

    useEffect(() => {
        if (inputDetails.success) {
            setTimeout(() => setInputDetails((prevState) => ({ ...prevState, success: '' })), 3000);
        }
    }, [inputDetails.success]);

    return (
        <CommonModal
            isModalOpen={isVerifyApplyKeyModalOpen}
            modalCloseHandler={() => modalCloseHandler()}
            modalContent={
                <div className={`${styles.verifyApplyKeyModalContent}`}>
                    <VerifyApplyKeyInput
                        isLoading={isLoading}
                        inputDetails={inputDetails}
                        inputOnChangeHandler={inputOnChangeHandler}
                        validateDetailsHandler={validateDetailsHandler}
                    />
                    <VerifyApplyKeyDetails
                        isApplyActive={isApplyActive}
                        isLoading={isLoading}
                        keyDetails={keyDetails}
                        user={user}
                        getActivatedUsers={getActivatedUsers}
                        activatedUsersDetails={activatedUsersDetails}
                    />
                </div>
            }
        />
    );
};
