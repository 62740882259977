import { BtnInfo } from 'theme/mui/buttons.theme';

import global from 'theme/global/Global.module.scss';
import styles from './SearchKeysDetails.module.scss';

import { DataGrid } from 'template/dataGrid/DataGrid.template';
import { DataTable } from 'template/dataTable/DataTable.template';

import { dateTimeUtils } from 'helpers/utilities/dateTime.util';

import { ActivatedUserApiInterface, KeyInterface } from 'interfaces/key.interface';

import { searchKeysDetailsGridData } from 'data/key/searchKeys/details/searchKeysDetails.gridData';
import { activatedUsersTableData } from 'data/key/searchKeys/details/activatedUsers.tableData';
import { generateExcelFile } from 'data/generateExcelFile/generateExcelFile.data';

interface SearchKeysDetailsInterface {
    keyDetails: KeyInterface;
    activatedUsersDetails: ActivatedUserApiInterface[] | undefined;
    getActivatedUsers: () => void;
    isLoading: boolean;
}

export const SearchKeysDetails = ({
    keyDetails,
    activatedUsersDetails,
    getActivatedUsers,
    isLoading,
}: SearchKeysDetailsInterface) => {
    const keyInfo = searchKeysDetailsGridData(keyDetails);
    const activatedUser = activatedUsersTableData(activatedUsersDetails);

    const exportExcelFileHandler = (data: { User: string; Activated: string }[]) => {
        let dataArray: [{ [key: string]: any }] = [{}];
        data.map((item, index) =>
            dataArray.push({
                No: `${index + 1}`,
                User: item.User,
                Activated: dateTimeUtils.getDateTimeFromIso(item.Activated),
            }),
        );
        generateExcelFile(dataArray, 'ClassPoint Key Activated Users');
    };

    return (
        <div className={`${global.moduleWrapper} ${styles.keyDetailsModule}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    <DataGrid data={keyInfo} />
                    <DataTable
                        data={activatedUser}
                        customHeadingElement={
                            <div className={`${styles.tableBtnContainer}`}>
                                <BtnInfo
                                    loading={isLoading}
                                    onClick={() => getActivatedUsers()}
                                    className={`${global.bodyXs} ${styles.tableBtn}`}
                                >
                                    See Users
                                </BtnInfo>
                                <BtnInfo
                                    onClick={() => exportExcelFileHandler(activatedUser.rows)}
                                    className={`${global.bodyXs} ${styles.tableBtn}`}
                                >
                                    Export
                                </BtnInfo>
                            </div>
                        }
                    />
                </div>
            </div>
        </div>
    );
};
