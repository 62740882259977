import { DATA_CONSTANT } from 'constants/data/data.constants';

export const commonUtils = {
    getNameInitials: (name: string | undefined): string => {
        if (!name) return '';
        const firstLetters = name
            .split(' ')
            .map((word) => word[0])
            .join('');
        return firstLetters;
    },

    stringToNumber: (inputString: string | undefined): number => {
        if (!inputString) return 0;
        let outputNumber = 0;
        for (let i = 0; i < inputString.length; i++) {
            let code = inputString.charCodeAt(i);
            outputNumber += code;
        }
        return outputNumber;
    },

    getColorForString: (inputString: string): string => {
        const colorIndex = commonUtils.stringToNumber(inputString) % DATA_CONSTANT.COLOR.AVATAR.length;
        return DATA_CONSTANT.COLOR.AVATAR[colorIndex];
    },

    makeFirstCharCapital: (value: string): string => {
        return value.charAt(0).toUpperCase() + value.slice(1);
    },

    convertToString: (value: any): string => {
        if ((Array.isArray(value) && value.length < 1) || value === undefined || value === 'undefined') {
            return '--';
        } else if (value === null) return '--null--';
        else return value.toString();
    },

    booleanToYesNo: (value: boolean | string | undefined): string => {
        if (value === true || value === 'true') return 'Yes';
        else if (value === false || value === 'false') return 'No';
        else return commonUtils.convertToString(value);
    },

    convertToBoolean: (value: string | undefined): boolean | string => {
        if (value === 'Yes' || value === 'true') return true;
        else if (value === 'No' || value === 'false') return false;
        else return commonUtils.convertToString(value);
    },

    separateByComma: (array: any) => {
        if (!array) return;
        const valueInString = Array.isArray(array) ? array.toString() : array;
        const stringArray = valueInString.split(',');
        return stringArray.join(', ');
    },

    trimArraySpace: (value: any[]): any[] => {
        const finalArray = [];
        for (let item of value) {
            finalArray.push(item.trim());
        }
        return finalArray;
    },

    trimCommaSeparatedString: (value: string): string[] => {
        const stringArray = value.split(',');
        return commonUtils.trimArraySpace(stringArray);
    },

    trimCommaAndSpaceSeparatedString: (value: string): string[] => {
        const spaceSeparatedString = value.split(' ').toString();
        const commaSeparatedString = spaceSeparatedString.split(',');
        const arrayWithoutEmptyValue = commaSeparatedString.filter((data) => data);
        return commonUtils.trimArraySpace(arrayWithoutEmptyValue);
    },

    removeCharactersFromString: (value: string, replaceCharacters: string[]): string => {
        let newString = value;
        for (let character of replaceCharacters) {
            newString = newString.replaceAll(character, '');
        }
        return newString;
    },

    starMarked: (value: any, isRequired: boolean | undefined): string => {
        if (isRequired) return `* ${value} *`;
        else return `${value}`;
    },

    insertLineBreak: (value: any): string => {
        const stringifiedValue = value.toString();
        const commaSeparatedArray = commonUtils.trimCommaSeparatedString(stringifiedValue);

        let finalString = '';
        commaSeparatedArray.map(
            (item) => (finalString = `${finalString}${finalString !== '' ? `, <br/>` : ''}${item}`),
        );
        return finalString;
    },

    padDigits: (number: number | string, padValue = 2) => {
        return number.toString().padStart(padValue, '0');
    },

    getDuplicateItemsInArray: (arrayValue: any) => {
        let finalArray: any[] = arrayValue;

        if (!Array.isArray(arrayValue)) {
            if (typeof arrayValue === 'string') finalArray = commonUtils.trimCommaAndSpaceSeparatedString(arrayValue);
            else return [];
        }

        const duplicateEmails = [...finalArray].filter((e, i, a) => a.indexOf(e) !== i);
        return duplicateEmails;
    },

    getUniqueItemsInArray: (arrayValue: any) => {
        let finalArray: any[] = arrayValue;

        if (!Array.isArray(arrayValue)) {
            if (typeof arrayValue === 'string') finalArray = commonUtils.trimCommaAndSpaceSeparatedString(arrayValue);
            else return [];
        }

        const uniqueEmail = [...finalArray].filter((e, i, a) => a.indexOf(e) === i);
        return uniqueEmail;
    },

    getCharacterCode: (value: string) => {
        return value.charCodeAt(0);
    },
};
