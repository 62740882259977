import { dateTimeUtils } from 'helpers/utilities/dateTime.util';

import { PastClassSessionsByUserInterface } from 'interfaces/session.interface';

export const pastSessionsTableData = (
    // sessionType: 'user' | 'class' | undefined,
    // sessionsDetails: (PastClassSessionsByUserInterface | PastClassSessionsByClassInterface)[] | undefined,
    sessionsDetails: PastClassSessionsByUserInterface[] | undefined,
    getSingleSessionDetails: (classSessionId: string) => void,
) => {
    const tableRowsByUser = sessionsDetails?.map((session) => {
        return {
            'Class Session Id': session.classSessionId,
            'Class Code': session.classCode,
            'Saved Class Name': session.savedClassName,
            Started: dateTimeUtils.getDateTimeFromIso(session.started),
            'Sum Points': session.sumPoints,
            'Activities Count': session.activitiesCount,
        };
    });

    // const tableRowsByClass = sessionsDetails?.map((session) => {
    //     return {
    //         'Class Session Id': session.classSessionId,
    //         Started: dateTimeUtils.getDateTimeFromIso(session.started),
    //         'Sum Points': session.sumPoints,
    //         'Activities Count': session.activitiesCount,
    //     };
    // });

    // const decideTableData = () => {
    //     if (sessionType === 'user' && tableRowsByUser) return tableRowsByUser;
    //     if (sessionType === 'class' && tableRowsByClass) return tableRowsByClass;
    //     return [];
    // };

    return {
        heading: 'Past Sessions',
        // rows: decideTableData(),
        rows: tableRowsByUser || [],
        cellProps: {
            'Class Session Id': { getSelectedValueOnClick: getSingleSessionDetails },
        },
    };
};
