import { BtnInfo } from 'theme/mui/buttons.theme';

import global from 'theme/global/Global.module.scss';
import styles from './WebinarReportDetails.module.scss';

import { DataGrid } from 'template/dataGrid/DataGrid.template';

import { WebinarReportApiResponseInterface } from 'interfaces/user.interface';

import { webinarReportGridData } from 'data/user/webinarReport/webinarReport.gridData';

interface WebinarReportDetailsInterface {
    isSelectedUsersDetailsLoading: boolean;
    webinarUserReport: WebinarReportApiResponseInterface[] | undefined;
    getClickCategoryUsers: (value: string, fieldName: string) => void;
}

export const WebinarReportDetails = ({
    isSelectedUsersDetailsLoading,
    webinarUserReport,
    getClickCategoryUsers,
}: WebinarReportDetailsInterface) => {
    const checkUsersDataInTableFormat =
        webinarUserReport && webinarReportGridData(webinarUserReport, getClickCategoryUsers);

    return (
        <div className={`${global.moduleWrapper} ${styles.webinarReportDetailsModule}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    {checkUsersDataInTableFormat && (
                        <DataGrid
                            data={checkUsersDataInTableFormat}
                            customHeadingElement={
                                isSelectedUsersDetailsLoading ? (
                                    <BtnInfo className={`${global.bodyXs} ${styles.loadingBtn}`}>Loading...</BtnInfo>
                                ) : (
                                    <div></div>
                                )
                            }
                        />
                    )}
                </div>
            </div>
        </div>
    );
};
