import { SubscribeLinkDetailsInterface } from 'interfaces/misc.interface';

import { generateSubscribeLinkCells } from 'data/generateCells/generateSubscribeLinkCells.data';

export const subscribeLinkGridData = (
    linkDetails: SubscribeLinkDetailsInterface[] | undefined,
    copyLinkValue: (details: string) => void,
) => {
    if (!linkDetails) return;

    const linkDetailsGridData = linkDetails.map((linkDetail) => {
        const cell = generateSubscribeLinkCells(linkDetail);
        return {
            heading: 'Subscribe Link Details',
            rows: [
                [cell.coupon, cell.link, cell.hiddenCouponLink],
                [cell.discountBy, cell.discountValue],
            ],
            cellProps: {
                Link: { getSelectedValueOnClick: copyLinkValue },
                'Hidden Coupon Link': { getSelectedValueOnClick: copyLinkValue },
            },
        };
    });

    return linkDetailsGridData;
};
