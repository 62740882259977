import { cellUtils } from 'helpers/utilities/cell.util';

import { WebinarReportApiResponseInterface } from 'interfaces/user.interface';

export const generateWebinarReportCells = (data: WebinarReportApiResponseInterface[]) => {
    const cell = cellUtils.tableCell;

    const totalEmails = data.map((item) => item.email).length;
    const newUserBasic = data.filter((item) => item.status === 'newUserBasic').length;
    const oldUserBasic = data.filter((item) => item.status === 'oldUserBasic').length;
    const newUserPro = data.filter((item) => item.status === 'newUserPro').length;
    const oldUserPro = data.filter((item) => item.status === 'oldUserPro').length;
    const oldUserBasicToPro = data.filter((item) => item.status === 'oldUserBasicToPro').length;
    const notUser = data.filter((item) => item.status === 'notUser').length;

    const acquisition = (newUserBasic + newUserPro) / (totalEmails - oldUserBasic - oldUserPro - oldUserBasicToPro);
    const conversion = (newUserPro + oldUserBasicToPro) / (totalEmails - oldUserPro - notUser);

    return {
        totalEmails: cell('Total Emails', totalEmails),
        oldUserBasic: cell('Old User Basic', oldUserBasic),
        oldUserPro: cell('Old User Pro', oldUserPro),
        oldUserBasicToPro: cell('Old User Basic To Pro', oldUserBasicToPro),
        newUserBasic: cell('New User Basic', newUserBasic),
        newUserPro: cell('New User Pro', newUserPro),
        notUser: cell('Not User', notUser),

        acquisition: cell(
            'Acquisition : (newUserBasic + newUserPro) / (totalEmailsCount - oldUserBasic - oldUserPro - oldUserBasicToPro)',
            `(${newUserBasic} + ${newUserPro}) / (${totalEmails} - ${oldUserBasic} - ${oldUserPro} - ${oldUserBasicToPro}) = ${
                newUserBasic + newUserPro === 0 || isNaN(acquisition) ? '0' : +(acquisition * 100).toFixed(2)
            }%`,
        ),
        conversion: cell(
            'Conversion : (newUserPro + oldUserBasicToPro)  / (totalEmailsCount - oldUserPro - notUser)',
            `(${newUserPro} + ${oldUserBasicToPro}) / (${totalEmails} - ${oldUserPro} - ${notUser}) = ${
                newUserPro + oldUserBasicToPro === 0 || isNaN(conversion) ? '0' : +(conversion * 100).toFixed(2)
            }%`,
        ),
    };
};
