import { FormEvent, useEffect, useState } from 'react';

import { BtnInfo } from 'theme/mui/buttons.theme';

import global from 'theme/global/Global.module.scss';
import styles from './WebinarReportTopBar.module.scss';

import { MultipleSearchBox } from 'template/searchBox/multiple/MultipleSearchBox.template';
import { InputFields } from 'template/inputFields/InputFields.template';

import { WebinarReportFormInterface } from 'interfaces/user.interface';
import { InputFieldsInterface } from 'interfaces/ui/inputFields.interface';

import { webinarReportInputFieldsData, inputFieldsIconsArray } from './webinarReportInputFieldsData';

interface WebinarReportTopBarInterface {
    isLoading: boolean;
    inputDetails: WebinarReportFormInterface;
    inputOnChangeHandler: (value: string, fieldName?: string) => void;
    validateDetails: (arg: FormEvent<HTMLFormElement>) => void;
    setIsCsvModalOpen: (arg: boolean) => void;
}

export const WebinarReportTopBar = ({
    isLoading,
    inputDetails,
    inputOnChangeHandler,
    validateDetails,
    setIsCsvModalOpen,
}: WebinarReportTopBarInterface) => {
    const [inputFields, setInputFields] = useState<InputFieldsInterface[] | undefined>();

    const updateFormHandler = () => {
        const formData = webinarReportInputFieldsData(inputDetails);
        setInputFields(formData);
    };

    useEffect(() => {
        updateFormHandler();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputDetails]);

    return (
        <div className={`${global.moduleWrapper} ${styles.topBarModule}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    <form onSubmit={(event) => validateDetails(event)} className={`${styles.form}`}>
                        <MultipleSearchBox
                            details={inputDetails}
                            isLoading={isLoading}
                            submitBtnText="Get report"
                            inputContent={
                                <div className={`${styles.inputFieldContainer}`}>
                                    {inputFields && (
                                        <InputFields
                                            inputFields={inputFields}
                                            inputDetails={inputDetails}
                                            inputIconsArray={inputFieldsIconsArray}
                                            inputOnChangeHandler={inputOnChangeHandler}
                                        />
                                    )}
                                </div>
                            }
                        />
                    </form>
                    <BtnInfo onClick={() => setIsCsvModalOpen(true)} className={`${styles.uploadDataBtn}`}>
                        Upload CSV
                    </BtnInfo>
                </div>
            </div>
        </div>
    );
};
