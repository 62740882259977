import { MdMenuOpen } from 'react-icons/md';

import global from 'theme/global/Global.module.scss';
import styles from './MobileTopBar.module.scss';

import { UI_ACTIONS } from 'context/features/uiSlice.redux';
import { useAppDispatch } from 'helpers/customHooks/useRedux.hook';
import { IMAGE_CONSTANT } from 'constants/image.constants';

export const MobileTopBar = () => {
    const dispatch = useAppDispatch();

    return (
        <div className={`${global.moduleWrapper} ${styles.mobileTopBarModule}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    <div className={`${styles.logoContainer}`}>
                        <img src={IMAGE_CONSTANT.IMAGE.LOGO_FULL} className={`${styles.logo}`} alt="ClassPoint" />
                    </div>
                    <div className={`${styles.mobileNavIconContainer}`}>
                        <MdMenuOpen
                            onClick={() => dispatch(UI_ACTIONS.TOGGLE_MOBILE_NAV())}
                            className={`${styles.icon}`}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
