import axios from 'axios';

import { logger } from '../logger.service';

import {
    ClassSessionDetailsInterface,
    LiveClassSessionsByUserInterface,
    PastClassSessionsByClassInterface,
    PastClassSessionsByUserInterface,
} from 'interfaces/session.interface';

export const sessionApi = {
    getPastClassSessionsByUser: async (
        userId: string,
        pageOffset: number,
        pageLimit: number,
    ): Promise<PastClassSessionsByUserInterface[] | string> => {
        try {
            const url = `${process.env.REACT_APP_APITWO}/classsessions/recent?userId=${userId}&skip=${pageOffset}&limit=${pageLimit}`;
            const response = await axios.get(url);
            return response.data;
        } catch (error: any) {
            logger.error('sessionApi.getPastClassSessionsByUser() error: ', error.response || error);
            return `Error : ${error.response.data.message}`;
        }
    },

    getPastClassSessionsByClass: async (
        savedClassId: string,
        pageOffset: number,
        pageLimit: number,
    ): Promise<PastClassSessionsByClassInterface[] | string> => {
        try {
            const url = `${process.env.REACT_APP_APITWO}/savedclasses/past-sessions?savedClassId=${savedClassId}&skip=${pageOffset}&limit=${pageLimit}`;
            const response = await axios.get(url);
            return response.data;
        } catch (error: any) {
            logger.error('sessionApi.getPastClassSessionsByClass() error: ', error.response || error);
            return `Error : ${error.response.data.message}`;
        }
    },

    getSingleSessionDetails: async (classSessionId: string): Promise<ClassSessionDetailsInterface | string> => {
        try {
            const url = `${process.env.REACT_APP_APITWO}/classsessions/summary?classSessionId=${classSessionId}`;
            const response = await axios.get(url);
            return response.data;
        } catch (error: any) {
            logger.error('sessionApi.getSingleSessionDetails() error: ', error.response || error);
            return `Error : ${error.response.data.message}`;
        }
    },

    getActivityDetails: async (activityId: string) => {
        try {
            const url = `${process.env.REACT_APP_APITWO}/activities?activityId=${activityId}`;
            const response = await axios.get(url);
            return response.data;
        } catch (error: any) {
            logger.error('sessionApi.getActivityDetails() error: ', error.response || error);
            return `Error : ${error.response.data.message}`;
        }
    },

    getLiveClassSessions: async (): Promise<LiveClassSessionsByUserInterface[] | string> => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_APITWO}/classcode/active`);
            return response.data;
        } catch (error: any) {
            logger.error('sessionApi.getLiveClassSessions() error: ', error.response || error);
            return `Error : ${error.response.data.message}`;
        }
    },

    getClassesInRegion: async (region: string) => {
        try {
            const response = await axios.get(`https://${region}.classpoint.app/liveclasses/summary`);
            return await response.data;
        } catch (error: any) {
            logger.error('sessionApi.getClassesInRegion() error: ', error.response || error);
            return `Error : ${error.response.data.message}`;
        }
    },

    getClassModelByEmail: async (region: string, email: string) => {
        try {
            const response = await axios.get(`https://${region}.classpoint.app/liveclasses?email=${email}`);
            return await response.data;
        } catch (error: any) {
            logger.error('sessionApi.getClassModelByEmail() error: ', error.response || error);
            return `Error : ${error.response.data.message}`;
        }
    },

    getUserModelByEmail: async (email: string) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/users?email=${email}`);
            return await response.data;
        } catch (error: any) {
            logger.error('sessionApi.getUserModelByEmail() error: ', error.response || error);
            return `Error : ${error.response.data.message}`;
        }
    },
};
