import { read } from 'xlsx';

import { commonUtils } from './utilities/common.util';
import { userApi } from 'services/api/user.api';

import {
    CheckUserApiResponseInterface,
    WebinarReportApiResponseInterface,
    WebinarReportFormInterface,
} from 'interfaces/user.interface';
import { CsvUploadFileInputInterface } from 'interfaces/common.interface';
import { DATA_CONSTANT } from 'constants/data/data.constants';

export const webinarReportHelper = {
    generateErrorMessage: (emailArray: string[], type: 'duplicate' | 'invalid') => {
        let errorMessage = type === 'duplicate' ? 'Duplicate emails found : ' : 'Invalid emails found : ';
        let totalCount = 0;

        if (emailArray.length > 0) {
            while (emailArray.length > 0) {
                // eslint-disable-next-line
                const emailArrayLength = emailArray.filter((email) => email === emailArray[0]).length;
                const firstEntryDuplicateCount = type === 'duplicate' ? emailArrayLength : emailArrayLength - 1;

                let duplicatedTimes = '';
                if (errorMessage.includes('times')) {
                    duplicatedTimes = ` ; ${emailArray[0]} - ${firstEntryDuplicateCount + 1} times`;
                } else duplicatedTimes = `${emailArray[0]} - ${firstEntryDuplicateCount + 1} times`;

                totalCount = totalCount + 1;
                errorMessage = errorMessage + duplicatedTimes;
                // eslint-disable-next-line
                emailArray = emailArray.filter((email) => email !== emailArray[0]);
            }
            return `${totalCount} ${errorMessage}`;
        }
        return '';
    },

    readCsvFile: (
        fileDetails: any,
        setCsvFileDetails: (arg: CsvUploadFileInputInterface) => void,
        setInputDetails: (arg: (prevState: WebinarReportFormInterface) => WebinarReportFormInterface) => void,
    ) => {
        const reader = new FileReader();
        reader.onload = function (e: any) {
            let fileData = read(e.target.result, { type: 'array' });

            const dataArray = fileData.Sheets.Sheet1;
            if (!dataArray) {
                return setCsvFileDetails({ file: undefined, error: 'Error : Please select a valid CSV file' });
            }

            const allValuesInDataArray = Object.values(dataArray).map((value) => value.v?.toString().toLowerCase());
            const validEmails = allValuesInDataArray.filter((value) => value?.match(DATA_CONSTANT.REGEX.EMAIL));
            setInputDetails((prevState) => ({
                ...prevState,
                email: commonUtils.separateByComma(validEmails),
                error: '',
            }));
        };

        fileDetails && reader.readAsArrayBuffer(fileDetails);
    },

    getUserStatusDataFromApi: async (uniqueEmailArray: string[], webinarTime: string) => {
        const maxChunkSize = 200;
        let userReport: string | WebinarReportApiResponseInterface[] = [];

        if (uniqueEmailArray.length > maxChunkSize) {
            let processedEmailArray = uniqueEmailArray;
            let selectedEmailArray: string[] = [];

            while (processedEmailArray.length > 0) {
                if (processedEmailArray.length > maxChunkSize) {
                    selectedEmailArray = processedEmailArray.slice(0, maxChunkSize);
                    processedEmailArray = processedEmailArray.slice(maxChunkSize, processedEmailArray.length);
                } else {
                    selectedEmailArray = processedEmailArray;
                    processedEmailArray = [];
                }

                const chunkReport = await userApi.webinarReport(selectedEmailArray, webinarTime);
                if (typeof chunkReport === 'string') return (userReport = chunkReport);
                else userReport.push(...chunkReport);
            }
        } else {
            userReport = await userApi.webinarReport(uniqueEmailArray, webinarTime);
        }

        return userReport;
    },

    getSelectedUserDataFromApi: async (emailArray: string[]) => {
        const maxChunkSize = 100;
        let usersData: string | CheckUserApiResponseInterface = { users: [], nonUsers: [] };

        if (emailArray.length > maxChunkSize) {
            let processedEmailArray = emailArray;
            let selectedEmailArray: string[] = [];

            while (processedEmailArray.length > 0) {
                if (processedEmailArray.length > maxChunkSize) {
                    selectedEmailArray = processedEmailArray.slice(0, maxChunkSize);
                    processedEmailArray = processedEmailArray.slice(maxChunkSize, processedEmailArray.length);
                } else {
                    selectedEmailArray = processedEmailArray;
                    processedEmailArray = [];
                }

                const chunkData = await userApi.checkUser(selectedEmailArray.toString());
                if (typeof chunkData === 'string') return (usersData = chunkData);
                else {
                    usersData.users.push(...chunkData.users);
                    usersData.nonUsers.push(...chunkData.nonUsers);
                }
            }
        } else {
            const chunkData = await userApi.checkUser(emailArray.toString());
            if (typeof chunkData === 'string') return (usersData = chunkData);
            else {
                usersData.users.push(...chunkData.users);
                usersData.nonUsers.push(...chunkData.nonUsers);
            }
        }

        return usersData;
    },
};
