import { useEffect, useState } from 'react';

import global from 'theme/global/Global.module.scss';
import styles from './UserDetailsModules.module.scss';

import { DataGrid } from 'template/dataGrid/DataGrid.template';
import { CommonModal } from 'template/modal/common/CommonModal.template';
import { JsonViewer } from 'template/jsonViewer/JsonViewer.template';

import { CurrentClassInterface } from 'interfaces/user.interface';

import { currentClassDetailsGridData } from 'data/user/userDetails/details/currentClassDetails.gridData';
import { currentClassCurrentActivityGridData } from 'data/user/userDetails/details/currentClassCurrentActivity.gridData';
import { currentClassToolbarActionsGridData } from 'data/user/userDetails/details/currentClassToolbarActions.gridData';

interface CurrentClassDetailsInterface {
    isLoading: boolean;
    liveClasses: CurrentClassInterface;
    liveClassesError: string;
    getCurrentClassDetails: () => void;
}

export const CurrentClassDetails = ({
    isLoading,
    liveClasses,
    liveClassesError,
    getCurrentClassDetails,
}: CurrentClassDetailsInterface) => {
    const [isCurrentActivityJsonModalOpen, setIsCurrentActivityJsonModalOpen] = useState(false);

    const showCurrentActivityJson = () => setIsCurrentActivityJsonModalOpen(true);

    const classDetails = currentClassDetailsGridData(liveClasses);
    const currentActivity = currentClassCurrentActivityGridData(liveClasses, showCurrentActivityJson);
    const toolbarActions = currentClassToolbarActionsGridData(liveClasses);

    useEffect(() => {
        getCurrentClassDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={`${global.moduleWrapper} ${styles.currentClassDetailsModule}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content} ${styles.currentClassContent}`}>
                    <CommonModal
                        isModalOpen={isCurrentActivityJsonModalOpen}
                        modalCloseHandler={() => setIsCurrentActivityJsonModalOpen(false)}
                        modalContent={
                            <div className={`${styles.currentActivityJsonViewModalContent}`}>
                                {liveClasses?.currentActivity && <JsonViewer jsonData={liveClasses.currentActivity} />}
                            </div>
                        }
                    />

                    {liveClassesError ? (
                        <p className={`${styles.noLiveClassText}`}>{isLoading ? 'Data loading...' : 'No live class'}</p>
                    ) : (
                        <>
                            <DataGrid data={classDetails} />
                            {liveClasses.currentActivity?.activityType && <DataGrid data={currentActivity} />}
                            <DataGrid data={toolbarActions} />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};
