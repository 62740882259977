import { useEffect, useState } from 'react';

import { Box, Tab, Tabs, Typography } from '@material-ui/core';

import { JsonViewer } from 'template/jsonViewer/JsonViewer.template';

import { sessionApi } from 'services/api/session.api';

type ClassDetailsProps = {
    region: string | undefined;
    email: string;
};

interface TabPanelInterface {
    children: React.ReactNode;
    index: number;
    value: number;
}

const ClassDetails = ({ region, email }: ClassDetailsProps) => {
    const [classDetails, setClassDetails] = useState<any>();
    const [value, setValue] = useState(0);

    useEffect(() => {
        if (!region) return;
        loadClassDetails(region, email);
    }, [region, email]);

    const loadClassDetails = async (region: string, email: string) => {
        const userModel = await sessionApi.getUserModelByEmail(email);
        const classModel = await sessionApi.getClassModelByEmail(region, email);
        setClassDetails({
            userModel,
            classModel,
        });
    };

    function TabPanel({ children, value, index, ...other }: TabPanelInterface) {
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleChange = (event: any, newValue: number) => setValue(newValue);

    return (
        <>
            {classDetails && (
                <>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} variant="scrollable">
                            <Tab label="userProfile" {...a11yProps(0)} style={{ textTransform: 'none' }} />
                            <Tab label="userSubscription" {...a11yProps(1)} style={{ textTransform: 'none' }} />
                            <Tab label="userStats" {...a11yProps(2)} style={{ textTransform: 'none' }} />
                            <Tab label="classStats" {...a11yProps(3)} style={{ textTransform: 'none' }} />
                            <Tab label="currentSlideshow" {...a11yProps(4)} style={{ textTransform: 'none' }} />
                            <Tab label="currentActivity" {...a11yProps(5)} style={{ textTransform: 'none' }} />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        {/* <pre>
                            {JSON.stringify(
                                {
                                    email: classDetails.userModel.email,
                                    userProfile: classDetails.userModel.userProfile,
                                    userBadges: classDetails.userModel.userBadges,
                                },
                                null,
                                2,
                            )}
                        </pre> */}
                        <JsonViewer
                            jsonData={
                                {
                                    email: classDetails.userModel.email,
                                    userProfile: classDetails.userModel.userProfile,
                                    userBadges: classDetails.userModel.userBadges,
                                } || null
                            }
                            isCopyDisabled={true}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        {/* <pre>{JSON.stringify(classDetails.userModel.userSubscription, null, 2)}</pre> */}
                        <JsonViewer jsonData={classDetails.userModel.userSubscription || null} isCopyDisabled={true} />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        {/* <pre>{JSON.stringify(classDetails.userModel.userStats, null, 2)}</pre> */}
                        <JsonViewer jsonData={classDetails.userModel.userStats || null} isCopyDisabled={true} />
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        {/* <pre>
                            {JSON.stringify(
                                {
                                    classSessionId: classDetails.classModel.classSessionId,
                                    startedOn: classDetails.classModel.startedOn,
                                    classLimit: classDetails.classModel.classLimit,
                                    isInSlideshow: classDetails.classModel.isInSlideshow,
                                    isLocked: classDetails.classModel.isLocked,
                                    isInPickedNames: classDetails.classModel.isInPickedNames,
                                    isSavedOnDb: classDetails.classModel.isSavedOnDb,
                                },
                                null,
                                2,
                            )}
                        </pre> */}
                        <JsonViewer
                            jsonData={
                                {
                                    classSessionId: classDetails.classModel.classSessionId,
                                    startedOn: classDetails.classModel.startedOn,
                                    classLimit: classDetails.classModel.classLimit,
                                    isInSlideshow: classDetails.classModel.isInSlideshow,
                                    isLocked: classDetails.classModel.isLocked,
                                    isInPickedNames: classDetails.classModel.isInPickedNames,
                                    isSavedOnDb: classDetails.classModel.isSavedOnDb,
                                } || null
                            }
                            isCopyDisabled={true}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                        {/* <pre>{JSON.stringify(classDetails.classModel.currentSlideshow, null, 2)}</pre> */}
                        <JsonViewer jsonData={classDetails.classModel.currentSlideshow || null} isCopyDisabled={true} />
                    </TabPanel>
                    <TabPanel value={value} index={5}>
                        {/* <pre>{JSON.stringify(classDetails.classModel.currentActivity, null, 2)}</pre> */}
                        <JsonViewer jsonData={classDetails.classModel.currentActivity || null} isCopyDisabled={true} />
                    </TabPanel>
                </>
            )}
        </>
    );
};

export default ClassDetails;
