import global from 'theme/global/Global.module.scss';
import styles from './CctDetails.module.scss';

import { DataTable } from 'template/dataTable/DataTable.template';

import { commonUtils } from 'helpers/utilities/common.util';

import { CctCsvUpdateReportInterface, NewCctApiResponseInterface } from 'interfaces/classPointCertified.interface';

import { cctInfoTableData } from 'data/classPointCertified/cctInfo.tableData';

interface CctDetailsInterface {
    cctInfo: NewCctApiResponseInterface[] | undefined;
    getSingleUserDetailsHandler: (userId: string) => void;
    csvUpdateReport: CctCsvUpdateReportInterface;
}

export const CctDetails = ({ cctInfo, getSingleUserDetailsHandler, csvUpdateReport }: CctDetailsInterface) => {
    const cctDetails = cctInfoTableData(cctInfo, getSingleUserDetailsHandler);

    return (
        <div className={`${global.moduleWrapper} ${styles.webinarReportDetailsModule}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    {csvUpdateReport.successEmails.length > 0 && (
                        <>
                            <p className={`${global.bodyXl} ${styles.success}`}>
                                Success email count : {csvUpdateReport.successEmails.length}
                            </p>
                            <p className={`${global.bodyXl} ${styles.success}`}>
                                Success emails : {commonUtils.separateByComma(csvUpdateReport.successEmails)}
                            </p>
                        </>
                    )}

                    {csvUpdateReport.failedEmails.length > 0 && (
                        <>
                            <p className={`${global.bodyXl} ${styles.error}`}>
                                Failed email count : {csvUpdateReport.failedEmails.length}
                            </p>
                            <p className={`${global.bodyXl} ${styles.error}`}>
                                Failed emails : {commonUtils.separateByComma(csvUpdateReport.failedEmails)}
                            </p>
                        </>
                    )}

                    {csvUpdateReport.invalidEmails.length > 0 && (
                        <>
                            <p className={`${global.bodyXl} ${styles.error}`}>
                                Invalid email count : {csvUpdateReport.invalidEmails.length}
                            </p>
                            <p className={`${global.bodyXl} ${styles.error}`}>
                                Invalid emails : {commonUtils.separateByComma(csvUpdateReport.invalidEmails)}
                            </p>
                        </>
                    )}

                    {csvUpdateReport.invalidDates.length > 0 && (
                        <>
                            <p className={`${global.bodyXl} ${styles.error}`}>
                                Invalid date count : {csvUpdateReport.invalidDates.length}
                            </p>
                            <p className={`${global.bodyXl} ${styles.error}`}>
                                Invalid dates : {commonUtils.separateByComma(csvUpdateReport.invalidDates)}
                            </p>
                        </>
                    )}

                    {<DataTable data={cctDetails} />}
                </div>
            </div>
        </div>
    );
};
