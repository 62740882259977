import { FormEvent, useState } from 'react';

import global from 'theme/global/Global.module.scss';
import styles from './ApplyKey.module.scss';

import { CommonModal } from 'template/modal/common/CommonModal.template';
import { MultipleSearchBox } from 'template/searchBox/multiple/MultipleSearchBox.template';
import { InputFields } from 'template/inputFields/InputFields.template';

import { keyValidations } from 'helpers/validation/key.validation';
import { commonUtils } from 'helpers/utilities/common.util';
import { keyHelper } from 'helpers/key.helper';
import { fileUtils } from 'helpers/utilities/file.util';
import { keyApi } from 'services/api/key.api';

import { ApplyKeyFormInterface } from 'interfaces/key.interface';
import { CsvUploadFileInputInterface } from 'interfaces/common.interface';

import { ApplyKeyTopBar } from './applyKeyTopBar/ApplyKeyTopBar';
import { DefaultLayout } from 'template/defaultLayout/DefaultLayout.template';

export const ApplyKey = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isCsvModalOpen, setIsCsvModalOpen] = useState(false);
    const [inputDetails, setInputDetails] = useState<ApplyKeyFormInterface>({ email: '', key: '' });
    const [csvFileDetails, setCsvFileDetails] = useState<CsvUploadFileInputInterface>({});
    const [successMessage, setSuccessMessage] = useState('');

    const inputOnChangeHandler = (value: string, fieldName?: string) => {
        if (!fieldName) return;
        setInputDetails((prevState) => ({ ...prevState, [fieldName]: value, error: '' }));
    };

    const fileInputOnChangeHandler = (file: any) => {
        const fileInfo = file && file[0];
        if (!fileInfo) return;
        const isValidCsvFile = fileUtils.isValidExcelOrCsv(fileInfo);
        setCsvFileDetails({
            file: isValidCsvFile ? fileInfo : undefined,
            error: isValidCsvFile ? '' : 'Error : Please select a valid CSV file',
        });
    };

    const applyKeyHandler = async (invalidEmails: string[]) => {
        // Note : Having "<" or ">" sign in email will cause error in the .match method
        const stringWithoutExceptionSymbols = commonUtils.removeCharactersFromString(inputDetails.email || '', [
            '<',
            '>',
        ]);

        const emailArray = commonUtils.trimCommaAndSpaceSeparatedString(stringWithoutExceptionSymbols);
        const invalidEmailArray = invalidEmails;
        const validEmailArray = emailArray.filter((email) => !invalidEmailArray.includes(email));
        let duplicateEmailArray = commonUtils.getDuplicateItemsInArray(validEmailArray);
        const uniqueEmailArray = commonUtils.getUniqueItemsInArray(validEmailArray);

        const duplicateEmailsErrorMessage = keyHelper.generateErrorMessage(duplicateEmailArray, 'duplicate');
        const invalidEmailsErrorMessage = keyHelper.generateErrorMessage(invalidEmailArray, 'invalid');
        setInputDetails((prevState) => ({
            ...prevState,
            error: duplicateEmailsErrorMessage
                ? `${duplicateEmailsErrorMessage} <br/> ${invalidEmailsErrorMessage}`
                : invalidEmailsErrorMessage,
        }));

        const apiError: string[] = [];
        const successEmails: string[] = [];

        for (let email of uniqueEmailArray) {
            const response = await keyApi.applyKey({ email: email, key: inputDetails.key });
            if (typeof response === 'string') apiError.push(response);
            else successEmails.push(email);
        }

        setIsLoading(false);

        if (apiError.length > 0) {
            setInputDetails((prevState) => ({
                ...prevState,
                error: `${prevState.error} <br/> ${apiError.toString().replaceAll(',', ', ')}`,
            }));
        }
        setSuccessMessage(`Successful : ${successEmails.toString().replaceAll(',', ', ')}`);
    };

    const csvFileHandler = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsCsvModalOpen(false);
        keyHelper.readCsvFile(csvFileDetails.file, setCsvFileDetails, setInputDetails);
    };

    const uploadCsvModalCloseHandler = () => {
        setIsCsvModalOpen(false);
        setCsvFileDetails({});
    };

    const removeFileSelectionHandler = () => {
        setCsvFileDetails({ file: undefined, error: '' });
    };

    const validateDetails = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsLoading(true);

        const isFormDetailsValid = keyValidations.applyKeyFormValidation(inputDetails.email);
        if (!Array.isArray(isFormDetailsValid)) {
            setInputDetails((prevState) => ({
                ...prevState,
                error: `Error : ${isFormDetailsValid}`,
            }));
            return setIsLoading(false);
        } else return applyKeyHandler(isFormDetailsValid);
    };

    return (
        <div className={`${global.componentWrapper} ${styles.applyKeyComponent}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    <CommonModal
                        isModalOpen={isCsvModalOpen}
                        modalCloseHandler={() => uploadCsvModalCloseHandler()}
                        modalContent={
                            <form onSubmit={(e) => csvFileHandler(e)} className={`${styles.csvInputModal}`}>
                                <MultipleSearchBox
                                    isLoading={isLoading}
                                    details={csvFileDetails}
                                    submitBtnText="Upload file"
                                    inputContent={
                                        <div className={`${styles.inputFieldContainer}`}>
                                            <InputFields
                                                inputFields={[
                                                    {
                                                        fieldType: 'file',
                                                        fieldName: 'file',
                                                        label: 'Upload CSV file',
                                                        error: csvFileDetails.error ? true : false,
                                                        removeSelection: () => removeFileSelectionHandler(),
                                                        fieldClassName: `${styles.csvInputField}`,
                                                    },
                                                ]}
                                                inputDetails={csvFileDetails}
                                                fileInputOnChangeHandler={fileInputOnChangeHandler}
                                            />
                                        </div>
                                    }
                                />
                            </form>
                        }
                    />

                    <DefaultLayout
                        content={
                            <div className={`${styles.applyKeyContent}`}>
                                <ApplyKeyTopBar
                                    isLoading={isLoading}
                                    inputDetails={inputDetails}
                                    inputOnChangeHandler={inputOnChangeHandler}
                                    validateDetails={validateDetails}
                                    successMessage={successMessage}
                                    setIsCsvModalOpen={setIsCsvModalOpen}
                                />
                            </div>
                        }
                    />
                </div>
            </div>
        </div>
    );
};
