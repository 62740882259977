import { startCase } from 'lodash';

import { cellUtils } from 'helpers/utilities/cell.util';
import { commonUtils } from 'helpers/utilities/common.util';

import { CellInterface } from 'interfaces/ui/cell.interface';
import { DATA_CONSTANT } from 'constants/data/data.constants';
import { CreatedAffiliateFullInterface } from 'interfaces/affiliate.interface';

export const createAffiliateGridData = (
    affiliateDetails: CreatedAffiliateFullInterface | null,
    copyValue: (value: string) => void,
) => {
    if (!affiliateDetails) return null;

    const columnCount = 2;
    const objectKeys = Object.keys(affiliateDetails).filter((key) => key !== 'adminEmail');

    let finalArray: CellInterface[][] = [];
    let singleRow: CellInterface[] = [];
    const cell = cellUtils.tableCell;
    const emptyCell = cellUtils.emptyTableCell;

    const checkLastArrayItem = () => {
        const emptyCellArray: CellInterface[] = [];
        [...Array(columnCount - singleRow.length)].map(() => emptyCellArray.push(emptyCell));

        if (finalArray.length === 0) finalArray = [[...singleRow, ...emptyCellArray]];
        else finalArray = [...finalArray, [...singleRow, ...emptyCellArray]];
    };

    for (let i = 0; i !== objectKeys.length; i++) {
        let cellValue: string;
        const fieldValue = affiliateDetails[objectKeys[i] as keyof CreatedAffiliateFullInterface];

        if (objectKeys[i] === 'countryCode') {
            const requiredObject = DATA_CONSTANT.OPTIONS.CREATE_AFFILIATE_COUNTRY.find(
                (data) => data.value === fieldValue?.toString(),
            );
            cellValue = requiredObject?.label || startCase(requiredObject?.value) || '';
        } else if (objectKeys[i] === 'links') cellValue = commonUtils.separateByComma(fieldValue);
        else cellValue = commonUtils.convertToString(fieldValue);

        const cellFormatData =
            objectKeys[i] === 'commission'
                ? cell('Commission Percentage', cellValue)
                : objectKeys[i] === 'url'
                ? cell('Url With Coupon', cellValue)
                : cell(startCase(objectKeys[i]), cellValue);

        if (i === 0) {
            singleRow.push(cellFormatData);
            if (i === objectKeys.length - 1) checkLastArrayItem();
        } else if (i % columnCount !== 0) {
            singleRow.push(cellFormatData);
            if (i === objectKeys.length - 1) checkLastArrayItem();
        } else {
            checkLastArrayItem();
            singleRow = [cellFormatData];
            if (i === objectKeys.length - 1) checkLastArrayItem();
        }
    }

    return {
        heading: `Name - ${affiliateDetails.name}`,
        rows: finalArray,
        cellProps: {
            'Coupon Code': { getSelectedValueOnClick: copyValue },
            'Url With Coupon': { getSelectedValueOnClick: copyValue },
            'Url With Code': { getSelectedValueOnClick: copyValue },
        },
    };
};
