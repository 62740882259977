import { BtnDisabled, BtnInfo } from 'theme/mui/buttons.theme';

import global from 'theme/global/Global.module.scss';
import styles from './VerifyApplyKeyInput.module.scss';

import { MultipleSearchBox } from 'template/searchBox/multiple/MultipleSearchBox.template';
import { InputFields } from 'template/inputFields/InputFields.template';

import { VerifyApplyKeyInterface } from 'interfaces/key.interface';

import { verifyApplyKeyInputFields, verifyApplyKeyInputIconsArray } from './verifyApplyKeyInputFieldsData';

interface VerifyApplyKeyTopBarInterface {
    isLoading: { [key: string]: boolean };
    inputDetails: VerifyApplyKeyInterface;
    inputOnChangeHandler: (value: string, fieldName?: string) => void;
    validateDetailsHandler: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, type: string) => void;
}

export const VerifyApplyKeyInput = ({
    isLoading,
    inputDetails,
    inputOnChangeHandler,
    validateDetailsHandler,
}: VerifyApplyKeyTopBarInterface) => {
    return (
        <div className={`${global.moduleWrapper} ${styles.createKeyDetailsModule}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    <MultipleSearchBox
                        details={inputDetails}
                        inputContent={
                            <form className={`${styles.form}`}>
                                <InputFields
                                    inputFields={verifyApplyKeyInputFields}
                                    inputDetails={inputDetails}
                                    inputIconsArray={verifyApplyKeyInputIconsArray}
                                    inputOnChangeHandler={inputOnChangeHandler}
                                />
                                <BtnInfo
                                    onClick={(e) => validateDetailsHandler(e, 'verify')}
                                    loading={isLoading.verify}
                                    className={`${styles.btn}`}
                                >
                                    Verify
                                </BtnInfo>
                                {isLoading.disableApplyBtn ? (
                                    <BtnDisabled className={`${styles.btn}`} disabled>
                                        Apply
                                    </BtnDisabled>
                                ) : (
                                    <BtnInfo
                                        onClick={(e) => validateDetailsHandler(e, 'apply')}
                                        loading={isLoading.apply}
                                        className={`${styles.btn}`}
                                    >
                                        Apply
                                    </BtnInfo>
                                )}
                            </form>
                        }
                    />
                </div>
            </div>
        </div>
    );
};
