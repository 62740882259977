import { startCase } from 'lodash';

import { dateTimeUtils } from 'helpers/utilities/dateTime.util';
// import { commonUtils } from 'helpers/utilities/common.util';

import { LatestUsersInterface, LatestUsersTableOptionsInterface } from 'interfaces/user.interface';

export const latestUsersTableData = (
    latestUsersDetails: LatestUsersInterface[] | undefined,
    getSingleUserDetailsHandler: (email: string) => void,
    // getUsageLogsHandler: (email: string) => void,
    showTableOptions: Partial<LatestUsersTableOptionsInterface>,
) => {
    const tableRows = latestUsersDetails?.map((user) => {
        return {
            Email: user.email,
            ...(showTableOptions.created && { Created: dateTimeUtils.getDateTimeFromIso(user.created) || '' }),
            ...(showTableOptions.firstCp2SignIn && {
                'First CP2 SignIn': dateTimeUtils.timeDifferenceRoundUpValue(user.created, user.firstCp2SignIn) || '',
            }),
            ...(showTableOptions.addedFirstActivityButton && {
                'Added First Activity Button':
                    dateTimeUtils.timeDifferenceRoundUpValue(user.firstCp2SignIn, user.addedFirstActivityButton) || '',
            }),
            ...(showTableOptions.firstSlideshowStarted && {
                'First Slideshow Started':
                    dateTimeUtils.timeDifferenceRoundUpValue(user.firstCp2SignIn, user.firstSlideshowStarted) || '',
            }),
            ...(showTableOptions.firstClassSessionStarted && {
                'First Class Session Started':
                    dateTimeUtils.timeDifferenceRoundUpValue(user.firstCp2SignIn, user.firstClassSessionStarted) || '',
            }),
            ...(showTableOptions.planStatus && { 'Plan Status': user.firstCp2SignIn ? user.planStatus : '--' }),
            ...(showTableOptions.lastClassSessionStarted && {
                'Last Class Session Started': dateTimeUtils.getDateTimeFromIso(user.lastClassSessionStarted) || '',
            }),
            ...(showTableOptions.country && { Country: user.country }),
            ...(showTableOptions.leadSource && { 'Lead Source': startCase(user.leadSource) || undefined }),
            ...(showTableOptions.jobRole && { 'Job Role': user.jobRole }),
            ...(showTableOptions.organization && { Organization: user.organization }),
            ...(showTableOptions.firstGoPro && {
                'First Go Pro': dateTimeUtils.getDateTimeFromIso(user.firstGoPro) || '',
            }),
            ...(showTableOptions.lastGoPro && {
                'Last Go Pro': dateTimeUtils.getDateTimeFromIso(user.lastGoPro) || '',
            }),
            // ...(showTableOptions.hasEnteredSlideshow && {
            //     'Has Entered Slideshow': user.firstCp2SignIn
            //         ? commonUtils.booleanToYesNo(user.hasEnteredSlideshow)
            //         : '--',
            // }),
            ...(showTableOptions.savedClassesCount && { 'Saved Classes Count': user.savedClassesCount }),
            ...(showTableOptions.signupOs && { 'Signup OS': user.signupOs }),
            ...(showTableOptions.cpVer && { 'CP Version': user.cpVer }),
        };
    });

    return {
        heading: 'Latest Users',
        rows: tableRows || [],
        cellProps: {
            Email: { getSelectedValueOnClick: getSingleUserDetailsHandler },
            // 'Has Usage Logs': { getSelectedValueOnClick: getUsageLogsHandler, functionArgument: 'Email' },
        },
    };
};
