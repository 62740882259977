import { ReactNode } from 'react';
import { MdClose } from 'react-icons/md';

import Modal from '@mui/material/Modal';

import { BtnInfo } from 'theme/mui/buttons.theme';

import global from 'theme/global/Global.module.scss';
import styles from './CommonModal.template.module.scss';

interface CommonModalInterface {
    isModalOpen: boolean;
    modalCloseHandler: () => void;
    modalContent: ReactNode;
    fixedWidth?: string;
    fixedHeight?: string;
    className?: string;
}

export const CommonModal = ({
    isModalOpen,
    modalCloseHandler,
    modalContent,
    fixedWidth,
    fixedHeight,
    className,
}: CommonModalInterface) => {
    return (
        <Modal
            open={isModalOpen}
            onClose={modalCloseHandler}
            className={`${global.moduleWrapper} ${styles.commonModalTemplate} ${className || ''}`}
        >
            <div
                className={`${global.contentWrapper} ${styles.contentWrapper}`}
                style={{ width: fixedWidth || 'max-content', height: fixedHeight || 'max-content' }}
            >
                <div
                    className={`${styles.content}`}
                    style={{ width: fixedWidth ? '100%' : 'max-content', height: fixedHeight ? '100%' : 'max-content' }}
                >
                    <div
                        className={`${styles.body}`}
                        style={{
                            width: fixedWidth ? '100%' : 'max-content',
                            height: fixedHeight ? '100%' : 'max-content',
                        }}
                    >
                        {modalContent}
                    </div>
                    <BtnInfo onClick={() => modalCloseHandler()} className={`${styles.closeBtn}`}>
                        <MdClose />
                    </BtnInfo>
                </div>
            </div>
        </Modal>
    );
};
