import { FormEvent } from 'react';

import global from 'theme/global/Global.module.scss';
import styles from './PastSessionsTopBar.module.scss';

import { SimpleSearchBox } from 'template/searchBox/simple/SimpleSearchBox.template';

import { TopBarSimpleFormInterface } from 'interfaces/ui/topBar.interface';

interface PastSessionsTopBarInterface {
    isLoading: boolean;
    inputDetails: TopBarSimpleFormInterface;
    inputOnChangeHandler: (value: string, fieldName?: string) => void;
    validateDetails: (arg: FormEvent<HTMLFormElement>) => void;
}

export const PastSessionsTopBar = ({
    isLoading,
    inputDetails,
    inputOnChangeHandler,
    validateDetails,
}: PastSessionsTopBarInterface) => {
    return (
        <div className={`${global.moduleWrapper} ${styles.topBarModule}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    <form onSubmit={(e) => validateDetails(e)} className={`${styles.form}`}>
                        <SimpleSearchBox
                            isLoading={isLoading}
                            details={inputDetails}
                            inputOnChangeHandler={inputOnChangeHandler}
                            placeholder="Enter user email"
                            submitBtnText="Get sessions"
                        />
                    </form>
                </div>
            </div>
        </div>
    );
};
