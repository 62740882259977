import { dateTimeUtils } from 'helpers/utilities/dateTime.util';
import { commonUtils } from 'helpers/utilities/common.util';
import { keyHelper } from 'helpers/key.helper';

import { KeyInterface } from 'interfaces/key.interface';
import { DATA_CONSTANT } from 'constants/data/data.constants';

export const viewEditKeysTableData = (
    keyDetails: KeyInterface[],
    getSingleKeyDetailsHandler: (key: string) => void,
) => {
    const heading = `View Edit Key(s) - ${keyDetails[0].type}`;

    const calculateActivatedToMaxRatio = (item: KeyInterface) => {
        const activatedUsers = item.activatedUsers?.length || 0;
        const numberOfActivations = item.numberOfActivations || 0;
        const maxActivations = item.maxActivations || 0;

        const totalActivated = activatedUsers + numberOfActivations;
        return `${totalActivated}/${maxActivations}`;
    };

    const allKeyCategories = keyHelper.getAllCategories();

    const tableCellProps = {
        Key: { getSelectedValueOnClick: getSingleKeyDetailsHandler },
    };

    if (keyDetails[0].type === DATA_CONSTANT.PLAN_TYPE.ORGANIZATION) {
        return {
            heading,
            rows: keyDetails.map((item) => {
                return {
                    Key: item.key,
                    IsTrial: commonUtils.booleanToYesNo(item.isTrial),
                    ValidTo: dateTimeUtils.getDateTimeFromIso(item.validTo),
                    PlanExpiryDate: dateTimeUtils.getDateTimeFromIso(item.planExpiryDate),
                    Organization: item.organization,
                    'Activated/Max': calculateActivatedToMaxRatio(item),
                    Category: allKeyCategories.find((data) => data.value === item.category)?.label,
                    Note: item.note,
                };
            }),
            cellProps: tableCellProps,
        };
    } else {
        return {
            heading,
            rows: keyDetails.map((item) => {
                return {
                    Key: item.key,
                    IsTrial: commonUtils.booleanToYesNo(item.isTrial),
                    ValidTo: dateTimeUtils.getDateTimeFromIso(item.validTo),
                    Duration: item.duration,
                    'Activated/Max': calculateActivatedToMaxRatio(item),
                    Category: allKeyCategories.find((data) => data.value === item.category)?.label,
                    Note: item.note,
                };
            }),
            cellProps: tableCellProps,
        };
    }
};
