import { CreateKeyCategoryInterface } from 'interfaces/key.interface';

export const createKeyLS = {
    setCreateKeyCategory: (value: CreateKeyCategoryInterface) => {
        localStorage.setItem('createKeyCategory', JSON.stringify(value));
    },

    getCreateKeyCategory: (): CreateKeyCategoryInterface | null => {
        return JSON.parse(localStorage.getItem('createKeyCategory')!);
    },
};
