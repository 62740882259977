import { styled, Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

// ========== Buttons ==========
export const IconBtn = styled(Button)({
    color: 'var(--clr_bg_info_dark)',

    minWidth: 0,
    padding: 0,
    background: 'var(--clr_bg_info_extremeLight)',
    border: 'none',

    '&:hover': {
        color: 'var(--clr_text_secondary)',

        background: 'var(--clr_bg_info)',
        border: 'none',

        svg: {
            transition: 'none',
        },
    },
});

export const BtnInfo = styled(LoadingButton)({
    letterSpacing: 0.5,
    color: 'var(--clr_text_secondary)',

    minWidth: 0,
    padding: 0,
    background: 'var(--clr_bg_info)',
    border: 'none',
    textTransform: 'none',

    '&:hover': {
        background: 'var(--clr_bg_info_dark)',
        border: 'none',
    },

    '.MuiLoadingButton-loadingIndicator': {
        color: 'var(--clr_text_secondary)',
    },
});

export const BtnSuccess = styled(LoadingButton)({
    letterSpacing: 0.5,
    color: 'var(--clr_text_secondary)',

    minWidth: 0,
    padding: 0,
    background: 'var(--clr_bg_success)',
    border: 'none',
    textTransform: 'none',

    '&:hover': {
        background: 'var(--clr_bg_success_dark)',
        border: 'none',
    },

    '.MuiLoadingButton-loadingIndicator': {
        color: 'var(--clr_text_secondary)',
    },
});

export const BtnWarnHighlight = styled(LoadingButton)({
    letterSpacing: 0.5,
    color: 'var(--clr_text_secondary)',

    minWidth: 0,
    padding: 0,
    background: 'var(--clr_text_warn)',
    border: 'none',
    textTransform: 'none',

    '&:hover': {
        background: 'var(--clr_text_warn_dark)',
        border: 'none',
    },

    '.MuiLoadingButton-loadingIndicator': {
        color: 'var(--clr_text_secondary)',
    },
});

export const BtnError = styled(LoadingButton)({
    letterSpacing: 0.5,
    color: 'var(--clr_text_error_light)',

    minWidth: 0,
    padding: 0,
    background: 'var(--clr_bg_error_extraLight)',
    border: 'none',
    textTransform: 'none',

    '&:hover': {
        color: 'var(--clr_text_secondary)',

        background: 'var(--clr_bg_error_light)',
        border: 'none',
    },

    '.MuiLoadingButton-loadingIndicator': {
        color: 'var(--clr_bg_error)',
    },
});

export const BtnErrorHighlight = styled(LoadingButton)({
    letterSpacing: 0.5,
    color: 'var(--clr_text_secondary)',

    minWidth: 0,
    padding: 0,
    background: 'var(--clr_bg_error_light)',
    border: 'none',
    textTransform: 'none',

    '&:hover': {
        background: 'var(--clr_bg_error)',
        border: 'none',
    },

    '.MuiLoadingButton-loadingIndicator': {
        color: 'var(--clr_text_secondary)',
    },
});

export const BtnNeutral = styled(LoadingButton)({
    letterSpacing: 0.5,
    color: 'var(--clr_text_primary)',

    minWidth: 0,
    padding: 0,
    background: 'var(--clr_bg_neutral_light)',
    border: 'none',
    textTransform: 'none',

    '&:hover': {
        color: 'var(--clr_text_secondary)',

        background: 'var(--clr_bg_info)',
        border: 'none',
    },

    '.MuiLoadingButton-loadingIndicator': {
        color: 'var(--clr_text_secondary)',
    },
});

export const BtnDisabled = styled(LoadingButton)({
    letterSpacing: 0.5,
    color: 'var(--clr_text_neutral_dark) !important',

    minWidth: 0,
    padding: 0,
    background: 'var(--clr_bg_neutral_light)',
    border: 'none',
    textTransform: 'none',

    '&:hover': {
        border: 'none',
    },
});

export const BtnList = styled(LoadingButton)({
    letterSpacing: 0.5,
    color: 'var(--clr_text_primary)',

    width: '100%',
    height: '100%',
    border: 'none',
    borderRadius: 0,
    textTransform: 'none',

    '&:hover': {
        background: 'var(--clr_bg_neutral_light)',
        border: 'none',
    },

    '.MuiLoadingButton-loadingIndicator': {
        color: 'var(--clr_text_primary)',
    },
});
