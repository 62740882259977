import { ReactNode } from 'react';

import global from 'theme/global/Global.module.scss';
import styles from './DefaultLayout.template.module.scss';

import { useWindowDimensions } from 'helpers/customHooks/useWindowDimensions.hook';

import { MobileTopBar } from 'components/common/mobileTopBar/MobileTopBar';

interface DefaultLayoutInterface {
    content?: ReactNode;
    noPadding?: boolean;
    className?: string;
}

export const DefaultLayout = ({ content, noPadding, className }: DefaultLayoutInterface) => {
    const windowWidth = useWindowDimensions().width;

    return (
        <div className={`${global.componentWrapper} ${styles.topBarLayoutTemplate} ${className || ''}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    {windowWidth < 768 && windowWidth > 0 && (
                        <div className={`${styles.mobileNav}`}>
                            <MobileTopBar />
                        </div>
                    )}

                    <div className={`${styles.mainContainer}`}>
                        {content && (
                            <div className={`${styles.bodyContainer} ${noPadding && styles.noPadding}`}>{content}</div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
