import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Collapse } from '@mui/material';
import List from '@mui/material/List';

import global from 'theme/global/Global.module.scss';
import styles from './Navigation.module.scss';

import { ExpandableListItem, ListItem } from 'template/sidebarListItem/ListItem.template';

import { ADMIN_ACTIONS } from 'context/features/adminSlice.redux';
import { useAppDispatch, useAppSelector } from 'helpers/customHooks/useRedux.hook';
import { sideBarUtils } from 'helpers/utilities/sideBar.util';

import { Avatar } from 'components/common/avatar/Avatar';
import { navigationData } from './NavigationData';

export const Navigation = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const adminStore = useAppSelector((state) => state).admin;
    const currentUrl = useLocation().pathname;

    const [activeTopLevel, setActiveTopLevel] = useState('');
    const [activeSubLevel, setActiveSubLevel] = useState('');
    const [isExpanded, setIsExpanded] = useState(sideBarUtils.getAllSubLevelId());

    const mainLevelClickHandler = (navItem: string, hierarchy: string) => {
        setIsExpanded((prevState) => ({ ...prevState, [navItem]: !prevState[navItem] }));

        if (navItem === 'logout') return dispatch(ADMIN_ACTIONS.LOGOUT_ADMIN());
        if (activeTopLevel !== navItem && hierarchy === 'singleLevel') {
            setActiveTopLevel(navItem);
            setActiveSubLevel('');
        }
    };

    const subLevelClickHandler = (mainLevel: string, navItem: string) => {
        activeTopLevel !== mainLevel && mainLevel !== 'logout' && setActiveTopLevel(mainLevel);
        activeSubLevel !== navItem && setActiveSubLevel(navItem);
        navigate(`/${mainLevel}/${navItem}`);
    };

    useEffect(() => {
        const urlParamArray = currentUrl.split('/');
        setIsExpanded((prevState) => ({ ...prevState, [urlParamArray[1]]: true }));
        setActiveTopLevel(urlParamArray[1]);
        setActiveSubLevel(urlParamArray[2]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUrl]);

    return (
        <div className={`${global.moduleWrapper} ${styles.navigation} `}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    <div className={`${styles.profileContainer}`}>
                        <div className={`${styles.avatarContainer}`}>
                            <Avatar
                                url={adminStore.adminAvatar}
                                text={adminStore.userDisplayName || ''}
                                globalFontSize="head5"
                            />
                        </div>
                        <div className={`${styles.detailsContainer}`}>
                            <h6 className={`${global.head6} ${styles.displayName}`}>{adminStore.userDisplayName}</h6>
                            <p className={`${global.bodySmall} ${styles.accessLevel}`}>{adminStore.displayAdminRole}</p>
                            <p className={`${global.bodySmall} ${styles.email}`}>{adminStore.email}</p>
                        </div>
                    </div>

                    <List className={`${styles.navContainer}`} disablePadding>
                        {navigationData.map((navGroup, index) => (
                            <div key={index} className={`${styles.navGroup}`}>
                                {navGroup.map((mainLevelItem) =>
                                    mainLevelItem.subLevelLinks.length > 0 ? (
                                        <div className={`${styles.mainLevelItemContainer}`} key={mainLevelItem.active}>
                                            <ExpandableListItem
                                                isOpen={isExpanded[mainLevelItem.active]}
                                                onClickHandler={() =>
                                                    mainLevelClickHandler(mainLevelItem.active, 'multiLevel')
                                                }
                                                className={`${styles.mainLevelItem} ${styles.expandableItem} ${
                                                    activeTopLevel === mainLevelItem.active && styles.active
                                                }`}
                                            >
                                                <div className={`${styles.iconContainer}`}>{mainLevelItem.icon}</div>
                                                <p className={`${global.bodyLarge} ${styles.title}`}>
                                                    {mainLevelItem.content}
                                                </p>
                                            </ExpandableListItem>
                                            <Collapse
                                                in={isExpanded[mainLevelItem.active]}
                                                timeout="auto"
                                                unmountOnExit
                                                className={`${styles.subMenuContainer}`}
                                            >
                                                <List disablePadding>
                                                    {mainLevelItem.subLevelLinks.length > 0 &&
                                                        mainLevelItem.subLevelLinks.map((subLevelItem) => (
                                                            <ListItem
                                                                key={subLevelItem.address}
                                                                onClickHandler={() =>
                                                                    subLevelClickHandler(
                                                                        mainLevelItem.active,
                                                                        subLevelItem.address,
                                                                    )
                                                                }
                                                                className={`${styles.subLevelItem} ${
                                                                    activeSubLevel === subLevelItem.address &&
                                                                    activeTopLevel === mainLevelItem.active &&
                                                                    styles.active
                                                                }`}
                                                            >
                                                                <p className={`${global.body} ${styles.title}`}>
                                                                    {subLevelItem.content}
                                                                </p>
                                                            </ListItem>
                                                        ))}
                                                </List>
                                            </Collapse>
                                        </div>
                                    ) : (
                                        <div key={mainLevelItem.active} className={`${styles.mainLevelItemContainer}`}>
                                            <ListItem
                                                onClickHandler={() =>
                                                    mainLevelClickHandler(mainLevelItem.active, 'singleLevel')
                                                }
                                                className={`${styles.mainLevelItem} ${
                                                    activeTopLevel === mainLevelItem.active &&
                                                    mainLevelItem.active !== 'Logout' &&
                                                    styles.active
                                                }`}
                                            >
                                                <div className={`${styles.iconContainer}`}>{mainLevelItem.icon}</div>
                                                <p className={`${global.bodyLarge} ${styles.title}`}>
                                                    {mainLevelItem.content}
                                                </p>
                                            </ListItem>
                                        </div>
                                    ),
                                )}
                            </div>
                        ))}
                    </List>
                </div>
            </div>
        </div>
    );
};
