export const webinarDefinition = [
    {
        highlight: 'Total Emails : ',
        details: 'Total number of emails passed to analyze',
    },
    {
        highlight: 'Old User Basic : ',
        details: 'Emails who were Basic users before the webinar, and are still on Basic',
    },
    {
        highlight: 'Old User Pro : ',
        details: 'Emails who were already Pro users before the webinar',
    },
    {
        highlight: 'Old User Basic To Pro : ',
        details: 'Emails who were on Basic before the webinar, but converted to Pro after the webinar',
    },
    {
        highlight: 'New User Basic : ',
        details: 'Emails who signed up as Basic user after the webinar',
    },
    {
        highlight: 'New User Pro : ',
        details: 'Emails who signed up after the webinar and converted to Pro',
    },
    {
        highlight: 'Not User : ',
        details: 'Emails who are still not user',
    },
];
