import { dateTimeUtils } from './utilities/dateTime.util';

import { DATA_CONSTANT } from 'constants/data/data.constants';

export const classPointCertifiedHelper = {
    statusGenerator: (startedDate: string, expiryDate: string) => {
        const CCT_STATUS = DATA_CONSTANT.OPTIONS.FILTER_CCT;

        const timeDifferenceFromStarted = dateTimeUtils.timeDifferenceInGivenUnit(startedDate, new Date(), 'day');
        const timeDifferenceFromExpired = dateTimeUtils.timeDifferenceInGivenUnit(new Date(), expiryDate, 'day');

        if (timeDifferenceFromStarted < 0) return 'Invalid';
        else if (timeDifferenceFromStarted <= 30 && timeDifferenceFromStarted >= 0) return CCT_STATUS.NEW;
        else if (timeDifferenceFromExpired <= 30 && timeDifferenceFromExpired >= 0) return CCT_STATUS.EXPIRING;
        else if (timeDifferenceFromExpired < 0) return CCT_STATUS.EXPIRED;
        else return CCT_STATUS.ACTIVE;
    },

    getCertificationStatus: (expiryDate: string | undefined) => {
        if (!expiryDate) return false;
        const now = new Date().getTime();
        return new Date(expiryDate).getTime() > now ? true : false;
    },
};
