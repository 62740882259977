import { cellUtils } from 'helpers/utilities/cell.util';
import { dateTimeUtils } from 'helpers/utilities/dateTime.util';
import { userUtils } from 'helpers/utilities/user.util';
import { commonUtils } from 'helpers/utilities/common.util';
import { UserInterface } from 'interfaces/user.interface';
import { DATA_CONSTANT } from 'constants/data/data.constants';

export const generateUserCells = (user: UserInterface) => {
    const cell = cellUtils.tableCell;
    const userSubscriptionType = userUtils.getUserSubscriptionType(user);

    return {
        email: cell('Email', user.email),
        userId: cell('User ID', user._id),
        isEmailVerified: cell('Is Email Verified?', commonUtils.booleanToYesNo(user.userStats.isEmailVerified)),

        firstName: cell('First Name', user.userProfile.firstName),
        lastName: cell('Last Name', user.userProfile.lastName),
        organization: cell('Organization', user.userProfile.organization),
        jobRole: cell('Job Role', user.userProfile.jobRole),
        subjects: cell('Subjects', user.userProfile.subjects),

        city: cell('City', user.userProfile.city),
        country: cell('Country', user.userProfile.country),
        language: cell('Language', user.userPreferences?.language),

        subscriptionId: cell('Subscription ID', user.userSubscription.subscriptionId),
        planType: cell('Plan Type', user.userSubscription.planType),
        isTrial: cell('On CP2 Trial?', userSubscriptionType === DATA_CONSTANT.PLAN_CATEGORY.TRIAL ? 'Yes' : 'No'),
        hasGonePro: cell('Was on Pro?', commonUtils.booleanToYesNo(user.userSubscription.hasGonePro)),
        isCanceled: cell(
            'Is Canceled?',
            commonUtils.booleanToYesNo(user.userSubscription.isCancelSubscriptionScheduled),
        ),
        planExpiry: cell('Plan Expiry Date', dateTimeUtils.getDateFromIso(user.userSubscription.planExpiryDate)),

        packageInfo: cell(
            'Current Package',
            user.userSubscription.userPackages && user.userSubscription.userPackages.length > 0
                ? `${
                      user.userSubscription.userPackages[user.userSubscription.userPackages.length - 1]._pId.packageName
                  } (${
                      user.userSubscription.userPackages[user.userSubscription.userPackages.length - 1]._pId.packageKey
                  })`
                : undefined,
        ),

        nextBillingDate: cell('Next Billing Date', dateTimeUtils.getDateFromIso(user.userSubscription.nextBillingDate)),
        billingPeriod: cell('Plan', user.userSubscription.billingPeriod),
        currencyCode: cell('Currency', user.userSubscription.currencyCode),
        priceAmount: cell('Amount', user.userSubscription.priceAmount),
        cardLastDigits: cell('Card Last 4 Digits', user.userSubscription.cardLastDigits),

        userCreated: cell('User Created', dateTimeUtils.getDateTimeFromIso(user.userStats.created)),
        firstSignIn: cell('First Sign In', dateTimeUtils.getDateTimeFromIso(user.userStats.firstSignIn)),
        firstCp2SignIn: cell('First CP2 Sign In', dateTimeUtils.getDateTimeFromIso(user.userStats.firstCp2SignIn)),
        addedFirstActivityButton: cell(
            'Added First Activity Button',
            dateTimeUtils.timeDifferenceRoundUpValue(
                user.userStats.firstCp2SignIn,
                user.userStats.addedFirstActivityButton,
            ),
        ),
        firstSlideshowStarted: cell(
            'First Slideshow Started',
            dateTimeUtils.timeDifferenceRoundUpValue(
                user.userStats.firstCp2SignIn,
                user.userStats.firstSlideshowStarted,
            ),
        ),
        firstClassSessionStarted: cell(
            'First Class Session Started',
            dateTimeUtils.timeDifferenceRoundUpValue(
                user.userStats.firstCp2SignIn,
                user.userStats.firstClassSessionStarted,
            ),
        ),
        lastClassSessionStarted: cell(
            'Last Class Session Started',
            dateTimeUtils.getDateTimeFromIso(user.userStats.lastClassSessionStarted),
        ),
        lastSignIn: cell('Last Sign In', dateTimeUtils.getDateTimeFromIso(user.userStats.lastSignIn)),
        lastApiVer: cell('Last Sign In From', user.lastUpdatedApiVer === 2 ? 'CP 2' : 'CP 1'),
        ssoTypes: cell('SSO Type', commonUtils.separateByComma(user.userStats.ssoTypes)),
        cpVer: cell('CP Version', user.userStats.cpVer),
        winVer: cell('Windows Version', user.userStats.winVer),
        officeVer: cell('Office Version', user.userStats.officeVer),
        dotnetVer: cell('.NET Version', user.userStats.dotnetVer),
        signupOs: cell('Signup OS', user.userStats.signupOs),

        currentKey: cell('Current Key', user.userSubscription.key),
        savedKeys: cell(
            'Saved Keys',
            user.userSubscription.keysSaved
                ? user.userSubscription.keysSaved.map((keySaved) => keySaved.key).join(', ')
                : undefined,
        ),
        usedKeys: cell(
            'Used Keys',
            user.userSubscription.keysApplied ? user.userSubscription.keysApplied.join(', ') : undefined,
        ),

        classLimit: cell('Class Limit', user.classLimit || 'Default'),
        savedClassesCount: cell('Saved Classes Count', user.userStats.savedClassesCount),

        canAccessAi: cell('Can Access AI', commonUtils.booleanToYesNo(user.userStats?.canAccessAi)),

        referralDownloads: cell('Referrer', user.userStats.referralDownloads),
        referrer: cell('Referrer', user.userStats.referrer?.email),
        refereesCount: cell('Referees Count', user.userStats.referees?.length),

        interestGamification: cell('Interest (Gamification)', user.userStats.interestsProfile?.gamification),
        interestPresentationTools: cell(
            'Interest (Presentation Tools)',
            user.userStats.interestsProfile?.presentationTools,
        ),
        interestInteractiveQuizzes: cell(
            'Interest (Interactive Quizzes)',
            user.userStats.interestsProfile?.interactiveQuizzes,
        ),
    };
};
