import { ClassSessionDetailsInterface } from 'interfaces/session.interface';

import { generateSessionDetailsCells } from 'data/generateCells/generateSessionDetailsCells.data';

export const singleSessionDetailsGridData = (singleSessionDetails: ClassSessionDetailsInterface) => {
    const cell = generateSessionDetailsCells(singleSessionDetails);

    return {
        heading: 'Session Details',
        rows: [
            [cell.classCode, cell.started, cell.participantsCount],
            [cell.activitiesCount, cell.responsesCount, cell.stars],
        ],
    };
};
