import { useState } from 'react';

import { BtnNeutral, BtnSuccess } from 'theme/mui/buttons.theme';

import global from 'theme/global/Global.module.scss';
import styles from './RenewCctModalContent.module.scss';

import { InputFields } from 'template/inputFields/InputFields.template';

import { dateTimeUtils } from 'helpers/utilities/dateTime.util';
import { commonUtils } from 'helpers/utilities/common.util';
import { classPointCertifiedApi } from 'services/api/classPointCertified.api';

import { renewCctModalInputFieldsData } from './renewCctModalInputFieldsData';

interface RenewCctModalContentInterface {
    modalCloseHandler: () => void;
    email: string;
    expiryDate: string | undefined;
    refreshUserDetails: () => void;
}

export const RenewCctModalContent = ({
    modalCloseHandler,
    email,
    expiryDate,
    refreshUserDetails,
}: RenewCctModalContentInterface) => {
    const [isLoading, setIsLoading] = useState(false);
    const [inputDetails, setInputDetails] = useState({
        renewCctExpiryDate: dateTimeUtils.addOrSubtractTime(
            dateTimeUtils.constructIsoFromLocalTime(expiryDate || new Date()),
            1,
            'year',
        ),
        notifyUser: false,
    });

    const inputOnChangeHandler = (value: string, fieldName?: string) => {
        if (!fieldName) return;
        const finalValue = fieldName === 'notifyUser' ? commonUtils.convertToBoolean(value) : value;
        setInputDetails((prevState) => ({ ...prevState, [fieldName]: finalValue }));
    };

    const editCctHandler = async () => {
        setIsLoading(true);

        const expiryDateIsoFromLocalTime = dateTimeUtils.constructIsoFromLocalTime(inputDetails.renewCctExpiryDate);
        const renewCct = await classPointCertifiedApi.renewCct(
            email,
            expiryDateIsoFromLocalTime,
            !inputDetails.notifyUser,
        );
        setIsLoading(false);

        if (typeof renewCct !== 'string') {
            refreshUserDetails();
            modalCloseHandler();
        }
    };

    return (
        <div className={`${global.moduleWrapper} ${styles.renewCctModalContentModule}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    <div className={`${styles.headContainer}`}>
                        <h4 className={`${global.head4} ${styles.head}`}>Renew CCT</h4>
                    </div>

                    <div className={`${styles.inputFieldContainer}`}>
                        <InputFields
                            inputFields={renewCctModalInputFieldsData}
                            inputDetails={inputDetails}
                            inputOnChangeHandler={inputOnChangeHandler}
                        />
                    </div>

                    <div className={`${styles.btnContainer}`}>
                        <BtnNeutral onClick={() => modalCloseHandler()} className={`${styles.btn}`}>
                            Cancel
                        </BtnNeutral>
                        <BtnSuccess loading={isLoading} onClick={() => editCctHandler()} className={`${styles.btn}`}>
                            Save
                        </BtnSuccess>
                    </div>
                </div>
            </div>
        </div>
    );
};
