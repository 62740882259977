import { FormEvent } from 'react';

import { BtnInfo } from 'theme/mui/buttons.theme';

import global from 'theme/global/Global.module.scss';
import styles from './ApplyKeyTopBar.module.scss';

import { MultipleSearchBox } from 'template/searchBox/multiple/MultipleSearchBox.template';
import { InputFields } from 'template/inputFields/InputFields.template';

import { ApplyKeyFormInterface } from 'interfaces/key.interface';
import { applyKeyInputFieldsData } from './applyKeyInputFieldsData';

interface ApplyKeyTopBarInterface {
    isLoading: boolean;
    inputDetails: ApplyKeyFormInterface;
    inputOnChangeHandler: (value: string, fieldName?: string) => void;
    validateDetails: (arg: FormEvent<HTMLFormElement>) => void;
    successMessage: string;
    setIsCsvModalOpen: (arg: boolean) => void;
}

export const ApplyKeyTopBar = ({
    isLoading,
    inputDetails,
    inputOnChangeHandler,
    validateDetails,
    successMessage,
    setIsCsvModalOpen,
}: ApplyKeyTopBarInterface) => {
    return (
        <div className={`${global.moduleWrapper} ${styles.topBarModule}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    <form onSubmit={(event) => validateDetails(event)} className={`${styles.form}`}>
                        <MultipleSearchBox
                            details={inputDetails}
                            isLoading={isLoading}
                            submitBtnText="Apply key"
                            inputContent={
                                <div className={`${styles.inputFieldContainer}`}>
                                    <InputFields
                                        inputFields={applyKeyInputFieldsData}
                                        inputDetails={inputDetails}
                                        inputOnChangeHandler={inputOnChangeHandler}
                                    />
                                </div>
                            }
                        />
                        {successMessage && <p className={`${styles.success}`}>{successMessage}</p>}
                    </form>
                    <BtnInfo onClick={() => setIsCsvModalOpen(true)} className={`${styles.uploadDataBtn}`}>
                        Upload CSV
                    </BtnInfo>
                </div>
            </div>
        </div>
    );
};
