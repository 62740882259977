import { BtnInfo } from 'theme/mui/buttons.theme';

import global from 'theme/global/Global.module.scss';
import styles from './VerifyApplyKeyDetails.module.scss';

import { DataGrid } from 'template/dataGrid/DataGrid.template';
import { DataTable } from 'template/dataTable/DataTable.template';

import { ActivatedUserApiInterface, KeyInterface } from 'interfaces/key.interface';
import { UserInterface } from 'interfaces/user.interface';

import { verifyApplyKeyUserGridData } from 'data/key/verifyApplyKey/verifyApplyKeyUser.gridData';
import { activatedUsersTableData } from 'data/key/searchKeys/details/activatedUsers.tableData';
import { subscriptionDetailsGridData } from 'data/user/userDetails/details/subscriptionDetails.gridData';
import { subscriptionHistoryTableData } from 'data/user/userDetails/details/subscriptionHistory.tableData';

interface VerifyApplyKeyDetailsInterface {
    isApplyActive: boolean;
    isLoading: { [key: string]: boolean };
    keyDetails: KeyInterface | undefined;
    user: UserInterface;
    getActivatedUsers: () => void;
    activatedUsersDetails: ActivatedUserApiInterface[] | undefined;
}

export const VerifyApplyKeyDetails = ({
    isApplyActive,
    isLoading,
    keyDetails,
    user,
    getActivatedUsers,
    activatedUsersDetails,
}: VerifyApplyKeyDetailsInterface) => {
    const keySummary = keyDetails && verifyApplyKeyUserGridData(keyDetails);
    const activatedUser = activatedUsersTableData(activatedUsersDetails);
    const subscriptionDetails = subscriptionDetailsGridData(user);
    const subscriptionHistory = subscriptionHistoryTableData(user);

    return (
        <div className={`${global.moduleWrapper} ${styles.verifyApplyKeySummaryModule}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    {isApplyActive ? (
                        <div className={`${styles.dataGroup}`}>
                            <DataGrid data={subscriptionDetails} />
                            <DataTable data={subscriptionHistory} />
                        </div>
                    ) : (
                        <div className={`${styles.dataGroup}`}>
                            {keySummary && <DataGrid data={keySummary} />}
                            {keyDetails && (
                                <DataTable
                                    data={activatedUser}
                                    customHeadingElement={
                                        <BtnInfo
                                            loading={isLoading.activatedUsers}
                                            onClick={() => getActivatedUsers()}
                                            className={`${styles.tableBtn} ${global.bodyXs}`}
                                        >
                                            See Users
                                        </BtnInfo>
                                    }
                                />
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
