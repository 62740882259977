import { Outlet } from 'react-router-dom';

import global from 'theme/global/Global.module.scss';
import styles from './Home.page.module.scss';

import { useWindowDimensions } from 'helpers/customHooks/useWindowDimensions.hook';
import { SideBar } from 'components/sideBar/SideBar';

export const HomePage = () => {
    const windowWidth = useWindowDimensions().width;

    return (
        <main className={`${global.pageWrapper} ${styles.homePage}`}>
            <section
                className={`${global.contentWrapper} ${styles.contentWrapper} ${styles.sidebar} ${
                    windowWidth < 768 && styles.mobileView
                } `}
            >
                <div className={`${styles.content}`}>
                    <SideBar />
                </div>
            </section>

            <section
                className={`${global.contentWrapper} ${styles.contentWrapper} ${styles.main} ${
                    windowWidth < 768 && styles.fullWidth
                }`}
            >
                <div className={`${styles.content}`}>
                    <Outlet />
                </div>
            </section>
        </main>
    );
};
