import global from 'theme/global/Global.module.scss';
import styles from './ViewEditTopBar.module.scss';

import { SimpleSearchBox } from 'template/searchBox/simple/SimpleSearchBox.template';

import { ViewEditContentFormInterface } from 'interfaces/content.interface';

interface ViewEditTopBarInterface {
    inputDetails: ViewEditContentFormInterface;
    inputOnChangeHandler: (value: string) => void;
    isLoading: boolean;
    getContentDetails: () => void;
}

export const ViewEditTopBar = ({
    inputDetails,
    inputOnChangeHandler,
    isLoading,
    getContentDetails,
}: ViewEditTopBarInterface) => {
    return (
        <div className={`${global.moduleWrapper} ${styles.topBarModule}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            getContentDetails();
                        }}
                        className={`${styles.form}`}
                    >
                        <SimpleSearchBox
                            details={inputDetails}
                            inputOnChangeHandler={inputOnChangeHandler}
                            isLoading={isLoading}
                            placeholder={`Enter content slug`}
                            submitBtnText="Search content"
                        />
                    </form>
                </div>
            </div>
        </div>
    );
};
