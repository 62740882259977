import { CellInterface } from '../../interfaces/ui/cell.interface';
import { commonUtils } from './common.util';

const emptyTableCell: CellInterface = { label: '', value: '' };

export const cellUtils = {
    emptyTableCell: emptyTableCell,

    tableCell: (label: string, value: any): CellInterface => {
        return { label, value: commonUtils.convertToString(value) };
    },
};
