import { UserInterface } from 'interfaces/user.interface';
import { DATA_CONSTANT } from 'constants/data/data.constants';

export const userUtils = {
    getUserSubscriptionType: (user: UserInterface): string => {
        if (!user.userSubscription.planType) return '--';

        if (user.userSubscription.isPremium) return DATA_CONSTANT.PLAN_CATEGORY.PREMIUM;
        if (user.userSubscription.planType === DATA_CONSTANT.PLAN_TYPE.BASIC) return DATA_CONSTANT.PLAN_CATEGORY.BASIC;
        if (user.userSubscription.isTrial) {
            if (!user.userSubscription.trialStarted) return DATA_CONSTANT.PLAN_CATEGORY.BASIC;
            return DATA_CONSTANT.PLAN_CATEGORY.TRIAL;
        }
        return DATA_CONSTANT.PLAN_CATEGORY.PRO;
    },
};
