import { FormEvent } from 'react';

import global from 'theme/global/Global.module.scss';
import styles from './ViewEditKeysTopBar.module.scss';

import { MultipleSearchBox } from 'template/searchBox/multiple/MultipleSearchBox.template';
import { InputFields } from 'template/inputFields/InputFields.template';

import { ViewEditKeysFormInterface } from 'interfaces/key.interface';

import { multipleSearchInputFieldsData, multipleSearchInputIconsArray } from './multipleSearchInputFieldsData';

interface ViewEditKeysTopBarInterface {
    keyFormDetails: ViewEditKeysFormInterface;
    inputOnChangeHandler: (value: string, fieldName?: string) => void;
    isLoading: boolean;
    validateDetails: (arg: FormEvent<HTMLFormElement>) => void;
}

export const ViewEditKeysTopBar = ({
    keyFormDetails,
    inputOnChangeHandler,
    isLoading,
    validateDetails,
}: ViewEditKeysTopBarInterface) => {
    const inputFields = multipleSearchInputFieldsData(keyFormDetails);

    return (
        <div className={`${global.moduleWrapper} ${styles.topBarModule}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    <form onSubmit={(e) => validateDetails(e)} className={`${styles.form}`}>
                        <MultipleSearchBox
                            details={keyFormDetails}
                            isLoading={isLoading}
                            submitBtnText="Search keys"
                            inputContent={
                                <div className={`${styles.inputFieldContainer}`}>
                                    <InputFields
                                        inputFields={inputFields}
                                        inputDetails={keyFormDetails}
                                        inputIconsArray={multipleSearchInputIconsArray}
                                        inputOnChangeHandler={inputOnChangeHandler}
                                    />
                                </div>
                            }
                        />
                    </form>
                </div>
            </div>
        </div>
    );
};
