import axios from 'axios';

import { logger } from '../logger.service';
import {
    AffiliateInterface,
    CreateAffiliateApiResponseInterface,
    CreateAffiliateInterface,
} from 'interfaces/affiliate.interface';

export const affiliateApi = {
    createAffiliate: async (
        details: CreateAffiliateInterface,
    ): Promise<CreateAffiliateApiResponseInterface | string> => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API}/affiliates`, details);
            return response.data;
        } catch (error: any) {
            logger.error('affiliateApi.createAffiliate() error: ', error.response || error);
            return `Error : ${error.response.data.message}`;
        }
    },

    getAffiliate: async (email: string): Promise<AffiliateInterface | string> => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/affiliates?email=${email}`);
            return response.data;
        } catch (error: any) {
            logger.error('affiliateApi.getAffiliate() error: ', error.response || error);
            return `Error : ${error.response.data.message}`;
        }
    },

    getAllAffiliates: async (): Promise<AffiliateInterface[] | string> => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/affiliates/all`);
            return response.data;
        } catch (error: any) {
            logger.error('affiliateApi.getAllAffiliates() error: ', error.response || error);
            return `Error : ${error.response.data.message}`;
        }
    },
};
