import { useEffect, useRef, useState } from 'react';
import { camelCase } from 'lodash';

import global from 'theme/global/Global.module.scss';
import styles from './CreateKey.module.scss';

import { DefaultLayout } from 'template/defaultLayout/DefaultLayout.template';

import { objectUtils } from 'helpers/utilities/object.util';
import { commonUtils } from 'helpers/utilities/common.util';
import { useAppSelector } from 'helpers/customHooks/useRedux.hook';
import { keyValidations } from 'helpers/validation/key.validation';
import { keyApi } from 'services/api/key.api';
import { CreateKeyInterface } from 'interfaces/key.interface';
import { DATA_CONSTANT } from 'constants/data/data.constants';

import { CreateKeySummary } from './components/createKeySummary/CreateKeySummary';
import { CreateKeyForm } from './components/createKeyForm/CreateKeyForm';
import { InstantAlert } from 'components/common/instantAlert/InstantAlert';

export const CreateKey = () => {
    const adminStore = useAppSelector((state) => state).admin;

    const showNotification = useRef<any>();

    const [isLoading, setIsLoading] = useState(false);
    const [showInstantAlert, setShowInstantAlert] = useState(false);
    const [inputDetails, setInputDetails] = useState<CreateKeyInterface>({
        type: DATA_CONSTANT.PLAN_TYPE.STANDARD,
        createdBy: adminStore.userDisplayName || '',
    });
    const [createdKeyDetails, setCreatedKeyDetails] = useState<CreateKeyInterface[]>([]);

    const inputOnChangeHandler = (value: string, fieldName?: string) => {
        if (!fieldName) return;

        if (fieldName === 'type') {
            return setInputDetails({ type: value, createdBy: adminStore.userDisplayName || '' });
        }

        let processedValue: string | number | boolean | undefined;
        switch (fieldName) {
            case 'isTrial':
            case 'isPremium': {
                processedValue = commonUtils.convertToBoolean(value);
                break;
            }
            case 'maxActivations':
            case 'duration':
            case 'numberOfKeys': {
                if (value) processedValue = parseInt(value);
                else processedValue = '';
                break;
            }
            default: {
                processedValue = value;
                break;
            }
        }

        setInputDetails((prevState) => ({ ...prevState, [fieldName]: processedValue, error: '' }));
    };

    const restructureObject = (finalObject: CreateKeyInterface): any => {
        const postBody = objectUtils.removeProperty(finalObject, ['numberOfKeys', 'error', 'errorField', 'success']);
        const trimmedFormDetails = objectUtils.trimSpace(postBody, ['domains', 'countries']);
        const formDetailsWithoutEmptyFields = objectUtils.removeEmptyField(trimmedFormDetails);
        const formDetailsWithConstructedIsoDateFromLocalTime = objectUtils.constructIsoFromLocalTimeInObject(
            formDetailsWithoutEmptyFields,
            ['validFrom', 'validTo', 'soldDate', 'planExpiryDate'],
        );
        return formDetailsWithConstructedIsoDateFromLocalTime;
    };

    const createKeyHandler = async (keyCount: number, trimmedDetails: any) => {
        let createKey: any;
        if (keyCount === 1) createKey = await keyApi.createKey(trimmedDetails);
        else createKey = await keyApi.createKeyMultiple(trimmedDetails, keyCount);

        if (typeof createKey === 'string') {
            const errorMessage = createKey;
            setInputDetails((prevState) => ({ ...prevState, error: errorMessage }));
        } else {
            if (Array.isArray(createKey) && createKey.length < 1) {
                setInputDetails((prevState) => ({ ...prevState, error: 'Error : Key creation unsuccessful' }));
            } else {
                setInputDetails((prevState) => ({ ...prevState, success: 'Success : Key created successfully' }));
                const createdKeyDetailsBody = restructureObject(inputDetails);
                setCreatedKeyDetails([
                    ...createdKeyDetails,
                    { key: keyCount > 1 ? createKey.toString() : createKey.key, ...createdKeyDetailsBody },
                ]);
            }
        }

        setIsLoading(false);
    };

    const validateDetailsHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        setIsLoading(true);

        const isFormDetailsValid = keyValidations.createKeysFormValidation(inputDetails);
        if (typeof isFormDetailsValid !== 'string') {
            setInputDetails((prevState) => ({
                ...prevState,
                error: `Error : ${isFormDetailsValid.error}`,
                errorField: isFormDetailsValid.errorField,
            }));
            return setIsLoading(false);
        } else {
            setInputDetails((prevState) => ({ ...prevState, error: '' }));

            let finalObject: CreateKeyInterface;
            if (inputDetails.category) finalObject = { ...inputDetails, category: camelCase(inputDetails.category) };
            else finalObject = { ...inputDetails };

            const keyCount = finalObject.numberOfKeys;
            const postBody = restructureObject(finalObject);
            keyCount && createKeyHandler(keyCount, postBody);
        }
    };

    useEffect(() => {
        if (inputDetails.type === DATA_CONSTANT.PLAN_TYPE.STANDARD) {
            setInputDetails((prevState) => ({ ...prevState, isTrial: false }));
        } else setInputDetails((prevState) => ({ ...prevState, isTrial: undefined }));
    }, [inputDetails.type]);

    useEffect(() => {
        if (inputDetails.success) {
            setTimeout(() => setInputDetails((prevState) => ({ ...prevState, success: '' })), 3000);
        }
    }, [inputDetails.success]);

    useEffect(() => {
        clearTimeout(showNotification.current);
        showNotification.current = setTimeout(() => setShowInstantAlert(false), 2000);
        return () => clearTimeout(showNotification.current);
    }, [showInstantAlert]);

    return (
        <div className={`${global.componentWrapper} ${styles.createKeyComponent}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    {showInstantAlert && <InstantAlert type="success" text="Successfully copied" />}
                    <DefaultLayout
                        content={
                            <div className={`${styles.createKeyContent}`}>
                                <CreateKeyForm
                                    isLoading={isLoading}
                                    inputDetails={inputDetails}
                                    inputOnChangeHandler={inputOnChangeHandler}
                                    validateDetailsHandler={validateDetailsHandler}
                                />
                                <CreateKeySummary
                                    createdKeyDetails={createdKeyDetails}
                                    setShowInstantAlert={setShowInstantAlert}
                                />
                            </div>
                        }
                    />
                </div>
            </div>
        </div>
    );
};
