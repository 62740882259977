import { objectUtils } from 'helpers/utilities/object.util';
import { commonUtils } from 'helpers/utilities/common.util';

import { ViewEditKeysFormInterface, CreateKeyInterface, VerifyApplyKeyInterface } from 'interfaces/key.interface';
import { ValidationErrorInterface } from 'interfaces/validation.interface';
import { DATA_CONSTANT } from 'constants/data/data.constants';

export const keyValidations = {
    searchKeysFormValidation: (keys: string): string => {
        if (!keys.trim()) return 'Error : Key can not be empty';
        else return '';
    },

    viewEditKeysFormValidation: (keyFormDetails: ViewEditKeysFormInterface): string => {
        if (!keyFormDetails.type?.trim()) return 'Error : Please choose a type';
        else return '';
    },

    createKeysFormValidation: (inputDetails: CreateKeyInterface): ValidationErrorInterface | string => {
        let errorObj = { errorField: '', error: '' };

        if (!inputDetails.market?.trim()) {
            errorObj = objectUtils.updateObjectKeyValue(errorObj, {
                errorField: 'market',
                error: 'Please choose a market',
            });
        }
        if (!inputDetails.category?.trim()) {
            errorObj = objectUtils.updateObjectKeyValue(errorObj, {
                errorField: 'category',
                error: 'Please choose a category',
            });
        }
        if (
            inputDetails.type.trim() === DATA_CONSTANT.PLAN_TYPE.STANDARD &&
            !inputDetails.duration?.toString().trim()
        ) {
            errorObj = objectUtils.updateObjectKeyValue(errorObj, {
                errorField: 'duration',
                error: 'Please provide a duration',
            });
        }
        if (inputDetails.type.trim() === DATA_CONSTANT.PLAN_TYPE.ORGANIZATION) {
            if (inputDetails.isTrial === undefined) {
                errorObj = objectUtils.updateObjectKeyValue(errorObj, {
                    errorField: 'isTrial',
                    error: 'Please choose a trial type',
                });
            }
            if (!inputDetails.planExpiryDate?.trim()) {
                errorObj = objectUtils.updateObjectKeyValue(errorObj, {
                    errorField: 'planExpiryDate',
                    error: 'Please provide an expiry date',
                });
            }
            if (inputDetails.planExpiryDate?.trim().slice(0, 10) === '0000-00-00') {
                errorObj = objectUtils.updateObjectKeyValue(errorObj, {
                    errorField: 'planExpiryDate',
                    error: 'Invalid plan expiry date',
                });
            }
            if (!inputDetails.organization?.trim()) {
                errorObj = objectUtils.updateObjectKeyValue(errorObj, {
                    errorField: 'organization',
                    error: 'Please provide organization name',
                });
            }
        }
        if (!inputDetails.numberOfKeys?.toString().trim() || inputDetails.numberOfKeys < 1) {
            errorObj = objectUtils.updateObjectKeyValue(errorObj, {
                errorField: 'numberOfKeys',
                error: 'Please enter number of keys',
            });
        }
        if (inputDetails.validFrom?.trim().slice(0, 10) === '0000-00-00') {
            errorObj = objectUtils.updateObjectKeyValue(errorObj, {
                errorField: 'validFrom',
                error: 'Invalid valid from date',
            });
        }
        if (inputDetails.validTo?.trim().slice(0, 10) === '0000-00-00') {
            errorObj = objectUtils.updateObjectKeyValue(errorObj, {
                errorField: 'validTo',
                error: 'Invalid valid to date',
            });
        }
        if (inputDetails.soldDate?.trim().slice(0, 10) === '0000-00-00') {
            errorObj = objectUtils.updateObjectKeyValue(errorObj, {
                errorField: 'soldDate',
                error: 'Invalid sold date',
            });
        }

        if (errorObj.error) return errorObj;
        else return '';
    },

    verifyApplyKeyFormValidation: (keyFormDetails: VerifyApplyKeyInterface): string => {
        if (!keyFormDetails.key?.trim()) return 'Error : Please enter a valid key';
        else return '';
    },

    applyKeyFormValidation: (inputDetails: string): string[] | string => {
        let error = '';

        if (!inputDetails.trim()) {
            error = 'Email can not be empty';
            return error;
        } else {
            // Note : Having "<" or ">" sign in email will cause error in the .match method
            const stringWithoutExceptionSymbols = commonUtils.removeCharactersFromString(inputDetails.trim() || '', [
                '<',
                '>',
            ]);
            const emailArray = commonUtils.trimCommaAndSpaceSeparatedString(stringWithoutExceptionSymbols);
            let invalidEmails = [];
            for (let email of emailArray) {
                if (!email.trim().match(DATA_CONSTANT.REGEX.EMAIL)) invalidEmails.push(email.trim());
            }
            return invalidEmails;
        }
    },
};
