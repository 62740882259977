import { BiNote } from 'react-icons/bi';
import { HiOutlineUserPlus } from 'react-icons/hi2';
import { IoMdGlobe } from 'react-icons/io';
import { TbStack } from 'react-icons/tb';

import styles from './EditKeyModalContent.module.scss';

import { KeyInterface } from 'interfaces/key.interface';
import { InputFieldsInterface } from 'interfaces/ui/inputFields.interface';

export const editKeyModalInputFieldsData = (
    isOrganization: boolean,
    keyDetails: KeyInterface,
): InputFieldsInterface[] => {
    if (isOrganization) {
        return [
            {
                fieldName: 'validFrom',
                fieldType: 'date',
                maxDateTime: keyDetails.validTo,
                fieldClassName: `${styles.inputField}`,
            },
            {
                fieldName: 'validTo',
                fieldType: 'date',
                minDateTime: keyDetails.validFrom,
                fieldClassName: `${styles.inputField}`,
            },
            {
                fieldName: 'planExpiryDate',
                fieldType: 'date',
                minDateTime: keyDetails.validTo,
                fieldClassName: `${styles.inputField}`,
            },
            {
                fieldName: 'isPremium',
                fieldType: 'selectBoolean',
                fieldClassName: `${styles.inputField}`,
            },
            {
                fieldName: 'countries',
                fieldClassName: `${styles.inputField}`,
            },
            {
                fieldName: 'maxActivations',
                inputType: 'number',
                fieldClassName: `${styles.inputField}`,
            },
            {
                fieldName: 'domains',
                fieldClassName: `${styles.inputField}`,
            },
            {
                fieldName: 'note',
                fieldClassName: `${styles.inputField}`,
            },
            {
                fieldName: 'organization',
                fieldClassName: `${styles.inputField}`,
            },
        ];
    } else {
        return [
            {
                fieldName: 'validFrom',
                fieldType: 'date',
                fieldClassName: `${styles.inputField}`,
            },
            {
                fieldName: 'validTo',
                fieldType: 'date',
                minDateTime: keyDetails.validFrom,
                fieldClassName: `${styles.inputField}`,
            },
            {
                fieldName: 'soldDate',
                fieldType: 'date',
                minDateTime: keyDetails.validFrom,
                fieldClassName: `${styles.inputField}`,
            },
            {
                fieldName: 'countries',
                fieldClassName: `${styles.inputField}`,
            },
            {
                fieldName: 'maxActivations',
                inputType: 'number',
                fieldClassName: `${styles.inputField}`,
            },
            {
                fieldName: 'domains',
                fieldClassName: `${styles.inputField}`,
            },
            {
                fieldName: 'note',
                fieldClassName: `${styles.inputField}`,
            },
        ];
    }
};

export const editKeyInputIconArray = {
    countries: <IoMdGlobe />,
    note: <BiNote />,
    createdBy: <HiOutlineUserPlus />,
    type: <TbStack />,
};
