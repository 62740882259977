import { commonUtils } from 'helpers/utilities/common.util';
import { dateTimeUtils } from 'helpers/utilities/dateTime.util';

import { CheckUsersInterface } from 'interfaces/user.interface';

export const searchUsersTableData = (
    checkUsersDetails: CheckUsersInterface[] | undefined,
    getSingleUserDetailsHandler: (email: string) => void,
    tableHeading?: string,
) => {
    const tableRows = checkUsersDetails?.map((user) => {
        return {
            Email: user.email,
            'First Cp2 SignIn': dateTimeUtils.getDateTimeFromIso(user.firstCp2SignIn),
            'Plan Status': user.planType,
            Country: user.country,
            'Is Trial': commonUtils.booleanToYesNo(user.isTrial),
        };
    });

    return {
        heading: tableHeading || 'Search User(s)',
        rows: tableRows || [],
        cellProps: {
            Email: { getSelectedValueOnClick: getSingleUserDetailsHandler },
        },
    };
};
