export const CREATE_KEY_COUNTRY_CONSTANT = [
    { value: 'singapore' },
    { value: 'thailand' },
    { value: 'taiwan' },
    { value: 'indonesia' },
    { value: 'philippines' },
    { value: 'vietnam' },
    { value: 'malaysia' },
    {
        value: 'saudiArabia',
        label: 'Saudi Arabia',
    },
    { value: 'qatar' },
    { value: 'oman' },
    {
        value: 'usa',
        label: 'USA',
    },
    {
        value: 'uk',
        label: 'UK',
    },
];

export const CREATE_AFFILIATE_COUNTRY_CONSTANT = [
    {
        value: 'DZ',
        label: 'Algeria',
    },
    {
        value: 'AU',
        label: 'Australia',
    },
    {
        value: 'BH',
        label: 'Bahrain',
    },
    {
        value: 'EG',
        label: 'Egypt',
    },
    {
        value: 'ID',
        label: 'Indonesia',
    },
    {
        value: 'IQ',
        label: 'Iraq',
    },
    {
        value: 'JO',
        label: 'Jordan',
    },
    {
        value: 'KW',
        label: 'Kuwait',
    },
    {
        value: 'LB',
        label: 'Lebanon',
    },
    {
        value: 'LY',
        label: 'Libya',
    },
    {
        value: 'MY',
        label: 'Malaysia',
    },
    {
        value: 'MR',
        label: 'Mauritania',
    },
    {
        value: 'MA',
        label: 'Morocco',
    },
    {
        value: 'OM',
        label: 'Oman',
    },
    {
        value: 'PS',
        label: 'Palestine',
    },
    {
        value: 'PH',
        label: 'Philippines',
    },
    {
        value: 'QA',
        label: 'Qatar',
    },
    {
        value: 'SA',
        label: 'Saudi Arabia',
    },
    {
        value: 'SG',
        label: 'Singapore',
    },
    {
        value: 'SD',
        label: 'Sudan',
    },
    {
        value: 'SY',
        label: 'Syria',
    },
    {
        value: 'TW',
        label: 'Taiwan',
    },
    {
        value: 'TH',
        label: 'Thailand',
    },
    {
        value: 'TN',
        label: 'Tunisia',
    },
    {
        value: 'AE',
        label: 'United Arab Emirates',
    },
    {
        value: 'GB',
        label: 'United Kingdom',
    },
    {
        value: 'US',
        label: 'United States',
    },
    {
        value: 'VN',
        label: 'Vietnam',
    },
    {
        value: 'YE',
        label: 'Yemen',
    },
];
