import { FormEvent, useEffect, useRef, useState } from 'react';
import parse from 'html-react-parser';

import global from 'theme/global/Global.module.scss';
import styles from './UploadFile.module.scss';

import { MultipleSearchBox } from 'template/searchBox/multiple/MultipleSearchBox.template';
import { InputFields } from 'template/inputFields/InputFields.template';

import { awsS3StorageApi } from 'services/api/awsS3Storage.api';

import { InstantAlert } from 'components/common/instantAlert/InstantAlert';

export const UploadFile = () => {
    const showNotification = useRef<any>();

    const [isLoading, setIsLoading] = useState(false);
    const [showInstantAlert, setShowInstantAlert] = useState<'success' | 'error' | ''>('');
    const [fileDetails, setFileDetails] = useState<{ file: File | null; fileUrl: string; error: '' }[]>([]);
    const [details, setDetails] = useState({
        fileUrl: '',
        error: '',
        fileName: '',
    });

    const fileInputOnChangeHandler = (file: any) => {
        const fileInfo = file && [...file];
        if (fileInfo.length < 1) return;
        const fileArray = fileInfo.map((file: any, index: number) => ({ id: index, file, fileUrl: '', error: '' }));
        setFileDetails(fileArray);
    };

    const removeFileSelectionHandler = () => {
        setFileDetails([]);
    };

    const fileUploadHandler = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (fileDetails.length < 1) return;

        setIsLoading(true);
        const newFileDetails: any = [];

        const newDate = new Date();
        const yearMonthDate = `${newDate.getFullYear()}${newDate.getMonth() + 1}${newDate.getDate()}`;
        const timeFormat = `${newDate.getHours()}${newDate.getMinutes()}${newDate.getSeconds()}${newDate.getMilliseconds()}`;

        for (let item of fileDetails) {
            if (!item.file) return;
            const objectKey = `file/${yearMonthDate}/${timeFormat}/${item.file.name}`;
            const response = await awsS3StorageApi.uploadToStorage(item.file, objectKey);
            if (!response) {
                newFileDetails.push({ ...item, fileUrl: '', error: `File upload failed (${item.file.name})` });
            }
            newFileDetails.push({ ...item, fileUrl: response, error: '' });
        }

        setFileDetails(newFileDetails);
        setIsLoading(false);
    };

    const copyLinkValue = () => {
        const fileUrlWithoutBreakTag = details.fileUrl.replaceAll(/<br\/>/g, ', ');
        navigator.clipboard.writeText(fileUrlWithoutBreakTag);
        setShowInstantAlert('success');
    };

    useEffect(() => {
        let fileUrl = '';
        let error = '';
        let fileName = '';

        fileDetails.forEach((file, index) => {
            if (fileUrl) fileUrl = file.fileUrl && `${fileUrl}<br/>${file.fileUrl}`;
            else fileUrl = file.fileUrl;
            error = file.error && `${error}<br/>${file.error}`;

            if (fileDetails.length === 1) fileName = file.file?.name || '';
            else {
                if (index === 0) fileName = `${file.file?.name || ''} + ${fileDetails.length - 1} other`;
            }
        });

        setDetails({ error, fileUrl, fileName });
    }, [fileDetails]);

    useEffect(() => {
        clearTimeout(showNotification.current);
        showNotification.current = setTimeout(() => setShowInstantAlert(''), 2000);
        return () => clearTimeout(showNotification.current);
    }, [showInstantAlert]);

    return (
        <div className={`${global.componentWrapper} ${styles.uploadContentComponent}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    {showInstantAlert && <InstantAlert type="success" text={'Successfully copied'} />}

                    <form onSubmit={(e) => fileUploadHandler(e)} className={`${styles.form}`}>
                        <MultipleSearchBox
                            isLoading={isLoading}
                            details={fileDetails}
                            submitBtnText="Upload file"
                            inputContent={
                                <div className={`${styles.inputFieldContainer}`}>
                                    <InputFields
                                        inputFields={[
                                            {
                                                fieldType: 'file',
                                                fieldName: 'file',
                                                label: 'Upload file',
                                                error: false,
                                                multipleFiles: true,
                                                removeSelection: () => removeFileSelectionHandler(),
                                                fileName: details.fileName,
                                                fieldClassName: `${styles.fileInputField}`,
                                            },
                                        ]}
                                        inputDetails={fileDetails}
                                        fileInputOnChangeHandler={fileInputOnChangeHandler}
                                    />
                                </div>
                            }
                            inputContainerClassName={styles.formFieldsContainer}
                            className={styles.inputWrapper}
                        />

                        {details.fileUrl && (
                            <p onClick={() => copyLinkValue()} className={`${styles.fileUrl}`}>
                                Link : <br /> {parse(details.fileUrl)}
                            </p>
                        )}
                        {details.error && <p className={`${styles.error}`}>Error : {parse(details.error)}</p>}
                    </form>
                </div>
            </div>
        </div>
    );
};
