import { cellUtils } from 'helpers/utilities/cell.util';

import { AffiliateInterface } from 'interfaces/affiliate.interface';

import { generateAffiliateCells } from 'data/generateCells/generateAffiliateCells.data';

export const getAffiliateDetailsGridData = (
    affiliateDetails: AffiliateInterface,
    copyValue: (value: string) => void,
) => {
    const emptyCell = cellUtils.emptyTableCell;
    const cell = generateAffiliateCells(affiliateDetails);

    return {
        heading: 'Affiliate Details',
        rows: [
            [cell.couponCode, cell.countryCode, cell.adminEmail],
            [cell.urlCode, cell.urlWithCode, cell.urlWithCoupon],
            [cell.links, emptyCell, emptyCell],
        ],
        cellProps: {
            'Url With Coupon': { getSelectedValueOnClick: copyValue },
            'Url With Code': { getSelectedValueOnClick: copyValue },
            'Affiliate Profile Link': { getSelectedValueOnClick: copyValue },
        },
    };
};
