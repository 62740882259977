import { AffiliateInterface } from 'interfaces/affiliate.interface';
import { generateAffiliateCells } from 'data/generateCells/generateAffiliateCells.data';

export const getAffiliateSummaryGridData = (affiliateDetails: AffiliateInterface) => {
    const cell = generateAffiliateCells(affiliateDetails);

    return {
        heading: 'Affiliate Overview',
        rows: [
            [cell.name, cell.email],
            [cell.discountPercentage, cell.commission],
        ],
    };
};
