import { useEffect, useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';

import { ThemeProvider } from '@mui/material/styles';

import { muiTheme } from './theme/mui/muiTheme.theme';

import { ADMIN_ACTIONS } from './context/features/adminSlice.redux';
import { useAppDispatch, useAppSelector } from './helpers/customHooks/useRedux.hook';
import { checkExpiryTime, processExpiryTime } from 'services/security/checkAuth.security';
import { storeLS } from 'services/localService/store.local';
import { keyApi } from 'services/api/key.api';
import { createKeyLS } from 'services/localService/createKey.local';

import { DATA_CONSTANT } from './constants/data/data.constants';

import LoginPage from './pages/auth/Login.page';
import { HomePage } from './pages/home/Home.page';

import { CustomError } from 'components/common/error/comingSoon/CustomError';
import { ComingSoon } from './components/common/error/comingSoon/ComingSoon';
import { UserDetails } from './components/home/user/userDetails/UserDetails';
import { SearchUsers } from 'components/home/user/searchUsers/SearchUsers';
import { LatestUsers } from 'components/home/user/latestUsers/LatestUsers';
import { WebinarReport } from 'components/home/user/webinarReport/WebinarReport';
// import { LiveSessionsOld } from 'components/home/sessions/liveSessionsOld/LiveSessionsOld';
import { PastSessions } from 'components/home/sessions/pastSessions/PastSessions';
import { CreateKey } from 'components/home/key/createKey/CreateKey';
import { SearchKeys } from 'components/home/key/searchKeys/SearchKeys';
import { ViewEditKeys } from 'components/home/key/viewEditKeys/ViewEditKeys';
import { ApplyKey } from 'components/home/key/applyKey/ApplyKey';
import { CreateAffiliate } from 'components/home/affiliate/createAffiliate/CreateAffiliate';
import { GetAffiliate } from 'components/home/affiliate/getAffiliate/GetAffiliate';
import { GetAllAffiliates } from 'components/home/affiliate/getAllAffiliates/GetAllAffiliates';
import { Cct } from 'components/home/classPointCertified/cct/Cct';
// import { Csc } from 'components/home/classPointCertified/csc/Csc';
import { CreateContent } from 'components/home/content/create/CreateContent';
import { ViewEditContent } from 'components/home/content/viewEdit/ViewEditContent';
import { SubscribeLink } from 'components/home/misc/subscribeLink/SubscribeLink';
import { AiAccess } from 'components/home/misc/aiAccess/AiAccess';
import { UploadFile } from 'components/home/misc/uploadFile/UploadFile';

import LiveClassesSummary from 'components/home/sessions/liveSessions/LiveClassesSummary';
import LiveClassesRegionView from 'components/home/sessions/liveSessions/LiveClassesRegionView';

export const App = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const store = useAppSelector((state) => state);

    const [readyToRender, setReadyToRender] = useState(false);

    const getMarketAndCategory = async () => {
        const fetchData = await keyApi.getCategoryJSON();
        if (typeof fetchData === 'string') return navigate('/error');
        else createKeyLS.setCreateKeyCategory(fetchData);
    };

    useEffect(() => {
        checkExpiryTime(dispatch);
        getMarketAndCategory();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (store.admin.email) {
            processExpiryTime(dispatch, navigate);
            setTimeout(() => {
                dispatch(ADMIN_ACTIONS.LOGOUT_ADMIN());
            }, DATA_CONSTANT.TIME.AUTO_LOGOUT);
        } else navigate('/login');

        setReadyToRender(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.admin.email]);

    useEffect(() => {
        storeLS.setStoreData(store);
    }, [store]);

    if (readyToRender)
        return (
            <ThemeProvider theme={muiTheme}>
                <Routes>
                    <Route index path="/login" element={<LoginPage />} />
                    <Route path="/" element={<HomePage />}>
                        <Route path="/user/details" element={<UserDetails />} />
                        <Route path="/user/search" element={<SearchUsers />} />
                        <Route path="/user/latest" element={<LatestUsers />} />
                        <Route path="/user/webinar-report" element={<WebinarReport />} />

                        {/* <Route path="/session/live-session" element={<LiveSessionsOld />} /> */}
                        {/* <Route path="/session/live-session" element={<LiveSessionsOld />} /> */}
                        <Route path="/session/live-session" element={<LiveClassesSummary />} />
                        <Route path="/session/live-session/:region" element={<LiveClassesRegionView />} />
                        <Route path="/session/past-session" element={<PastSessions />} />

                        <Route path="/key/create" element={<CreateKey />} />
                        <Route path="/key/search" element={<SearchKeys />} />
                        <Route path="/key/view-edit" element={<ViewEditKeys />} />
                        <Route path="/key/apply" element={<ApplyKey />} />

                        <Route path="/affiliate/create" element={<CreateAffiliate />} />
                        <Route path="/affiliate/get" element={<GetAffiliate />} />
                        <Route path="/affiliate/all" element={<GetAllAffiliates />} />

                        <Route path="/classpoint-certified/cct" element={<Cct />} />
                        {/* <Route path="/classpoint-certified/csc" element={<Csc />} /> */}

                        <Route path="/content/create" element={<CreateContent />} />
                        <Route path="/content/view-edit" element={<ViewEditContent />} />

                        <Route path="/misc/subscribe-link" element={<SubscribeLink />} />
                        <Route path="/misc/ai-access" element={<AiAccess />} />
                        <Route path="/misc/upload-file" element={<UploadFile />} />

                        <Route path="error" element={<CustomError />}></Route>
                        <Route path="*" element={<ComingSoon />}></Route>
                    </Route>
                </Routes>
            </ThemeProvider>
        );
    else return <div></div>;
};
