import global from 'theme/global/Global.module.scss';
import styles from './GetAffiliateDetails.module.scss';

import { DataGrid } from 'template/dataGrid/DataGrid.template';
import { DataTable } from 'template/dataTable/DataTable.template';

import { AffiliateInterface } from 'interfaces/affiliate.interface';

import { getAffiliateDetailsGridData } from 'data/affiliate/getAffiliate/details/getAffiliateDetails.gridData';
import { affiliateInvoicesTableData } from 'data/affiliate/getAffiliate/details/affiliateInvoices.tableData';

interface GetAffiliateDetailsInterface {
    affiliateDetails: AffiliateInterface;
    setShowInstantAlert: (arg: boolean) => void;
}

export const GetAffiliateDetails = ({ affiliateDetails, setShowInstantAlert }: GetAffiliateDetailsInterface) => {
    const copyValue = (value: string) => {
        navigator.clipboard.writeText(value);
        setShowInstantAlert(true);
    };

    const affiliateInfo = getAffiliateDetailsGridData(affiliateDetails, copyValue);
    const invoicesTableData = affiliateInvoicesTableData(affiliateDetails.transactions);

    return (
        <div className={`${global.moduleWrapper} ${styles.keyDetailsModule}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    <DataGrid data={affiliateInfo} />
                    <DataTable data={invoicesTableData} />
                </div>
            </div>
        </div>
    );
};
