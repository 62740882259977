import { generateKeyCells } from 'data/generateCells/generateKeyCells.data';
import { KeyInterface } from 'interfaces/key.interface';

export const searchKeysSummaryGridData = (keyDetails: KeyInterface, copyKeyValue: (keyValue: string) => void) => {
    const cell = generateKeyCells(keyDetails);

    return {
        heading: 'Key Overview',
        rows: [
            [cell.key, cell.type],
            [cell.isTrial, cell.validTo],
        ],
        cellProps: {
            Key: { getSelectedValueOnClick: copyKeyValue },
        },
    };
};
