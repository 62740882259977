import { configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';

import { ADMIN_REDUCERS } from '../features/adminSlice.redux';
import { ERROR_REDUCERS } from '../features/errorSlice.redux';
import { UI_REDUCERS } from '../features/uiSlice.redux';

const logger = createLogger();

export const store = configureStore({
    reducer: {
        admin: ADMIN_REDUCERS,
        error: ERROR_REDUCERS,
        ui: UI_REDUCERS,
    },
    middleware: (getDefaultMiddleware) => {
        const isDevelopment = process.env.REACT_APP_ENV === 'develop';
        if (isDevelopment) return getDefaultMiddleware().concat(logger);
        else return getDefaultMiddleware();
    },
});

export type StoreType = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
