import { FormEvent } from 'react';
import { BsPersonCircle } from 'react-icons/bs';

import global from 'theme/global/Global.module.scss';
import styles from './UserTopBar.module.scss';

import { SimpleSearchBox } from 'template/searchBox/simple/SimpleSearchBox.template';

import { TopBarSimpleFormInterface } from 'interfaces/ui/topBar.interface';

interface UserTopBarInterface {
    isLoading: boolean;
    userFormDetails: TopBarSimpleFormInterface;
    inputOnChangeHandler: (arg: string) => void;
    validateDetails: (arg: FormEvent<HTMLFormElement>) => void;
}

export const UserTopBar = ({
    isLoading,
    userFormDetails,
    inputOnChangeHandler,
    validateDetails,
}: UserTopBarInterface) => {
    return (
        <div className={`${global.moduleWrapper} ${styles.topBarModule}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    <form onSubmit={(e) => validateDetails(e)} className={`${styles.form}`}>
                        <SimpleSearchBox
                            details={userFormDetails}
                            inputOnChangeHandler={inputOnChangeHandler}
                            isLoading={isLoading}
                            placeholder="Enter email"
                            maxLength={60}
                            inputIcon={<BsPersonCircle className={`${styles.inputIcon}`} />}
                            submitBtnText="Get user"
                        />
                    </form>
                </div>
            </div>
        </div>
    );
};
