import axios from 'axios';

import { logger } from '../logger.service';

import { CouponInterface } from 'interfaces/misc.interface';

export const couponApi = {
    verifyCoupon: async (couponCode: string, planCode = 'Annual'): Promise<CouponInterface | string> => {
        try {
            const response = await axios.get(
                process.env.REACT_APP_API +
                    `/zoho/subscription/verify-coupon?couponCode=${couponCode}&planCode=${planCode}`,
            );
            return response.data;
        } catch (error: any) {
            logger.error('couponApi.verifyCoupon() error: ', error.response || error);
            return `Error : ${error.response.data.message}`;
        }
    },
};
