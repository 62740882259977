import styles from './Login.module.scss';

import { AdminLoginInterface } from 'interfaces/admin.interface';
import { InputFieldsInterface } from 'interfaces/ui/inputFields.interface';

export const loginInputFieldsData = (loginFormDetails: AdminLoginInterface): InputFieldsInterface[] => {
    return [
        {
            fieldName: 'email',
            error: loginFormDetails.emailInvalid,
            maxLength: 50,
            isRequired: true,
            fieldClassName: styles.inputField,
            variant: 'standard',
        },
        {
            fieldName: 'password',
            error: loginFormDetails.passwordInvalid,
            inputType: loginFormDetails.showPassword ? 'text' : 'password',
            maxLength: 50,
            isRequired: true,
            fieldClassName: styles.password,
            optionClassName: styles.inputField,
            canHideValue: true,
            isValueHidden: loginFormDetails.showPassword,
            hiddenValueFieldName: 'showPassword',
            disableAutoComplete: true,
            variant: 'standard',
        },
    ];
};
