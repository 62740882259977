import { FormEvent, useEffect, useRef, useState } from 'react';

import global from 'theme/global/Global.module.scss';
import styles from './SearchKeys.module.scss';

import { TabLayout } from 'template/tabLayout/TabLayout.template';
import { TopBarLayout } from 'template/topBarLayout/TopBarLayout.template';

import { commonUtils } from 'helpers/utilities/common.util';
import { keyValidations } from 'helpers/validation/key.validation';
import { keyApi } from 'services/api/key.api';
import { ActivatedUserApiInterface, KeyInterface, SearchKeyFormInterface } from 'interfaces/key.interface';

import { SearchKeysTopBar } from './components/searchKeysTopBar/SearchKeysTopBar';
import { SearchKeysSummary } from './components/searchKeysSummary/SearchKeysSummary';
import { SearchKeysDetails } from './components/searchKeysDetails/SearchKeysDetails';
import { JsonDetails } from './components/searchKeysDetails/JsonDetails';
import { ViewEditKeysDetails } from '../viewEditKeys/components/viewEditKeysDetails/ViewEditKeysDetails';
import { InstantAlert } from 'components/common/instantAlert/InstantAlert';

export const SearchKeys = () => {
    const showNotification = useRef<any>();

    const [isGetKeyLoading, setIsGetKeyLoading] = useState(false);
    const [isActivatedUsersLoading, setIsActivatedUsersLoading] = useState(false);
    const [showInstantAlert, setShowInstantAlert] = useState(false);
    const [multipleKeyDetails, setMultipleKeyDetails] = useState<KeyInterface[]>();
    const [singleKeyDetails, setSingleKeyDetails] = useState<KeyInterface>();
    const [activatedUsersDetails, setActivatedUsersDetails] = useState<ActivatedUserApiInterface[]>();
    const [keyFormDetails, setKeyFormDetails] = useState<SearchKeyFormInterface>({ value: '' });

    const inputOnChangeHandler = (value: string) => {
        setKeyFormDetails((prevState) => ({ ...prevState, value: value, error: '' }));
    };

    const getSingleKeyDetails = async (keyValue: string) => {
        setIsGetKeyLoading(true);

        const key = await keyApi.getKey(keyValue.trim());
        setIsGetKeyLoading(false);

        if (typeof key === 'string') setKeyFormDetails((prevState) => ({ ...prevState, error: key }));
        else setSingleKeyDetails(key);
    };

    const getMultipleKeysDetails = async (keyValue: { keys: string }) => {
        setIsGetKeyLoading(true);

        const key = await keyApi.getKeyMultiple(keyValue);
        setIsGetKeyLoading(false);

        if (typeof key === 'string') setKeyFormDetails((prevState) => ({ ...prevState, error: key }));
        else setMultipleKeyDetails(key);
    };

    const determineGetKeyApi = (type?: string) => {
        if (type === 'delete' && (!multipleKeyDetails || multipleKeyDetails.length < 2)) {
            setSingleKeyDetails(undefined);
            setMultipleKeyDetails(undefined);
            return setKeyFormDetails({ value: '' });
        } else {
            const trimmedKey = commonUtils.trimCommaAndSpaceSeparatedString(keyFormDetails.value);
            if (trimmedKey.length > 1) {
                !type && setSingleKeyDetails(undefined);
                getMultipleKeysDetails({ keys: trimmedKey.toString() });
            } else {
                !type && setMultipleKeyDetails(undefined);
                getSingleKeyDetails(trimmedKey.toString());
            }
        }
    };

    const getActivatedUsers = async () => {
        setIsActivatedUsersLoading(true);
        const activatedUsers = await keyApi.activatedUsers(singleKeyDetails?.key.trim() || keyFormDetails.value.trim());
        setIsActivatedUsersLoading(false);
        if (typeof activatedUsers !== 'string') setActivatedUsersDetails(activatedUsers);
    };

    const getSingleKeyDetailsHandler = async (key: string) => {
        if (!key) return;
        const data = multipleKeyDetails?.find((item) => item.key === key);
        setSingleKeyDetails(data);
    };

    const tableBackBtnOnClickHandler = () => {
        setSingleKeyDetails(undefined);
        setActivatedUsersDetails([]);
    };

    const validateDetails = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setActivatedUsersDetails(undefined);
        setSingleKeyDetails(undefined);
        setMultipleKeyDetails(undefined);

        const isFormDetailsValid = keyValidations.searchKeysFormValidation(keyFormDetails.value);
        if (isFormDetailsValid !== '') setKeyFormDetails((prevState) => ({ ...prevState, error: isFormDetailsValid }));
        else determineGetKeyApi();
    };

    useEffect(() => {
        singleKeyDetails?.key && getSingleKeyDetailsHandler(singleKeyDetails.key);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [multipleKeyDetails]);

    useEffect(() => {
        clearTimeout(showNotification.current);
        showNotification.current = setTimeout(() => setShowInstantAlert(false), 2000);
        return () => clearTimeout(showNotification.current);
    }, [showInstantAlert]);

    const tabContent = singleKeyDetails && [
        {
            id: 0,
            label: 'Key Info',
            content: (
                <>
                    <SearchKeysSummary
                        keyDetails={singleKeyDetails}
                        setSingleKeyDetails={setSingleKeyDetails}
                        refreshKeyDetails={determineGetKeyApi}
                        setShowInstantAlert={setShowInstantAlert}
                    />
                    <SearchKeysDetails
                        keyDetails={singleKeyDetails}
                        activatedUsersDetails={activatedUsersDetails}
                        getActivatedUsers={getActivatedUsers}
                        isLoading={isActivatedUsersLoading}
                    />
                </>
            ),
        },
        {
            id: 1,
            label: 'JSON',
            content: <JsonDetails keyDetails={singleKeyDetails} />,
        },
    ];

    return (
        <div className={`${global.componentWrapper} ${styles.searchKeyComponent}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    {showInstantAlert && <InstantAlert type="success" text="Successfully copied" />}
                    <TopBarLayout
                        topBar={
                            <SearchKeysTopBar
                                keyFormDetails={keyFormDetails}
                                inputOnChangeHandler={inputOnChangeHandler}
                                isLoading={isGetKeyLoading}
                                validateDetails={validateDetails}
                            />
                        }
                        content={
                            singleKeyDetails ? (
                                <TabLayout
                                    tabs={tabContent}
                                    backBtnOnClickHandler={multipleKeyDetails && tableBackBtnOnClickHandler}
                                    noOuterPadding={true}
                                />
                            ) : (
                                multipleKeyDetails && (
                                    <ViewEditKeysDetails
                                        keyDetails={multipleKeyDetails}
                                        getSingleKeyDetailsHandler={getSingleKeyDetailsHandler}
                                    />
                                )
                            )
                        }
                    />
                </div>
            </div>
        </div>
    );
};
