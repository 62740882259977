import { useState } from 'react';

import { BtnInfo } from 'theme/mui/buttons.theme';

import global from 'theme/global/Global.module.scss';
import styles from './CurrentClassSummary.module.scss';

import { CommonModal } from 'template/modal/common/CommonModal.template';
import { DataGrid } from 'template/dataGrid/DataGrid.template';
import { JsonViewer } from 'template/jsonViewer/JsonViewer.template';

import { CurrentClassInterface } from 'interfaces/user.interface';

import { CurrentClassCard } from './CurrentClassCard';

import { currentClassSummaryGridData } from 'data/user/userDetails/summary/currentClassSummary.gridData';

interface CurrentClassSummaryInterface {
    liveClasses: CurrentClassInterface;
}

export const CurrentClassSummary = ({ liveClasses }: CurrentClassSummaryInterface) => {
    const currentClassSummary = currentClassSummaryGridData(liveClasses);

    const [isCurrentClassJsonModalOpen, setIsCurrentClassJsonModalOpen] = useState(false);

    return (
        <div className={`${global.moduleWrapper} ${styles.currentClassSummaryModule}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    <CommonModal
                        isModalOpen={isCurrentClassJsonModalOpen}
                        modalCloseHandler={() => setIsCurrentClassJsonModalOpen(false)}
                        modalContent={
                            <div className={`${styles.currentClassJsonViewModalContent}`}>
                                {liveClasses && <JsonViewer jsonData={liveClasses} />}
                            </div>
                        }
                    />

                    <CurrentClassCard liveClasses={liveClasses} />
                    <DataGrid data={currentClassSummary} />

                    <BtnInfo onClick={() => setIsCurrentClassJsonModalOpen(true)} className={`${styles.jsonViewBtn}`}>
                        JSON View
                    </BtnInfo>
                </div>
            </div>
        </div>
    );
};
