import { FormEvent, useEffect, useRef, useState } from 'react';

import global from 'theme/global/Global.module.scss';
import styles from './GetAffiliate.module.scss';

import { TabLayout } from 'template/tabLayout/TabLayout.template';
import { TopBarLayout } from 'template/topBarLayout/TopBarLayout.template';

import { affiliateValidations } from 'helpers/validation/affiliate.validation';
import { affiliateApi } from 'services/api/affiliate.api';

import { AffiliateInterface, GetAffiliateFormInterface } from 'interfaces/affiliate.interface';

import { GetAffiliateTopBar } from './components/getAffiliateTopBar/GetAffiliateTopBar';
import { GetAffiliateSummary } from './components/getAffiliateSummary/GetAffiliateSummary';
import { GetAffiliateDetails } from './components/getAffiliateDetails/GetAffiliateDetails';
import { JsonDetails } from './components/getAffiliateDetails/JsonDetails';
import { InstantAlert } from 'components/common/instantAlert/InstantAlert';

export const GetAffiliate = () => {
    const showNotification = useRef<any>();

    const [isLoading, setIsLoading] = useState(false);
    const [showInstantAlert, setShowInstantAlert] = useState(false);
    const [affiliateDetails, setAffiliateDetails] = useState<AffiliateInterface>();
    const [formDetails, setFormDetails] = useState<GetAffiliateFormInterface>({ value: '' });

    const inputOnChangeHandler = (value: string) => {
        setFormDetails((prevState) => ({ ...prevState, value: value, error: '' }));
    };

    const getAffiliateDetails = async () => {
        const affiliateDetails = await affiliateApi.getAffiliate(formDetails.value.trim());
        setIsLoading(false);

        if (typeof affiliateDetails === 'string') {
            setFormDetails((prevState) => ({ ...prevState, error: affiliateDetails }));
        } else setAffiliateDetails(affiliateDetails);
    };

    const validateDetails = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setIsLoading(true);
        setAffiliateDetails(undefined);

        const isFormDetailsValid = affiliateValidations.searchAffiliateFormValidation(formDetails.value);
        if (isFormDetailsValid !== '') {
            setFormDetails((prevState: GetAffiliateFormInterface) => ({ ...prevState, error: isFormDetailsValid }));
            return setIsLoading(false);
        } else return getAffiliateDetails();
    };

    useEffect(() => {
        clearTimeout(showNotification.current);
        showNotification.current = setTimeout(() => setShowInstantAlert(false), 2000);
        return () => clearTimeout(showNotification.current);
    }, [showInstantAlert]);

    return (
        <div className={`${global.componentWrapper} ${styles.searchAffiliateComponent}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    {showInstantAlert && <InstantAlert type="success" text="Successfully copied" />}
                    <TopBarLayout
                        topBar={
                            <GetAffiliateTopBar
                                formDetails={formDetails}
                                inputOnChangeHandler={inputOnChangeHandler}
                                isLoading={isLoading}
                                validateDetails={validateDetails}
                            />
                        }
                        content={
                            <TabLayout
                                tabs={
                                    affiliateDetails && [
                                        {
                                            id: 0,
                                            label: 'Details',
                                            content: (
                                                <>
                                                    <GetAffiliateSummary affiliateDetails={affiliateDetails} />
                                                    <GetAffiliateDetails
                                                        affiliateDetails={affiliateDetails}
                                                        setShowInstantAlert={setShowInstantAlert}
                                                    />
                                                </>
                                            ),
                                        },
                                        {
                                            id: 1,
                                            label: 'JSON',
                                            content: <JsonDetails affiliateDetails={affiliateDetails} />,
                                        },
                                    ]
                                }
                                noOuterPadding={true}
                            />
                        }
                    />
                </div>
            </div>
        </div>
    );
};
