import global from 'theme/global/Global.module.scss';
import styles from './UserDetailsModules.module.scss';

interface AnalyticsInterface {
    userEmail: string;
}

export const Analytics = ({ userEmail }: AnalyticsInterface) => {
    const urlEncodedEmail = userEmail?.replace('@', '%40');
    let iframeUrl = `https://analytics.zoho.com/open-view/2146714000009652498?ZOHO_CRITERIA="users"."email"%3D'${urlEncodedEmail}'`;

    return (
        <div className={`${global.moduleWrapper} ${styles.userAnalyticsModule}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content} ${styles.analyticsContent}`}>
                    <iframe src={iframeUrl} title="Zoho Analytics" className={`${styles.iframe}`} />
                </div>
            </div>
        </div>
    );
};
