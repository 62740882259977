export const fileUtils = {
    isValidExcelOrCsv: (fileInfo: any) => {
        let fileExtension = fileInfo.name.split('.').pop().toLowerCase();
        if (fileExtension === 'xlsx' || fileExtension === 'csv' || fileExtension === 'xls') return true;
        return false;
    },

    isValidImage: (fileInfo: any) => {
        let fileExtension = fileInfo.name.split('.').pop().toLowerCase();
        if (fileExtension === 'png' || fileExtension === 'jpg' || fileExtension === 'jpeg') return true;
        return false;
    },
};
