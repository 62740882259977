import global from 'theme/global/Global.module.scss';
import styles from './SearchKeysDetails.module.scss';

import { JsonViewer } from 'template/jsonViewer/JsonViewer.template';
import { KeyInterface } from 'interfaces/key.interface';

export const JsonDetails = ({ keyDetails }: { keyDetails: KeyInterface }) => {
    return (
        <div className={`${global.moduleWrapper} ${styles.jsonDetailsModule}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    <JsonViewer jsonData={keyDetails} />
                </div>
            </div>
        </div>
    );
};
