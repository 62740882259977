import { FormEvent, useEffect, useRef, useState } from 'react';

import global from 'theme/global/Global.module.scss';
import styles from './GetAllAffiliates.module.scss';

import { TabLayout } from 'template/tabLayout/TabLayout.template';
import { TopBarLayout } from 'template/topBarLayout/TopBarLayout.template';

import { affiliateApi } from 'services/api/affiliate.api';

import { AffiliateFilterDataInterface, AffiliateInterface } from 'interfaces/affiliate.interface';

import { GetAffiliateDetails } from '../getAffiliate/components/getAffiliateDetails/GetAffiliateDetails';
import { GetAllAffiliatesDetails } from './components/getAllAffiliatesDetails/GetAllAffiliatesDetails';
import { GetAffiliateSummary } from '../getAffiliate/components/getAffiliateSummary/GetAffiliateSummary';
import { JsonDetails } from '../getAffiliate/components/getAffiliateDetails/JsonDetails';
import { InstantAlert } from 'components/common/instantAlert/InstantAlert';
import { CustomError } from 'components/common/error/comingSoon/CustomError';
import { Loading } from 'components/common/loading/Loading';
import { GetAllAffiliatesTopBar } from './components/getAllAffiliatesTopBar/GetAllAffiliatesTopBar';

export const GetAllAffiliates = () => {
    const showNotification = useRef<any>();

    const [isLoading, setIsLoading] = useState(false);
    const [isSingleAffiliateLoading, setIsSingleAffiliateLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showInstantAlert, setShowInstantAlert] = useState(false);
    const [singleAffiliateDetails, setSingleAffiliateDetails] = useState<AffiliateInterface | undefined>();
    const [allAffiliatesDetails, setAllAffiliatesDetails] = useState<AffiliateInterface[]>();
    const [filteredCctInfo, setFilteredCctInfo] = useState<AffiliateInterface[]>();
    const [filterDetails, setFilterDetails] = useState<AffiliateFilterDataInterface>({});

    const filterInputOnChangeHandler = (value: string, fieldName?: string) => {
        if (!fieldName) return;
        setFilterDetails((prevState) => ({ ...prevState, [fieldName]: value }));
    };

    const resetFilterHandler = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setFilterDetails({});
        setFilteredCctInfo(allAffiliatesDetails);
    };

    const filterDataHandler = () => {
        const search = filterDetails.search?.toLowerCase().trim();
        const countryCode = filterDetails.countryCode?.toLowerCase().trim();

        const filterDetailsKeys = Object.keys(filterDetails);
        let finalData = allAffiliatesDetails;

        if (finalData) {
            for (let keyValue of filterDetailsKeys) {
                if (keyValue === 'search' && search) {
                    finalData = finalData.filter((data) => {
                        const affiliateProps = ['name', 'email'];

                        for (let prop of affiliateProps) {
                            const value = data[prop as keyof typeof data]?.toString().toLowerCase().trim();
                            if (value && value.includes(search)) return data;
                        }
                        return null;
                    });
                }
                if (keyValue === 'countryCode' && countryCode) {
                    finalData = finalData.filter(
                        (data) => data.countryCode && data.countryCode.toLowerCase().trim().includes(countryCode),
                    );
                }
            }

            setFilteredCctInfo(finalData);
        }
    };

    const getAllAffiliates = async () => {
        setIsLoading(true);
        const userDetails = await affiliateApi.getAllAffiliates();
        if (typeof userDetails === 'string') setErrorMessage(userDetails);
        else {
            setAllAffiliatesDetails(userDetails);
            setFilteredCctInfo(userDetails);
        }
        setIsLoading(false);
    };

    const getSingleAffiliateHandler = async (email: string) => {
        if (!email) return;

        setIsSingleAffiliateLoading(true);
        const affiliateDetails = await affiliateApi.getAffiliate(email.trim());
        setIsSingleAffiliateLoading(false);

        if (typeof affiliateDetails === 'string') setErrorMessage(affiliateDetails);
        else setSingleAffiliateDetails(affiliateDetails);
    };

    const tableBackBtnOnClickHandler = () => setSingleAffiliateDetails(undefined);

    useEffect(() => {
        clearTimeout(showNotification.current);
        showNotification.current = setTimeout(() => setShowInstantAlert(false), 2000);
        return () => clearTimeout(showNotification.current);
    }, [showInstantAlert]);

    useEffect(() => {
        filterDataHandler();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterDetails]);

    useEffect(() => {
        getAllAffiliates();
    }, []);

    const tabContent = singleAffiliateDetails && [
        {
            id: 0,
            label: 'Details',
            content: (
                <>
                    <GetAffiliateSummary affiliateDetails={singleAffiliateDetails} />
                    <GetAffiliateDetails
                        affiliateDetails={singleAffiliateDetails}
                        setShowInstantAlert={setShowInstantAlert}
                    />
                </>
            ),
        },
        {
            id: 1,
            label: 'JSON',
            content: <JsonDetails affiliateDetails={singleAffiliateDetails} />,
        },
    ];

    return (
        <div className={`${global.componentWrapper} ${styles.getAllAffiliateComponent}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    {showInstantAlert && <InstantAlert type="success" text="Successfully copied" />}
                    {isLoading && <Loading />}
                    {errorMessage && <CustomError message={errorMessage} />}

                    {!isLoading &&
                        (!singleAffiliateDetails ? (
                            <TopBarLayout
                                topBar={
                                    <GetAllAffiliatesTopBar
                                        isLoading={isLoading}
                                        filterDetails={filterDetails}
                                        filterInputOnChangeHandler={filterInputOnChangeHandler}
                                        resetFilterHandler={resetFilterHandler}
                                    />
                                }
                                content={
                                    <div className={`${styles.moduleContainer}`}>
                                        {filteredCctInfo && (
                                            <GetAllAffiliatesDetails
                                                allAffiliatesDetails={filteredCctInfo}
                                                getSingleAffiliateHandler={getSingleAffiliateHandler}
                                                isSingleAffiliateLoading={isSingleAffiliateLoading}
                                            />
                                        )}
                                    </div>
                                }
                            />
                        ) : (
                            <TabLayout tabs={tabContent} backBtnOnClickHandler={tableBackBtnOnClickHandler} />
                        ))}
                </div>
            </div>
        </div>
    );
};
