import { FormEvent, useEffect, useState } from 'react';

import global from 'theme/global/Global.module.scss';
import styles from './UserDetails.module.scss';

import { TabLayout } from 'template/tabLayout/TabLayout.template';
import { CommonModal } from 'template/modal/common/CommonModal.template';

import { userValidations } from 'helpers/validation/user.validation';
import { urlUtils } from 'helpers/utilities/url.utils';
// import { classPointCertifiedHelper } from 'helpers/classPointCertified.helper';
import { userApi } from 'services/api/user.api';
import { classPointCertifiedApi } from 'services/api/classPointCertified.api';

import { UserInterface, CurrentClassInterface } from 'interfaces/user.interface';
import { CctInterface } from 'interfaces/classPointCertified.interface';
import { TopBarSimpleFormInterface } from 'interfaces/ui/topBar.interface';

import { UserTopBar } from './components/userTopBar/UserTopBar';
import { UserSummary } from './components/userSummary/UserSummary';
import { PersonalDetails } from './components/userDetailsModules/PersonalDetails';
import { SubscriptionDetails } from './components/userDetailsModules/SubscriptionDetails';
import { SavedClassesDetails } from './components/userDetailsModules/SavedClassesDetails';
import { Analytics } from './components/userDetailsModules/Analytics';
import { UsageLogs } from './components/userDetailsModules/UsageLogs';
import { DebugLogs } from './components/userDetailsModules/DebugLogs';
import { CurrentClassDetails } from './components/userDetailsModules/CurrentClassDetails';
import { CurrentClassSummary } from './components/currentClassSummary/CurrentClassSummary';
import { JsonDetails } from './components/userDetailsModules/JsonDetails';
import { JsonViewer } from 'template/jsonViewer/JsonViewer.template';

interface UserDetailsInterface {
    singleUserDetailsEmail?: string;
    singleUserDetailsUserId?: string;
    tableBackBtnOnClickHandler?: () => void;
    singleUserDetailsErrorHandler?: (error: string) => void;
}

export const UserDetails = ({
    singleUserDetailsEmail,
    singleUserDetailsUserId,
    tableBackBtnOnClickHandler,
    singleUserDetailsErrorHandler,
}: UserDetailsInterface) => {
    const [user, setUser] = useState<UserInterface>();
    const [cct, setCct] = useState<CctInterface>();
    const [isLoading, setIsLoading] = useState(false);
    const [liveClasses, setLiveClasses] = useState<CurrentClassInterface>();
    const [liveClassesError, setLiveClassesError] = useState('Loading data...');
    const [deleteUserResponse, setDeleteUserResponse] = useState<any>();
    const [changeEmailResponse, setChangeEmailResponse] = useState<any>();
    const [clearUserLocationResponse, setClearUserLocationResponse] = useState<any>();
    const [userFormDetails, setUserFormDetails] = useState<TopBarSimpleFormInterface>({
        value: '',
        error: '',
    });

    const inputOnChangeHandler = (value: string) => {
        setUserFormDetails((prevState) => ({ ...prevState, value: value.trim().toLowerCase(), error: '' }));
    };

    const getUser = async (value = userFormDetails.value, type: 'email' | 'userId' = 'email') => {
        setUser(undefined);
        setCct(undefined);
        const userDetails = await userApi.getUser(value, type);
        setIsLoading(false);

        if (typeof userDetails === 'string') {
            setUserFormDetails((prevState) => ({ ...prevState, error: userDetails }));

            if (singleUserDetailsErrorHandler) {
                singleUserDetailsErrorHandler(userDetails);
                tableBackBtnOnClickHandler && tableBackBtnOnClickHandler();
            }
        } else setUser(userDetails);
    };

    const getCct = async () => {
        if (!user?.email) return;
        const cctDetails = await classPointCertifiedApi.getCctInfoByEmailOrId(user.email, 'email');
        setIsLoading(false);

        if (typeof cctDetails === 'string') {
            setUserFormDetails((prevState) => ({ ...prevState, error: cctDetails }));
        } else setCct(cctDetails);
    };

    const validateDetails = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsLoading(true);

        const isFormDetailsValid = userValidations.getUserDetailsFormValidation(userFormDetails.value);
        if (isFormDetailsValid !== '') {
            setUserFormDetails((prevState) => ({ ...prevState, error: isFormDetailsValid }));
            return setIsLoading(false);
        } else return getUser();
    };

    const getCurrentClassDetails = async () => {
        setLiveClasses(undefined);
        setIsLoading(true);
        setLiveClassesError('');

        if (user?.userClassSession) {
            const hubBaseUrl = urlUtils.generateHubBaseUrl(user.userClassSession.cpcsRegion);
            const currentClasses = await userApi.liveClasses(
                hubBaseUrl,
                singleUserDetailsEmail?.trim() || user.email.trim(),
            );

            if (typeof currentClasses !== 'string') setLiveClasses(currentClasses);
            else setLiveClassesError('No live class');
        } else setLiveClassesError('No live class');

        return setIsLoading(false);
    };

    const refreshUserDetails = (email?: string) => {
        if (email) {
            setUserFormDetails((prevState) => ({ ...prevState, value: email }));
            return getUser(email);
        }
        if (singleUserDetailsUserId) return getUser(singleUserDetailsUserId, 'userId');
        else if (singleUserDetailsEmail) return getUser(singleUserDetailsEmail);
        else if (userFormDetails.value) return getUser();
    };

    const deleteUserAlertHandler = (response: any) => {
        setUser(undefined);
        setDeleteUserResponse(response);
    };

    const changeEmailAlertHandler = (response: any, newEmail: string) => {
        setClearUserLocationResponse(undefined);
        setChangeEmailResponse(response);
        refreshUserDetails(newEmail);
    };

    const clearUserLocationAlertHandler = (response: any) => {
        setDeleteUserResponse(undefined);
        setClearUserLocationResponse(response);
        refreshUserDetails();
    };

    useEffect(() => {
        // const isCCT = classPointCertifiedHelper.getCertificationStatus(user?.userProfile.cctExpiryDate);
        user?.userProfile.cctExpiryDate && getCct();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        singleUserDetailsEmail
            ? getUser(singleUserDetailsEmail)
            : singleUserDetailsUserId && getUser(singleUserDetailsUserId, 'userId');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const tabContent = user && [
        {
            id: 0,
            label: 'Summary',
            content: (
                <>
                    <UserSummary
                        getUser={getUser}
                        user={user}
                        cct={cct}
                        getCct={getCct}
                        refreshUserDetails={refreshUserDetails}
                        deleteUserAlertHandler={deleteUserAlertHandler}
                        changeEmailAlertHandler={changeEmailAlertHandler}
                        clearUserLocationAlertHandler={clearUserLocationAlertHandler}
                    />
                    <PersonalDetails user={user} cct={cct} />
                </>
            ),
        },
        {
            id: 1,
            label: 'Subscription',
            content: (
                <>
                    <UserSummary
                        getUser={getUser}
                        user={user}
                        cct={cct}
                        getCct={getCct}
                        refreshUserDetails={refreshUserDetails}
                        deleteUserAlertHandler={deleteUserAlertHandler}
                        changeEmailAlertHandler={changeEmailAlertHandler}
                        clearUserLocationAlertHandler={clearUserLocationAlertHandler}
                    />
                    <SubscriptionDetails user={user} />
                </>
            ),
        },
        {
            id: 2,
            label: 'Saved Classes',
            content: (
                <>
                    <UserSummary
                        getUser={getUser}
                        user={user}
                        cct={cct}
                        getCct={getCct}
                        refreshUserDetails={refreshUserDetails}
                        deleteUserAlertHandler={deleteUserAlertHandler}
                        changeEmailAlertHandler={changeEmailAlertHandler}
                        clearUserLocationAlertHandler={clearUserLocationAlertHandler}
                    />
                    <SavedClassesDetails user={user} />
                </>
            ),
        },
        {
            id: 3,
            label: 'Analytics',
            content: <Analytics userEmail={user.email} />,
        },
        {
            id: 4,
            label: 'Usage Logs',
            content: <UsageLogs userEmail={user.email} />,
        },
        {
            id: 5,
            label: 'Debug Logs',
            content: <DebugLogs user={user} />,
        },
        {
            id: 6,
            label: 'Current Class',
            content: liveClasses && (
                <>
                    <CurrentClassSummary liveClasses={liveClasses} />
                    <CurrentClassDetails
                        isLoading={isLoading}
                        liveClasses={liveClasses}
                        liveClassesError={liveClassesError}
                        getCurrentClassDetails={getCurrentClassDetails}
                    />
                </>
            ),
        },
        {
            id: 7,
            label: 'JSON',
            content: <JsonDetails user={user} />,
        },
    ];

    return (
        <div className={`${global.componentWrapper} ${styles.userComponent}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    <TabLayout
                        topBar={
                            !singleUserDetailsEmail &&
                            !singleUserDetailsUserId && (
                                <UserTopBar
                                    isLoading={isLoading}
                                    userFormDetails={userFormDetails}
                                    inputOnChangeHandler={inputOnChangeHandler}
                                    validateDetails={validateDetails}
                                />
                            )
                        }
                        tabs={tabContent}
                        backBtnOnClickHandler={
                            (singleUserDetailsEmail || singleUserDetailsUserId) && tableBackBtnOnClickHandler
                        }
                    />

                    <CommonModal
                        isModalOpen={!!deleteUserResponse || !!changeEmailResponse}
                        modalCloseHandler={() =>
                            deleteUserResponse ? setDeleteUserResponse(undefined) : setChangeEmailResponse(undefined)
                        }
                        modalContent={
                            <JsonViewer
                                jsonData={deleteUserResponse || { response: changeEmailResponse } || {}}
                                isCopyDisabled={true}
                                className={styles.deleteResponse}
                            />
                        }
                        className={styles.notificationModal}
                    />

                    <CommonModal
                        isModalOpen={!!clearUserLocationResponse}
                        modalCloseHandler={() => setClearUserLocationResponse(undefined)}
                        modalContent={<div className={`${styles.modalNotification}`}>{clearUserLocationResponse}</div>}
                        className={styles.notificationModal}
                    />
                </div>
            </div>
        </div>
    );
};
