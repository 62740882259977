import styles from './RenewCctModalContent.module.scss';

import { InputFieldsInterface } from 'interfaces/ui/inputFields.interface';

export const renewCctModalInputFieldsData: InputFieldsInterface[] = [
    {
        fieldType: 'radioBoolean',
        fieldName: 'notifyUser',
        label: 'Should the user be notified?',
        fieldClassName: `${styles.inputField}`,
    },
    {
        fieldType: 'date',
        fieldName: 'renewCctExpiryDate',
        minDateTime: new Date().toISOString(),
        fieldClassName: `${styles.inputField}`,
    },
];
