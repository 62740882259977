import { FormEvent, useEffect, useRef, useState } from 'react';

import global from 'theme/global/Global.module.scss';
import styles from './Cct.module.scss';

import { TopBarLayout } from 'template/topBarLayout/TopBarLayout.template';
// import { CommonModal } from 'template/modal/common/CommonModal.template';
// import { MultipleSearchBox } from 'template/searchBox/multiple/MultipleSearchBox.template';
// import { InputFields } from 'template/inputFields/InputFields.template';

// import { fileUtils } from 'helpers/utilities/file.util';
// import { miscHelper } from 'helpers/misc.helper';
// import { classPointCertifiedValidations } from 'helpers/validation/classPointCertified.validation';
import { classPointCertifiedHelper } from 'helpers/classPointCertified.helper';
import { classPointCertifiedApi } from 'services/api/classPointCertified.api';

import {
    CctCsvUpdateReportInterface,
    CctFilterDataInterface,
    NewCctApiResponseInterface,
    // NewCctInterface,
} from 'interfaces/classPointCertified.interface';
// import { CsvUploadFileInputInterface } from 'interfaces/common.interface';

import { CctDetails } from './components/cctDetails/CctDetails';
// import { CctUploadTopBar } from './components/cctUploadTopBar/CctUploadTopBar';
import { CctFilterTopBar } from './components/cctFilterTopBar/CctFilterTopBar';
import { InstantAlert } from 'components/common/instantAlert/InstantAlert';
import { UserDetails } from 'components/home/user/userDetails/UserDetails';

export const Cct = () => {
    const showNotification = useRef<any>();
    const [isLoading, setIsLoading] = useState(false);

    const [error, setError] = useState('');
    const [showInstantAlert, setShowInstantAlert] = useState(false);
    // const [isCsvModalOpen, setIsCsvModalOpen] = useState(false);
    // const [csvFileDetails, setCsvFileDetails] = useState<CsvUploadFileInputInterface>({});
    // const [csvFileEncodedData, setCsvFileEncodedData] = useState<NewCctInterface[]>([]);
    const [singleUserDetailsById, setSingleUserDetailsById] = useState<string | undefined>();
    const [singleUserDetailsError, setSingleUserDetailsError] = useState('');
    const [allCctInfo, setAllCctInfo] = useState<NewCctApiResponseInterface[]>();
    const [filteredCctInfo, setFilteredCctInfo] = useState<NewCctApiResponseInterface[]>();
    const [filterDetails, setFilterDetails] = useState<CctFilterDataInterface>({});
    // eslint-disable-next-line  @typescript-eslint/no-unused-vars
    const [csvUpdateReport, setCsvUpdateReport] = useState<CctCsvUpdateReportInterface>({
        successEmails: [],
        failedEmails: [],
        invalidEmails: [],
        invalidDates: [],
    });

    const filterInputOnChangeHandler = (value: string, fieldName?: string) => {
        if (!fieldName) return;
        setFilterDetails((prevState) => ({ ...prevState, [fieldName]: value }));
    };

    const filterMultipleSelectOnChangeHandler = (value: string[], fieldName?: string) => {
        if (!fieldName) return;
        setFilterDetails((prevState) => ({ ...prevState, [fieldName]: value }));
    };

    // const fileInputOnChangeHandler = (fileInfo: any) => {
    //     if (!fileInfo) return;
    //     const isValidCsvFile = fileUtils.isValidExcelOrCsv(fileInfo);
    //     setCsvFileDetails({
    //         file: isValidCsvFile ? fileInfo : undefined,
    //         error: isValidCsvFile ? '' : 'Error : Please select a valid CSV file',
    //     });
    // };

    // const removeFileSelectionHandler = () => {
    //     setCsvFileDetails({ file: undefined, error: '' });
    // };

    // const csvFileHandler = (e: FormEvent<HTMLFormElement>) => {
    //     e.preventDefault();
    //     setIsCsvModalOpen(false);
    //     miscHelper.readCsvFileForCct(csvFileDetails.file, setCsvFileDetails, setCsvFileEncodedData);
    // };

    const getCctInfo = async () => {
        setIsLoading(true);
        setAllCctInfo(undefined);
        const allCctData = await classPointCertifiedApi.getAllCctInfo();
        setIsLoading(false);

        if (typeof allCctData === 'string') return setError(allCctData);
        setAllCctInfo(allCctData);
        return setFilteredCctInfo(allCctData);
    };

    const filterDataHandler = () => {
        setError('');

        const search = filterDetails.search?.toLowerCase().trim();
        const country = filterDetails.country?.toLowerCase().trim();
        const status = filterDetails.status;

        const filterDetailsKeys = Object.keys(filterDetails);
        let finalData = allCctInfo;

        if (finalData) {
            for (let keyValue of filterDetailsKeys) {
                if (keyValue === 'search' && search) {
                    finalData = finalData.filter((data) => {
                        const cctProps = ['displayName', 'started', 'titleExpiryDate', 'country', 'jobTitle'];

                        for (let prop of cctProps) {
                            const value = data[prop as keyof typeof data]?.toString().toLowerCase().trim();
                            if (value && value.includes(search)) return data;
                        }

                        const status = classPointCertifiedHelper
                            .statusGenerator(data.started, data.titleExpiryDate)
                            .toLowerCase();
                        if (status.includes(search)) return data;
                        return null;
                    });
                }
                if (keyValue === 'country' && country) {
                    finalData = finalData.filter(
                        (data) => data.country && data.country.toLowerCase().trim().includes(country),
                    );
                }
                if (keyValue === 'status' && status && status.length > 0) {
                    const statusString = status.toString().toLowerCase().trim();

                    finalData = finalData.filter((data) => {
                        const cctStatus = classPointCertifiedHelper
                            .statusGenerator(data.started, data.titleExpiryDate)
                            .toLowerCase();
                        if (statusString.includes(cctStatus)) return data;
                        return null;
                    });
                }
            }

            setFilteredCctInfo(finalData);
            finalData.length < 1 && setError('No match found');
        }
    };

    // const updateCct = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    //     event.preventDefault();
    //     setIsLoading(true);

    //     const getValidDataArray = classPointCertifiedValidations.cctInfoDataValidation(csvFileEncodedData);

    //     if (getValidDataArray[1].length > 0) {
    //         setCsvUpdateReport((prevState) => ({ ...prevState, invalidEmails: getValidDataArray[1] }));
    //     }
    //     if (getValidDataArray[2].length > 0) {
    //         setCsvUpdateReport((prevState) => ({ ...prevState, invalidDates: getValidDataArray[1] }));
    //     }

    //     for (let i = 0; i < getValidDataArray[0].length; i++) {
    //         const email = getValidDataArray[0][i].email;
    //         const cctResponse = await classPointCertifiedApi.postCctInfo(getValidDataArray[0][i]);

    //         if (typeof cctResponse === 'string' && cctResponse.includes('Error')) {
    //             setCsvUpdateReport((prevState) => ({
    //                 ...prevState,
    //                 failedEmails: [...prevState.failedEmails, email],
    //             }));
    //         } else {
    //             setCsvUpdateReport((prevState) => ({
    //                 ...prevState,
    //                 successEmails: [...prevState.successEmails, email],
    //             }));
    //         }
    //     }

    //     getCctInfo();
    //     setIsLoading(false);
    // };

    const getSingleUserDetailsHandler = async (userId: string) => setSingleUserDetailsById(userId);

    const singleUserDetailsErrorHandler = (error: string) => setSingleUserDetailsError(error);

    const tableBackBtnOnClickHandler = () => setSingleUserDetailsById(undefined);

    // const uploadCsvModalCloseHandler = () => {
    //     setIsCsvModalOpen(false);
    //     setCsvFileDetails({});
    // };

    const resetFilterHandler = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setFilterDetails({});
        setFilteredCctInfo(allCctInfo);
    };

    useEffect(() => {
        filterDataHandler();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterDetails]);

    useEffect(() => {
        clearTimeout(showNotification.current);
        showNotification.current = setTimeout(() => {
            showInstantAlert && setShowInstantAlert(false);
            singleUserDetailsError && setSingleUserDetailsError('');
            // ! Note : Only clear the setError if Topbar is hidden
            setError('');
        }, 2000);
        return () => clearTimeout(showNotification.current);
    }, [showInstantAlert, singleUserDetailsError]);

    useEffect(() => {
        getCctInfo();
    }, []);

    return singleUserDetailsById ? (
        <UserDetails
            singleUserDetailsUserId={singleUserDetailsById}
            tableBackBtnOnClickHandler={tableBackBtnOnClickHandler}
            singleUserDetailsErrorHandler={singleUserDetailsErrorHandler}
        />
    ) : (
        <div className={`${global.componentWrapper} ${styles.cctComponent}`}>
            <div className={`${global.contentWrapper} ${styles.contentWrapper}`}>
                <div className={`${styles.content}`}>
                    {/* //! Note : Default InstantAlert if Topbar is shown */}
                    {/* {(showInstantAlert || singleUserDetailsError) && (
                        <InstantAlert
                            type={singleUserDetailsError ? 'error' : 'success'}
                            text={singleUserDetailsError ? singleUserDetailsError : 'Successfully copied'}
                        />
                    )} */}

                    {/* //! Note : Modified InstantAlert if Topbar is hidden */}
                    {(showInstantAlert || singleUserDetailsError || error) && (
                        <InstantAlert
                            type={singleUserDetailsError || error ? 'error' : 'success'}
                            text={
                                singleUserDetailsError || error
                                    ? singleUserDetailsError || error
                                    : 'Successfully copied'
                            }
                        />
                    )}

                    {/* <CommonModal
                        isModalOpen={isCsvModalOpen}
                        modalCloseHandler={() => uploadCsvModalCloseHandler()}
                        modalContent={
                            <form onSubmit={(e) => csvFileHandler(e)} className={`${styles.csvInputModal}`}>
                                <MultipleSearchBox
                                    isLoading={isLoading}
                                    details={csvFileDetails}
                                    submitBtnText="Upload file"
                                    inputContent={
                                        <div className={`${styles.inputFieldContainer}`}>
                                            <InputFields
                                                inputFields={[
                                                    {
                                                        fieldType: 'file',
                                                        fieldName: 'file',
                                                        label: 'Upload CSV file',
                                                        error: csvFileDetails.error ? true : false,
                                                        removeSelection: () => removeFileSelectionHandler(),
                                                        fieldClassName: `${styles.csvInputField}`,
                                                    },
                                                ]}
                                                inputDetails={csvFileDetails}
                                                fileInputOnChangeHandler={fileInputOnChangeHandler}
                                            />
                                        </div>
                                    }
                                />
                            </form>
                        }
                    /> */}

                    <TopBarLayout
                        topBar={
                            //! Note : Upload topbar is hidden because update feature is not required for now. If it is enabled then the InstantAlert component should also change.
                            // <CctUploadTopBar
                            //     isLoading={isLoading}
                            //     updateCct={updateCct}
                            //     setIsCsvModalOpen={setIsCsvModalOpen}
                            //     error={error}
                            // />

                            <CctFilterTopBar
                                isLoading={isLoading}
                                filterDetails={filterDetails}
                                filterInputOnChangeHandler={filterInputOnChangeHandler}
                                filterMultipleSelectOnChangeHandler={filterMultipleSelectOnChangeHandler}
                                resetFilterHandler={resetFilterHandler}
                            />
                        }
                        content={
                            <div className={`${styles.cctDetails}`}>
                                <CctDetails
                                    cctInfo={filteredCctInfo}
                                    getSingleUserDetailsHandler={getSingleUserDetailsHandler}
                                    csvUpdateReport={csvUpdateReport}
                                />
                            </div>
                        }
                    />
                </div>
            </div>
        </div>
    );
};
